import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';


import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useUser } from 'contexts/user';
import Table from 'components/Table';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';

import Detail from 'components/Detail';
import Loading from 'components/Loading';
import NotFound from 'components/NotFound';

const useStyles = makeStyles((theme) => createStyles({
    theader: {
        borderBottom: '0.1rem solid rgba(255, 255, 255, 0.12)',
        padding: '2rem',
    },
    td: {
        padding: '2rem',
        '& + &': {
            borderLeft: '0.1rem solid rgba(255, 255, 255, 0.12)',
        },
    },
    headerTD: {
        padding: '1rem',
    },
    tds: {
        padding: '0.5rem',
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
        width: 'auto',
        textAlign: 'baseline',
        verticalAlign: 'baseline',
    },
    table: {
        marginTop: '2rem',
        marginBottom: '2rem',
        paddingTop: '1rem',
        width: '98%',
        border: '0.1rem solid rgba(255, 255, 255, 0.12)',
    },
    headerTable: {
        marginTop: '2rem',
        marginBottom: '2rem',
        width: '98%',
        border: '0.1rem solid rgba(255, 255, 255, 0.12)',
    },
    StyledFormattedMessage: {
        color: theme.palette.primary.main,
    },
    StyledLoading: { // loading
        width: '100%',
        height: '100%',
    },
}));


function QualityControlReport() {
    const { api } = useUser();
    const classes = useStyles();
    const { client, detailId:qcId } = useParams();
    const [QCDetails, setQCDetails] = React.useState({});
    const [fetchingQCDetails, setFetchingQCDetails] = React.useState({});
    const {
        qcHeader, qc, qcComment, qcFormatage,
        qcAudio, qcFormatageSuite, qcFooter,
        qcFooterLegend,
    } = QCDetails;
    async function fetchQC(abortController = new AbortController()) {
        if (client && qcId) {
            try {
                setFetchingQCDetails(true);
                const response = await api.get(`${client}/qc/${qcId}`, {}, { signal: abortController.signal });
                if (response.ok) {
                    const data = await response.json();
                    setQCDetails(data);
                } else {
                    setQCDetails({});
                }
                setFetchingQCDetails(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchQC(abortController);
        return () => {
            abortController.abort();
        };
    }, [client, qcId]);

    let headers = []
    let header = []
    let generalInfo = []
    let image = []
    let image2 = []

    const styleGrid = {
        // Label and Value side by side instead of one above the other.
        display: 'flex',
        justifyContent: 'space-between',
    };
    const commonProps = {
        isUnique: 1, hasLocale: 1, languageId: 'en', valueType: 'text', sm: 8, md: 8, lg: 8, xl: 8, style: styleGrid, readOnly: true
    };

    if (qcHeader) {
        headers = { ...qcHeader, ['dateQc']: new Date(qcHeader.dateQc).toISOString().slice(0, 10) };
        header = [
            'client', 'titreSerie', 'titrePgm',
        ].map((key) => (headers[key]));
        generalInfo = [
            'duree', 'stdSource', 'formatRecord', 'magPlay', 'origineSource', 'stdRecord', 'formatSource', 'magRecord', 'timecode', 'vitc',
        ].map((key) => ({ key, value: qc[key], ...commonProps }));

        image = [
            'imgRatio', 'imgVersionLang', 'imgTitleLang', 'imgOpenCreditLang', 'imgEndCreditLang', 'imgStLang', 'imgSupersStcLang', 'imgStcLang',
        ].map((key) => ({ key, value: qc[key], ...commonProps }));
        image2 = [
            'imgNoirs', 'imgVideo', 'imgChroma', 'imgHorizontal', 'imgVertical',
        ].map((key) => ({ key, value: qc[key], ...commonProps }));
    }
    let displayAsset = null;
    if (isEmpty(QCDetails) && fetchingQCDetails) {
        displayAsset = <Loading />;
    } else if (isEmpty(QCDetails) && !fetchingQCDetails) {
        displayAsset = <NotFound />;
    } else {

        displayAsset = (
            <div>
                <h3>
                    <FormattedMessage id="TECH_SPECS" /> {`# ${headers.idQc}`}
                </h3>
                <h3>{headers.qcType}</h3>
                <table className={classes.headerTable}>
                    <td className={classes.headerTD}>
                        {headers.client}
                        <br/>
                        {[headers.titreSerie, headers.titrePgm].filter((x)=>x).join(' - ')}
                    </td>
                    <td className={classes.headerTD} style={{textAlign: 'end', verticalAlign: 'bottom'}}>
                        {headers.dateQc}
                    </td>
                </table>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <td className={classes.tds}>
                                <FormattedMessage id="GENERAL_INFO" />
                            </td>
                            <td className={classes.tds} style={{ width: '30%' }}>
                                <FormattedMessage id="FORMATTING" />
                            </td>
                            <td className={classes.tds}>
                                <FormattedMessage id="IMAGE" />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <td className={classes.tds}>
                            <Details
                                data={generalInfo}
                            />
                        </td>
                        <td className={classes.tds}>
                            <Details
                                data={qcFormatage.map(({description, timecode}) => ({label: description, key: description, value: timecode, ...commonProps}))}
                            />
                        </td>
                        <td className={classes.tds}>
                            <Details
                                data={image}
                            />
                            <p />
                            <Details
                                data={image2}
                            />
                        </td>
                    </tbody>
                </table>
                <table className={classes.table}>
                    <Table
                        rows={qcAudio}
                        columns={[
                            {
                                field: 'assignationPistes',
                                title: 'AUDIO_TRACK',
                            },
                            {
                                field: 'contenuAudio',
                                title: '',
                            },
                            {
                                field: 'typeAudio',
                                title: '',
                            },
                            {
                                field: 'r128',
                                title: 'R-128',
                            },
                            {
                                field: 'a85',
                                title: 'A/85',
                            },
                            {
                                field: 'loundness',
                                title: 'LOUDNESS',
                            },
                            {
                                field: 'lra',
                                title: 'LRA',
                            },
                            {
                                field: 'truePeak',
                                title: 'TRUE_PEAK',
                            },
                            {
                                field: 'dialNorm',
                                title: 'NORMAL_DIALOG',
                            },
                        ]}
                        uniqueField={['assignationPistes']}
                        isSortable
                        pagenate={false}


                    />
                </table>
                <table className={classes.table}>
                    <Table
                        rows={qcComment}
                        columns={[
                            {
                                field: 'tcIn',
                                title: 'IN',
                            },
                            {
                                field: 'tcOut',
                                title: 'OUT',
                            },
                            {
                                field: 'commentaire',
                                title: 'COMMENT',
                            },
                            {
                                field: 'nature',
                                title: 'NATURE',
                            },
                            {
                                field: 'coteCcir',
                                title: 'ITU',
                            },
                        ]}
                        uniqueField={['idCommentaire']}
                        pagenate={false}
                    />
                </table>
                {isEmpty(qcFormatageSuite) ? null : (
                    <table className={classes.table}>
                        <thead className={classes.theader}>
                            <td className={classes.tds}>
                                <FormattedMessage id="FORMATTING_CONTINUED" />
                            </td>
                        </thead>
                        <Table
                            rows={qcFormatageSuite}
                            columns={[
                                {
                                    field: 'contenuQc',
                                },
                                {
                                    field: 'timecodeImprime',
                                },
                            ]}
                            uniqueField={['contenuQc']}
                            pagenate={false}


                        />
                    </table>
                )}
                <table className={classes.table}>
                    <td className={classes.headerTD}>
                        {qcFooter}
                        <br />
                        <br />
                        {qcFooterLegend}
                    </td>

                </table>

            </div>
        );
    }

    return displayAsset;
}

function Details(props) {
    const classes = useStyles();
    const {
        data, loading, readOnly,
        onChange, options, viewedLanguage,
    } = props;

    let details = null;
    if (isEmpty(data) && loading) {
        details = <Loading className={classes.StyledLoading} />;
    } else if (isEmpty(data) && !loading) {
        details = <NotFound />;
    } else {
        details = (
            <Grid container spacing={2} alignItems="flex-start">
                {
                    data.map((d) => {
                        const {
                            key, value, valueType,
                            isUnique, hasLocale, sm,
                            md, lg, xl, label,
                            style, // Used to change display and justifyContent (See QualityControlReport)
                        } = d;
                        if (((Array.isArray(value) && isEmpty(value)) || isNil(value)) && readOnly) {
                            return false;
                        }

                        return (
                            <Detail label={label || key.toUpperCase()} sm={sm} md={md} lg={lg} xl={xl} style={style} >
                                {value}
                            </Detail>
                        )

                    })
                }
            </Grid>
        );
    }
    return details;
}

export default QualityControlReport;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import isEmpty from 'lodash/isEmpty';
import range from 'lodash/range';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddCommentIcon from '@mui/icons-material/AddCommentTwoTone';
import CloseIcon from '@mui/icons-material/CloseTwoTone';
import Skeleton from '@mui/material/Skeleton';
import Chip from '@mui/material/Chip';
import useMediaQuery from '@mui/material/useMediaQuery';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';

import { useUser } from 'contexts/user';
import { useEventListener } from 'contexts/event';
import { useSocketListener } from 'contexts/socket';
import FormattedDateOrTime from 'components/FormattedDateOrTime';

const useStyles = makeStyles((theme) => createStyles({
    listRoot: {
        flexGrow: 1,
        maxWidth: '100%',
        flexBasis: 0,
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    
    root: {
        padding: theme.spacing(0, 2),
    },
    strong: {
        fontWeight: 600,
        display: 'block',
    },
    block: {
        display: 'block',
    },
    listItem: {
        position: 'relative',
        padding:  theme.spacing(1, 1.5, 1, 2),
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(0, 0, .5),
        transition: `background-color ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
    },
    menuButton: {
        margin: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

function DiscussionList(props) {
    const { api, user } = useUser();
    const {
        setActive, relation, relationId, isLoading: parentLoading,
        cancelRefreshList, refreshList, enableCreateDiscussion,
        createDiscussionEnabled, includeRelationDetails, activeId,
        handleDrawerToggle, className, removeCreateDiscussionButton
    } = props;
    const classes = useStyles();
    const [discussions, setDiscussions] = React.useState([]);
    const [fetchingDiscussions, setFetchingDiscussions] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [showDeleted, setShowDeleted] = React.useState(false);

    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    async function fetchDiscussions(abortController = new AbortController(), callback) {
        try {
            setFetchingDiscussions(true);
            setFailed(false);
            const response = await api.get('/discussion/', { relation, showDeleted, relationId }, { signal: abortController.signal });

            if (response.ok) {
                const data = await response.json();
                setDiscussions(data);
                if (callback) {
                    callback()
                }
            } else {
                setDiscussions([]);
                setFailed(true);
            }
        } catch (error) {
            console.log(error)
            if (!abortController.signal.aborted) {
                console.error(error);
            }
        }
        setFetchingDiscussions(false);
    }

    const handleEvent = React.useCallback((event) => {
        fetchDiscussions();
    }, []);

    // useEventListener('discussion', handleEvent);
    useSocketListener('discussion', handleEvent);

    React.useEffect(() => {
        const abortController = new AbortController();

        fetchDiscussions(abortController);
        return () => {
            abortController.abort();
        };
    }, [relationId, relation, showDeleted]);

    React.useEffect(() => {
        const abortController = new AbortController();
        if (refreshList) {
            fetchDiscussions(abortController, cancelRefreshList);
        }
        return () => {
            abortController.abort();
        };
    }, [refreshList]);

    const isLoading = parentLoading || (isEmpty(discussions) && fetchingDiscussions);

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid container spacing={1} justifyContent="space-between">
                {removeCreateDiscussionButton ?  null :
                <Grid item>
                <IconButton onClick={enableCreateDiscussion}  disabled={createDiscussionEnabled} size="large">
                    <AddCommentIcon />
                </IconButton>
                </Grid>
              }
                
                <Grid item>
                    <IconButton onClick={handleDrawerToggle} size="large" className={classes.menuButton}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <List className={[classes.listRoot,className].filter(x=> !!x).join(",")}>
                {
                    (isLoading ? range(3).map((k) => ({ discussionId: k })) : discussions).map((discussion) => (
                        <DiscussionItem
                            key={discussion.discussionId}
                            className={classes.listItem}
                            id={discussion.discussionId}
                            subject={discussion.subject}
                            isInternal={discussion.isInternal}
                            isDeleted={discussion.isDeleted}
                            isEmployee={discussion.isEmployee}
                            hasProblem={discussion.problemStatusId}
                            setActive={setActive}
                            isLoading={isLoading}
                            disabled={createDiscussionEnabled}
                            relationDetail={includeRelationDetails ? discussion.relationTitle_1 : undefined}
                            latestCommentText={discussion.latestCommentText}
                            latestCommentBy={discussion.latestCommentBy}
                            latestCreatedDate={discussion.latestCreatedDate}
                            latestCreatorId={discussion.latestCreatorId}
                            isNew={discussion.isNew}
                            isActive={activeId === discussion.discussionId}
                            strongClass={classes.strong}
                            blockClass={classes.block}
                        />
                    ))
                }
            </List>
        </Grid>
    );
}

function DiscussionItem(props) {
    const {
        id, subject, setActive,
        isLoading, disabled, relationDetail,
        isActive, strongClass, latestCommentText,
        latestCommentBy, latestCreatedDate, latestCreatorId,
        isNew, className, blockClass, hasProblem
    } = props;

    const { isCurrentUser } = useUser();

    function handleSetActive() {
        if (isLoading) {
            return;
        }
        setActive(id);
    }

    let commentBy = latestCommentBy && latestCommentBy.split(' ')[0];

    if (isCurrentUser(latestCreatorId)) {
        commentBy = <FormattedMessage id="YOU" />;
    }

    return (
        <ListItemButton onClick={handleSetActive} disabled={disabled} selected={isActive} className={className}>
            <ListItemText
                primary={(
                    <>
                        <Grid component="span" container spacing={1} justifyContent="space-between" alignItems="baseline" wrap="nowrap">
                            <Grid item xs zeroMinWidth component="span">
                                { hasProblem === 'REPORTED' ?
                                    <Typography variant="body1" component="span" title={subject} noWrap className={isNew ? strongClass : blockClass}>
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                           <ErrorIcon fontSize="small" color="error" style={{ marginRight: '8px' }} />
                                            <Tooltip title={<FormattedMessage id="HAS_PROBLEM" />}>
                                                <span>
                                                {
                                            isLoading ? (
                                                <Skeleton variant="text" width="80%" />
                                            ) : (subject)
                                            }
                                                </span>
                                            </Tooltip>

                                        </span>
                                </Typography>

                                :
                                    <Typography variant="body1" component="span" title={subject} noWrap className={isNew ? strongClass : blockClass}>
                                        {
                                            isLoading ? (
                                                <Skeleton variant="text" width="80%" />
                                            ) : (subject)
                                        }
                                    </Typography>
                                }

                            </Grid>
                            <Grid component="span" item xs={isLoading ? 2 : undefined}>
                                <Typography variant="caption" noWrap component="span"  className={blockClass}>
                                    {
                                        !isLoading ? null : <Skeleton variant="text" />
                                    }
                                    {
                                        isLoading || !latestCreatedDate ? null : (
                                            <FormattedDateOrTime
                                                value={latestCreatedDate}
                                                month="numeric"
                                                day="numeric"
                                            />
                                        )
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                        {!relationDetail ? null : <Typography title={relationDetail} noWrap>{relationDetail}</Typography>}
                    </>
                )}
                secondary={((commentBy && latestCommentText) || isLoading) && (
                    <Grid component="span" container spacing={1} alignItems="first baseline">
                        <Grid component="span" item xs={isLoading ? 2 : undefined}>
                            <Typography variant="body2" noWrap component="span" className={blockClass}>
                                {
                                    isLoading ? <Skeleton variant="text" /> : <>{commentBy}:</>
                                }
                            </Typography>
                        </Grid>
                        <Grid component="span" item xs={isLoading ? 9 : true} zeroMinWidth>
                            <Typography variant="body2" noWrap component="span" className={blockClass}>
                                {
                                    isLoading ? <Skeleton variant="text" /> : latestCommentText
                                }
                            </Typography>
                        </Grid>
                        {
                            isLoading || !isNew ? null : (
                                <Grid component="span" item xs="auto" zeroMinWidth>
                                    <Chip
                                        size="small"
                                        color="secondary"
                                        style={{width: '8px', height: '8px'}}
                                    />
                                </Grid>
                            )
                        }
                    </Grid>
                )}
            />
        </ListItemButton>
    );
}

DiscussionList.propTypes = {
    setActive: PropTypes.func.isRequired,
    relation: PropTypes.string,
    relationId: PropTypes.number,
    isLoading: PropTypes.bool,
    cancelRefreshList: PropTypes.func.isRequired,
    refreshList: PropTypes.bool.isRequired,
    enableCreateDiscussion: PropTypes.func.isRequired,
    createDiscussionEnabled: PropTypes.bool.isRequired,
    includeRelationDetails: PropTypes.bool,
};

DiscussionList.defaultProps = {
    relation: null,
    relationId: null,
    isLoading: false,
    includeRelationDetails: false,
};

DiscussionItem.propTypes = {
    id: PropTypes.number.isRequired,
    subject: PropTypes.string,
    setActive: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    relationDetail: PropTypes.string,
    latestCommentText: PropTypes.string,
    latestCommentBy: PropTypes.string,
    latestCreatedDate: PropTypes.string,
    latestCreatorId: PropTypes.number,
    isNew: PropTypes.bool,
    isActive: PropTypes.bool.isRequired,
    strongClass: PropTypes.string.isRequired,
};

DiscussionItem.defaultProps = {
    relationDetail: null,
    latestCommentText: '',
    latestCommentBy: '',
    latestCreatedDate: '',
    latestCreatorId: null,
    isNew: null,
    subject: null,
};

export default DiscussionList;

// ----------------------------------------------------------------------

export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
            backgroundColor: theme.palette.mode === 'dark' ? '#333D49' : theme.palette.grey[200],
            color: theme.palette.mode === 'dark' ? theme.palette.grey[500] : theme.palette.grey[600],
            boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
        },
        arrow: {
          color: theme.palette.grey[800]
        }
      }
    }
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';

import { useUser } from 'contexts/user';
import FormSelect from 'components/FormSelect';

// TODO: fix mapping so its universal!!!!!
const mappedPermissions = {
    distribution: { title: 'DISTRIBUTION', contact: 'distribution', api: 'distribution' },
    invoices: { title: 'INVOICES', contact: 'invoices', api: 'facturation' },
    kdm: { title: 'KDM', contact: 'kdm', api: 'kdm' },
    manageUser: { title: 'MANAGE_USER', contact: 'clientManagement', api: 'clientWebGestion' },
    physicalVault: { title: 'PHYSICAL_VAULT', contact: 'physicalVault', api: 'physicalVault' },
    vod: { title: 'VOD', contact: 'vodOrders', api: 'vodCommande' },
    dcp: { title: 'DCP', contact: 'dcp' },
};

const propTypes = {
    userPermission: PropTypes.number.isRequired,
    clientDetails: PropTypes.shape({
        clientPermissions: PropTypes.shape({
            distribution: PropTypes.number,
            invoices: PropTypes.number,
            kdm: PropTypes.number,
            manageUser: PropTypes.number,
            physicalVault: PropTypes.number,
            vod: PropTypes.number,
        }),
    }).isRequired,
    actionRunning: PropTypes.bool.isRequired,
    fetchClientDetails: PropTypes.func.isRequired,
    setActionRunning: PropTypes.func.isRequired,
    userName: PropTypes.string.isRequired,
    currentClient: PropTypes.number.isRequired,
    clientName: PropTypes.string.isRequired,
};

const defaultContactInfo = {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
};

Object.keys(mappedPermissions).forEach((key) => {
    defaultContactInfo[key] = undefined;
});

function defaultValidation(value) { // retuns if valid
    return value === undefined || value.length > 0;
}

function emailValidation(value) { // retuns if valid
    return value === undefined || /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(value);
}

function AddContact(props) {
    const {
        currentClient, fetchClientDetails, userName, clientName,
        setActionRunning, clientDetails: { clientPermissions = {} }, userPermission,
        actionRunning,
    } = props;
    const { api } = useUser();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isOpen, setIsOpen] = React.useState(false);
    const [addingContact, setAddingContact] = React.useState(false);
    const [contactInfo, setContactInfo] = React.useState(defaultContactInfo);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal(event=null, reason='') {
        if (reason !== 'backdropClick') {
            clearModal();
        }
    }

    function clearModal() {
        setIsOpen(false);
        setContactInfo(defaultContactInfo);
    }

    function handleInputChange({ target: { value, name } }) {
        setContactInfo({ ...contactInfo, [name]: value });
    }

    async function addContact(abortController = new AbortController()) {
        if (currentClient) {
            const {
                email, firstName, lastName, ...selectedPermissions
            } = contactInfo;

            const selectedValues = {};
            Object.keys(selectedPermissions).forEach((key) => {
                if (key in mappedPermissions) {
                    selectedValues[mappedPermissions[key].api] = selectedPermissions[key];
                }
            });

            const snackId = enqueueSnackbar(<FormattedMessage id="ADDING_CONTACT" />, { variant: 'loading', persist: true });

            setActionRunning(true);
            setAddingContact(true);

            const response = await api.post(`/contacts/${currentClient}`, {
                clientName,
                firstName,
                lastName,
                email,
                createdBy: userName,
                ...selectedValues,
            }, { signal: abortController.signal });

            if (response.ok) {
                clearModal();
                fetchClientDetails(abortController);
                closeSnackbar(snackId);
                enqueueSnackbar(<FormattedMessage id="CONTACT_ADDED" />, { variant: 'success' });
            } else {
                closeSnackbar(snackId);
                enqueueSnackbar(<FormattedMessage id="ERROR_ADDING_CONTACT" />, { variant: 'error' });
            }
            setActionRunning(false);
            setAddingContact(false);
        }
    }

    if (userPermission !== 2) {
        return null;
    }

    const keyValuePermissions = Object.keys(clientPermissions).map((key) => ({ key, value: clientPermissions[key] }));
    const validPermissions = keyValuePermissions.filter((perm) => perm.value > 0 && perm.key in mappedPermissions);
    const permissions = [
        { value: 0, label: 'NO_ACCES' },
        { value: 1, label: 'DISPLAY' },
        { value: 2, label: 'MANAGEMENT' },
    ];

    const {
        email, firstName, lastName,
    } = contactInfo;

    return <>
        <Button
            variant="outlined"
            size="small"
            color="primary"
            disabled={actionRunning}
            onClick={openModal}
        >
            {
                addingContact ? (
                    <CircularProgress color="inherit" size="1.54rem" />
                ) : (
                    <FormattedMessage id="ADD" />
                )
            }
        </Button>
        <Dialog open={isOpen} onClose={closeModal} maxWidth="sm" fullWidth>
            <DialogTitle>
                <FormattedMessage id="ADD_USER" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id="PLEASE_SUPPLY_INFO" />
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            margin="normal"
                            name="firstName"
                            value={firstName === undefined ? '' : firstName}
                            label={<FormattedMessage id="FIRST_NAME" />}
                            onChange={handleInputChange}
                            error={!defaultValidation(firstName)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            margin="normal"
                            name="lastName"
                            value={lastName === undefined ? '' : lastName}
                            label={<FormattedMessage id="LAST_NAME" />}
                            onChange={handleInputChange}
                            error={!defaultValidation(lastName)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            margin="normal"
                            name="email"
                            value={email === undefined ? '' : email}
                            label={<FormattedMessage id="EMAIL" />}
                            onChange={handleInputChange}
                            error={!emailValidation(email)}
                        />
                    </Grid>
                    {
                        validPermissions.map(({ key }) => (
                            <Grid item xs={6} key={key}>
                                <FormSelect
                                    fullWidth
                                    margin="normal"
                                    name={key}
                                    value={contactInfo[key] === undefined ? '' : contactInfo[key]}
                                    onChange={handleInputChange}
                                    label={<FormattedMessage id={mappedPermissions[key].title} />}
                                >
                                    {
                                        permissions.map(({ value, label }) => (
                                            <MenuItem key={value} value={value}>
                                                <FormattedMessage id={label} />
                                            </MenuItem>
                                        ))
                                    }
                                </FormSelect>
                            </Grid>
                        ))
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={clearModal}>
                    <FormattedMessage id="CANCEL" />
                </Button>
                <Button
                    color="primary"
                    onClick={addContact}
                    disabled={!firstName || !lastName || !emailValidation(email)}
                >
                    <FormattedMessage id="CREATE" />
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}

AddContact.propTypes = propTypes;

export default AddContact;

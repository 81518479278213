import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useParams, generatePath } from 'react-router-dom';

import { useUser } from 'contexts/user';
import Paths from 'routes/Paths';
import DynamicLogoImg from 'components/DynamicLogoImg';
import FallbackImage from 'components/FallbackImage';
import RouteLink from 'components/RouteLink';

const propTypes = {
    clients: PropTypes.arrayOf(PropTypes.shape({
        client: PropTypes.number,
    })).isRequired,
};

const useStyles = makeStyles((theme) => createStyles({
    img: {
        objectFit: 'scale-down',
        height: 'auto',
        maxWidth: '100%',
        maxHeight: theme.spacing(3.75),
    },
    client: {
        display: 'flex',
        fontWeight: '700',
        padding: theme.spacing(0, 2),
        marginTop: theme.spacing(1),
    },
    link: {
        color: 'inherit',
        font: 'inherit',
        textDecoration: 'none',
        letterSpacing: 'inherit',
        width: '100%',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    button: {
        padding: theme.spacing(0, 1.5, 0, 2),
        margin: theme.spacing(0, 0, .5),
        width: '100%',
        justifyContent: 'flex-start',
        '&:hover' : {
            backgroundColor: 'transparent',
        }
    },
}));

function ClientSelector(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { api } = useUser();
    const theme = useTheme();
    const classes = useStyles();
    const { client, page } = useParams();
    const [logo, setLogo] = React.useState([]);

    function handleOpenMenu({ currentTarget }) {
        setAnchorEl(currentTarget);
    }

    function handleCloseMenu() {
        setAnchorEl(null);
    }

    const { clients } = props;

    let currentClientId = parseInt(client, 10) || clients.find((c) => c.default).client;
    let currentClient = clients.find(({ client: c }) => c === currentClientId);

    if (!currentClient) {
        currentClient = clients.find((c) => c.default);
        currentClientId = currentClient.client;
    }

    const clientLogo = `${DynamicLogoImg(theme.palette.background.paper, currentClient.lightLogoId, currentClient.darkLogoId)}`;
    const fallback = currentClient.lightLogoId ? currentClient.lightLogoId : currentClient.darkLogoId;
    const invalid = !currentClient.lightLogoId && !currentClient.darkLogoId

    return (
        <div className={classes.client}>
            <Button
                className={classes.button}
                onClick={handleOpenMenu}
                disabled={clients.length === 1}
                disableRipple
                disableFocusRipple
            >
                <FallbackImage
                    key={currentClientId}
                    alt={currentClient.name}
                    className={classes.img}
                    source={invalid ? undefined : api.static(`/storage/${clientLogo}`, { default: 'false' }, 'SERVICE')}
                    fallback={invalid ? undefined : api.static(`/storage/${fallback}`, {}, 'SERVICE')}
                />
            </Button>
            <Menu
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                {
                    clients.map((c) => {
                        const { client: clientId, name } = c;
                        const toPath = (Paths.find(({ path, permissions = {} }) => (
                            path.indexOf(`/${page}/`) === 0 && Object.keys(permissions).every((permission) => (
                                c[permission] >= permissions[permission]
                            ))
                        )) || Paths.find(({ permissions = {} }) => (
                            Object.keys(permissions).every((permission) => (
                                c[permission] >= permissions[permission]
                            ))
                        ))).path;
                        return (
                            <MenuItem
                                key={clientId}
                            >
                                <RouteLink
                                    className={classes.link}
                                    to={generatePath(toPath, { page, client: clientId, 0: '' })}
                                    onClick={handleCloseMenu}
                                >
                                    {name[0].toUpperCase() + name.substr(1).toLowerCase()}
                                </RouteLink>
                            </MenuItem>
                        );
                    })
                }
            </Menu>
        </div>
    );
}

ClientSelector.propTypes = propTypes;

export default ClientSelector;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from  'clsx';

import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { alpha } from '@mui/material/styles';

import RouteLink from 'components/RouteLink';
import FallbackImage from 'components/FallbackImageOLD';

const propTypes = {
    id: PropTypes.number.isRequired,
    poster: PropTypes.string.isRequired,
    originalTitle: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    href: PropTypes.string,
    inVault: PropTypes.bool.isRequired,
    selected: PropTypes.bool.isRequired,
    classes: PropTypes.shape({}),
    defaultImage: PropTypes.string.isRequired,
};

const defaultProps = {
    onClick: null,
    classes: {},
    href: undefined,
};

const useStyles = makeStyles((theme) => createStyles({
    entity: {  // modify List.jsx as well
        background: 'transparent',
        boxShadow: 'none',
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'visible',
        textOverflow: 'hidden',
        position: 'relative',
        padding: theme.spacing(1),
        '& + &': {
            margin: 0,
        },
    },
    entityImage: {
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.customShadows['z16'],
        maxHeight: '100%',
        maxWidth: '100%',
    },
    entityImageLoading: {
        height: 'unset',
        aspectRatio: '2/3',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.customShadows['z16'],
    },
    entityAction: {  // modify List.jsx as well
        width: '100%',
        display: 'flex',
        textAlign: 'inherit',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'initial',
        height: '225px',
        [theme.breakpoints.up('lg')]: {
            height: '300px',
        },
        '& .MuiCardActionArea-focusHighlight, & .MuiTouchRipple-root': {
            aspectRatio: '2/3',
            borderRadius: theme.shape.borderRadius,
        },
    },
    inVaultIcon: {
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(0.5),
        alignSelf: 'flex-start',
    },
    entitySelected: {
        backgroundColor: alpha(theme.palette.primary.main, 0.25),
    },
    entityContent: {
        marginTop: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        padding: 0,
        '&:last-child': {
            padding: 0,
        },
        '& > p': {
            marginTop: theme.spacing(1),
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%',
            // fontSize: '.85rem',
            fontSize: '0.9rem',
            lineHeight: '1.15',
            fontWeight: '500',
        },
        '& > p:last-of-type': {
            marginTop: theme.spacing(0.4),
            display: 'inline-flex',
            alignItems: 'center',
            fontWeight: '400',
        },
    },
    contentTitle: {
        flex: 1,
    },
    invisible: {
        display: 'none',
    }
}));

function Entity(props) {
    const {
        id, poster, originalTitle, onClick, href,
        inVault, selected, entityType,
        defaultImage, aggregator,
    } = props;

    const classes = useStyles();

    return (

        <Card className={clsx(classes.entity,  selected && classes.entitySelected)}>
            <CardActionArea
                to={href}
                component={href ? RouteLink : undefined}
                onClick={onClick}
                value={id}
                className={classes.entityAction}
                title={originalTitle}
            >
                <CardMedia
                    alt={`${originalTitle} poster`}
                    component={FallbackImage}
                    source={poster}
                    image={poster}
                    fallback={defaultImage}
                    className={classes.entityImage}
                    loadingProps={{
                        className: classes.entityImageLoading
                    }}
                />
            </CardActionArea>
            <CardContent className={classes.entityContent}>
                <Typography variant="subtitle1" color="textPrimary" component="p">
                    <span className={classes.contentTitle}>
                        {originalTitle}
                    </span>
                    <CloudCircleIcon className={`${classes.inVaultIcon} ${!inVault ? classes.invisible : ''}`} fontSize="small" />
                </Typography>
                <Typography variant="caption" color="textSecondary" component="p" style={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
                    <span className={!entityType ? classes.invisible : ''}>
                        {entityType}
                    </span>
                </Typography>
            </CardContent>
        </Card>
    );
}

Entity.propTypes = propTypes;
Entity.defaultProps = defaultProps;

export default Entity

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import { isFunction } from 'lodash';
import Typography from '@mui/material/Typography';
import { Redirect, useParams, useHistory } from 'react-router-dom';

const propTypes = {
    children: PropTypes.arrayOf(PropTypes.shape({
        props: PropTypes.shape({
            isconfirmpage: PropTypes.bool,
            nextValid: PropTypes.bool,
            isaSubmit: PropTypes.bool,
            header: PropTypes.node,
        }),
    })).isRequired,
    hasCancel: PropTypes.bool,
    showRequiredMessage: PropTypes.bool,
    closeModal: PropTypes.func,
    onSubmit: PropTypes.func,
    contentComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func, PropTypes.shape({})]),
    actionsComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func, PropTypes.shape({})]),
    headerComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func, PropTypes.shape({})]),
};

const defaultProps = {
    hasCancel: false,
    closeModal: () => {},
    onSubmit: () => {},
    contentComponent: 'div',
    actionsComponent: 'div',
    headerComponent: 'div',
    showRequiredMessage: true,
};


function Wizard(props) {
    const [step, setStep] = React.useState(0);
    const [altStep, setAltStep] = React.useState(0);
    const altActive = altStep >= 1;

    const {
        children, hasCancel: parentHasCancel, closeModal, onSubmit, redirectPath,
        contentComponent: ContentComponent, actionsComponent: ActionsComponent, headerComponent: HeaderComponent,
        showRequiredMessage, contentClassName: propsContentClassName, jumpIndex, jump, resetJump,
        nextActionLabel: parentNextActionLabel, cancelActionLabel: parentCancelActionLabel, previousActionLabel: parentPreviousActionLabel
    } = props;

    const singleLevelChildren = children.reduce(( memo, child ) => {
        if (Array.isArray(child) && child.every((c) => Array.isArray(c))) {
            return memo.concat(child.reduce((m, c) => m.concat(c)))
        }
        if (child.props.isPageBundle) {
            return memo.concat(child.type(child.props))
        }
        return memo.concat(child)
    }, [])
    // const singleLevelChildrens = children.reduce(( memo, child ) =>  memo.concat(child), [])

    const singleLevelChildrens = singleLevelChildren.reduce(( memo, child )=> Array.isArray(child) && child.every((c) => Array.isArray(c)) ? memo.concat(child.reduce((m, c) => m.concat(c))) : memo.concat(child), [])

    // const singleLevelChildrens = children.reduce(( memo, child ) =>  memo.concat(expandChildren(child)), [])
    const filteredMainChildren = singleLevelChildrens.filter((child) => child ? !child.props.altStep && !child.props.isDisabled : child);
    const filteredAltChildren = singleLevelChildrens.filter((child) => child && filteredMainChildren[step] ? child.props.altStep === filteredMainChildren[step].props.altId && !child.props.isDisabled : false);

    let filteredChildren = filteredMainChildren;
    let stepCount = step;
    if (altActive) {
        filteredChildren = filteredAltChildren;
        stepCount = altStep - 1;
    }

    const {
        isconfirmpage, nextValid, isaSubmit, isSubmit,
        stepHeader, lastStep: childLastStep, altId,
        altAction, altActionLabel, previousAction, nextAction, nextActionLabel=parentNextActionLabel,
        cancelActionLabel=parentCancelActionLabel, previousActionLabel=parentPreviousActionLabel,
        contentClassName: childContentClassName, shouldReturn=true,
        autoNext, onMount, beforeMount, hasCancel=parentHasCancel,
        ...validChildProps
    } = (filteredChildren[stepCount] || { props: {} }).props;

    const contentClassName = [propsContentClassName, childContentClassName].filter((x)=>x).join(' ');
    const nbChildren = filteredChildren.length - 1;
    const lastStep = nbChildren === stepCount;

    const buttonNavigation = React.useCallback(({ currentTarget: { value } }) => {
        let stepSetter = setStep;
        let stepCounter = step;

        if (altActive && stepCount < filteredAltChildren.length - 1) {
            stepSetter = setAltStep;
            stepCounter = altStep;
        } else if (altStep > 0) {
            setAltStep(0);
        }

        let newStep;
        if (value === '-1') {
            newStep = stepCounter - 1;
        } else {
            newStep = stepCounter + 1;
        }

        const { beforeMount } = filteredChildren[newStep] && filteredChildren[newStep].props || {};

        if (beforeMount) {
            beforeMount();
        }

        stepSetter(newStep);
    }, [setStep, step, altActive, stepCount, filteredAltChildren]);

    const handleAltAction = React.useCallback((event) => {
        const { beforeMount } = filteredAltChildren[altStep] && filteredAltChildren[altStep].props || {};
        if (beforeMount) {
            beforeMount();
        }

        if (isFunction(altAction)) {
            altAction()
        }
        setAltStep(1)
    }, [filteredAltChildren, altAction]);

    const handleClick = React.useCallback((event) => {
        if (isconfirmpage && !isaSubmit) {
            closeModal(event);
        } else if (!isaSubmit) {
            if (nextAction) {
                nextAction(event, buttonNavigation);
            } else if (lastStep && shouldReturn) {
                setAltStep(0);
            } else {
                buttonNavigation(event);
            }
        } else if (lastStep) {
            onSubmit(event);
        } else {
            onSubmit(event);
        }
    }, [isconfirmpage, isaSubmit, closeModal, nextAction, buttonNavigation, lastStep, shouldReturn, onSubmit]);

    const handlePreviousClick = React.useCallback((event) => {
        if (previousAction) {
            previousAction(event, buttonNavigation);
        } else {
            buttonNavigation(event);
        }
    }, [previousAction, buttonNavigation]);


    const isSubmitAction = (!altActive && lastStep && !isconfirmpage) || isaSubmit;


    let nextButtonLabel = isconfirmpage ? 'OK' : 'NEXT';

    if (isSubmitAction) {
        nextButtonLabel = 'SUBMIT';
    }

    React.useEffect(() => {
        if (onMount) {
            onMount();
        }
    }, [stepCount])

    React.useEffect(() => {
        if (autoNext && nextValid) {
            handleClick({ currentTarget: { value: "1" } })
        }
    }, [autoNext, nextValid, handleClick])


    return (
        <>
            {
                !stepHeader ? false : (
                    <HeaderComponent>
                        {stepHeader}
                    </HeaderComponent>
                )
            }
            <ContentComponent className={contentClassName}>
                {filteredChildren[stepCount] && React.createElement(filteredChildren[stepCount].type, validChildProps)}
            </ContentComponent>
            <ActionsComponent>
                {
                    !altAction ? null : (
                        <div style={{ marginRight: 'auto' }}>
                            <Button
                                onClick={handleAltAction}
                            >
                                {altActionLabel}
                            </Button>
                        </div>
                    )
                }
                {
                    !showRequiredMessage ? null : (
                        <Typography variant="caption" style={{ marginRight: 'auto' }}>
                            <FormattedMessage id="REQUIRED_FIELDS" />
                        </Typography>
                    )
                }
                {
                    isconfirmpage || !hasCancel ? null : (
                        <Button
                            name="cancel"
                            onClick={closeModal}
                        >
                            {
                                cancelActionLabel ? cancelActionLabel : (
                                    <FormattedMessage id="CANCEL" />
                                )
                            }
                        </Button>
                    )

                }
                {
                    step === 0 || isconfirmpage ? null : (
                        <Button
                            value="-1"
                            disabled={step === 0}
                            onClick={handlePreviousClick}
                        >
                            {
                                previousActionLabel ? previousActionLabel : (
                                    <FormattedMessage id="PREVIOUS" />
                                )
                            }
                        </Button>
                    )
                }
                {
                    autoNext ? null : (
                        <Button
                            value="1"
                            onClick={handleClick}
                            disabled={isconfirmpage && !isSubmitAction ? false : !nextValid}
                            color={isSubmitAction ? 'primary' : undefined}
                        >
                            {
                                nextActionLabel ? nextActionLabel : (
                                    <FormattedMessage id={nextButtonLabel} />
                                )
                            }
                        </Button>
                    )
                }

            </ActionsComponent>
        </>
    );
}


Wizard.propTypes = propTypes;

Wizard.defaultProps = defaultProps;


export default Wizard;

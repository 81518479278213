import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { generatePath, useRouteMatch, useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import parseISO from 'date-fns/parseISO';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useUser } from 'contexts/user';
import RouteLink from 'components/RouteLink';
import { useConstants } from 'contexts/constants';
import ErrorIcon from '@mui/icons-material/Error';

import DateRangePicker from 'components/DateRangePicker';
import Discussion from 'components/Discussion';
import DiscussionBadge from 'components/Discussion/Badge';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import OrderDetails from 'components/Orders/Details';
import Table from 'components/Table/EnhancedTable';
import DownloadButton from 'components/DownloadButton';
import { VodOrderDefinition } from 'routes/Paths/Definitions';

const discussionTab = 'DISCUSSION';
const detailsTab = 'DETAILS';
const orderTab = 'ORDER';
const orderDetailsTabs = [detailsTab, orderTab];
const defaultTab = detailsTab;

const useStyles = makeStyles((theme) => createStyles({
    strikethrough: {
        textDecoration: 'line-through',
    },
    notes: {
        marginTop: theme.spacing(2),
    },
    orderDetails: {
        marginTop: '0',
        marginBottom: '0',
    },
    editableDetails: {
        marginLeft: '10px',
        marginTop: '0',
        marginBottom: '0',
    },
    root: {
        flexGrow: 1,
        paddingTop: theme.spacing(2),
    },
    dialog: {
    },
    dialogDiscussion: {
        '& > .MuiDialog-container.MuiDialog-scrollPaper > .MuiPaper-root.MuiDialog-paper': {
            height: '100%',
        },
    },
    discussion: {
        height: 'auto',
        width: 'auto',
        flex: '1 1 auto',
        margin: 0,
    },
    problemContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    problemIcon: {
        marginRight: theme.spacing(1),
    },
    discussionButton: {
        '& .MuiButton-label': {
            display: 'inline-flex',
        },
        '& .MuiButton-endIcon.MuiButton-iconSizeMedium > :first-child': {
            fontSize: '0.8125rem',
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& .MuiTouchRipple-root': {
            display: 'none',
        },
    },
}));

const propTypes = {
};

const defaultProps = {
};

function fncContentOwner({ contentOwner = '', contentAggregator = '' }) {
    const length = 8;
    const maxLength = contentAggregator ? length : length * 2;
    const styles = {
        maxWidth: `${maxLength}ch`,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'inline-block',
    };

    const title = `${contentOwner}${contentAggregator ? ` / ${contentAggregator}` : ''}`;
    return (
        <span title={title} style={{ display: 'inline-flex' }}>
            <span style={styles}>{contentOwner}</span>
            {
                !contentAggregator ? null : (
                    <>
                        <span style={{ ...styles, margin: '0 1ch' }}>/</span>
                        <span style={styles}>{contentAggregator}</span>
                    </>
                )
            }
        </span>
    );
}

function TabPanel(props) {
    const { children, currentValue, value } = props;

    return value === currentValue && children;
}

function Order(props) {
    const match = useRouteMatch();
    const history = useHistory();
    const { order: orderId, client } = match.params;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { VodWrite, refreshPage, page, setPage } = props;
    const defaulttTopicType = 'suggestion';
    const { api, user } = useUser();
    const [order, setOrder] = React.useState({});
    const [fetchingOrder, setFetchingOrder] = React.useState(false);
    const [updatingOrder, setUpdatingOrder] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const [isCancelSelected, setIsCancelSelected] = React.useState(false);
    const [newWindowStart, setNewWindowStart] = React.useState('');
    const [newWindowEnd, setNewWindowEnd] = React.useState('');
    const [newRating, setNewRating] = React.useState('');
    const [newAdvisory, setNewAdvisory] = React.useState('');
    const [cancelOrderReason, setCancelOrderReason] = React.useState('');
    const [creatingTopic, setCreatingTopic] = React.useState(false);
    const [topicType, setTopicType] = React.useState(defaulttTopicType || 'question');
    const [topicTitle, setTopicTitle] = React.useState('');
    const [selectedTab, setSelectedTab] = React.useState(defaultTab);
    const userPermission = user.clients.find((c) => c.client == client);
    const vodOrdersPermission = userPermission.vodOrders;
    const classes = useStyles();
    const {
        ratings: ratingsUnfiltered, isLoading: fetchingConstants,
    } = useConstants();

    const ratings = ratingsUnfiltered.filter((thing, index, self) => (
        index === self.findIndex((t) => (t.label === thing.label))
    ));

    function closeModal() {
        setSelectedTab(detailsTab);
        history.push({
            pathname: generatePath(match.path, { client }),
            search: history.location.search,
        });
        setPage(page)
    }

    async function fetchOrder(abortController = new AbortController()) {
        if (client && orderId) {
            try {
                setFetchingOrder(true);
                setFailed(false);

                const response = await api.get(`/vod/orders/${client}/${orderId}`, {}, { signal: abortController.signal });

                if (response.ok) {
                    const data = await response.json();
                    setOrder(data);
                } else {
                    setOrder({});
                    setFailed(true);
                }

                setFetchingOrder(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    async function updateOrder(params, abortController = new AbortController()) {
        const { cancelOrder } = params;
        if (client && orderId) {
            const snackId = enqueueSnackbar(<FormattedMessage id={cancelOrder ? 'CANCELLING_ORDER' : 'UPDATING_ORDER'} />, { variant: 'loading', persist: true });
            setUpdatingOrder(true);
            setFailed(false);
            let response = { ok: false };
            try {
                response = await api.put(`/vod/orders/${client}/${orderId}`, params, { signal: abortController.signal });
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            } finally {
                closeSnackbar(snackId);
                if (response.ok) {
                    enqueueSnackbar(<FormattedMessage id={cancelOrder ? 'ORDER_CANCELLED' : 'ORDER_UPDATED'} />, { variant: 'success' });
                    closeModal();
                    if (refreshPage) {
                        refreshPage();
                    }
                } else {
                    enqueueSnackbar(<FormattedMessage id={cancelOrder ? 'ERROR_CANCELLING_ORDER' : 'ERROR_UPDATING_ORDER'} />, { variant: 'error' });
                }
                setUpdatingOrder(false);
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        if (orderId) {
            fetchOrder(abortController);
        } else {
            setOrder({});
            setFetchingOrder(false);
            setUpdatingOrder(false);
            setFailed(false);
            setIsEditing(false);
            setIsCancelSelected(false);
            setNewWindowStart(undefined);
            setNewWindowEnd(undefined);
            setNewRating('');
            setNewAdvisory('');
            setCancelOrderReason('');
            setPage(page ||0)
        }
        return () => {
            abortController.abort();
        };
    }, [client, orderId]);

    const {
        contentType, language, orderDate, orderedBy,
        resolution, isModifiable, isCancelable, title, audioType,
        encodingFormat, advisory, rating, contentAdvisory, translationType,
        product, itemStatus, contentOwner, cancelledNotes,
        cancelledDate, episodeNumber, seasonNumber, cc,
        deliveryDate, windowEnd, windowStart, noteCommande1,
        noteCommande2, noteProduction, notification,
        ccMarketStatus, contentAggregator, itemStatusId,
        packageAssets, receivedDateItem, hasProblem,
        poNumber, description, broadcaster, deliverTo,
        broadcastDelay, orderItems, specDocument, orderDocument,
        cancelledBy,
    } = order;

    React.useEffect(() => {
        setNewWindowStart(windowStart);
        setNewWindowEnd(windowEnd);
        setNewRating(rating);
        setNewAdvisory(contentAdvisory);
    }, [windowStart, windowEnd, rating, contentAdvisory]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function handleCancelOrder() {
        setIsCancelSelected(!isCancelSelected);
        setCancelOrderReason('');
    }

    function handleEdit() {
        setIsEditing(!isEditing);
        setNewWindowStart(windowStart);
        setNewWindowEnd(windowEnd);
        setNewRating(rating);
        setNewAdvisory(contentAdvisory);
    }

    function addDays(date, nDays) {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + nDays);
        return newDate;
    }

    function handleApply() {
        const params = {
            windowStart: newWindowStart,
            windowEnd: newWindowEnd,
            rating: newRating,
            advisory: newAdvisory,
            cancelOrder: isCancelSelected,
            message: cancelOrderReason,
        };
        updateOrder(params);
    }

    function handleOrderItem({ itemId }) {
        setSelectedTab(detailsTab);
        history.push({
            pathname: generatePath(VodOrderDefinition.path, { client, order: itemId }),
            search: history.location.search,
        });
    }

    const canSave = (
        newWindowStart !== windowStart
        || newWindowEnd !== windowEnd
        || newRating !== rating
        || newAdvisory !== contentAdvisory
        || (isCancelSelected && !isEmpty(cancelOrderReason))
    );

    let content = null;
    let notes = [];

    if (cancelledNotes) {
        notes.push({
            label: 'CANCELATION',
            content: cancelledNotes.split('\n'),
            date: cancelledDate,
        });
    }

    if (noteProduction) {
        notes.push({
            label: 'MELS',
            content: noteProduction.split('\n'),
        });
    }

    if (noteCommande1 || noteCommande2) {
        notes.push({
            label: 'CLIENT',
            content: [...(noteCommande1 || '').split('\n'), ...(noteCommande2 || '').split('\n')],
        });
    }

    if (notification) {
        notes = notes.concat(notification.map((notif) => ({
            label: 'DISTRIBUTOR',
            content: [notif.emailObject],
            date: notif.sentDate,
            additionalContent: notif.sentTo.replace(/"/gi, ''),
        })));
    }

    const loading = fetchingOrder || updatingOrder;
    const notFound = !loading && isEmpty(order);
    const hasContent = !notFound && parseInt(orderId, 10) === order.itemId;

    if (loading) {
        content = (
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        );
    } else if (notFound) {
        content = (
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id="NOT_FOUND" />
                </DialogContentText>
            </DialogContent>
        );
    } else if (isCancelSelected) {
        content = (
            <>
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <Grid container wrap="wrap" spacing={2} className={classes.orderDetails}>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    maxRows={3}
                                    multiline
                                    margin="normal"
                                    value={cancelOrderReason}
                                    required
                                    fullWidth
                                    minRows={3}
                                    label={<FormattedMessage id="CANCEL_ORDER_REASON" />}
                                    error={isEmpty(cancelOrderReason)}
                                    helperText={!isEmpty(cancelOrderReason) ? null : <FormattedMessage id="MUST_FILL_FIELD" />}
                                    onChange={({ target: { value } }) => { setCancelOrderReason(value); }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </>
        );
    } else if (isEditing) {
        content = (
            <>
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <Grid container wrap="wrap" spacing={2} className={classes.orderDetails}>
                        <Grid item xs={12}>
                            <DateRangePicker
                                clearable
                                fullWidth
                                inputFormat="yyyy-MM-dd"
                                inputVariant="outlined"
                                size="small"
                                margin="dense"
                                disableToolbar
                                startValue={newWindowStart}
                                startName="newWindowStart"
                                startLabel={<FormattedMessage id="AVAILABLE_FROM" />}
                                endName="newWindowEnd"
                                endValue={newWindowEnd}
                                endLabel={<FormattedMessage id="AVAILABLE_TO" />}
                                onStartChange={({ target: { value } }) => { setNewWindowStart(value); }}
                                onEndChange={({ target: { value } }) => { setNewWindowEnd(value); }}
                            />
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={4}>
                                <Typography color="textSecondary" gutterBottom>
                                    <FormattedMessage id="RATING" />
                                </Typography>
                                <Typography color="textPrimary">
                                    <Autocomplete
                                        options={ratings || []}
                                        getOptionLabel={(option) => (option && option.label) || newRating || ''}
                                        freeSolo
                                        value={newRating || ''}
                                        inputValue={newRating || ''}
                                        onInputChange={(e, newInputValue) => { setNewRating(newInputValue); }}
                                        renderInput={(props) => (
                                            <TextField
                                                {...props}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography color="textSecondary" gutterBottom>
                                    <FormattedMessage id="ADVISORY" />
                                </Typography>
                                <Typography color="textPrimary">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        value={newAdvisory || ''}
                                        // label={<FormattedMessage id="ADVISORY" />}
                                        onChange={({ target: { value } }) => { setNewAdvisory(value); }}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </>
        );
    } else if (hasContent) {
        content = (
            <DialogContent>
                <OrderDetails
                    orderType="VOD"
                    poNumber={poNumber}
                    title={title}
                    description={`${description}${product ? ` - ${product}` : ''}${product && encodingFormat ? ' -' : ''}${encodingFormat ? ` (${encodingFormat})` : ''}`}
                    orderStatus={itemStatus}
                    orderStatusId={itemStatusId}
                    hasProblem={hasProblem}
                    cancelledDate={cancelledDate}
                    cancelledReason={cancelledNotes}
                    cancelledBy={cancelledBy}
                    availabilityStart={windowStart}
                    availabilityEnd={windowEnd}
                    orderDate={orderDate}
                    orderBy={orderedBy}
                    client={broadcaster}
                    deliverTo={deliverTo}
                    deliverDate={deliveryDate}
                    additionalDetails={advisory}
                    packageContent={
                        !packageAssets || !Array.isArray(packageAssets) ? null : packageAssets.map(
                            ({ descriptionPart1, descriptionPart2, status, assetTypeId }) => ({
                                description: descriptionPart1 || descriptionPart2,
                                status: (assetTypeId === 'CC' ? ((status === 'Not received' || status === 'Non reçu') ? ccMarketStatus : status) : status)
                            })
                        )
                    }
                    broadcastDelay={broadcastDelay}
                />
            </DialogContent>
        );
    }

    return (
        <Dialog
            fullWidth={!loading}
            open={!!orderId}
            onClose={selectedTab === discussionTab ? undefined : closeModal}
            className={!loading && selectedTab === discussionTab ? [classes.dialog, classes.dialogDiscussion].join(' ') : classes.dialog}
            maxWidth={selectedTab === discussionTab ? 'lg' : 'sm'}
        >
            {
                loading ? null : (
                    <AppBar position="static">
                        <Tabs indicatorColor="primary" textColor="inherit" value={selectedTab} onChange={handleTabChange}>
                            <Tab label={<FormattedMessage id="DETAILS" />} value={detailsTab} />
                            {
                                !orderItems || orderItems.length <= 1 ? null : (
                                    <Tab label={<FormattedMessage id="OTHER_ORDER_ITEMS" />} value={orderTab} />
                                )
                            }
                            <Tab
                                value={discussionTab}
                                label={(
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        className={classes.discussionButton}
                                        endIcon={(
                                            <DiscussionBadge
                                                relationId={parseInt(orderId, 10)}
                                                relation="order_item"
                                                contactId={user.contact}
                                                client={client}
                                            />
                                        )}
                                    >
                                        <FormattedMessage id="DISCUSSIONS" />
                                    </Button>
                                )}
                            />
                        </Tabs>
                    </AppBar>
                )
            }
            <TabPanel currentValue={selectedTab} value={detailsTab}>
                {content}
            </TabPanel>
            <TabPanel currentValue={selectedTab} value={orderTab}>
                <DialogContent>
                    <Table
                        rows={orderItems}
                        isSortable
                        size="small"
                        pagenate={false}
                        showDivisions={false}
                        uniqueField="itemId"
                        onRowClick={handleOrderItem}
                        columns={[
                            {
                                field: 'title',
                                title: 'TITLE',
                            },
                            {
                                field: 'description',
                                title: 'ASSET',
                            },
                            {
                                field: 'encodingFormat',
                                title: 'FORMAT',
                            },
                            {
                                field: 'statusDescription',
                                title: 'STATUS',
                            },
                        ]}
                    />
                </DialogContent>
            </TabPanel>
            <TabPanel currentValue={selectedTab} value={discussionTab}>
                <Discussion
                    relationId={parseInt(orderId, 10)}
                    relation="order_item"
                    client={client}
                    read={vodOrdersPermission >= 1}
                    write={vodOrdersPermission >= 2}
                    className={classes.discussion}
                    isLoading={loading}
                    disucssionType="order"
                />
            </TabPanel>
            {
                loading ? null : (
                    <DialogActions>

                        {
                            !orderDetailsTabs.includes(selectedTab) || !specDocument || isEditing ? null : (
                                <DownloadButton
                                    url={`/storage/download/${specDocument}`}
                                    apiOptions={{ scope: 'SERVICE' }}
                                >
                                    <FormattedMessage id="DO_YOU_HAVE_SPEC_FILE" />
                                </DownloadButton>
                            )
                        }
                        {
                            !orderDetailsTabs.includes(selectedTab) || !orderDocument || isEditing ? null : (
                                <DownloadButton
                                    url={`/storage/download/${orderDocument}`}
                                    apiOptions={{ scope: 'SERVICE' }}
                                >
                                    <FormattedMessage id="ORDER_DOCUMENT" />
                                </DownloadButton>
                            )
                        }
                        {
                            selectedTab !== detailsTab || !VodWrite ? null : (
                                <>
                                    {
                                        !isCancelable || isEditing || isCancelSelected ? null : (
                                            <Button onClick={handleCancelOrder}>
                                                <FormattedMessage id="CANCEL_ORDER" />
                                            </Button>
                                        )
                                    }
                                    {
                                        !isModifiable ? null : (
                                            <Button onClick={isEditing || !isCancelSelected ? handleEdit : handleCancelOrder}>
                                                <FormattedMessage id={isEditing || isCancelSelected ? 'CANCEL' : 'EDIT'} />
                                            </Button>
                                        )
                                    }
                                </>
                            )
                        }
                        <div style={{ marginRight: 'auto' }} />
                        {
                            isEditing || isCancelSelected ? (
                                <Button onClick={handleApply} disabled={!canSave}>
                                    <FormattedMessage id="SAVE" />
                                </Button>
                            ) : (
                                <Button
                                    underline="none"
                                    component={RouteLink}
                                    to={generatePath(match.path, { client })}
                                    onClick={closeModal}
                                    color="primary"
                                >
                                    <FormattedMessage id="CLOSE" />
                                </Button>
                            )
                        }

                    </DialogActions>
                )
            }
        </Dialog>
    );
}

Order.propTypes = propTypes;
Order.defaultProps = defaultProps;

export default Order;

import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import ClearIcon from '@mui/icons-material/Clear';


import InputMenuItem from 'components/InputMenuItem';
import FormSelect from 'components/FormSelect';
import DateRangePicker from 'components/DateRangePicker';
import { useUser } from 'contexts/user';
import { filter, isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        marginBottom: theme.spacing(2),
    },
    badge: {
        opacity: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        borderRadius: 'inherit',
        lineHeight: 1,
        fontSize: 'inherit',
        padding: 'inherit',
        '.MuiBadge-badge:hover > &': {
            opacity: 1,
        },
        '&, &:active, &:hover': {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main,
        },
    },
}));

const propTypes = {
    // fetchOrders: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
    fetchingOrders: PropTypes.bool.isRequired,
    clientId: PropTypes.number.isRequired,
    initialFilters: PropTypes.shape({
        titleSearch: PropTypes.string,
        status: PropTypes.arrayOf(PropTypes.number),
        deliveredTo: PropTypes.arrayOf(PropTypes.number),
        orderId: PropTypes.string,
        version: PropTypes.arrayOf(PropTypes.string), // was number but caused warning
        translation: PropTypes.arrayOf(PropTypes.string), // was number but caused warning
        statusCc: PropTypes.arrayOf(PropTypes.string), // was number but caused warning
        encodingFormat: PropTypes.arrayOf(PropTypes.number),
        product: PropTypes.arrayOf(PropTypes.string), // was number but caused warning
        contentType: PropTypes.arrayOf(PropTypes.string), // was number but caused warning
        advertisment: PropTypes.string,
        hasProblem: PropTypes.bool,
        fromStartWindow: PropTypes.string, // was number but caused warning
        toStartWindow: PropTypes.string, // was number but caused warning
        fromEndWindow: PropTypes.string, // was number but caused warning
        toEndWindow: PropTypes.string, // was number but caused warning
        toDeliveryDate: PropTypes.string, // was number but caused warning
        fromDeliveryDate: PropTypes.string, // was number but caused warning
        toOrderOn: PropTypes.string, // was number but caused warning
        fromOrderOn: PropTypes.string, // was number but caused warning
        episodeNumber: PropTypes.string, // was number but caused warning
        episodeTitle: PropTypes.string,
        seasonNumber: PropTypes.string, // was number but caused warning
        commanderPar: PropTypes.string,
    }).isRequired,
};

const defaultProps = {
};

function DistributionFilter(props) {
    const {
        clientId,
        initialFilters,
        applyFilters,
        fetchingOrders,
        defaultFilter,
    } = props;

    const { api } = useUser();
    const classes = useStyles();

    const [orderFilterTypes, setOrderFilterTypes] = React.useState({
        statuses: [],
        ccStatus: [],
        contentType: [],
        deliveredTo: [],
        encodingFormat: [],
        language: [],
        product: [],
        translationType: [],
        orderType:[],

    });
    const [fetchingFilters, setFetchingFilters] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [filtersActive, setFiltersActive] = React.useState(!isEqual(defaultFilter, initialFilters));
    const [filters, setFilters] = React.useState(initialFilters);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const {
        statuses: orderStatuses, ccStatus: orderCcStatus, contentType: orderContentType, deliveredTo: orderdeliveredTo,
        encodingFormat: orderEncodingFormat, language: orderLanguage, product: orderProduct,
        translationType: orderTranslationType, orderType : orderTypes,
    } = orderFilterTypes;

    async function fetchFilters(abortController = new AbortController()) {
        if (clientId) {
            try {
                setFetchingFilters(true);
                setFailed(false);

                const response = await api.get(`/distribution/orders/${clientId}/filter`, {}, { signal: abortController.signal });

                if (response.ok) {
                    const data = await response.json();
                    setOrderFilterTypes(data);
                } else {
                    setFailed(true);
                    setOrderFilterTypes({});
                }

                setFetchingFilters(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchFilters(abortController);
        // fetchOrders(abortController);
        return () => {
            abortController.abort();
        };
    }, [clientId]);

    function handleInputChange({ target: { name, value } }) {
        setFilters({ ...filters, [name]: value });
    }

    function handleHasProblemeChange({ target: { name, checked } }) {
        setFilters({ ...filters, [name]: checked ? true : null });
    }

    const handleMenuClick = React.useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    function handleSubmit(event) {
        event.preventDefault();
        handleMenuClose();
        applyFilters(filters);
        setFiltersActive(true);
    }

    function clearFilters(event) {
        event.stopPropagation();
        handleMenuClose();
        setFilters(defaultFilter);

        if (filtersActive) {
            applyFilters(defaultFilter);
        }
    }

    const {
        titleSearch, status, deliveredTo, orderId,
        version, translation, statusCc, encodingFormat,
        product, contentType, advertisment, fromStartWindow,
        toStartWindow, fromEndWindow, toEndWindow, toDeliveryDate,
        fromDeliveryDate, toOrderOn, fromOrderOn, episodeNumber,
        episodeTitle, seasonNumber, commanderPar, poNumber, hasProblem, orderType,
    } = filters;

    const filterCount = React.useMemo(() => (
        (Object.keys(filters || {}).filter((key) => (filters[key] && !isEmpty(filters[key]))) || []).length + (filters.hasProblem ? 1 : 0)
    ), [filters]);

    return (
        <>
            <Grid
                component="form"
                onSubmit={handleSubmit}
                container
                spacing={1}
                alignItems="center"
            >
                <Grid item xs lg>
                    <TextField
                        margin="dense"
                        variant="outlined"
                        type="text"
                        name="titleSearch"
                        size="small"
                        fullWidth
                        value={titleSearch}
                        label={<FormattedMessage id="TITLE" />}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs lg={3}>
                    <FormSelect
                        multiple
                        fullWidth
                        margin="dense"
                        variant="outlined"
                        size="small"
                        onChange={handleInputChange}
                        name="status"
                        value={status}
                        label={<FormattedMessage id="STATUS" />}
                    >
                        {
                            orderStatuses.map(({ idStatutItem, description }) => (
                                <MenuItem value={idStatutItem} key={idStatutItem}>
                                    {description}
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </Grid>
                <Grid item xs lg={3}>
                    <FormSelect
                        fullWidth
                        size="small"
                        label={<FormattedMessage id="ORDER_TYPE" />}
                        name="orderType"
                        value={orderType}
                        // multiple
                        clearable
                        onChange={handleInputChange}
                    >
                        {
                            orderTypes.map(({ id, description }) => (
                                <MenuItem value={id} key={id}>
                                    {/* Mapped to ORDER_TYPE_ADAPTATION, ORDER_TYPE_CC, ORDER_TYPE_DELIVERY, ORDER_TYPE_SUBTITLING, ORDER_TYPE_DESC_VIDEO, ORDER_TYPE_VAULT_ENTRY, ORDER_TYPE_VAULT_REMOVAL */}
                                    <FormattedMessage id={`ORDER_TYPE_${id}`}/>
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </Grid>
                <Grid item>
                    <Badge
                        color="primary"
                        overlap="circular"
                        invisible={filterCount < 1}
                        badgeContent={(
                            <>
                                <span>
                                    {filterCount}
                                </span>
                                <IconButton size="small" className={classes.badge} onClick={clearFilters} color="error">
                                    <ClearIcon fontSize="inherit" />
                                </IconButton>
                            </>
                        )}
                    >
                        <IconButton onClick={handleMenuClick} variant="outlined" size="large">
                            <FilterListIcon />
                        </IconButton>
                    </Badge>
                </Grid>
                <Grid item>
                    <Button
                        size="small"
                        disabled={fetchingFilters || fetchingOrders}
                        type="submit"
                        color="primary"
                        variant="outlined"
                    >
                        <FormattedMessage id="SEARCH" />
                    </Button>
                </Grid>
            </Grid>
            <Drawer
                anchor="right"
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <List style={{ height: '100%', overflow: 'auto' }}>
                    <ListItem dense>
                        <TextField
                            fullWidth
                            name="poNumber"
                            size="small"
                            value={poNumber}
                            label={<FormattedMessage id="PO_NUMBER" />}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <TextField
                            name="orderId"
                            fullWidth
                            value={orderId}
                            label={<FormattedMessage id="DELIVERIES_NUMBER" />}
                            size="small"
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <TextField
                            name="episodeTitle"
                            fullWidth
                            value={episodeTitle}
                            label={<FormattedMessage id="EPISODE_TITLE" />}
                            size="small"
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <FormSelect
                            fullWidth
                            size="small"
                            label={<FormattedMessage id="CC_STATUS" />}
                            name="statusCc"
                            value={statusCc}
                            multiple
                            onChange={handleInputChange}
                        >
                            {
                                orderCcStatus.map(({ id, description }) => (
                                    <MenuItem value={id} key={id}>
                                        {description}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </ListItem>
                    <ListItem dense>
                        <FormSelect
                            multiple
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            size="small"
                            onChange={handleInputChange}
                            name="deliveredTo"
                            value={deliveredTo}
                            label={<FormattedMessage id="DESTINATION" />}
                        >
                            {
                                orderdeliveredTo.map(({ id, nom }) => (
                                    <MenuItem value={id} key={id}>
                                        {nom[0].toUpperCase() + nom.substr(1).toLowerCase()}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </ListItem>
                    <ListItem dense>
                        <FormSelect
                            fullWidth
                            size="small"
                            label={<FormattedMessage id="VERSION" />}
                            name="version"
                            value={version}
                            multiple
                            onChange={handleInputChange}
                        >
                            {
                                orderLanguage.map(({ id, description }) => (
                                    <MenuItem value={id} key={id}>
                                        {description}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </ListItem>
                    <ListItem dense>
                        <FormSelect
                            fullWidth
                            size="small"
                            label={<FormattedMessage id="TRANSLATION_TYPE" />}
                            name="translation"
                            value={translation}
                            multiple
                            onChange={handleInputChange}
                        >
                            {
                                orderTranslationType.map(({ id, description }) => (
                                    <MenuItem value={id} key={id}>
                                        {description}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </ListItem>
                    <ListItem dense>
                        <FormSelect
                            fullWidth
                            size="small"
                            label={<FormattedMessage id="CONTENT_TYPE" />}
                            name="contentType"
                            value={contentType}
                            multiple
                            onChange={handleInputChange}
                        >
                            {
                                orderContentType.map(({ id, description }) => (
                                    <MenuItem value={id} key={id}>
                                        {description}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </ListItem>
                    <ListItem dense>
                        <FormSelect
                            fullWidth
                            size="small"
                            label={<FormattedMessage id="PRODUCT" />}
                            name="product"
                            value={product}
                            multiple
                            onChange={handleInputChange}
                        >
                            {
                                orderProduct.map(({ xmlProduit }) => (
                                    <MenuItem value={xmlProduit} key={xmlProduit}>
                                        {xmlProduit}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </ListItem>
                    <ListItem dense>
                        <FormControlLabel
                            margin="none"
                            control={<Checkbox checked={!!hasProblem} onChange={handleHasProblemeChange} name="hasProblem" />}
                            label={<FormattedMessage id="HAS_PROBLEM" />}
                        />
                    </ListItem>
                    <ListItem dense>
                        <DateRangePicker
                            clearable
                            fullWidth
                            inputFormat="yyyy-MM-dd"
                            inputVariant="outlined"
                            size="small"
                            margin="dense"
                            disableToolbar
                            startValue={fromStartWindow}
                            startName="fromStartWindow"
                            startLabel={<FormattedMessage id="START_WINDOW" />}
                            endName="toStartWindow"
                            endValue={toStartWindow}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <DateRangePicker
                            clearable
                            fullWidth
                            inputFormat="yyyy-MM-dd"
                            inputVariant="outlined"
                            size="small"
                            margin="dense"
                            disableToolbar
                            startValue={fromEndWindow}
                            startName="fromEndWindow"
                            startLabel={<FormattedMessage id="END_WINDOW" />}
                            endName="toEndWindow"
                            endValue={toEndWindow}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <DateRangePicker
                            clearable
                            fullWidth
                            inputFormat="yyyy-MM-dd"
                            inputVariant="outlined"
                            size="small"
                            margin="dense"
                            disableToolbar
                            startValue={fromDeliveryDate}
                            startName="fromDeliveryDate"
                            startLabel={<FormattedMessage id="ESTIMATED_DELIVERY" />}
                            endName="toDeliveryDate"
                            endValue={toDeliveryDate}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <DateRangePicker
                            clearable
                            fullWidth
                            inputFormat="yyyy-MM-dd"
                            inputVariant="outlined"
                            size="small"
                            margin="dense"
                            disableToolbar
                            startValue={fromOrderOn}
                            startName="fromOrderOn"
                            startLabel={<FormattedMessage id="ORDERED_ON" />}
                            endName="toOrderOn"
                            endValue={toOrderOn}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <TextField
                            fullWidth
                            name="commanderPar"
                            size="small"
                            value={commanderPar}
                            label={<FormattedMessage id="ORDER_BY" />}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <TextField
                            fullWidth
                            name="advertisment"
                            size="small"
                            value={advertisment}
                            label={<FormattedMessage id="ADVISORY" />}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <TextField
                            fullWidth
                            name="seasonNumber"
                            size="small"
                            value={seasonNumber}
                            label={<FormattedMessage id="SEASON_NUMBER" />}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <TextField
                            fullWidth
                            name="episodeNumber"
                            size="small"
                            value={episodeNumber}
                            label={<FormattedMessage id="EPISODE_NUMBER" />}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                </List>
                <ListItem>
                    <Grid container justifyContent="flex-end" spacing={2} alignItems="center">
                        <Grid item>
                            <Button
                                onClick={clearFilters}
                                variant="text"
                            >
                                <FormattedMessage id="CANCEL" />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={handleSubmit}
                                disabled={fetchingFilters || fetchingOrders}
                                type="submit"
                                color="primary"
                                variant="contained"
                            >
                                <FormattedMessage id="SEARCH" />
                            </Button>
                        </Grid>
                    </Grid>
                </ListItem>
            </Drawer>
        </>
    );
}

DistributionFilter.propTypes = propTypes;
DistributionFilter.defaultProps = defaultProps;

export default DistributionFilter;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import throttle from 'lodash/throttle';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';


import { useUser } from 'contexts/user';

function ExternalTitleSearch(props) {
    const {
        label, onChange, value, name,
        fullWidth, variant, margin, year,
        onOptionSelect,
        error, helperText,
    } = props;

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [isFetching, setIsFetching] = React.useState(false);
    const { api } = useUser();


    async function fetchTitles(request, callback, abortController = new AbortController()) {
        try {
            const response = await api.get('/metadata', { title: request.value, quickSearch: true, year: request.year }, { signal: abortController.signal });

            if (response.ok) {
                const data = await response.json();
                callback(data);
            }

        } catch (error) {
            setIsFetching(false);
            if (!abortController.signal.aborted) {
                console.error(error);
            }
        }

    }

    async function fetchTitle(option, abortController = new AbortController()) {
        try {
            const response = await api.get(`/metadata/${option.value}`, { includePoster: true }, { signal: abortController.signal });
            if (response.ok) {
                const data = await response.json();
                const posterUrl = await api.get(`/metadata/${option.value}/poster`);
                return {...data, originalPoster: posterUrl.url, poster: option.value};
            }

        } catch (error) {
            if (!abortController.signal.aborted) {
                console.error(error);
            }
            return {
                'TITLE': [...option.altTitles, { value: option.label }],
                'PROD_YEAR': option.year,
                'POSTER': option.value,
            }
        }
    }

    const fetch = React.useMemo(() =>
        throttle((request, callback, abortController) => {
            fetchTitles(request, callback, abortController);
        }, 500),
    []);

    const handleOptionSelected = React.useCallback(async (event, selectedOption, action) => {

        if (action === 'clear') {
            setOptions([]);
            onOptionSelect({
                'TITLE': [{ value: '' }],
                'PROD_YEAR': null,
                'POSTER': null,
            });
            // onChange('');
        } else if (action === 'selectOption') {
            let title = {};
            if (selectedOption.inputValue) {
                title = {
                    'TITLE': [{ value: selectedOption.inputValue }],
                }

            } else {
                title = await fetchTitle(selectedOption);
            }
            if (title) {
            onOptionSelect(title);
            }
        }
    }, []);

    React.useEffect(() => {
        const abortController = new AbortController();
        let active = true;
        if (value === '') {
            setOptions(value ? [value] : []);
            onOptionSelect({
                'TITLE': [{ value: '' }],
            });
            return undefined;
        }

        const callback = (data) => {
            if (active) {
                let newOptions = [];

                if (data) {
                  newOptions = [...newOptions, ...data];
                }
                setOptions(newOptions);
                setIsFetching(false);
            }
        };
        setIsFetching(true);
        fetch({year, value}, callback, abortController);

        return () => {
            abortController.abort();
            active = false;
            setIsFetching(false);
        };
    }, [value, fetch]);

    return (

        <Autocomplete
            clearOnBlur
            selectOnFocus
            freeSolo


//             getOptionLabel={(option) => (option.nom || (isString(option) ? option : ''))}
//             renderOption={(props, option) => {
//                 if (typeof option === 'string') {
//                     return (
//                         <li {...props}>
//                             {option}
//                         </li>
//                     );
//                 }
//
//                 if (option.inputValue) {
//                     return (
//                         <li {...props}>
//                             <span>
//                                 <FormattedMessage
//                                     id="NEW_DESTINATION_X"
//                                     values={{ name: <em>{option.inputValue}</em> }}
//                                 />
//                             </span>
//                         </li>
//                     );
//                 }
//
//                 return (
//                     <li {...props}>
//                         {option.nom}
//                     </li>
//                 );
//             }}
//             filterOptions={(options, params) => {
//                 const filtered = filterOptions(options, params);
//
//                 // Suggest the creation of a new value
//                 if (params.inputValue !== '') {
//                     filtered.push({
//                         inputValue: params.inputValue,
//                         nom: params.inputValue,
//                     });
//                 }
//
//                 return filtered;
//             }}


            isOptionEqualToValue={(option, v) => option.value === v.value}
            renderOption={(props, option) => {
                if (typeof option === 'string') {
                    return (
                        <MenuItem {...props}>
                            <ListItemText
                                primary={option}
                            />
                        </MenuItem>
                    );
                }

                if (option.inputValue) {
                    return (
                        <MenuItem {...props}>
                            <ListItemText
                                primary={
                                    <FormattedMessage
                                        id="NEW_TITLE_X"
                                        values={{ name: <em>{option.inputValue}</em> }}
                                    />
                                }
                            />
                        </MenuItem>
                    );
                }
                return (
                    <MenuItem {...props} key={props.id} >
                        <ListItemText
                            primary={`${option.label} (${option.year})`}
                            secondary={!option.altTitles || option.altTitles.length < 1 ? undefined : (
                                <>
                                    {'alt: '}
                                    {
                                        option.altTitles.map(({ language, value }, index) => <span key={index}>{value}</span>)
                                    }
                                </>
                            )}
                        />
                    </MenuItem>
                )
            }}

            onChange={handleOptionSelected}
            filterOptions={(x, params) => {
                const filtered = x;
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        label: params.inputValue,
                    });
                }

                return filtered;
            }}
            options={options}

            autoComplete
            includeInputInList
            // margin={margin}
            // variant={variant}
            // fullWidth={fullWidth}
            // name={name}
            // onInputChange={onChange}
            // value={value}


            renderInput={(params) => (
                <TextField
                    {...params}
                    margin={margin}
                    variant={variant}
                    fullWidth={fullWidth}
                    name={name}
                    value={value}
                    onChange={onChange}
                    label={label}
                    error={error}
                    helperText={helperText}

                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                  }}
                />
            )}
        />
    );
}

export default ExternalTitleSearch;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';

import { useUser } from 'contexts/user';
import Table from 'components/Table';

const propTypes = {
    titles: PropTypes.arrayOf(PropTypes.number).isRequired,
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

const defaultProps = {
};


function DistributionProceed(props) {
    const { isOpen, titles, closeModal } = props;
    const { client } = useParams();
    const { api, user: { contact } } = useUser();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [selectedAssets, setSelectedAssets] = React.useState([]);
    const [assets, setAssets] = React.useState([]);
    const [fetchingAssets, setFetchingAssets] = React.useState(false);
    const [failed, setFailed] = React.useState(false);

    async function fetchAssets(abortController = new AbortController()) {
        if (client && titles) {
            try {
                setFetchingAssets(true);
                setFailed(false);
                const response = await api.get(`${client}/forest/`, { entityIds: titles }, { signal: abortController.signal });
                if (response.ok) {
                    const data = await response.json();
                    setAssets(data);
                } else {
                    setAssets([]);
                    setFailed(true);
                }
                setFetchingAssets(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    async function addToWorkspace() {
        if (client && selectedAssets && contact) {
            const snackId = enqueueSnackbar(<FormattedMessage id="ADDING_TO_WORKSPACE" />, { variant: 'loading', persist: true });

            const response = await api.post(`/distribution/workspace/${client}`, { asset_ids: selectedAssets, contact_id: contact });
            if (response.ok) {
                closeSnackbar(snackId);
                enqueueSnackbar(<FormattedMessage id="ADDED_TO_WORKSPACE" />, { variant: 'success' });
                closeModal();
                setSelectedAssets([]);
            } else {
                closeSnackbar(snackId);
                enqueueSnackbar(<FormattedMessage id="ERROR_ADDING_TO_WORKSPACE" />, { variant: 'error' });
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        if (isOpen) {
            fetchAssets(abortController);
        }
        return () => {
            abortController.abort();
        };
    }, [isOpen]);

    return (
        <Dialog open={isOpen} maxWidth="md" fullWidth>
            {
                !isEmpty(assets) || fetchingAssets ? null : (
                    <FormattedMessage id="NO_ASSET" />
                )
            }
            {
                isEmpty(assets) && !fetchingAssets ? null : (
                    <>
                        <DialogTitle>
                            <FormattedMessage id="SELECT_ASSETS" />
                        </DialogTitle>
                        <DialogContent>
                            <Table
                                isSortable
                                rows={assets}
                                pagenate={false}
                                uniqueField="id"
                                isSelectable
                                isLoading={fetchingAssets}
                                selected={selectedAssets}
                                setSelected={setSelectedAssets}
                                columns={[
                                    {
                                        field: 'parentTitle',
                                        title: 'ORIGINAL_TITLE',
                                    },
                                    {
                                        field: 'assetDescription',
                                        title: 'DESCRIPTION',
                                    },
                                ]}

                            />
                        </DialogContent>

                    </>
                )
            }
            <DialogActions>
                <Button
                    name="cancel"
                    onClick={closeModal}
                >
                    <FormattedMessage id="CANCEL" />
                </Button>
                <Button
                    name="Send"
                    color="primary"
                    onClick={addToWorkspace}
                    disabled={isEmpty(selectedAssets)}
                >
                    <FormattedMessage id="ADD" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DistributionProceed.propTypes = propTypes;
DistributionProceed.defaultProps = defaultProps;

export default DistributionProceed;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import uniqueId from 'lodash/uniqueId';
import isEmpty from 'lodash/isEmpty';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme) => createStyles({
    buttonLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));

function ImageInput(props) {
    const classes = useStyles();
    const [fileInputId] = React.useState(uniqueId('file-input-'));
    const [src, setSrc] = React.useState(null);
    const {
        name, style, onChange, variant,
        className, fileList, accept='image/*', label,
        fullWidth, originalSrc, deleteEnabled, alt,
    } = props;

    React.useEffect(() => {
        if (fileList && fileList instanceof FileList && fileList[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSrc(e.target.result);
            };

            reader.readAsDataURL(fileList[0]); // convert to base64 string
        } else {
            setSrc(null);
        }
    });

    const clearImage = React.useCallback(() => onChange({ target: { files: undefined } }), [name, onChange]);
    const deleteImage = React.useCallback(() => onChange({ target: { files: null } }), [name, onChange]);

    const hasFile = !isEmpty(fileList) && fileList[0];
    return <>
        <img
            alt={alt}
            onError={()=>{setSrc(null)}}
            src={src || originalSrc}
            style={style}
            className={className}
        />
        <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs>
                <label htmlFor={fileInputId}>
                    <input
                        name={name}
                        style={{ display: 'none' }}
                        id={fileInputId}
                        type="file"
                        accept={accept}
                        onChange={onChange}
                        multiple={false}
                    />
                    <Button
                        size="small"
                        variant={variant}
                        component="span"
                    >
                        <span className={classes.buttonLabel}>
                            {
                                label ? label : <FormattedMessage id="UPLOAD_FILE" />
                            }
                        </span>
                    </Button>
                </label>
            </Grid>
            <Grid item>
                {
                    !hasFile ? null : (
                        <IconButton size="small" className={classes.iconButton} onClick={clearImage}>
                            <ClearIcon size="small" />
                        </IconButton>
                    )
                }
                {
                    hasFile || !deleteEnabled ? null : (
                        <IconButton size="small" className={classes.iconButton} onClick={deleteImage}>
                            <DeleteForeverIcon size="small" />
                        </IconButton>
                    )
                }
            </Grid>
        </Grid>
    </>;
}

export default ImageInput;

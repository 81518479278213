import React from 'react';

import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
    //'7f17baa36cf7f11c039e395bb4c2c913T1JERVI6MjczMDIsRVhQSVJZPTE2NTgzMzQ2MTcwMDAsS0VZVkVSU0lPTj0x',
    // 'ca12f8176c76334f723598bfe04edff5Tz01MTY3NixFPTE2OTYwODY2NzIzMTUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
    '1af621270ec4d5add3b152e9f48964bcTz03NTcxNCxFPTE3Mjc2MzgwMTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

 if (process.env.NODE_ENV === 'development') {
     const whyDidYouRender = require('@welldone-software/why-did-you-render');
     whyDidYouRender(React);
 }

import ReactDOM from 'react-dom';


import CURRENT from 'utils/environment';
import MainRoute from 'routes/MainRoute';

if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill');
    require('@formatjs/intl-pluralrules/locale-data/fr'); // Add locale data for fr
    require('@formatjs/intl-pluralrules/locale-data/en'); // Add locale data for en
}

if (!Intl.RelativeTimeFormat) {
    require('@formatjs/intl-relativetimeformat/polyfill');
    require('@formatjs/intl-relativetimeformat/locale-data/fr'); // Add locale data for fr
    require('@formatjs/intl-relativetimeformat/locale-data/en'); // Add locale data for en
}

const rootEl = document.getElementById('react-dom-container');

const render = (Component) => {
    console.info(`Init App Version: ${CURRENT.appVersion()}`);
    ReactDOM.render(
        <Component />,
        rootEl,
    );
};


render(MainRoute);

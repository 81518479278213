import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Toolbar from '@mui/material/Toolbar';
import { FormattedMessage, useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Helmet } from 'react-helmet';

import { useUser } from 'contexts/user';
import ContactList from './List';
import AddContact from './Add';
import ModifyContact from './Modify';
import RemoveContact from './Remove';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    card: {
        alignSelf: 'flex-start',
    }
}));

const propTypes = {
};

function Contacts(props) {
    const { currentClient } = props;
    const { api, user } = useUser();
    const classes = useStyles();
    const [selectedContacts, setSelectedContacts] = React.useState([]);
    const [actionRunning, setActionRunning] = React.useState(false);
    const [clientDetails, setClientDetails] = React.useState({});
    const [failed, setFailed] = React.useState({});
    const intl = useIntl();

    const fetchClientDetails = React.useCallback(async (abortController = new AbortController()) => {
        if (currentClient) {
            try {
                setActionRunning(true);
                setFailed(false);
                setSelectedContacts([]);
                const response = await api.get(`/contacts/${currentClient}`, {}, { signal: abortController.signal });
                if (response.ok) {
                    const data = await response.json();
                    setClientDetails(data);
                } else {
                    setClientDetails({});
                    setFailed(true);
                }

                setActionRunning(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }, [currentClient]);

    const userPermission = user.clients.find((c) => c.client === currentClient);
    const managementPermission = userPermission.clientManagement;
    const writeManagement = managementPermission === 2;

    return (
        <div className={classes.root}>
            <Card>
                <Helmet>
                    <title>
                        {`Melodie - ${intl.formatMessage({ id: 'USERS' })}`}
                    </title>
                </Helmet>
                <div className={classes.root}>
                    <Toolbar>
                        <Grid
                            container
                            justifyContent="flex-end"
                            spacing={2}
                        >
                            <Grid item>
                                <AddContact
                                    userName={user.name}
                                    fetchClientDetails={fetchClientDetails}
                                    clientName={userPermission.name}
                                    currentClient={currentClient}
                                    userPermission={managementPermission}
                                    setActionRunning={setActionRunning}
                                    clientDetails={clientDetails}
                                    actionRunning={actionRunning}
                                />
                            </Grid>
                            <Grid item>
                                <ModifyContact
                                    clientDetails={clientDetails}
                                    userName={user.name}
                                    clientName={userPermission.name}
                                    currentClient={currentClient}
                                    userPermission={managementPermission}
                                    selectedContacts={selectedContacts}
                                    fetchClientDetails={fetchClientDetails}
                                    actionRunning={actionRunning}
                                    setActionRunning={setActionRunning}
                                />
                            </Grid>
                            <Grid item>
                                <RemoveContact
                                    userName={user.name}
                                    clientName={userPermission.name}
                                    currentClient={currentClient}
                                    userPermission={managementPermission}
                                    selectedContacts={selectedContacts}
                                    clientDetails={clientDetails}
                                    fetchClientDetails={fetchClientDetails}
                                    actionRunning={actionRunning}
                                    setActionRunning={setActionRunning}
                                />
                            </Grid>
                        </Grid>
                    </Toolbar>
                    <ContactList
                        writeManagement={writeManagement}
                        currentClient={currentClient}
                        handleSelection={setSelectedContacts}
                        selectedContacts={selectedContacts}
                        clientDetails={clientDetails}
                        fetchClientDetails={fetchClientDetails}
                        actionRunning={actionRunning}
                        setActionRunning={setActionRunning}
                    />
                </div>
            </Card>
        </div>
    );
}

Contacts.propTypes = propTypes;

export default Contacts;

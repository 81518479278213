import isEmpty from 'lodash/isEmpty';

export function validateEmail(email) {
    return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(email);
}

export const newReleaseSection = 'NEW_RELEASE';
export const defaultSection = '';
export const fileDelivery = 'FILE';
export const tapeDelivery = 'PHYSICAL_ELEMENT';

export const deliveryTypes = [fileDelivery, tapeDelivery];
export const defaultDeliveryType = fileDelivery;


// services
// export const orderTitleService = 'DELIVERY';
export const vaultService = 'VAULT_ENTRY';
export const subtitleService = 'SUBTITLING';
export const adaptationService = 'ADAPTATION';
export const closedCaptionService = 'CC';
export const videoDescriptionService = 'DESC_VIDEO';
export const versionService = 'NEW_ELEMENT';
export const catalogueElementService = 'CATALOGUE_ELEMENT';

export const singleSelectServices = [adaptationService, subtitleService, videoDescriptionService, closedCaptionService];
export const creationServices = [adaptationService, subtitleService, videoDescriptionService, closedCaptionService];
// export const freeSelectServices = [orderTitleService, vaultService];
export const freeSelectServices = [catalogueElementService, vaultService, versionService];
export const languageSelectionServices = [subtitleService]

export const commonLocales = ['en', 'fr-ca', 'fr'];
export const resolutionOptions = ['HD', 'UHD', 'SD', 'IDK'];
export const frameRateOption = ['23.98', '24', '25i', '25p', '29.97i', '29.97p', 'IDK'];
export const VERSIONS = [ 'VERSION_EX', 'VERSION_SENS', 'SHORT', 'EXTENDED', 'INTERNATIONAL', 'THEATRICAL', 'CONTINUE' ]
export const versionsTrailer = [ 'NONE','WITH_DATE', 'WITHOUT_DATE', 'LAUNCH', 'PRE_LAUNCH' ]
export const commonTypes = ['MOVIE', 'SERIES'];
export const ccOptions = ['NONE', 'PARTIAL', 'FULL'];

export const NEVER = 'N';
export const OPTIONAL = 'O';
export const ALWAYS = 'A';
export const IDK = 'IDK';
export const TEXTLESS = 'Textless';
export const AUDIO = 'AUDIO';
export const MONO = 'MONO';
export const STEREO = 'STEREO';
export const AUDIO51 = '51';
export const MONO_SHORT = '1.0';
export const STEREO_SHORT = '2.0';
export const AUDIO51_SHORT = '5.1';
export const DESCRIBED_VIDEO = 'DESC_VIDEO';
export const MUSIC_AND_EFFECTS = 'M&E';

export const CC_ASSET_TYPE = 'CC';
export const SUBTITLE_ASSET_TYPE = 'SUBTITLE';
export const AUDIO_ASSET_TYPE = 'AUDIO';

export const SECTIONS = [
    { value: 'NEW_RELEASE', label: 'NEW_RELEASE' },
    { value: 'LIBRARY', label: 'LIBRARY' },
];

export const PRODUCTS = [
    { value: 'SVOD', label: 'SVOD' },
    { value: 'TVOD', label: 'TVOD' },
    { value: 'CVOD', label: 'CVOD' },
];

export const emptyAsset = {
    assetIndex: (new Date()).valueOf(),
    assetType: '',
    tvDefinition: '',
    assetLanguage: '',
    translationTypes: '',
    frameRate: '',
    ratio: '',
    runTime: '',
    element: '',
    version: '',
    season: '',
    seasonTitle: '',
    episode: '',
    episodeTitle: '',
    partNumber: '',
    audioTrack: [],
    partNumner: '',
    isPartial: '',
};


export const initialState = {
    selectedService: null,
    jumpIndex:0,
    fileIndex:0,
    jump: false,
    targetLanguage: '',
    currentServiceIndex: 0,
    selectedTitles: [],
    assetsDescription: [],
    titlesDescription: [],
    selectedTitlesInfo: [],
    selectedAssets: [],
    newTitle: {
        title: null,
        originalLanguage: null,
        entityType: null,
        numberChronological: null,
        numberProduction: null,
        prodYear: null,
        countryOrigin: null,
        ratingBC: null,
        ratingON: null,
        ratingQC: null,
        runtime: null,
        studio: null,
        genre: null,
        actor: null,
        director: null,
        producer: null,
        writer: null,
        shortDesc: null,
        summary: null,
        poster: null,
    },
    assetList: [],
    newAsset: {
        assetType: '',
        hasCc: '',
        hasSt: '',
        tvDefinition: '',
        assetLanguage: '',
        translationTypes: '',
        frameRate: '',
        ratio: '',
        runTime: '',
        hasTextless: '',
        selectedtitle:'',
        assetValidOption:[],
        isPartial: '',
    },
    destination: [
        {
            sendTo: [],
            jointFile: '',
            deliveryClient: '',
            deliveryDate: null,
            receptionDate: null,
            deliveryInfoSupplied: false,
            section: defaultSection,
            isnew: false,
            virtualVaultEntry: false,
            externalRecipient: false,
            redirect: false,
            poNumber: '',
            tvodsvod: '',
            notes: '',
            startDate: null,
            endDate: null,
            workFile: null,
            specFile: false,
        },
    ],
    outputData: [
        {
            piste: [],
            loudness: '',
            codec: '',
            codecAutre: '',
            bitrate: '',
            wrapper: '',
            wrapperAutre: '',
            frame: '',
            textless: false,
            encodageNoHearing: false,
            scanningMethod: '0',
            otherSpecification: '',
            rubanType: '',
            rubanTypeAutre: '',
            ardoise: '',
        },
    ],
    delivery:
    [
        {
            deliveryType: 'DELIVERY',
            deliverTo: '',
            tapeDeliveryType: '',
            fileDeliveryType: '',
            hardDiskDeliveryType: '',
            otherDelivery: '',
            deliveryAddress: '',
        },
    ]

};

export function entityTypeElementOptions(entityType, options) {
    if (entityType === 'SERIES') {
        return [ 'EPISODE', 'TRAILER' ];
    }
    return [ 'TRAILER', 'ORIGINAL_VERSION', 'OTHER_VERSION', 'PART', 'PREVIEW' ];
}

export function serviceAssetTypes(selectedService, options) {
    if (selectedService === vaultService) {
        return options.filter(({assetTypeId}) => ['VIDEO', 'AUDIO', 'SUBTITLE', 'CC',].includes(assetTypeId));
    } else if (selectedService === adaptationService) {
        return options.filter(({assetTypeId}) => ['VIDEO'].includes(assetTypeId));
    } else if (selectedService === subtitleService) {
        return options.filter(({assetTypeId}) => ['SUBTITLE'].includes(assetTypeId));
    }
    return options.filter(({assetTypeId}) => ['VIDEO', 'AUDIO', 'SUBTITLE', 'CC',].includes(assetTypeId));
}

export function ensureDefaultUndefined(data) {
    let newData = data;
    if (Array.isArray(data)) {
        newData = data.map((d) => ensureDefaultUndefined(d));
    } else if (data instanceof Object) {
        newData = {};
        Object.entries(data).forEach(([k,v]) => newData[k] = ensureDefaultUndefined(v));
    } else if (data === '') {
        newData = undefined;
    }


    return newData
}

import React from 'react';
import { useParams } from 'react-router-dom';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useUser } from 'contexts/user';
import Table from 'components/Table';
import { singleSelectServices, freeSelectServices, languageSelectionServices, vaultService } from './constants';
import RadioMulti from 'components/RadioMulti';
import { useConstants } from 'contexts/constants'
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';

import AssetDescription from 'components/Distribution/Assets/Description';

const commonLocales = ['en', 'fr-ca', 'fr'];

const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'grid',
        gridTemplateRows: 'auto max-content',
        height: '100%',
    },
}));

function AssetSelection(props) {
    const {
        selectedService, selectedTitles, selectedAssets, onChange,
        setSelectedAssets, setAssetsDescription, setTargetLanguage, targetLanguage
    } = props;

    const classes = useStyles();
    const { client } = useParams();
    const { api } = useUser();
    const [assets, setAssets] = React.useState([]);
    const [fetchingAssets, setFetchingAssets] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const { locales } = useConstants();

    const singleSelection = singleSelectServices.includes(selectedService);
    const enableLangueSelection = languageSelectionServices.includes(selectedService)

    async function fetchAssets(abortController = new AbortController()) {
        if (client && selectedTitles) {
            try {
                setFetchingAssets(true);
                setFailed(false);
                const response = await api.get(`${client}/forest/`, { entityIds: selectedTitles }, { signal: abortController.signal });
                if (response.ok) {
                    const data = await response.json();
                    const parsedData = data.filter((asset) => {
                        if (selectedService === vaultService && (asset.isInVault || 'PACKAGE' === asset.assetTypeId)) {
                            return false;
                        }
                        if (!freeSelectServices.includes(selectedService)) {
                            return ['VIDEO', 'PACKAGE'].includes(asset.assetTypeId);
                        }
                        return true;
                    })
                    setAssets(parsedData);
                    setAssetsDescription(parsedData.filter((a) => selectedAssets.includes(a.id) ));
                } else {
                    setAssets([]);
                    setFailed(true);
                }
                setFetchingAssets(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchAssets(abortController);

        return () => {
            abortController.abort();
        };
    }, [selectedTitles, selectedService]);

    React.useEffect(() => {
        const abortController = new AbortController();
        const assetsSel = assets.filter((a) => selectedAssets.includes(a.id) )
        setAssetsDescription(assetsSel);
        const localIdsSelected = assetsSel.map(({ localeId }) => localeId ).filter((val) => !isEmpty(val));

        setTargetLanguage(isEmpty(localIdsSelected) ? undefined : localIdsSelected[0]);

        return () => {
            abortController.abort();
        };
    }, [selectedAssets]);

    const handleOnSelect = React.useCallback((valueOrFunction) => {
        onChange(valueOrFunction);
    }, [onChange]);

    const handlelang = React.useCallback(({ target : { name, value } }) => {
        setTargetLanguage(value);
    }, []);

    return (
        <div>
            <div className={classes.root}>
                <Table
                    multiple={!singleSelection}
                    pagenate={false}
                    uniqueField="id"
                    isSelectable
                    showDivisions={false}
                    isLoading={fetchingAssets}
                    selected={selectedAssets}
                    setSelected={handleOnSelect}
                    rows={assets}
                    emptyMessage="NO_ASSETS_AVAILABLE"
                    columns={[
                        {
                            field: 'parentTitle',
                            title: 'ORIGINAL_TITLE',
                        },
                        {
                            field: 'assetDescription',
                            title: 'DESCRIPTION',
                            Component: AssetDescription
                        },
                    ]}

                />
                { !enableLangueSelection ? false :
                    <Grid item xs={12} sx={(theme) => ({padding: theme.spacing(0,1)})}>
                        <RadioMulti
                            row
                            label={<FormattedMessage id="DESTINATION_LANGUAGE" />}
                            fullWidth
                            size="small"
                            name="assetLanguage"
                            value={targetLanguage}
                            onChange={handlelang}
                            commonValues={commonLocales}
                            options={locales}
                        />
                    </Grid>
                }
            </div>
        </div>
    );
}

export default AssetSelection;

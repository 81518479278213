import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';

import { useUser } from 'contexts/user';
import RouteLink from 'components/RouteLink';

const useStyles = makeStyles((theme) => createStyles({
    CheckboxDiv: {
        '&:hover .MuiCheckbox-root:first-child': {
            opacity: 1,
            pointerEvents: 'auto',
        },
    },
    Indented: {
        marginLeft: theme.spacing(3),
    },
    InputCheck: {
        transition: `opacity ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
        padding: '0rem',
        margin: '0rem',
        '&.Mui-checked': {
            opacity: 1,
            pointerEvents: 'auto',

        },
        '&:hover': {
            opacity: 1,
            pointerEvents: 'auto',

        },

    },
    EntityIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
    },
    activeLink: {
        color: theme.palette.primary.main,
    },
    notFound: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
    },
}));

const defaultTreeState = { children: [], assets: [], entityId: '' };

const tempTree = {
    entityId: 'skel-0',
    width: '60%',
    children: [
        {
            ...defaultTreeState,
            entityId: 'skel-0-0',
            width: '60%',
        },
        {
            ...defaultTreeState,
            entityId: 'skel-0-1',
            width: '40%',
            children: [
                {
                    ...defaultTreeState,
                    entityId: 'skel-0-1-0',
                    width: '60%',
                },
                {
                    ...defaultTreeState,
                    entityId: 'skel-0-1-1',
                    width: '45%',
                },
            ],
        },
        {
            ...defaultTreeState,
            entityId: 'skel-0-2',
            width: '30%',
        },
    ],
};

const inVaultStatuses = ['ARCHIVED', 'PENDING_QC', 'PENDING_CLIENT', 'APPROVED'];

function recurseFilterInVaultOnly(tree) {
    const { assets, children } = tree || { assets: [], children: [], };

    const childrenInVault = children.reduce((acc, child) => {
        const vaultChild = recurseFilterInVaultOnly(child);
        return vaultChild ? acc.concat(vaultChild) : acc;
    }, []);

    const assetsInVault = assets.filter(
        ({ vaultStatusId }) => inVaultStatuses.includes(vaultStatusId),
    );
    const filt = { ...tree, assets: assetsInVault, children: childrenInVault };
    return filt.assets.length > 0 || filt.children.length > 0 ? filt : null;
}

function EntityTree(props) {
    const { api } = useUser();
    const { client } = useParams();
    const [tree, setTree] = React.useState(tempTree);
    const [fetchingTree, setFetchingTree] = React.useState(false);
    const { selectedAssets, handleAssetSelection, rootId, showInVaultOnly, managementPermission } = props;

    async function fetchTree(abortController = new AbortController()) {
        if (client && rootId) {
            try {
                setFetchingTree(true);
                const response = await api.get(`${client}/entity/${rootId}/tree`, null, { signal: abortController.signal, artificialDelay: 100 });

                if (response.ok) {
                    const data = await response.json();
                    setTree(data);
                } else {
                    setTree({});
                }
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
            setFetchingTree(false);
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchTree(abortController);

        return () => {
            abortController.abort();
        };
    }, [client, rootId]);

    const filteredTree = (showInVaultOnly ? recurseFilterInVaultOnly(tree) : tree) || {};

    const filteredAssets = React.useMemo(() => {
        const { assets = [], childrenAssets = [] } = filteredTree;

        return [...assets, ...childrenAssets].filter(({isHidden, inVault}) => (!showInVaultOnly || showInVaultOnly === inVault) && !isHidden);

    }, [filteredTree])

    React.useEffect(() => {

        if (showInVaultOnly) {
            const invaultSelection = filteredAssets.filter(({ assetId }) => selectedAssets.includes(assetId)).map(({ assetId }) => assetId);
            handleAssetSelection(invaultSelection, true);

        }
    }, [showInVaultOnly])

    if (!fetchingTree && isEmpty(filteredTree)) {
        return (
            <Grid container justifyContent="center" alignItems="center">
                <FormattedMessage id="NO_VAULT_TITLES" />
            </Grid>
        );
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Branch
                skeleton={tree === tempTree || fetchingTree}
                entity={fetchingTree ? tempTree : filteredTree}
                client={client}
                selectedAssets={selectedAssets}
                handleAssetSelection={handleAssetSelection}
                managementPermission={managementPermission}
                showInVaultOnly={showInVaultOnly}
                style={{ height: '100%', overflow: 'auto' }}
            />
            <Typography variant="caption" sx={(theme) => ({marginTop: theme.spacing(1)})}>
                <FormattedMessage
                    id="TOTAL_ASSETS"
                    values={{
                        total: filteredAssets.length,
                        selected: selectedAssets.length,
                    }}
                />
            </Typography>
        </div>
    );
}

function Branch(props) {
    const classes = useStyles();
    const {
        entity = { children: [] }, client, handleAssetSelection,
        selectedAssets, indented, managementPermission, skeleton,
        showInVaultOnly, style,
    } = props;

    const [listOpen, setListOpen] = React.useState(true);

    const entityAssets = React.useMemo(() => {
        const { assets = [], childrenAssets = [] } = entity;

        return [...assets, ...childrenAssets].filter(({ inVault, isHidden }) => (
            (!showInVaultOnly || showInVaultOnly === inVault) && !isHidden
        )).map(({ assetId }) => assetId);

    }, [entity.entityId, showInVaultOnly]);

    function handleEntitySelection() {
        if (!handleAssetSelection || isEmpty(entityAssets)) {
            return;
        }

        handleAssetSelection(entityAssets);
    }

    const { entityId, title = '' } = entity;

    const hasChildren = !isEmpty(entityAssets);
    let checkboxState = 0;
    if (hasChildren) {
        if (entityAssets.some((assetId) => selectedAssets.includes(assetId))) {
            checkboxState = 1;
            if (entityAssets.every((assetId) => selectedAssets.includes(assetId))) {
                checkboxState = 2;
            }
        }
    }

    function handleOpen() {
        setListOpen(!listOpen)
    }

    return (
        <List key={entityId} className={indented ? classes.Indented : null} disablePadding style={style}>
            <ListItem className={classes.CheckboxDiv} disableGutters>
                {!managementPermission ? null : (
                    <ListItemIcon className={classes.EntityIcon}>
                        <Checkbox
                            className={classes.InputCheck}
                            checked={checkboxState !== 0}
                            name={`entity-${entityId}`}
                            onChange={handleEntitySelection}
                            value={entityId}
                            indeterminate={checkboxState === 1}
                            disabled={skeleton}
                        />
                    </ListItemIcon>
                )}
                <ListItemText
                    primary={
                        (
                            skeleton ? <Skeleton width={entity.width} /> : (
                                    <RouteLink
                                        navLink
                                        underline="none"
                                        activeClassName={classes.activeLink}
                                        to={`/distribution/${client}/entities/${entityId}/?view=details`}
                                        color="textPrimary"
                                    >
                                        {title}
                                    </RouteLink>

                            )
                        )
                    }
                />
                {
                    isEmpty(entity.children) ? false : (
                        <IconButton size="small" className={classes.iconButton} onClick={handleOpen}>
                        {listOpen ? <ExpandLess/> : <ExpandMore />}
                        </IconButton>
                    )
                }
            </ListItem>


            <Collapse in={listOpen} timeout="auto" unmountOnExit>


            {

                (entity.children || []).map((e) => (
                    <Branch
                        skeleton={skeleton}
                        key={e.entityId}
                        entity={e}
                        classes={classes}
                        indented
                        client={client}
                        selectedAssets={selectedAssets}
                        handleAssetSelection={handleAssetSelection}
                        managementPermission={managementPermission}
                        showInVaultOnly={showInVaultOnly}
                    />
                ))
            }
            </Collapse>
        </List>
    );
}

Branch.propTypes = {
    entity: PropTypes.shape({
        assets: PropTypes.arrayOf(PropTypes.shape({})),
        children: PropTypes.arrayOf(PropTypes.shape({})),
        childrenAssets: PropTypes.arrayOf(PropTypes.shape({})),
        title: PropTypes.string,
        entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
    client: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    handleAssetSelection: PropTypes.func.isRequired,
    selectedAssets: PropTypes.arrayOf(PropTypes.number).isRequired,
    indented: PropTypes.bool,
    managementPermission: PropTypes.bool.isRequired,
    skeleton: PropTypes.bool.isRequired,
    showInVaultOnly: PropTypes.bool.isRequired,
};

Branch.defaultProps = {
    indented: false,
}
export default EntityTree;

import React from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
    useRouteMatch, generatePath, useParams, useHistory,
} from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import RouteLink from 'components/RouteLink';
import { useUser } from 'contexts/user';
import NotFound from 'components/NotFound';

const propTypes = {
    invoiceId: PropTypes.string,
    clientId: PropTypes.string,
};

const defaultProps = {
    invoiceId: null,
    clientId: null,
};

const useStyles = makeStyles(() => createStyles({
    table: {
        minWidth: 0,
    },
    units: {
        whiteSpace: 'pre',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: "'Roboto Mono', monospace",
    },
    tableData: {
        verticalAlign: 'baseline',
        borderBottom: 'none',
    },
    description: {
        wordBreak: 'keep-all',
    },
    finePrint: {
        marginRight: 'auto',
        fontStyle: 'italic',
    },
}));

function Invoice() {
    const classes = useStyles();

    const history = useHistory();
    const match = useRouteMatch();
    const { client, invoice } = useParams(); // use props?
    const { api } = useUser();
    const [fetchingInvoice, setFetchingInvoice] = React.useState(false);
    const [invoiceDetails, setInvoiceDetails] = React.useState({});
    const [failed, setFailed] = React.useState(false);

    function closeModal() {
        history.push({
            pathname: generatePath(match.path, { client }),
            search: history.location.search,
        });
    }

    async function fetchObject(abortController = new AbortController()) {
        if (client && invoice) {
            try {
                setFailed(false);
                setFetchingInvoice(true);

                const response = await api.get(`/invoices/${client}/${invoice}`, {}, { signal: abortController.signal });

                if (response.ok) {
                    const data = await response.json();
                    setInvoiceDetails(data);
                } else {
                    setFailed(true);
                    setInvoiceDetails({});
                }
                setFetchingInvoice(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        if (invoice && invoice !== invoiceDetails.invoiceNumber) {
            fetchObject(abortController);
        }
        return () => {
            abortController.abort();
        };
    }, [client, invoice]);

    const {
        invoiceNumber, items, currency,
        netTotal, total,
    } = invoiceDetails;

    let content = null;

    if (fetchingInvoice) {
        content = (
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        );
    } else if (!fetchingInvoice && failed) {
        content = (
            <DialogContent>
                <NotFound message="NOT_FOUND" />
            </DialogContent>
        );
    } else if (invoiceNumber && ((invoice === invoiceNumber) || !invoice)) {
        const unitLength = Math.max(...items.map(({ units }) => (units ? units.length : 0))); // useMemo?
        content = (
            <>
                <DialogContent>
                    <Table size="small" className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="right">
                                    <FormattedMessage id="QUANTITY" />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage id="DESCRIPTION" />
                                </TableCell>
                                <TableCell align="right">
                                    <FormattedMessage id="UNIT_PRICE" />
                                </TableCell>
                                <TableCell align="right">
                                    <FormattedMessage id="AMOUNT" />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                items.map(({
                                    quantity, description, rate, units,
                                    netCost, id,
                                }) => (
                                    <TableRow key={id}>
                                        <TableCell className={`${classes.units} ${classes.tableData}`} align="right">
                                            {quantity}
                                        </TableCell>
                                        <TableCell className={`${classes.description} ${classes.tableData}`}>
                                            {description}
                                        </TableCell>
                                        <TableCell className={classes.tableData} align="right">
                                            {
                                                !rate ? null : (
                                                    <pre className={classes.units}>
                                                        <FormattedNumber
                                                            value={rate}
                                                            style="decimal"
                                                            minimumFractionDigits={2}
                                                        />{ units ? ` / ${units.padEnd(unitLength, ' ')}` : '' }
                                                    </pre>
                                                )
                                            }
                                        </TableCell>
                                        <TableCell className={`${classes.units} ${classes.tableData}`} align="right">
                                            {
                                                !netCost ? null : (
                                                    <FormattedNumber
                                                        value={netCost}
                                                        style="decimal"
                                                        minimumFractionDigits={2}
                                                    />
                                                )
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Typography className={classes.finePrint} variant="subtitle2" color="textSecondary">
                        <FormattedMessage id="DOCUMENT_NOT_A_BILL" />
                    </Typography>
                    <Button
                        underline="none"
                        component={RouteLink}
                        to={{
                            pathname: generatePath(match.path, { client }),
                            search: history.location.search,
                        }}
                        // onClick={closeModal}
                        color="primary"
                    >
                        <FormattedMessage id="CLOSE" />
                    </Button>
                </DialogActions>
            </>
        );
    }

    return (
        <Dialog
            open={!!invoice}
            onClose={closeModal}
            maxWidth="md"
        >
            {content}
        </Dialog>
    );
}

Invoice.propTypes = propTypes;
Invoice.defaultProps = defaultProps;

export default Invoice;

import React, { createContext, useEffect, useState, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { useUser } from 'contexts/user';

const ConstantsContext = createContext();
ConstantsContext.displayName = "ConstantsContext";


const defaultVODOptions = {
    broadcasterOptions: [], fileDeliveryOptions: [], tapeHdDeliveryTypes: [], languages: [],
    ratings: [], codecOptions: [], bitrateOptions: [], wrapperOptions: [],
    frameRateOptions: [], trackOptions: [], loundnessOptions: [], tapeTypeOptions: [],
    resolutionTypeOptions: [], closedCaptionOptions: [], assetTypeOptions: [], audioTypeOptions: [],
    sectionsOptions: [], productsOptions: [], hardDiskId: undefined, pickUpId: undefined,
    dynamicRangeTypes: [], translationTypeOptions: [], ratioImage: [], audioTrackTypeOptions: [],
    audioContenTypeOptions: [],
};

function useConstants() {
    const context = useContext(ConstantsContext);
    if (!context) {
        throw new Error('useConstants must be used within a ConstantsProvider');
    }

    const [genres, locales, countries, metadataTypes, entityTypes, ratings, isLoading, defaultImage, vodOptions] = context;
    return {
        genres,
        locales,
        countries,
        metadataTypes,
        entityTypes,
        ratings,
        isLoading,
        defaultImage,
        vodOptions,
    };
}

function ConstantsProvider(props) {
    const [genres, setGenres] = useState([]);
    const [locales, setLocales] = useState([]);
    const [countries, setCountries] = useState([]);
    const [metadataTypes, setMetadataTypes] = useState({});
    const [entityTypes, setEntityTypes] = useState([]);
    const [ratings, setRatings] = useState([]);
    const [advisories, setAdvisories] = useState([]);
    const [vodOptions, setVodOptions] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [failed, setFailed] = useState(false);
    const [attempt, setAttempt] = useState(1);

    const { user, api } = useUser();
    const { client } = useParams();

    async function fetchGenres(abortController = new AbortController()) {
        const response = await api.get('genres', {}, { signal: abortController.signal });
        if (response.ok) {
            const data = await response.json();
            setGenres(data.map(({ genreId, description }) => ({ value: genreId, label: description })));
            setLoading(isEmpty(genres) || isEmpty(locales) || isEmpty(countries) || isEmpty(metadataTypes) || isEmpty(entityTypes) || isEmpty(ratings) || isEmpty(advisories) || isEmpty(vodOptions));
        }
    }

    async function fetchLocales(abortController = new AbortController()) {
        const response = await api.get('/locales', {}, { signal: abortController.signal });
        if (response.ok) {
            const data = await response.json();
            setLocales(data.map(({ localeId, description, pluralDescription }) => ({ value: localeId, label: description, pluralDescription })));
            setLoading(isEmpty(genres) || isEmpty(locales) || isEmpty(countries) || isEmpty(metadataTypes) || isEmpty(entityTypes) || isEmpty(ratings) || isEmpty(advisories) || isEmpty(vodOptions));
        }
    }

    async function fetchCountries(abortController = new AbortController()) {
        const response = await api.get('countries', {}, { signal: abortController.signal });
        if (response.ok) {
            const data = await response.json();
            setCountries(data.map(({ countryId, description }) => ({ value: countryId, label: description })));
            setLoading(isEmpty(genres) || isEmpty(locales) || isEmpty(countries) || isEmpty(metadataTypes) || isEmpty(entityTypes) || isEmpty(ratings) || isEmpty(advisories) || isEmpty(vodOptions));
        }
    }

    async function fetchMetadataTypes(abortController = new AbortController()) {
        const response = await api.get('/entity/metatypes', {}, { signal: abortController.signal });
        if (response.ok) {
            const data = await response.json();
            setMetadataTypes(data);
            setLoading(isEmpty(genres) || isEmpty(locales) || isEmpty(countries) || isEmpty(metadataTypes) || isEmpty(entityTypes) || isEmpty(ratings) || isEmpty(advisories) || isEmpty(vodOptions));
        }
    }

    async function fetchEntityTypes(abortController = new AbortController()) {
        const response = await api.get('/entity/types', {}, { signal: abortController.signal });
        if (response.ok) {
            const data = await response.json();
            setEntityTypes(data.map(({ entityTypeId, description, isRoot }) => ({ value: entityTypeId, label: description, isRoot })));
            setLoading(isEmpty(genres) || isEmpty(locales) || isEmpty(countries) || isEmpty(metadataTypes) || isEmpty(entityTypes) || isEmpty(ratings) || isEmpty(advisories) || isEmpty(vodOptions));
        }
    }

    async function fetchRatings(abortController = new AbortController()) {
        const response = await api.get('/ratings', {}, { signal: abortController.signal });
        if (response.ok) {
            const data = await response.json();
            setRatings(data.map(({ ratingId, rating, order, region }) => ({ value: ratingId, label: rating, order, region })));
            setLoading(isEmpty(genres) || isEmpty(locales) || isEmpty(countries) || isEmpty(metadataTypes) || isEmpty(entityTypes) || isEmpty(ratings) || isEmpty(advisories) || isEmpty(vodOptions));
        }
    }

    async function fetchAdvisories(abortController = new AbortController()) {
        const response = await api.get('/advisories', {}, { signal: abortController.signal });
        if (response.ok) {
            const data = await response.json();
            setAdvisories(data.map(({ idAdvisory, code, description, language, idClientWeb }) => ({ idAdvisory, value: code, label: description, language, idClientWeb })));
            setLoading(isEmpty(genres) || isEmpty(locales) || isEmpty(countries) || isEmpty(metadataTypes) || isEmpty(entityTypes) || isEmpty(ratings) || isEmpty(advisories) || isEmpty(vodOptions));
        }
    }


    async function fetchVODOptions(abortController = new AbortController()) {
        const response = await api.get('/vod-options/', {}, { signal: abortController.signal });
        if (response.ok) {
            const data = await response.json();
            const {
                broadcasters: broadcasterOptions, fileDeliveryTypes: fileDeliveryOptions, tapeHdDeliveryTypes, languages, ratings, advisories,
                codecs: codecOptions, bitrates: bitrateOptions, wrappers: wrapperOptions, frameRates: frameRateOptions,
                tracks: trackOptions, loundness: loundnessOptions, tapeTypes: tapeTypeOptions, resolutionTypes: resolutionTypeOptions,
                closedCaptions: closedCaptionOptions, assetTypes: assetTypeOptions, audioTypes: audioTypeOptions, sections: sectionsOptions, products: productsOptions,
                hardDiskId, pickUpId, clientServerId, dynamicRangeTypes, translationTypes: translationTypeOptions, ratioImage, audioTrackType : audioTrackTypeOptions,
                audioContentType : audioContenTypeOptions
            } = data;
            const ratingsValuesLabels = ratings.map(({ ratingId, rating, order, region }) => ({ value: ratingId, label: rating, order, region }));
            const advisoriesValuesLabels = advisories.map(({ idAdvisory, code, description, language, idClientWeb }) => ({ idAdvisory, value: code, label: description, language, idClientWeb }));

            setVodOptions((options) => ({
                ...options,
                broadcasterOptions, fileDeliveryOptions, tapeHdDeliveryTypes, languages,
                ratings: ratingsValuesLabels, advisories: advisoriesValuesLabels, codecOptions, bitrateOptions, wrapperOptions,
                frameRateOptions, trackOptions, loundnessOptions, tapeTypeOptions,
                resolutionTypeOptions, closedCaptionOptions, assetTypeOptions, audioTypeOptions,
                sectionsOptions, productsOptions, hardDiskId, pickUpId, clientServerId,
                dynamicRangeTypes, translationTypeOptions, ratioImage, audioTrackTypeOptions,
                audioContenTypeOptions,
            }));
            setLoading(isEmpty(genres) || isEmpty(locales) || isEmpty(countries) || isEmpty(metadataTypes) || isEmpty(entityTypes) || isEmpty(ratings) || isEmpty(vodOptions));
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        if (attempt < 3) {
            setAttempt(attempt + 1);
            try {
                fetchGenres(abortController);
                fetchMetadataTypes(abortController);
                fetchLocales(abortController);
                fetchCountries(abortController);
                fetchEntityTypes(abortController);
                fetchRatings(abortController);
                fetchAdvisories(abortController);
                fetchVODOptions(abortController);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
                setFailed(true);
            }
        }
        return () => {
            abortController.abort();
        };
    }, [failed]);

    if (isLoading && !isEmpty(genres) && !isEmpty(locales) && !isEmpty(countries) && !isEmpty(metadataTypes) && !isEmpty(entityTypes) && !isEmpty(ratings) && !isEmpty(vodOptions)) {
        setLoading(false);
    }

    const userLang = user.language.toLowerCase();
    const imageLang = ['fr', 'en'].includes(userLang) ? `default_${userLang}.png` : 'default.png';
    const [defaultImage] = useState(api.static(`/images/titles/${imageLang}`, {}, 'SERVICE'));

    const value = useMemo(() => (
        [genres, locales, countries, metadataTypes, entityTypes, ratings, isLoading, defaultImage, vodOptions]
    ), [genres, locales, countries, metadataTypes, entityTypes, ratings, isLoading, defaultImage, vodOptions]);

    return (
        <ConstantsContext.Provider value={value}>
            {props.children}
        </ConstantsContext.Provider>
    );
}


export { ConstantsProvider, useConstants, ConstantsContext };

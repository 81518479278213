import React from 'react';
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Skeleton from '@mui/material/Skeleton';

import Detail from 'components/Detail';
import { useConstants } from 'contexts/constants';
import FormSelect from 'components/FormSelect';
import FormMultiText from 'components/FormMultiText';
import ImageInput from 'components/ImageInput';
import ExternalTitleSearch from 'components/ExternalTitleSearch';

const useEntityDetailsStyles = makeStyles((theme) => createStyles({
    poster: {
        objectFit: 'cover',
        backgroundColor: theme.palette.divider,
        borderRadius: theme.shape.borderRadius,
        height: '30vh',
        width: '20.25vh', // 0.675 ratio
        display: 'block',
        marginBottom: theme.spacing(1),
    },
    container: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
}));

function EntityDetails(props) {
    const {
        metadata = {}, displayLanguage, handleInputChange,
        editMode, fetchingMetadata, externalTitle = false,
        onExternalTitleSelect,
    } = props;

    const {
        title, genre, actor, runtime, writer,
        prodYear, director, countryOrigin,
        shortDesc, summary, producer,
        originalLanguage, poster, originalPoster,
    } = metadata;

    const classes = useEntityDetailsStyles();

    const {
        locales, genres, metadataTypes,
        countries, isLoading: fetchingConstants,
        defaultImage,
    } = useConstants();

    const hasPoster = ('poster' in metadata || 'originalPoster' in metadata) && editMode;
    const hasTitle = 'title' in metadata;
    return (
        <Grid container spacing={2} alignItems="flex-start" className={classes.container}>
            <MetdataDetail
                handleInputChange={handleInputChange}
                loading={fetchingConstants || fetchingMetadata}
                xs="auto"
                isUnique
                valueType="image"
                defaultValue={originalPoster}
                value={poster}
                field="poster"
                label="CHANGE"
                editMode={editMode}
                className={classes.poster}
                defaultImage={defaultImage}
                displayLanguage={displayLanguage}
            />
            <Grid item container xs spacing={2} alignItems="flex-start">
                <MetdataDetail
                    handleInputChange={handleInputChange}
                    year={prodYear}
                    externalTitle={externalTitle}
                    onOptionSelect={onExternalTitleSelect}
                    loading={fetchingConstants || fetchingMetadata}
                    xs={6}
                    md={hasPoster ? 3 : 3}
                    label="TITLE"
                    hasLocale={metadataTypes.title && metadataTypes.title.hasLocale}
                    isUnique={metadataTypes.title && metadataTypes.title.isUnique}
                    valueType={metadataTypes.title && metadataTypes.title.valueType}
                    value={title}
                    field="title"
                    editMode={editMode}
                    displayLanguage={displayLanguage}
                />
                <MetdataDetail
                    handleInputChange={handleInputChange}
                    loading={fetchingConstants || fetchingMetadata}
                    xs={12}
                    md={hasPoster ? 9 : 9}
                    label="SHORT_DESCRIPTION"
                    hasLocale={metadataTypes.shortDesc && metadataTypes.shortDesc.hasLocale}
                    isUnique={metadataTypes.shortDesc && metadataTypes.shortDesc.isUnique}
                    valueType={metadataTypes.shortDesc && metadataTypes.shortDesc.valueType}
                    value={shortDesc}
                    displayLanguage={displayLanguage}
                    editMode={editMode}
                    field="shortDesc"
                />
                <MetdataDetail
                    handleInputChange={handleInputChange}
                    loading={fetchingConstants || fetchingMetadata}
                    xs={12}
                    hasLocale={metadataTypes.summary && metadataTypes.summary.hasLocale}
                    isUnique={metadataTypes.summary && metadataTypes.summary.isUnique}
                    valueType={metadataTypes.summary && metadataTypes.summary.valueType}
                    label="SYNOPSIS"
                    value={summary}
                    displayLanguage={displayLanguage}
                    editMode={editMode}
                    field="summary"
                />
                <MetdataDetail
                    handleInputChange={handleInputChange}
                    loading={fetchingConstants || fetchingMetadata}
                    xs={6}
                    md={hasPoster ? 4 : 3}
                    label="ORIGINAL_LANGUAGE"
                    hasLocale={metadataTypes.originalLanguage && metadataTypes.originalLanguage.hasLocale}
                    isUnique={metadataTypes.originalLanguage && metadataTypes.originalLanguage.isUnique}
                    valueType={metadataTypes.originalLanguage && metadataTypes.originalLanguage.valueType}
                    chips
                    value={originalLanguage}
                    displayLanguage={displayLanguage}
                    field="originalLanguage"
                    editMode={editMode}
                    input={FormSelect}
                    selectOptions={locales}
                />
                <MetdataDetail
                    handleInputChange={handleInputChange}
                    loading={fetchingConstants || fetchingMetadata}
                    xs={6}
                    md={hasPoster ? 4 : 3}
                    label="GENRE"
                    hasLocale={metadataTypes.genre && metadataTypes.genre.hasLocale}
                    isUnique={metadataTypes.genre && metadataTypes.genre.isUnique}
                    valueType={metadataTypes.genre && metadataTypes.genre.valueType}
                    chips
                    value={genre}
                    field="genre"
                    displayLanguage={displayLanguage}
                    editMode={editMode}
                    input={FormSelect}
                    selectOptions={genres}
                />
                <MetdataDetail
                    handleInputChange={handleInputChange}
                    loading={fetchingConstants || fetchingMetadata}
                    xs={6}
                    md={hasPoster ? 4 : 3}
                    label="RUNTIME"
                    hasLocale={metadataTypes.runtime && metadataTypes.runtime.hasLocale}
                    isUnique={metadataTypes.runtime && metadataTypes.runtime.isUnique}
                    valueType={metadataTypes.runtime && metadataTypes.runtime.valueType}
                    value={runtime}
                    displayLanguage={displayLanguage}
                    editMode={editMode}
                    field="runtime"
                    input={TextField}
                />
                <MetdataDetail
                    handleInputChange={handleInputChange}
                    loading={fetchingConstants || fetchingMetadata}
                    xs={6}
                    md={hasPoster ? 4 : 3}
                    label="YEAR"
                    hasLocale={metadataTypes.prodYear && metadataTypes.prodYear.hasLocale}
                    isUnique={metadataTypes.prodYear && metadataTypes.prodYear.isUnique}
                    valueType={metadataTypes.prodYear && metadataTypes.prodYear.valueType}
                    value={prodYear}
                    displayLanguage={displayLanguage}
                    editMode={editMode}
                    input={TextField}
                    field="prodYear"
                />
                <MetdataDetail
                    handleInputChange={handleInputChange}
                    loading={fetchingConstants || fetchingMetadata}
                    xs={6}
                    md={hasPoster ? 4 : 3}
                    label="PRODUCER"
                    hasLocale={metadataTypes.producer && metadataTypes.producer.hasLocale}
                    isUnique={metadataTypes.producer && metadataTypes.producer.isUnique}
                    valueType={metadataTypes.producer && metadataTypes.producer.valueType}
                    chips
                    value={producer}
                    displayLanguage={displayLanguage}
                    editMode={editMode}
                    field="producer"
                    input={FormMultiText}
                />
                <MetdataDetail
                    handleInputChange={handleInputChange}
                    loading={fetchingConstants || fetchingMetadata}
                    xs={6}
                    md={hasPoster ? 4 : 3}
                    label="DIRECTOR"
                    hasLocale={metadataTypes.director && metadataTypes.director.hasLocale}
                    isUnique={metadataTypes.director && metadataTypes.director.isUnique}
                    valueType={metadataTypes.director && metadataTypes.director.valueType}
                    chips
                    value={director}
                    displayLanguage={displayLanguage}
                    editMode={editMode}
                    field="director"
                    input={FormMultiText}
                />
                <MetdataDetail
                    handleInputChange={handleInputChange}
                    loading={fetchingConstants || fetchingMetadata}
                    xs={6}
                    label="WRITER"
                    hasLocale={metadataTypes.writer && metadataTypes.writer.hasLocale}
                    isUnique={metadataTypes.writer && metadataTypes.writer.isUnique}
                    valueType={metadataTypes.writer && metadataTypes.writer.valueType}
                    chips
                    value={writer}
                    displayLanguage={displayLanguage}
                    editMode={editMode}
                    field="writer"
                    input={FormMultiText}
                />
                <MetdataDetail
                    handleInputChange={handleInputChange}
                    loading={fetchingConstants || fetchingMetadata}
                    xs={12}
                    md={hasPoster ? 12 : 6}
                    label="ACTORS"
                    hasLocale={metadataTypes.actor && metadataTypes.actor.hasLocale}
                    isUnique={metadataTypes.actor && metadataTypes.actor.isUnique}
                    valueType={metadataTypes.actor && metadataTypes.actor.valueType}
                    chips
                    value={actor}
                    displayLanguage={displayLanguage}
                    editMode={editMode}
                    field="actor"
                    input={FormMultiText}
                />
                <MetdataDetail
                    handleInputChange={handleInputChange}
                    loading={fetchingConstants || fetchingMetadata}
                    xs={6}
                    label="COUNTRY"
                    hasLocale={metadataTypes.countryOrigin && metadataTypes.countryOrigin.hasLocale}
                    isUnique={metadataTypes.countryOrigin && metadataTypes.countryOrigin.isUnique}
                    valueType={metadataTypes.countryOrigin && metadataTypes.countryOrigin.valueType}
                    chips
                    value={countryOrigin}
                    displayLanguage={displayLanguage}
                    editMode={editMode}
                    field="countryOrigin"
                    input={FormSelect}
                    selectOptions={countries}
                />
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => createStyles({
    StyledFormattedMessage: {
        color: theme.palette.primary.main,
    },
    SkeletonPadding: {
        marginBottom: theme.spacing(1),
    },
}));

const MetdataDetail = (props) => {
    const {
        xs, sm, md,
        lg, xl, label,
        chips, editMode, value,
        displayLanguage,
        field, hasLocale,
        loading, isUnique,
        valueType = "", handleInputChange,
        selectOptions, defaultValue, className,
        defaultImage, externalTitle, onOptionSelect, year,
    } = props;
    const classes = useStyles();

    function onChange({ target: { value: targetValue } }, multiData) {
        // multiData is the data from the FormMutliText
        let newValue = null;

        if (isUnique) {
            if (hasLocale) {
                newValue = [
                    ...value.filter((v) => v.language !== displayLanguage),
                    { language: displayLanguage, value: targetValue, label: targetValue, id: undefined }
                ];
            } else {
                newValue = { ...value, value: targetValue, label: targetValue };
            }
        } else if (valueType && valueType.indexOf('id(') === 0) {
            newValue = targetValue.map((d) => ((Array.isArray(value) && value.find((v) => v && v.value === d)) || { id: undefined, value: d }));
        } else if (hasLocale) {
            if (value) {
                newValue = [
                    ...value.filter((v) => v.language !== displayLanguage),
                    { language: displayLanguage, value: targetValue, label: targetValue, id: undefined }
                ];
            } else {
                newValue = [
                    { language: displayLanguage, value: targetValue, label: targetValue, id: undefined }
                ];
            }
        } else if (multiData) {
            newValue = multiData.map((d) => (isString(d) ? { id: undefined, value: d } : { id: d.value, value: d.text }));
        }
        handleInputChange(field, newValue);
    }

    let displayValue = null;
    if (editMode && !loading && valueType) {
        if (isUnique) {
            if (hasLocale) {
                displayValue = (!isEmpty(value) && value.find((v) => v.language === displayLanguage)) || '';
            } else {
                displayValue = value && value.value ? value.value : '';
            }
        } else if (valueType.indexOf('id(') === 0) {
            displayValue = value && Array.isArray(value) ? value.map((v) => v.value) : [];
        } else if (hasLocale) {
            displayValue = (!isEmpty(value) && value.find((v) => v.language === displayLanguage)) || '';
        } else {
            displayValue = value && Array.isArray(value) ? value.map((val) => ({ text: val.value, value: val.id })) : "";
        }

        const [, typeFn, typeArg, typeOnly] = valueType.match(/(\w+)\(([\w,]+)\)|(\w+)/);
        const typeArgs = typeArg ? typeArg.split(',') : [];

        let Input = null;
        let inputProps = {};

        switch (typeFn || typeOnly) {
            case 'text':
                if (isUnique || hasLocale) {
                    Input = TextField;
                } else {
                    Input = FormMultiText;
                }
                break;
            case 'textarea':
                Input = TextField;
                inputProps = {
                    multiline: true,
                    maxRows: field === 'shortDesc' ? 2 : 10,
                };
                break;
            case 'range':
                Input = TextField;
                inputProps = {
                    min: typeArgs.length === 3 ? typeArgs[0] : undefined,
                    max: typeArgs.length === 3 ? typeArgs[1] : undefined,
                };
                break;
            case 'id':
                Input = FormSelect;
                inputProps = {
                    multiple: !isUnique,
                    children: selectOptions.map(({ label, value }) => (
                        <MenuItem key={label} value={value}>
                            {label}
                        </MenuItem>
                    )),
                };
                break;
            case 'time':
                Input = TextField;
                inputProps = {
                    min: 0,
                };
                break;
            case 'image':
                Input = ImageInput;
                inputProps = {
                    fileList: value,
                    originalSrc: value === null ? defaultImage : (defaultValue || defaultImage),
                    deleteEnabled: (value === null ? defaultImage : (defaultValue || defaultImage)) !== defaultImage,
                    onChange: ({ target: { files } }) => {
                        handleInputChange(field, files);
                    },
                };
                break;
            default:
                Input = null;
                break;
        }

        if (externalTitle) {
            Input = ExternalTitleSearch;
            inputProps = {
                year, onOptionSelect,
            }
        }

        return !Input ? null : (
            <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                <Input
                    value={displayValue && displayValue.value !== undefined ? displayValue.value : displayValue}
                    fullWidth
                    className={className}
                    name={field}
                    label={(
                        <span className={hasLocale ? classes.StyledFormattedMessage : null}>
                            <FormattedMessage
                                id={label}
                            />
                        </span>
                    )}
                    onChange={onChange}
                    {...inputProps}
                />
            </Grid>
        );
    }
    if (loading) {
        displayValue = <Skeleton variant="rectangular" />;
    } else if (isUnique || hasLocale) {
        if (hasLocale && Array.isArray(value)) {
            value.forEach((langValue) => {
                if (!displayValue && langValue.language === displayLanguage) {
                    displayValue = langValue.value;
                }
            });
        } else if (value && value.label) {
            displayValue = value.label;
        }
    } else if (Array.isArray(value)) {
        displayValue = value.map((v) => v.label);
    } else {
        displayValue = value;
    }


    return (
        <Detail xs={xs} sm={sm} md={md} lg={lg} xl={xl} label={loading ? <Skeleton className={classes.SkeletonPadding} variant="rectangular" width="30%" /> : label} chips={chips}>
            {displayValue}
        </Detail>
    );
}

const metadataShape = PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.string,
    language: PropTypes.string,
});

MetdataDetail.propTypes = {
    value: PropTypes.oneOfType([PropTypes.arrayOf(metadataShape), metadataShape]),//.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(metadataShape), metadataShape]),
    selectOptions: PropTypes.arrayOf(PropTypes.shape()),
    valueType: PropTypes.string,
    xs: PropTypes.oneOf([PropTypes.number,"auto"]),
    sm: PropTypes.oneOf([PropTypes.number,"auto"]),
    md: PropTypes.oneOf([PropTypes.number,"auto"]),
    lg: PropTypes.oneOf([PropTypes.number,"auto"]),
    xl: PropTypes.oneOf([PropTypes.number,"auto"]),
    label: PropTypes.string,
    chips: PropTypes.bool,
    editMode: PropTypes.bool,
    displayLanguage: PropTypes.string,
    field: PropTypes.string,
    inputProps: PropTypes.shape({}),
    hasLocale: PropTypes.number,
    loading: PropTypes.bool,
    isUnique: PropTypes.number,
    handleInputChange: PropTypes.func,
};

export default EntityDetails;

import React from 'react';
import { createTheme, adaptV4Theme } from '@mui/material/styles';


import shape from 'utils/theme/shape';
import palette from 'utils/theme/palette';
import typography from 'utils/theme/typography';
import componentsOverride from 'utils/theme/overrides';
import shadows, { customShadows } from 'utils/theme/shadows';

export default function ThemeConfig({ darkMode = false, lang = 'fr' }) {
    const themeOptions = React.useMemo(() => ({
        palette: palette(darkMode),
        shape,
        typography: typography(lang),
        shadows: shadows(darkMode),
        customShadows: customShadows(darkMode),
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    'input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration': {
                        '-webkit-appearance': 'none',
                    },
                },
            },
        }
    }), [darkMode]);

    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);

    return theme;
}

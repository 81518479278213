import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useParams, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import Detail from 'components/Detail';
import { useUser } from 'contexts/user';
import { queryStringToObj } from 'utils/ofQuery';
import { EditMetadataDialogLoadSave } from 'components/Distribution/Entities/MetadataDialog';

function EntityDetails(props) {
    const {
        defaultLanguages,
        displayLanguage,
        handleLangChange,
    } = props;

    const { api, user } = useUser();
    const { id, client } = useParams();
    const [details, setDetails] = React.useState({});
    const [fetchingDetails, setFetchingDetails] = React.useState(false);
    const { metadata = {}, title } = details;
    const {
        genre, actor, runtime, writer,
        prodYear, director, countryOrigin,
        shortDescription, synopsis, producer,
    } = metadata;
    const defaultPath = `/distribution/${client}/entities/${id}/`;
    const location = useLocation();
    const titleView = queryStringToObj(location.search).view;

    const handleFetchEntityDetails = React.useCallback((value) => {
        setDetails(value);
    }, [setDetails]);

    async function fetchEntity(abortController = new AbortController()) {
        if (client && id) {
            try {
                setFetchingDetails(true);
                const response = await api.get(`${client}/entity/${id}`, { detailLevel: 2 }, { signal: abortController.signal });

                if (response.ok) {
                    const data = await response.json();
                    setDetails(data);
                } else {
                    setDetails({});
                }
                setFetchingDetails(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchEntity(abortController);
        return () => {
            abortController.abort();
        };
    }, [client, id]);

    return (
        <>
            <Grid container spacing={2}>
                <Detail xs={6} md={3} label="TITLE">
                    {title}
                </Detail>
                <Detail xs={6} md={9} label="SHORT_DESCRIPTION">
                    {shortDescription}
                </Detail>
                <Detail xs={12} label="SYNOPSIS">
                    {synopsis}
                </Detail>
                <Detail xs={6} md={3} label="GENRE" chips>
                    {genre}
                </Detail>
                <Detail xs={6} md={3} label="RUNTIME">
                    {runtime}
                </Detail>
                <Detail xs={6} md={3} label="YEAR">
                    {prodYear}
                </Detail>
                <Detail xs={6} md={3} label="PRODUCER" chips>
                    {producer}
                </Detail>
                <Detail xs={6} md={3} label="DIRECTOR" chips>
                    {director}
                </Detail>
                <Detail xs={6} label="ACTORS" chips>
                    {actor}
                </Detail>
                <Detail xs={6} label="WRITER" chips>
                    {writer}
                </Detail>
                <Detail xs={6} label="COUNTRY" chips>
                    {countryOrigin}
                </Detail>
            </Grid>
            <EditMetadataDialogLoadSave
                defaultPath={defaultPath}
                open={titleView === 'editEntity'}
                defaultLanguages={defaultLanguages}
                displayLanguage={displayLanguage}
                handleLangChange={handleLangChange}
                handleFetchEntityDetails={handleFetchEntityDetails}
            />
        </>
    );
}

EntityDetails.propTypes = {
    defaultLanguages: PropTypes.arrayOf(PropTypes.string),
    displayLanguage: PropTypes.string.isRequired,
    handleLangChange: PropTypes.func.isRequired,
};

EntityDetails.defaultProps = {
    defaultLanguages: undefined,
};

export default EntityDetails;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from  'clsx';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { tableRowClasses } from '@mui/material/TableRow';
import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme) => createStyles({
    noBorder: {
        borderBottom: 'none',
    },
    basic: {
        transition: `opacity ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}, background-color ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}, color ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
        '&[disabled]' : {
            color: theme.palette.action.disabled,
            opacity: theme.palette.action.disabledOpacity,
            pointerEvents: 'none',
        },
        [`&.${tableCellClasses.head}, .${tableRowClasses.hover}:hover > &`]: {
            backgroundColor: theme.palette.mode === 'dark' ? '#333D49' : theme.palette.grey[200],
        },
        [`.${tableRowClasses.selected} > &, .${tableRowClasses.selected}.${tableRowClasses.hover}:hover > &`]: {
            backgroundColor: alpha(theme.palette.primary.main, 0.08),
        },
        [`&.${tableCellClasses.head}`]: {
            color: theme.palette.mode === 'dark' ? theme.palette.grey[500] : theme.palette.grey[600],
        },
        [`&.${tableCellClasses.head}:first-child, &.${tableCellClasses.body}:first-child`]: {
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
        },
        [`&.${tableCellClasses.head}:last-child, &.${tableCellClasses.body}:last-child`]: {
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
        }
    }
}));

const propTypes = {
    align: PropTypes.string,
    component: PropTypes.node,
    scope: PropTypes.string,
    borders: PropTypes.bool,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    padding: PropTypes.string,
    colspan: PropTypes.number,
    size: PropTypes.string,
    sortDirection: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
};

const defaultProps = {
    align: undefined,
    component: undefined,
    scope: undefined,
    borders: undefined,
    children: undefined,
    colspan: undefined,
    padding: undefined,
    size: undefined,
    sortDirection: undefined,
    disabled: false,
};

function EnhancedTableCell(props) {
    const {
        align, component, scope, disabled,
        children, borders, colspan,
        padding, size, sortDirection,
    } = props;

    const classes = useStyles();
    return (
        <TableCell
            sortDirection={sortDirection}
            size={size}
            padding={padding}
            component={component}
            scope={scope}
            disabled={disabled}
            colSpan={colspan}
            align={align}
            className={clsx(!borders && classes.noBorder, classes.basic, disabled && classes.disabled)}
        >
            {children}
        </TableCell>
    );
}

EnhancedTableCell.propTypes = propTypes;
EnhancedTableCell.defaultProps = defaultProps;

export default EnhancedTableCell;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { useParams, generatePath, useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import ListItemButton from '@mui/material/ListItemButton';

import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ListSubheader from '@mui/material/ListSubheader';

import ClosedCaptionIcon from '@mui/icons-material/ClosedCaptionTwoTone';
import SubtitlesIcon from '@mui/icons-material/SubtitlesTwoTone';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import ImageIcon from '@mui/icons-material/ImageTwoTone';
import GraphicEqIcon from '@mui/icons-material/GraphicEqTwoTone';
import DescriptionIcon from '@mui/icons-material/DescriptionTwoTone';
import AllInboxIcon from '@mui/icons-material/AllInboxTwoTone';
import TheatersIcon from '@mui/icons-material/TheatersTwoTone';
import PanoramaIcon from '@mui/icons-material/PanoramaTwoTone';
import CloudCircleIcon from '@mui/icons-material/CloudCircleTwoTone';
import ListItemIcon from '@mui/material/ListItemIcon';

import RouteLink from 'components/RouteLink';
import { permissionCheck } from 'components/ForbiddenCheck';
import { useUser } from 'contexts/user';
import { VodOrderDefinition, DistributionOrderDefinition } from 'routes/Paths/Definitions';

const propTypes = {
    relations: PropTypes.arrayOf(PropTypes.shape({
        relationType: PropTypes.string,
        relationId: PropTypes.number,
        relationInfo: PropTypes.shape({}),
    })),
};

const defaultProps = {
    relations: [],
};

const useStyles = makeStyles((theme) => createStyles({
    AssetIcon: {
        opacity: 1,
        transition: `opacity ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,
        '.Mui-checked ~ &, .MuiAvatar-root:hover > &, .MuiListItem-root:hover &': {
            opacity: 0,
        },
    },
    MenuHeader: {
        padding: '0rem',
        margin: '0rem',
       
    },
  
}));

function OrderItemRelation(props) {
    const {
        relationId, orderId, relationType, assetRelations,orderType,
        broadcasterId, description,deliverTo, distributorId, format, onClick, relationInfo, displayAsset,
        orderName, status, title, orderTypeDescription, singleRelation, assetRelationTitle, component: Component = RouteLink,
        componentProps: cp = {},
    } = props;
    const { user: { clients } } = useUser();
    const { client } = useParams();
    const history = useHistory();

    let orderPath = null;
    const componentProps = { ...cp, onClick };


    const currentClientId = parseInt(client, 10);
    const distributorAccess = permissionCheck(DistributionOrderDefinition.permissions, clients.find((c) => c.client === distributorId));
    const broadcasterAccess = permissionCheck(VodOrderDefinition.permissions, clients.find((c) => c.client === broadcasterId));
    let orderClientId = currentClientId;
    let relation_title = null  
    let orderDeliveryType = null

    if ([distributorId, broadcasterId].some((e) => currentClientId === e)) {
        if (distributorId === broadcasterId && distributorAccess && broadcasterAccess) {
            orderPath = VodOrderDefinition;
        } else if (currentClientId === broadcasterId && broadcasterAccess) {
            orderPath = VodOrderDefinition;
        } else if (currentClientId === distributorId && distributorAccess) {
            orderPath = DistributionOrderDefinition;
        }
    } else if (broadcasterAccess) {
        orderPath = VodOrderDefinition;
        orderClientId = broadcasterId;
    } else if (distributorAccess) {
        orderPath = DistributionOrderDefinition;
        orderClientId = distributorId;
    }

    if (orderPath) {
        const pathname = generatePath(orderPath.path, { client: orderClientId, order: orderId });
        const search = history.location.search;
        componentProps.to = {
            pathname,search
        };
    }

    if(deliverTo) {
        orderDeliveryType = <FormattedMessage id="DELIVERY_TO" values={{name:<> &nbsp; <strong> { ` ${deliverTo}  ${format ? `[${format}]` : '' }`}</strong></>}} />
    } else {
        orderDeliveryType = `${orderTypeDescription}  ${format ? `[${format}]` : '' }`
    }


    if (relationType === 'asset') {
        if (singleRelation) {
            // relation_title = assetRelationTitle
            relation_title = <>{`#${orderId} - `} {orderDeliveryType} </>

        } else { 
            relation_title = <>{`#${orderId} - `} {orderDeliveryType} </>
        }
    } else {
        relation_title= <>{`${title} ${description}`} {orderDeliveryType}</>
    }
    return (
        
        <Component {...componentProps}>
            {relation_title}
        </Component>
    );
}

function RelationTitle(props) {
    const { relations, setDiscussionId, assetRelations, relation } = props;
    const {assetType , entityPath, assetDescription } = assetRelations
    const params = useParams();
    const classes = useStyles();
    const { client } = params;
    const allowedValues = ['asset', 'order_item'];
    const history = useHistory();
    let displayAsset = false
    let assetRelationTitle = null;
    let disableRelationButton = false
    if (!isEmpty(assetRelations)) {
        assetRelationTitle = `${assetRelations.entityPath}/${assetRelations.assetDescription}`
    }
    if (isEmpty(relations) && !isEmpty(assetRelations)) {
        displayAsset = true
    }

    if (isEmpty(relations) && isEmpty(assetRelations)) {
        disableRelationButton = true
    }

    const IconForType = ({ type, className }) => ({
        [type]: <InsertDriveFileIcon className={className} />,
        CC: <ClosedCaptionIcon className={className} />,
        VIDEO: <TheatersIcon className={className} />,
        AUDIO: <GraphicEqIcon className={className} />,
        PACKAGE: <AllInboxIcon className={className} />,
        SUBTITLE: <SubtitlesIcon className={className} />,
        DOCUMENT: <DescriptionIcon className={className} />,
        PICTURE: <PanoramaIcon className={className} />,
        POSTER: <ImageIcon className={className} />,
    })[type];
    
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function getRelationComponent(relationType) {
        let Component = null;
        if (allowedValues.includes(relationType)) {
            Component = OrderItemRelation;
        }
        return Component;
    }


    if (!Array.isArray(relations)) {
        return null;
    }
    
    if (relations.length === 1) {
        const { relationId, relationType, relationInfo } = relations[0];
        if (relationType !== 'asset') {
        const Component = getRelationComponent(relationType);
        if (Component) {
            return (
                <Component
                    relationId={relationId}
                    relationType={relationType}
                    singleRelation={relations.length === 1}
                    displayAsset={displayAsset}
                    assetRelationTitle={assetRelationTitle}
                    {...relationInfo}
                    {...assetRelations}
                    onClick={()=> setDiscussionId(null)}
                    component={Button}
                    color="primary"
                    componentProps={{
                        component: RouteLink,
                        underline: 'none',
                        startIcon: <AccountTreeIcon />
                    }}
                />
            )
        }
        return null;
    }
}
    
    return (
        <>
           
            <Button
                color="primary"
                onClick={handleClick}
                disabled={displayAsset ||  disableRelationButton}
                startIcon={disableRelationButton ?null : <AccountTreeIcon /> }
            >
                {displayAsset || relations.length <1 ? 
                assetRelationTitle
                :
                relations.length
            }
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                variant="menu"
            >   
            { isEmpty(assetRelations) ? null :
                <>
                <ListItemButton>
                  <ListItemIcon>
                    <IconForType type={assetType} className={classes.AssetIcon} />
                    </ListItemIcon>
                  <ListItemText primary={assetRelationTitle} />
                </ListItemButton>
                { isEmpty(relations) ? null :
                <ListSubheader ><strong><FormattedMessage id="RELATED_ORDERS" /></strong></ListSubheader>
                }
                </>
            }
                
                {
                    relations.map(({ relationId, relationType, relationInfo }) => {
                        const Component = getRelationComponent(relationType);
                        if (Component) {
                            return (
                                <Component
                                    relationId={relationId}
                                    singleRelation={false}
                                    relationInfo={relationInfo}
                                    relationType={relationType}
                                    {...relationInfo}
                                    component={MenuItem}
                                    componentProps={{
                                        component: RouteLink,
                                        underline: 'none',
                                    }}
                                />
                            );
                        }
                        return null;
                    })
                }
            </Menu>
        </>
    )
}

RelationTitle.propTypes = propTypes;
RelationTitle.defaultProps = defaultProps;

export default RelationTitle;

import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Card from '@mui/material/Card';
import Toolbar from '@mui/material/Toolbar';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useLocation, generatePath, useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { queryStringFromObj, queryStringToObj } from 'utils/ofQuery';

import useLocationSearch from 'hooks/useLocationSearch';
import DcpList from 'components/Dcp/List';
import Filter from 'components/Dcp/Filter';
import Details from 'components/Dcp/Details';
import { useUser } from 'contexts/user';

const useStyles = makeStyles(() => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    card: {
        alignSelf: 'flex-start',
    }
}));

const propTypes = {
};

const defaultFilter = {
    dcpName: '',
    title: '',
    studio: '',
    productionDcp: '',
    duration: '',
    credit: '',
    idDcp: '',
    catdvId: '',
    type: [], 
    language: [], 
    resolution: [], 
    subtitles: [], 
    territory: [], 
    ratio: [], 
    audio: [], 
    standard: [], 
    contentType: [], 
    typePackage: [], 
    threeD: null,
    vi: null,
    hi: null,
    ccap: null,
    dbox: null,
    ocap: null,
    archiveVoute: null,
    archiveVv: null,
    atmos: null,
    auro: null,
    fromDate: null,
    toDate: null,
    dateProdFromDate: null,
    dateProdToDate: null,
    deleteFromDate: null,
    deleteProdToDate: null,
    toWillBeDeletedDate: null,
    fromWillBeDeletedDate: null,
};

function View() {

    const classes = useStyles();
    const [pages, setPages] = React.useState({});

    const [isLoading, setIsLoading] = React.useState(false);
    const [failed, setFailed] = React.useState(false); // TODO handle failed
    console.log(`IN DCPPPPPPPPPPPPPPPPPPP`)
    const { api } = useUser();
    const match = useRouteMatch();
    const intl = useIntl();

    const { params: { dcp, client } } = match;
    const Dcps = (Object.values(pages) || []).reduce((acc, lst) => ([...acc, ...lst]), []);
    const fullCount = (Dcps.length > 0 ? Dcps[0].fullCount : 0);
    const [page, setPage] = useLocationSearch('page', 0);
    const [rowsPerPage, setRowsPerPage] = useLocationSearch('rowsPerPage', 50);
    const [order, setOrder] = useLocationSearch('order', 'asc');
    const [orderBy, setOrderBy] = useLocationSearch('orderBy', 'barcode');
    const [filters, setFilters] = useLocationSearch(Object.keys(defaultFilter), defaultFilter);

    const curPageRows = pages[page] || [];
    const fetchDcps = React.useCallback(async (flushObjects = false, pageToFetch = 0, limit = 50, sortBy, sort, abortController = new AbortController()) => {
        if (client) {
            setIsLoading(true);
            setFailed(false);

            try {
                const query = {
                    ...filters,
                    orderBy: sortBy,
                    order: sort,
                    offset: pageToFetch * limit,
                    limit,
                };

                const response = await api.get(`/dcp/${client}`, query, { signal: abortController.signal });
                if (response.ok) {
                    const data = await response.json();
                    setPages((currentPages) => (flushObjects ? { [pageToFetch]: data } : { ...currentPages, [pageToFetch]: data }));
                } else {
                    setPages((currentPages) => (flushObjects ? {} : omit(currentPages, pageToFetch)));
                    setFailed(true);
                }
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
            setIsLoading(false);
        }
    }, [client, filters]);

    const handlePageChange = React.useCallback((newPage) => {
        if (isEmpty(pages[newPage])) {
            setIsLoading(true);
        }
        setPage(newPage);
    }, [pages]);
    return (
        <div className={classes.root}>
            <Card>
                <Helmet>
                    <title>
                        {`Melodie - ${intl.formatMessage({ id: 'DCPS' })}${dcp ? ` #${dcp}` : ''}`}
                    </title>
                </Helmet>
                <div className={classes.root}>
                    <Toolbar>
                        <Filter
                            initialFilters={filters}
                            applyFilters={setFilters}
                            isLoading={isLoading}
                            clientId={client}
                            defaultFilter={defaultFilter}
                        />
                    </Toolbar>
                    <Details
                        clientId={client}
                        dcpId={dcp}
                    />
                    <DcpList
                        clientId={client}
                        failed={failed}
                        filters={filters}
                        setPage={handlePageChange}
                        page={page}
                        fetchPage={fetchDcps}
                        pageRows={curPageRows}
                        totalRowCount={fullCount}
                        isLoading={isLoading}
                        order={order}
                        setOrder={setOrder}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </div>
            </Card>
        </div>
    );
}

View.propTypes = propTypes;

export default View;

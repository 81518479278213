import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SendIcon from '@mui/icons-material/Send';
import Chip from '@mui/material/Chip';

import Grid from '@mui/material/Grid';
import isEmpty from 'lodash/isEmpty';
import FileInput from 'components/FileInput';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import AttachFileIcon from '@mui/icons-material/AttachFileTwoTone';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturbTwoTone';
import DoneIcon from '@mui/icons-material/DoneTwoTone';
import DeleteForever from '@mui/icons-material/DeleteForeverTwoTone';
import ErrorIcon from '@mui/icons-material/ErrorTwoTone';
import Tooltip from '@mui/material/Tooltip';


import { useUser } from 'contexts/user';

const useStyles = makeStyles((theme) => createStyles({
    entryForm: {
        padding: theme.spacing(0.5, 1),
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'

    },
    entryFormInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        // padding: 10,
        '&+&': {
            marginLeft: theme.spacing(1),
        }
    },
    submitButton: {
        padding: 10,
        position: 'absolute',
        right: 30

    },
    barButton: {
        padding: theme.spacing(0.5, 1),
        display: 'inline-flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexGap: 2
    },
    documentRoot: {
        padding: 0,
        margin: theme.spacing(1,0,.5),
        display: 'flex',
        listStyle: 'none',
        gap: theme.spacing(.5),
        flexWrap: 'wrap',
        width: '100%',
    },
    documentItem: {
        maxWidth: '200px',
    }
}));

function DiscussionInput(props) {
    const {
        addComment, commentRef, handleChange,
        discussionId, isDiscussionLoading,
        disabled, handleDocuments, newDocuments,
        removeDocument, updateDiscussion, discussion,
        forceRefreshList
    } = props;

    const intl = useIntl();
    const { user } = useUser();
    const classes = useStyles();
    const {client} = useParams();
    const [newCommentText, setNewCommentText] = React.useState('');
    const [internalComment, setInternalComment] = React.useState(false);

    const userPermission = user.clients.find((c) => c.client === parseInt(client, 10)) || {};

    function handleInputChange({ target }) {
        const { value } = target;
        setNewCommentText(value);
    }

    function handleInternalComment({ target: { checked } }) {
        setInternalComment(checked);
    }

    async function handleKeyPress(event) {
        const {
            key, altKey, ctrlKey, shiftKey,
        } = event;

        if (key !== 'Enter' || shiftKey || ctrlKey || altKey) {
            return;
        }
        event.preventDefault();
        if (newCommentText.replace(/\s/gi, '') === '') {
            setNewCommentText('');
            return;
        }
        addComment(newCommentText, internalComment, (response) => {
            if (response.ok) {
                setInternalComment(false);
                setNewCommentText('');
            }
        });
    }

    async function handleAddComment(event) {
        event.preventDefault();
        if (newCommentText.replace(/\s/gi, '') === ''  && isEmpty(newDocuments)){
            setNewCommentText('');
            return;
        }
        addComment(newCommentText, internalComment, (response) => {
            if (response.ok) {
                setInternalComment(false);
                setNewCommentText('');
            }
        });
    }

    function deleteDiscussion() {
        updateDiscussion({ isDeleted: !discussion.isDeleted });
        forceRefreshList()
    }

    function ignoreDiscussion(event) {
        updateDiscussion({ problemStatusId: 'IGNORED' });
        forceRefreshList()
    }

    function solveDisucussion(event) {
        updateDiscussion({ problemStatusId: 'SOLVED' });
        forceRefreshList()
    }

    function reportDisucussion(event) {
        updateDiscussion({ problemStatusId: 'REPORTED' });
        forceRefreshList()
    }


    return (
        <>
            <Paper className={classes.entryForm}>
                <InputBase
                    inputRef={commentRef}
                    fullWidth
                    multiline
                    maxRows={2}
                    value={newCommentText}
                    onChange={handleInputChange}
                    placeholder={intl.formatMessage({ id: 'NEW_COMMENT' })}
                    disabled={disabled || !discussionId || isDiscussionLoading }
                    className={classes.entryFormInput}
                    onKeyPress={handleKeyPress}
                />
                {
                    !newDocuments || newDocuments.length <= 0 ? null : (
                        <ul className={classes.documentRoot}>
                            {
                                newDocuments.map(({name}, index, documentList) => (
                                    <li className={classes.documentItem}>
                                        <Chip
                                            label={name}
                                            title={name}
                                            onDelete={() => removeDocument(index)}
                                            deleteIcon={<DeleteIcon />}
                                        />
                                    </li>
                                ))
                            }
                        </ul>
                    )
                }
            </Paper>
            <div className={classes.entryForm}>
                <FileInput
                    size="small"
                    onChange={handleDocuments}
                    showFileName={false}
                    noLabel
                    iconButton
                    multiple
                    disabled={disabled || !discussionId || isDiscussionLoading}
                    wrapperProps={{
                        className: classes.iconButton
                    }}
                    sx={(theme) => ({color: theme.palette.text.primary})}
                    icon={<AttachFileIcon />}
                />
                {
                    !user.employee ? null : (
                        <FormControlLabel
                            className={classes.iconButton}
                            labelPlacement="start"
                            control={(
                                <Checkbox
                                    size="small"
                                    disabled={disabled || !discussionId || isDiscussionLoading}
                                    checked={internalComment}
                                    onChange={handleInternalComment}
                                    name="internalComment"
                                />
                            )}
                            label={<FormattedMessage id="INTERNAL" />}
                        />
                    )
                }
                <IconButton
                    type="submit"
                    disabled={disabled || !discussionId || isDiscussionLoading || (!newCommentText && isEmpty(newDocuments))}
                    color="primary"
                    className={classes.submitButton}
                    size="large"
                    onClick={handleAddComment}
                >
                    <SendIcon />
                </IconButton>
            </div>
        </>
    );
}

export default DiscussionInput;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
    useParams, useLocation, useRouteMatch, generatePath,
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { useUser } from 'contexts/user';
import RouteLink from 'components/RouteLink';
import EntityTree from 'components/Distribution/Entities/Tree';
import Assets from 'components/Distribution/Assets';
import QualityControlReport from 'components/Distribution/Assets/QualityControlReport';
import OrderDetails from 'components/Distribution/Assets/OrderDetails';
import AssetDetails from 'components/Distribution/Assets/Details';
import EntityDetails from 'components/Distribution/Entities/EntityDetails';
import EntityTitle from 'components/Distribution/Entities/Title';
import OrderCreation from 'components/Distribution/Orders/Create';
import { DistributionEntityDefinition, DistributionEntityDefinitionAsset } from 'routes/Paths/Definitions';


const useStyles = makeStyles((theme) => createStyles({
    assets: {
        padding: theme.spacing(1, 2),
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        overflow: 'hidden',
    },
    activeLink: {
        color: theme.palette.primary.main,
    },
    notFound: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
    },
    EntityView: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridGap: '2rem',
        height: '100%',

    },
    EntityFooter: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 2fr',
        gridGap: '2rem',
        overflow: 'hidden',

    },
    lists: {
        overflow: 'hidden',
    },
    OverFlowDiv: {
        height: '100%',
        padding: theme.spacing(2),
        overflowY: 'auto',
        overflowX: 'auto',
    },
}));

function Entity(props) {
    const { readOnly } = props;
    const [selectedAssets, setSelectedAssets] = React.useState([]);
    const [viewedEntity, setViewedEntity] = React.useState(null);
    const [rootId, setRootId] = React.useState(null);
    const {
        id, view, assetId, client, qcId, orderId, detailId, detailType
    } = useParams();

    const { api,user, user: { contact, language } } = useUser();
    const [modalOpen, setModalOpen] = React.useState('');
    const [modalOpen2, setModalOpen2] = React.useState(false);
    const classes = useStyles();
    const location = useLocation();
    const paths = useRouteMatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [showInVaultOnly, setShowInVaultOnly] = React.useState(false);
    const [originalLanguage, setOriginalLanguage] = React.useState(undefined);
    const [displayLanguage, setDisplayLanguage] = React.useState(language ? language.toLowerCase() : 'fr');
    const userPermission = user.clients.find((c) => c.client === parseInt(client, 10)) || {};
    const managementPermission = userPermission.distribution === 2;
    async function addToWorkspace(abortController = new AbortController()) {
        if (client) {
            const snackId = enqueueSnackbar(<FormattedMessage id="ADDING_TO_WORKSPACE" />, { variant: 'loading', persist: true });

            const response = await api.post(`/distribution/workspace/${client}`, { asset_ids: selectedAssets, contact_id: contact }, { signal: abortController.signal });
            if (response.ok) {
                closeSnackbar(snackId);
                enqueueSnackbar(<FormattedMessage id="ADDED_TO_WORKSPACE" />, { variant: 'success' });
                setSelectedAssets([]);
            } else {
                closeSnackbar(snackId);
                enqueueSnackbar(<FormattedMessage id="ERROR_ADDING_TO_WORKSPACE" />, { variant: 'error' });
            }
        }
    }

    async function fetchEntityRoot(abortController = new AbortController()) {
        if (client) {
            const response = await api.get(`/${client}/entity/${id}/root/`, null, { signal: abortController.signal });
            if (response.ok) {
                const data = await response.json();
                setRootId(data.rootId);
            } else {
                setRootId(null);
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchEntityRoot(abortController);
        return () => {
            abortController.abort();
        };
    }, [client, id]);

    async function fetchEntityInfo(abortController = new AbortController()) {
        if (client) {
            const entity = await api.get(`${client}/entity/${id}/info`, { language }, { signal: abortController.signal });
            if (entity.ok) {
                const data = await entity.json();
                setViewedEntity(data);
            } else {
                setViewedEntity(null);
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchEntityInfo(abortController);
        return () => {
            abortController.abort();
        };
    }, [client, id]);

    const handleOriginalLanguage = React.useCallback((value) => {
        setOriginalLanguage(value);
    }, []);

    const handleLangChange = React.useCallback((event, newValue) => {
        setDisplayLanguage(newValue);
    }, []);

    function handleSwitchVault({ target: { checked } }) {
        setShowInVaultOnly(checked);
    }

    function handleOnSelect(assets, override) {
        setSelectedAssets((currentSelection) => {
            if (override) {
                return assets;
            }
            let newSelection = [];
            if (assets.every((assetId) => currentSelection.includes(assetId))) {
                newSelection = currentSelection.filter((assetId) => !assets.includes(assetId));
            } else {
                newSelection = uniqBy(currentSelection.concat(assets));
            }
            return newSelection
        });
    }

    function handleOpenModal({ currentTarget: { value } }) {
        setModalOpen(value);
    }

    function handleCloseModal() {
        setModalOpen('');
        setSelectedAssets([]);
    }


    function handleOpenModal2() {
        setModalOpen2(true);
    }

    function handleCloseModal2() {
        setModalOpen2(false);
    }

    const titleId = parseInt(id, 10);
    const currentClientId = parseInt(client, 10);
    // const assetId = parseInt(assetId, 10);

    const {
        entityType: { hasMetadata },
    } = viewedEntity || { entityType: { hasMetadata: 'N' } };
    const defaultLanguages = originalLanguage ? ['en', 'fr', originalLanguage] : ['en', 'fr'];
    const assetDetailsActive = assetId;
    const entityDetailsActive = rootId && titleId !== rootId && !assetDetailsActive;
    return (
        <Card>
        <div className={classes.EntityView}>
            <EntityTitle
                client={currentClientId}
                id={titleId}
                language={language}
                view={view}
                rootId={rootId}
                handleOriginalLanguage={handleOriginalLanguage}
                displayLanguage={displayLanguage}
                handleLangChange={handleLangChange}
            />
            <div className={classes.assets}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <FormControlLabel
                            control={(
                                <Switch

                                    checked={showInVaultOnly}
                                    onChange={handleSwitchVault}
                                    name="showInVaultOnly"
                                    value="showInVaultOnly"
                                />
                            )}
                            label={<FormattedMessage id="VAULT_TITLES" />}
                        />
                    </Grid>
                    {!managementPermission ? null : (
                        <>
                            <Grid item>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    name="Send"
                                    value="Send"
                                    component={RouteLink}
                                    to={`${generatePath(paths.path, { client, id })}?view=editEntity`}
                                    disabled={rootId == id || hasMetadata === 'N'}
                                >
                                    <FormattedMessage id="EDIT" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={addToWorkspace}
                                    disabled={isEmpty(selectedAssets)}
                                >
                                    <FormattedMessage id="ADD_TO_WORKSPACE" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={handleOpenModal2}
                                    // disabled={isEmpty(selectedAssets)}
                                >
                                    <FormattedMessage id="ORDER" />
                                </Button>
                            </Grid>
                        </>)}
                </Grid>
                <Grid container className={classes.lists}>
                    <Grid item xs={3} className={classes.OverFlowDiv}>
                        <EntityTree
                            id={titleId}
                            client={currentClientId}
                            handleAssetSelection={handleOnSelect}
                            selectedAssets={selectedAssets}
                            classes={classes}
                            rootId={rootId}
                            showInVaultOnly={showInVaultOnly}
                            managementPermission={managementPermission}
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.OverFlowDiv}>
                        <Assets
                            entityId={titleId}
                            clientId={currentClientId}
                            activeAssetId={assetId}
                            handleAssetSelection={handleOnSelect}
                            selectedAssets={selectedAssets}
                            path={paths.path}
                            showInVaultOnly={showInVaultOnly}
                            managementPermission={managementPermission}
                        />
                    </Grid>

                    <Grid item xs={6} className={classes.OverFlowDiv}>
                        {
                            !assetDetailsActive ? null : (
                                <AssetDetails
                                    readOnly={readOnly}
                                    entitytId={titleId}
                                    path={paths.path}
                                    showInVaultOnly={showInVaultOnly}
                                />
                            )
                        }
                        {
                            !entityDetailsActive ? null : (
                                <EntityDetails
                                    defaultLanguages={defaultLanguages}
                                    displayLanguage={displayLanguage}
                                    handleLangChange={handleLangChange}
                                />
                            )
                        }
                    </Grid>
                </Grid>
            </div>

            {
                detailType !== 'order' ? null : (
                    <OrderDetails
                        orderId={detailId}
                        client={currentClientId}
                        assetId={assetId}
                        id={id}
                        readOnly={readOnly}
                />
                    )
                }

            <Dialog
                open={(detailType === 'qc' && !!detailId)}
                maxWidth={false}
            >
                <DialogContent>
                    <QualityControlReport
                        qcId={detailId}
                        client={currentClientId}
                        assetId={assetId}
                        readOnly={readOnly}
                    />

                </DialogContent>
                <DialogActions>
                    <Link
                        component={RouteLink}
                        to={{ pathname: generatePath(assetId? DistributionEntityDefinitionAsset.path : DistributionEntityDefinition.path, { client, id, assetId }), search: location.search }}
                        underline="hover">
                        <FormattedMessage id="CLOSE" />
                    </Link>
                </DialogActions>
            </Dialog>
            <Dialog
                open={modalOpen2}
                className={classes.wizard}
                maxWidth="lg"
                // fullWidth
            >
                <OrderCreation
                    closeModal={handleCloseModal2}
                    selectedAssets={!isEmpty(selectedAssets) && selectedAssets}
                    selectedTitles={[titleId]}
                />
            </Dialog>
        </div>
        </Card>
    );
}

Entity.propTypes = {
    readOnly: PropTypes.bool,
};

Entity.defaultProps = {
    readOnly: true,
};

export default Entity;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import isEqual from 'lodash/isEqual';
import isEmpty  from 'lodash';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Badge from '@mui/material/Badge';
import FormSelect from 'components/FormSelect';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useUser } from 'contexts/user';
import DateRangePicker from 'components/DateRangePicker';

import Drawer from '@mui/material/Drawer';


const propTypes = {
    // fetchOrders: PropTypes.func.isRequired,
    applyFilters: PropTypes.func.isRequired,
    fetchingOrders: PropTypes.bool.isRequired,
    clientId: PropTypes.number.isRequired,
    initialFilters: PropTypes.shape({
        dcpName: PropTypes.string,
        achiveVv: PropTypes.arrayOf(PropTypes.number),
        archiveVoute: PropTypes.arrayOf(PropTypes.number),
        atmos: PropTypes.arrayOf(PropTypes.number),
        auro: PropTypes.arrayOf(PropTypes.number),
        ccap: PropTypes.arrayOf(PropTypes.number),
        dbox: PropTypes.arrayOf(PropTypes.number),
        idDcp: PropTypes.arrayOf(PropTypes.number),
        archiveVoute: PropTypes.arrayOf(PropTypes.number),
        hi: PropTypes.arrayOf(PropTypes.number),
        ocap: PropTypes.arrayOf(PropTypes.number),
        vi: PropTypes.arrayOf(PropTypes.number),
        audio: PropTypes.string,
        catdvDeleteDate: PropTypes.arrayOf(PropTypes.string),
        typePackage: PropTypes.arrayOf(PropTypes.string), 
        
    }).isRequired,
};

const defaultProps = {
};

const STANDARDS = [
    { id: 'IOP', description: 'Interop' },
    { id: 'SMPTE', description: 'SMPTE' },
];

const PACKAGES = [
    { id: 'OV', description: 'Original Version' },
    { id: 'VF', description: 'Version File' },
];



const useStyles = makeStyles((theme) => createStyles({
    root: {
        marginBottom: theme.spacing(2),
    },
    badge: {
        opacity: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        borderRadius: 'inherit',
        lineHeight: 1,
        fontSize: 'inherit',
        padding: 'inherit',
        '.MuiBadge-badge:hover > &': {
            opacity: 1,
        },
        '&, &:active, &:hover': {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main,
        },
    },
}));



function DcpFilter(props) {
    const {
        initialFilters,
        clientId,
        applyFilters,
        isLoading,
        fetchingOrders ,
        defaultFilter,
    } = props;

    

    const [dcpFilterTypes, setDcpFilterTypes] = React.useState({
        territoryList:[],
        ratioList:[],
        resolutionList:[],
        languageList: [],
        contentTypesList: [],
        audioList: [],

    });


    const classes = useStyles();
    const { api } = useUser();

    const [filters, setFilters] = React.useState(initialFilters);
    const [fetchingFilters, setFetchingFilters] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [filtersActive, setFiltersActive] = React.useState(!isEqual(defaultFilter, initialFilters));
    const [anchorEl, setAnchorEl] = React.useState(null);

    const {
        contentTypesList, territoryList, ratioList,
        audioList, languageList, resolutionList
    } = dcpFilterTypes;

    async function fetchFilters(abortController = new AbortController()) {
        if (clientId) {
            try {
                setFetchingFilters(true);
                setFailed(false);
                const response = await api.get(`/dcp/${clientId}/filter`, {}, { signal: abortController.signal });

                if (response.ok) {
                    const data = await response.json();
                    setDcpFilterTypes(data);
                } else {
                    setFailed(true);
                    setDcpFilterTypes({});
                }

                setFetchingFilters(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

   
    function handleInputChange({ target: { name, value } }) {
        setFilters({ ...filters, [name]: value });
    }

    const {
        fromDate, toDate, dcpName,
        idDcp, atmos, audio, auro, ccap,
        credit, dateProduction, dbox, duration,
        hi, language, ocap, productionDcp, ratio, 
        ratioImage, resolution, studio, subtitles, standard,
        territory, threeD, title, packageType, typePackage, vi,
        dateProdFromDate,dateProdToDate,  catdvDeleteDate, catdvId,
        archiveVv, archiveVoute, contentType, fromWillBeDeletedDate,
        toWillBeDeletedDate, available
    } = filters;

   

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchFilters(abortController);
        return () => {
            abortController.abort();
        };
    }, [clientId]);

    function filterDcps(event) {
        event.preventDefault();
        applyFilters(filters);
    }
    function handleCheckBoxChange({ target: { name, checked } }) {
        setFilters({ ...filters, [name]: checked ? true : null });
    }
    
    function handleInputChange({ target: { name, value } }) {
        setFilters({ ...filters, [name]: value });
    }
    
    const handleAvailability = React.useCallback(({ target: { name, checked } }) => {
        setFilters({ ...filters, [name]: checked ? true : null });
        // setFilters((oldFilter) => ({ ...oldFilter, available: checked }));
    }, []);
    
    const handleMenuClick = React.useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);
    
    const handleMenuClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);
    
    function handleSubmit(event) {
        event.preventDefault();
        handleMenuClose();
        applyFilters(filters);
        setFiltersActive(true);
    }
    
    function clearFilters(event) {
        event.stopPropagation();
        handleMenuClose();
        setFilters(defaultFilter);
    
        if (filtersActive) {
            applyFilters(defaultFilter);
        }
    }
    const filterCount = React.useMemo(() => {
        let count = 0;
        for (let key in filters) {
            if (filters.hasOwnProperty(key)) {
                const value = filters[key];
                if (value !== null && value !== undefined && value !== "" && !(Array.isArray(value) && value.length === 0)) {
                    count++;
                }
            }
        }
        return count;
    }, [filters]);

    // }
        
        
    //     (Object.keys(filters || {}).filter((key) => (filters[key] && !isEmpty(filters[key]))) || []).length + (filters.atmos ? 1 : 0)
    // ), [filters]);

    return (
        <>
            <Grid
                component="form"
                container
                spacing={1}
                alignItems="center"
                onSubmit={filterDcps}
            >
                <Grid item xs>
                    <TextField
                        variant="outlined"
                        name="idDcp"
                        size="small"
                        fullWidth
                        margin="dense"
                        value={idDcp}
                        label={<FormattedMessage id="DCP_ID" />}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs>
                     <TextField
                        variant="outlined"
                        name="title"
                        margin="dense"
                        fullWidth
                        value={title}
                        label={<FormattedMessage id="TITLE" />}
                        size="small"
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs>
                    <DateRangePicker
                        clearable
                        fullWidth
                        inputformat="yyyy-MM-dd"
                        inputVariant="outlined"
                        margin="dense"
                        size="small"
                        disableToolbar
                        startValue={fromDate}
                        startName="fromDate"
                        startLabel={<FormattedMessage id="CREATED_ON" />}
                        endName="toDate"
                        endValue={toDate}
                        endLabel={<FormattedMessage id="END" />}
                        onChange={handleInputChange}
                    />
                </Grid>
               
                 <Badge
                        color="primary"
                        overlap="circular"
                        invisible={filterCount === 0}
                        badgeContent={(
                            <>
                                <span>
                                    {filterCount}
                                </span>
                                <IconButton
                                    size="small"
                                    className={classes.badge}
                                    onClick={clearFilters}
                                    color="error"
                                >
                                    <ClearIcon fontSize="inherit" />
                                </IconButton>
                            </>
                        )}

                    >
                        <IconButton onClick={handleMenuClick} size="large">
                            <FilterListIcon />
                        </IconButton>
                    </Badge>
                <Grid item>
                    <Button
                        type="submit"
                        color="primary"
                        variant="outlined"
                        disabled={isLoading}
                    >
                        <FormattedMessage id="SEARCH" />
                    </Button>
                </Grid>
            </Grid>
        <Drawer
            anchor="right"
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >

            <List style={{ height: '100%', overflow: 'auto' }}>
                <ListItem dense>
                    <FormControlLabel
                        control={(
                            <Switch
                                checked={available}
                                onChange={handleAvailability}
                                name="available"
                                value="available"
                                title={<FormattedMessage id="AVAILABLE" />}
                            />
                        )}
                        label={<FormattedMessage id="AVAILABLE" />}
                    />
                </ListItem>
                <ListItem dense>
                    <TextField
                        name="studio"
                        fullWidth
                        value={studio}
                        label={<FormattedMessage id="STUDIO" />}
                        size="small"
                        onChange={handleInputChange}
                    />
                </ListItem>
                <ListItem dense>
                    <TextField
                        name="productionDcp"
                        fullWidth
                        value={productionDcp}
                        label={<FormattedMessage id="PRODUCTION_DCP" />}
                        size="small"
                        onChange={handleInputChange}
                    />
                </ListItem>
                <ListItem dense>
                    <TextField
                        name="duration"
                        fullWidth
                        value={duration}
                        label={<FormattedMessage id="DURATION" />}
                        size="small"
                        onChange={handleInputChange}
                    />
                </ListItem>
                <ListItem dense>
                    <TextField
                        name="credit"
                        fullWidth
                        value={credit}
                        label={<FormattedMessage id="CREDIT" />}
                        size="small"
                        onChange={handleInputChange}
                    />
                </ListItem>
                <ListItem dense>
                    <FormSelect
                        fullWidth
                        size="small"
                        label={<FormattedMessage id="TYPE" />}
                        name="contentType"
                        value={contentType}
                        multiple
                        onChange={handleInputChange}
                    >
                        {
                            contentTypesList.map(({ id, description }) => (
                                <MenuItem value={id} key={id}>
                                    {description}
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </ListItem>
                <ListItem dense>
                    <FormSelect
                        fullWidth
                        size="small"
                        label={<FormattedMessage id="LANGUAGE" />}
                        name="language"
                        value={language}
                        multiple
                        onChange={handleInputChange}
                    >
                        {
                            languageList.map(({ id, description }) => (
                                <MenuItem value={id} key={id}>
                                    {description}
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </ListItem>
                <ListItem dense>
                    <FormSelect
                        fullWidth
                        size="small"
                        label={<FormattedMessage id="TERRITORY" />}
                        name="territory"
                        value={territory}
                        multiple
                        onChange={handleInputChange}
                    >
                        {
                            territoryList.map(({ id, description }) => (
                                <MenuItem value={id} key={id}>
                                    {description}
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </ListItem>

                <ListItem dense>
                    <FormSelect
                        fullWidth
                        size="small"
                        label={<FormattedMessage id="RATIO" />}
                        name="ratio"
                        value={ratio}
                        multiple
                        onChange={handleInputChange}
                    >
                        {
                            ratioList.map(({ id, description }) => (
                                <MenuItem value={id} key={id}>
                                    {description}
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </ListItem> 
                <ListItem dense>
                    <FormSelect
                        fullWidth
                        size="small"
                        label={<FormattedMessage id="AUDIO" />}
                        name="audio"
                        value={audio}
                        multiple
                        onChange={handleInputChange}
                    >
                        {
                            audioList.map(({ id, description }) => (
                                <MenuItem value={id} key={id}>
                                    {description}
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </ListItem>                 

                <ListItem dense>
                    <FormSelect
                        fullWidth
                        size="small"
                        label={<FormattedMessage id="RESOLUTION" />}
                        name="resolution"
                        value={resolution}
                        multiple
                        onChange={handleInputChange}
                    >
                        {
                            resolutionList.map(({ id, description }) => (
                                <MenuItem value={id} key={id}>
                                    {description}
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </ListItem> 

                <ListItem dense>
                    <FormSelect
                        fullWidth
                        size="small"
                        label={<FormattedMessage id="PACKAGE" />}
                        name="typePackage"
                        value={typePackage}
                        multiple
                        onChange={handleInputChange}
                    >
                        {
                            PACKAGES.map(({ id, description }) => (
                                <MenuItem value={id} key={id}>
                                    {description}
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </ListItem> 

                <ListItem dense>
                    <FormSelect
                        fullWidth
                        size="small"
                        label={<FormattedMessage id="STANDARD" />}
                        name="standard"
                        value={standard}
                        multiple
                        onChange={handleInputChange}
                    >
                        {
                            STANDARDS.map(({ id, description }) => (
                                <MenuItem value={id} key={id}>
                                    {description}
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </ListItem>          
                <ListItem dense>
                    <DateRangePicker
                        clearable
                        fullWidth
                        inputFormat="yyyy-MM-dd"
                        inputVariant="outlined"
                        size="small"
                        margin="dense"
                        disableToolbar
                        startValue={fromWillBeDeletedDate}
                        startName="fromWillBeDeletedDate"
                        startLabel={<FormattedMessage id="DELETED_DATE" />}
                        endName="toWillBeDeletedDate"
                        endValue={toWillBeDeletedDate}
                        onChange={handleInputChange}
                    />
                </ListItem>
                

                    

                <ListItem dense>
                    <FormControlLabel
                        margin="none"
                        control={<Checkbox checked={!!atmos} onChange={handleCheckBoxChange} name="atmos" />}
                        label={<FormattedMessage id="ATMOS" />}
                    />
                </ListItem>
                <ListItem dense>
                    <FormControlLabel
                        margin="none"
                        control={<Checkbox checked={!!auro} onChange={handleCheckBoxChange} name="auro" />}
                        label={<FormattedMessage id="AURO" />}
                    />
                </ListItem>
                <ListItem dense>
                    <FormControlLabel
                        margin="none"
                        control={<Checkbox checked={!!ccap} onChange={handleCheckBoxChange} name="ccap" />}
                        label={<FormattedMessage id="CCAP" />}
                    />
                </ListItem>
                <ListItem dense>
                    <FormControlLabel
                        margin="none"
                        control={<Checkbox checked={!!hi} onChange={handleCheckBoxChange} name="hi" />}
                        label={<FormattedMessage id="HI" />}
                    />
                </ListItem>
                <ListItem dense>
                    <FormControlLabel
                        margin="none"
                        control={<Checkbox checked={!!ocap} onChange={handleCheckBoxChange} name="ocap" />}
                        label={<FormattedMessage id="OCAP" />}
                    />
                </ListItem>
                <ListItem dense>
                    <FormControlLabel
                        margin="none"
                        control={<Checkbox checked={!!threeD} onChange={handleCheckBoxChange} name="threeD" />}
                        label={<FormattedMessage id="3D" />}
                    />
                </ListItem>
                <ListItem dense>
                    <FormControlLabel
                        margin="none"
                        control={<Checkbox checked={!!vi} onChange={handleCheckBoxChange} name="vi" />}
                        label={<FormattedMessage id="VI" />}
                    />
                </ListItem>
                <ListItem dense>
                    <FormControlLabel
                        margin="none"
                        control={<Checkbox checked={!!dbox} onChange={handleCheckBoxChange} name="dbox" />}
                        label={<FormattedMessage id="DBOX" />}
                    />
                </ListItem>

            </List>
            <ListItem>
                <Grid container justifyContent="flex-end" spacing={2} alignItems="center">
                    <Grid item>
                        <Button
                            onClick={clearFilters}
                            variant="text"
                        >
                            <FormattedMessage id="CANCEL" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={handleSubmit}
                            disabled={fetchingFilters || fetchingOrders}
                            type="submit"
                            color="primary"
                            variant="contained"
                        >
                            <FormattedMessage id="SEARCH" />
                        </Button>
                    </Grid>
                </Grid>
            </ListItem>
    </Drawer>
    </>
    );
}

DcpFilter.propTypes = propTypes;
DcpFilter.defaultProps = defaultProps;

export default DcpFilter;

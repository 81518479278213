import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';

import EnhancedTableCell from './EnhancedTableCell';

const propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        align: PropTypes.oneOf(['right', 'left', 'center']),
        Component: PropTypes.func,
    })).isRequired,
    orderBy: PropTypes.string,
    order: PropTypes.oneOf(['asc', 'desc']),
    isSortable: PropTypes.bool.isRequired,
    onRequestSort: PropTypes.func,
    isSelectable: PropTypes.bool.isRequired,
    numSelectable: PropTypes.number.isRequired,
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    showDivisions: PropTypes.bool,
};

const defaultProps = {
    order: 'asc',
    orderBy: '',
    onRequestSort: null,
    showDivisions: true,
};

const visuallyHidden = {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
};

class EnhancedTableHeader extends PureComponent {
    createSortHandler = (property) => {
        const { onRequestSort } = this.props;
        return (event) => {
            onRequestSort(event, property);
        };
    }


    render() {
        const {
            columns, orderBy, order, isSortable,
            isSelectable, numSelectable, numSelected, rowCount, onSelectAllClick,
            showDivisions, ref, multiple
        } = this.props;

        return (
            <TableHead ref={ref}>
                <TableRow>
                    {
                        !isSelectable  ? null : (
                            <EnhancedTableCell align="center" padding="checkbox" borders={showDivisions}>
                                <Checkbox
                                    indeterminate={numSelected > 0 && numSelected < numSelectable}
                                    checked={numSelected === numSelectable && numSelectable > 0}
                                    onChange={onSelectAllClick}
                                    disabled={numSelectable === 0 || !multiple}
                                />
                            </EnhancedTableCell>
                        )
                    }
                    {
                        columns.map(({ field, title, align }) => (
                            <EnhancedTableCell
                                key={field}
                                align={align}
                                borders={showDivisions}
                                {... isSortable ? { sortDirection: orderBy === field ? order : false } : {}}
                            >
                                {
                                    !isSortable ? title && (
                                        <FormattedMessage id={title} />
                                    ) : title && (
                                        <TableSortLabel
                                            active={orderBy === field}
                                            direction={order}
                                            onClick={this.createSortHandler(field)}
                                            disabled={rowCount === 0}
                                        >
                                            <FormattedMessage id={title} />
                                            {
                                                orderBy !== field ? null : (
                                                    <span style={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                )
                                            }
                                        </TableSortLabel>
                                    )
                                }
                            </EnhancedTableCell>
                        ))
                    }
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHeader.propTypes = propTypes;
EnhancedTableHeader.defaultProps = defaultProps;

export default EnhancedTableHeader;

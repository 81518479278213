import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useUser } from 'contexts/user';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { filter, isEmpty } from 'lodash';
import isEqual from 'lodash/isEqual';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import DateRangePicker from 'components/DateRangePicker';
import InputMenuItem from 'components/InputMenuItem';
import FormSelect from 'components/FormSelect';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
    },
    badge: {
        opacity: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        borderRadius: 'inherit',
        lineHeight: 1,
        fontSize: 'inherit',
        padding: 'inherit',
        '.MuiBadge-badge:hover > &': {
            opacity: 1,
        },
        '&, &:active, &:hover': {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main,
        },
    },
}));

const propTypes = {
    clientId: PropTypes.number.isRequired,
    initialFilters: PropTypes.shape({
        titleSearch: PropTypes.string,
        product: PropTypes.arrayOf(PropTypes.string),
        fromDate: PropTypes.instanceOf(Date),
        toDate: PropTypes.instanceOf(Date),
        status: PropTypes.arrayOf(PropTypes.number),
        distributor: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    applyFilters: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const defaultProps = {
};


function VODFilter(props) {
    const {
        clientId,
        initialFilters,
        applyFilters,
        isLoading,
        defaultFilter,
    } = props;

    const classes = useStyles();
    const [orderStatuses, setOrderStatuses] = React.useState([]);
    const [orderDistributors, setOrderDistributors] = React.useState([]);
    const [orderCcStatus, setOrderCcStatus] = React.useState([]);
    const [orderTypes, setOrderTypes] = React.useState([]);
    const [orderContentType, setOrderContentType] = React.useState([]);
    const [orderEncodingFormat, setOrderEncodingFormat] = React.useState([]);
    const [orderLanguage, setOrderLanguage] = React.useState([]);
    const [orderProduct, setOrderProduct] = React.useState([]);
    const [orderTranslationType, setOrderTranslationType] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const { api } = useUser();
    const [fetchingFilters, setFetchingFilters] = React.useState(false);
    const [fetchingFiltersFailed, setFetchingFiltersFailed] = React.useState(false);
    const [filtersActive, setFiltersActive] = React.useState(!isEqual(defaultFilter, initialFilters));
    const [filters, setFilters] = React.useState(initialFilters);

    const {
        titleSearch, status, distributor, orderId, itemId,
        version, translation, statusCc, encodingFormat,
        product, contentType, advertisment, fromStartWindow,
        toStartWindow, fromEndWindow, toEndWindow, toDeliveryDate,
        fromDeliveryDate, toOrderOn, fromOrderOn, episodeNumber,
        episodeTitle, seasonNumber, commanderPar, poNumber, hasProblem,
        orderType,
    } = filters;

    const handleMenuClick = React.useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    function handleInputChange({ target: { name, value } }) {
        setFilters({ ...filters, [name]: value });
    }

    function handleHasProblemeChange({ target: { name, checked } }) {
        setFilters({ ...filters, [name]: checked ? true : null });
    }

    async function fetchFilters(abortController = new AbortController()) {
        if (clientId) {
            try {
                setFetchingFilters(true);
                setFetchingFiltersFailed(false);
                const response = await api.get(`/vod/orders/${clientId}/filters`, {}, {signal: abortController.signal});
                if (response.ok) {
                    const {
                        statuses, ccStatus, contentType, distributors,
                        encodingFormat, language, product, translationType, orderType
                    } = await response.json();
                    setOrderStatuses(statuses);
                    setOrderDistributors(distributors);
                    setOrderCcStatus(ccStatus);
                    setOrderTypes(orderType);
                    setOrderContentType(contentType);
                    setOrderEncodingFormat(encodingFormat);
                    setOrderLanguage(language);
                    setOrderProduct(product);
                    setOrderTranslationType(translationType);
                } else {
                    setOrderStatuses([]);
                    setOrderDistributors([]);
                    setOrderCcStatus([]);
                    setOrderTypes([]);
                    setOrderContentType([]);
                    setOrderEncodingFormat([]);
                    setOrderLanguage([]);
                    setOrderProduct([]);
                    setOrderTranslationType([]);
                    setFetchingFiltersFailed(true);
                }
                setFetchingFilters(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchFilters(abortController);
        return () => {
            abortController.abort();
        };
    }, [clientId]);

    function handleSubmit(event) {
        event.preventDefault();
        handleMenuClose();
        applyFilters(filters);
        setFiltersActive(true);
    }

    function clearFilters(event) {
        event.stopPropagation();
        handleMenuClose();
        setFilters(defaultFilter);

        if (filtersActive) {
            applyFilters(defaultFilter);
        }
    }

    const filterCount = React.useMemo(() => (
        (Object.keys(filters || {}).filter((key) => (filters[key] && !isEmpty(filters[key]))) || []).length + (filters.hasProblem ? 1 : 0)
    ), [filters]);


    return (
        <>
            <Grid
                component="form"
                onSubmit={handleSubmit}
                container
                spacing={1}
                alignItems="center"
            >
                <Grid item xs>
                    <TextField
                        size="small"
                        margin="dense"
                        variant="outlined"
                        type="text"
                        name="titleSearch"
                        fullWidth
                        value={titleSearch}
                        label={<FormattedMessage id="TITLE" />}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid
                    item
                    xs
                    lg={3}
                >
                    <FormSelect
                        fullWidth
                        size="small"
                        multiple
                        margin="dense"
                        variant="outlined"
                        name="status"
                        value={status}
                        onChange={handleInputChange}
                        label={<FormattedMessage id="STATUS" />}
                    >
                        {
                            orderStatuses.map(({ idStatutItem, description }) => (
                                <MenuItem value={idStatutItem} key={idStatutItem}>
                                    {description}
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </Grid>
                <Grid
                    item
                    xs
                    lg={3}
                >
                    <FormSelect
                        fullWidth
                        multiple
                        size="small"
                        margin="dense"
                        variant="outlined"
                        name="distributor"
                        value={distributor}
                        onChange={handleInputChange}
                        label={<FormattedMessage id="DISTRIBUTOR" />}
                    >
                        {
                            orderDistributors.map(({ idClient, nom }) => (
                                <MenuItem value={idClient} key={idClient}>
                                    {nom[0].toUpperCase() + nom.substr(1).toLowerCase()}
                                </MenuItem>
                            ))
                        }
                    </FormSelect>
                </Grid>
                <Grid item>
                    <Badge
                        color="primary"
                        overlap="circular"
                        invisible={filterCount < 1}
                        badgeContent={(
                            <>
                                <span>
                                    {filterCount}
                                </span>
                                <IconButton size="small" className={classes.badge} onClick={clearFilters} color="error">
                                    <ClearIcon fontSize="inherit" />
                                </IconButton>
                            </>
                        )}
                    >
                        <IconButton onClick={handleMenuClick} size="large">
                            <FilterListIcon />
                        </IconButton>
                    </Badge>
                </Grid>
                <Grid item>
                    <Button
                        disabled={fetchingFilters || isLoading}
                        type="submit"
                        color="primary"
                        variant="outlined"
                    >
                        <FormattedMessage id="SEARCH" />
                    </Button>
                </Grid>
            </Grid>
            <Drawer
                anchor="right"
                keepMounted
                open={!!anchorEl}
                onClose={handleMenuClose}
            >
                <List style={{ height: '100%', overflow: 'auto' }}>
                    <ListItem dense>
                        <TextField
                            fullWidth
                            name="poNumber"
                            size="small"
                            value={poNumber}
                            label={<FormattedMessage id="PO_NUMBER" />}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <TextField
                            name="itemId"
                            fullWidth
                            value={itemId}
                            label={<FormattedMessage id="DELIVERIES_NUMBER" />}
                            size="small"
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <TextField
                            name="episodeTitle"
                            size="small"
                            fullWidth
                            value={episodeTitle}
                            label={<FormattedMessage id="EPISODE_TITLE" />}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <FormSelect
                            fullWidth
                            size="small"
                            label={<FormattedMessage id="CC_STATUS" />}
                            name="statusCc"
                            value={statusCc}
                            multiple
                            onChange={handleInputChange}
                        >
                            {
                                orderCcStatus.map(({ id, description }) => (
                                    <MenuItem value={id} key={id}>
                                        {description}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </ListItem>
                    <ListItem dense>
                        <FormSelect
                            fullWidth
                            size="small"
                            label={<FormattedMessage id="ORDER_TYPE" />}
                            name="orderType"
                            value={orderType}
                            // multiple
                            onChange={handleInputChange}
                        >
                            {
                                orderTypes.map(({ id, description }) => (
                                    <MenuItem value={id} key={id}>
                                        {description}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </ListItem>
                    <ListItem dense>
                        <FormSelect
                            fullWidth
                            size="small"
                            label={<FormattedMessage id="VERSION" />}
                            name="version"
                            value={version}
                            multiple
                            onChange={handleInputChange}
                        >
                            {
                                orderLanguage.map(({ id, description }) => (
                                    <MenuItem value={id} key={id}>
                                        {description}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </ListItem>
                    <ListItem dense>
                        <FormSelect
                            fullWidth
                            size="small"
                            label={<FormattedMessage id="TRANSLATION_TYPE" />}
                            name="translation"
                            value={translation}
                            multiple
                            onChange={handleInputChange}
                        >
                            {
                                orderTranslationType.map(({ id, description }) => (
                                    <MenuItem value={id} key={id}>
                                        {description}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </ListItem>
                    <ListItem dense>
                        <FormSelect
                            fullWidth
                            size="small"
                            label={<FormattedMessage id="CONTENT_TYPE" />}
                            name="contentType"
                            value={contentType}
                            multiple
                            onChange={handleInputChange}
                        >
                            {
                                orderContentType.map(({ id, description }) => (
                                    <MenuItem value={id} key={id}>
                                        {description}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </ListItem>
                    <ListItem dense>
                        <FormSelect
                            fullWidth
                            size="small"
                            label={<FormattedMessage id="FORMAT" />}
                            name="encodingFormat"
                            value={encodingFormat}
                            multiple
                            onChange={handleInputChange}
                        >
                            {
                                orderEncodingFormat.map(({ id, description }) => (
                                    <MenuItem value={description} key={id}>
                                        {description}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </ListItem>
                    <ListItem dense>
                        <FormSelect
                            fullWidth
                            size="small"
                            label={<FormattedMessage id="PRODUCT" />}
                            name="product"
                            value={product}
                            multiple
                            onChange={handleInputChange}
                        >
                            {
                                orderProduct.map(({ xmlProduit }) => (
                                    <MenuItem value={xmlProduit} key={xmlProduit}>
                                        {xmlProduit}
                                    </MenuItem>
                                ))
                            }
                        </FormSelect>
                    </ListItem>
                    <ListItem dense>
                        <FormControlLabel
                            fullWidth
                            control={<Checkbox checked={hasProblem} onChange={handleHasProblemeChange} name="hasProblem" />}
                            label={<FormattedMessage id="HAS_PROBLEM" />}
                        />
                    </ListItem>
                    <ListItem dense>
                        <DateRangePicker
                            clearable
                            fullWidth
                            inputFormat="yyyy-MM-dd"
                            inputVariant="outlined"
                            size="small"
                            margin="dense"
                            disableToolbar
                            startValue={fromStartWindow}
                            startName="fromStartWindow"
                            startLabel={<FormattedMessage id="START_WINDOW" />}
                            endName="toStartWindow"
                            endValue={toStartWindow}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <DateRangePicker
                            clearable
                            fullWidth
                            inputFormat="yyyy-MM-dd"
                            inputVariant="outlined"
                            size="small"
                            margin="dense"
                            disableToolbar
                            startValue={fromEndWindow}
                            startName="fromEndWindow"
                            startLabel={<FormattedMessage id="END_WINDOW" />}
                            endName="toEndWindow"
                            endValue={toEndWindow}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <DateRangePicker
                            clearable
                            fullWidth
                            inputFormat="yyyy-MM-dd"
                            inputVariant="outlined"
                            size="small"
                            margin="dense"
                            disableToolbar
                            startValue={fromDeliveryDate}
                            startName="fromDeliveryDate"
                            startLabel={<FormattedMessage id="ESTIMATED_DELIVERY" />}
                            endName="toDeliveryDate"
                            endValue={toDeliveryDate}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <DateRangePicker
                            clearable
                            fullWidth
                            inputFormat="yyyy-MM-dd"
                            inputVariant="outlined"
                            size="small"
                            margin="dense"
                            disableToolbar
                            startValue={fromOrderOn}
                            startName="fromOrderOn"
                            startLabel={<FormattedMessage id="ORDERED_ON" />}
                            endName="toOrderOn"
                            endValue={toOrderOn}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <TextField
                            name="commanderPar"
                            fullWidth
                            size="small"
                            value={commanderPar}
                            label={<FormattedMessage id="ORDER_BY" />}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <TextField
                            name="advertisment"
                            fullWidth
                            size="small"
                            value={advertisment}
                            label={<FormattedMessage id="ADVISORY" />}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <TextField
                            name="seasonNumber"
                            fullWidth
                            size="small"
                            value={seasonNumber}
                            label={<FormattedMessage id="SEASON_NUMBER" />}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                    <ListItem dense>
                        <TextField
                            name="episodeNumber"
                            fullWidth
                            size="small"
                            value={episodeNumber}
                            label={<FormattedMessage id="EPISODE_NUMBER" />}
                            onChange={handleInputChange}
                        />
                    </ListItem>
                </List>
                <ListItem>
                <Grid container justifyContent="flex-end" spacing={2} alignItems="center">
                    <Grid item>
                        <Button
                            onClick={clearFilters}
                            variant="text"
                        >
                            <FormattedMessage id="CLOSE" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={handleSubmit}
                            type="submit"
                            color="primary"
                            variant="contained"
                        >
                            <FormattedMessage id="SEARCH" />
                        </Button>
                    </Grid>
                </Grid>
            </ListItem>
            </Drawer>
        {/* </form> */}
        </>
    );
}

VODFilter.propTypes = propTypes;
VODFilter.defaultProps = defaultProps;

export default VODFilter;

import React from 'react';
import Management from 'components/Contacts';
import Invoices from 'components/Invoices';

import NotFound from 'components/NotFound';
import Entities from 'components/Distribution/Catalogue';
import Entity from 'components/Distribution/Entities/Entity';
import DistriOrders from 'components/Distribution/Orders';
import DistriWorkspace from 'components/Distribution/Orders/Workspace';
import PendingDistri from 'components/Distribution/Orders/Pending';
import VOD from 'components/VOD';
import PendingVod from 'components/VOD/Pending';
import SubmitOrder from 'components/VOD/SubmitOrder';
import PhysicalVault from 'components/Vault';
import Dcp from 'components/Dcp';
import {
    VodOrderDefinition, VodPendingOrderDefinition, VodSubmitOrderDefinition, DistributionEntitiesDefinition,
    DistributionPendingOrderDefinition, DistributionEntityDefinition, DistributionOrderDefinition, DistributionWorkspaceDefinition,
    DistributionSubmitOrderDefinition, PhysicalVaultDefinition, InvoicesDefinition, ManagementDefinition, DistributionAssetOrderDefinition,
    NotFoundDefinition, DcpDefinition
} from 'routes/Paths/Definitions';

export const VodOrderPath = {
    ...VodOrderDefinition,
    component: VOD,
};

export const VodPendingOrderPath = {
    ...VodPendingOrderDefinition,
    component: PendingVod,
};
export const VodSubmitOrderPath = {
    ...VodSubmitOrderDefinition,
    component: SubmitOrder,
};
export const DistributionEntitiesPath = {
    ...DistributionEntitiesDefinition,
    component: Entities,
};
export const DistributionPendingOrderPath = {
    ...DistributionPendingOrderDefinition,
    component: PendingDistri,
};
export const DistributionEntityPath = {
    ...DistributionEntityDefinition,
    component: Entity,
};
// export const DistributionAssetPath = {
//     ...DistributionAssetOrderDefinition,
//     component: Entity,
// };
export const DistributionOrderPath = {
    ...DistributionOrderDefinition,
    component: DistriOrders,
};
export const DistributionWorkspacePath = {
    ...DistributionWorkspaceDefinition,
    component: DistriWorkspace,
};
export const PhysicalVaultPath = {
    ...PhysicalVaultDefinition,
    component: PhysicalVault,
};
export const InvoicesPath = {
    ...InvoicesDefinition,
    component: Invoices,
};
export const ManagementPath = {
    ...ManagementDefinition,
    component: Management,
};
export const NotFoundPath = {
    ...NotFoundDefinition,
    component: NotFound,
};

export const DcpPath = {
    ...DcpDefinition,
    component: Dcp,
};

export default [
    VodOrderPath,
    VodPendingOrderPath,
    VodSubmitOrderPath,
    DistributionEntitiesPath,
    DistributionPendingOrderPath,
    DistributionEntityPath,
    DistributionOrderPath,
    DistributionWorkspacePath,
    PhysicalVaultPath,
    InvoicesPath,
    ManagementPath,
    NotFoundPath,
    DcpPath,
]; // order matters

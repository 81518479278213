import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';

// import AuthenticatedImage from 'components/AuthenticatedImage';
import { useUser } from 'contexts/user';


const propTypes = {
    alt: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    fallback: PropTypes.string.isRequired,
    className: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    loading: PropTypes.bool,
};

const defaultProps = {
    className: undefined,
    height: undefined,
    width: undefined,
    loading: false,
};


function FallbackImage(props) {
    const {
        alt, source, fallback, className,
        height, width,
        loading: parentLoading, loadingProps={}, withAuth,
    } = props;

    const [controlledLoading, setControlledLoading] = React.useState(withAuth || parentLoading);
    const [errored, setErrored] = React.useState(false);
    const [authenticatedSource, setAutheticatedSource] = React.useState();
    const { api } = useUser();

    const [loading, setLoading, src] = withAuth ? [controlledLoading, setControlledLoading, authenticatedSource] : [parentLoading, () => {}, source];

    async function getSource(abortController = new AbortController()) {
        setLoading(true)
        try {
            const response = await api.get(source, {}, { signal: abortController.signal });

            if (response.ok) {
                const imageBlob = await response.blob();
                const imageObjectURL = URL.createObjectURL(imageBlob);

                setAutheticatedSource(imageObjectURL);
            } else {
                setErrored(true);
            }
        } catch (error) {
            if (!abortController.signal.aborted) {
                console.error(error);
            }
            setErrored(true);
        }
        setLoading(false)
    }


    function handleError() {
        if (!errored) {
            setErrored(true);
        }
    }

    function handleLoad() {
        if (authenticatedSource) {
            URL.revokeObjectURL(authenticatedSource);
        }
    }

    React.useEffect(() => {
        setErrored(false);
        if (withAuth) {
            const abortController = new AbortController();

            getSource(abortController);
            return () => {
                abortController.abort();
                handleLoad();
            };
        }
    }, [source, withAuth]); // reset if source prop changes


    if (loading) {
        return (
            <Skeleton
                className={className}
                height={height}
                width={width}
                variant="rectangular"
                {...loadingProps}
            />
        );
    }

    return (
        <img
            className={className}
            alt={alt}
            // use onLoad to indicate that the image is loaded, therefore we can remove loading overlay
            onError={handleError}
            onLoad={handleLoad}
            src={errored || !src ? fallback : src}
            height={height}
            width={width}
        />
    );

}

FallbackImage.propTypes = propTypes;
FallbackImage.defaultProps = defaultProps;

export default FallbackImage;

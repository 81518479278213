import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';


export function OrderObject({ orderTypeDescription, deliveredTo, broadcaster, poNumber, createdClientName}) {
    const to = deliveredTo || broadcaster;
    let data = to ? <FormattedMessage id="DELIVERY_TO" values={{name: <strong>{to}</strong>}} /> : orderTypeDescription;
    let other_client = null;
    let po_data = null;

    if (createdClientName) {
        other_client = (
            <Tooltip title={
                <FormattedMessage
                    id="ORDER_BY_NAME"
                    values={{ orderBy: createdClientName, createdClientName: null }}
                />
            }>
                <Chip
                    size="small"
                    variant="outlined"
                    label={createdClientName}
                    sx={{verticalAlign: 'baseline', marginLeft: '8px'}}
                />
            </Tooltip>
        );
    }

    if (poNumber) {
        po_data = (
            <Chip
                size="small"
                variant="outlined"
                label={`po. ${poNumber}`}
                sx={{verticalAlign: 'baseline', marginLeft: '8px'}}
            />
        );
    }

    return (
        <>
            {data}
            {po_data}
            {other_client}
        </>
    );
}

export function DeliveryDate({ deliveryDate }) {
    return !deliveryDate ? null : (
        <FormattedDate value={deliveryDate} year="numeric" month="long" day="2-digit" timeZone="UTC" />
    );
}

export function fncTooltip({ statusDescription, hasProblem }) {
    let statusDefinition = '';
    if (statusDescription === 'En attente de master') {
        statusDefinition = "MELS est en attente d'un ou plusieurs éléments sources provenant du distributeur (vidéo,cc, etc.).";
    } else if (statusDescription === 'Annulé') {
        statusDefinition = 'La commande a été annulée soit par MELS ou par le client';
    } else if (statusDescription === 'En production') {
        statusDefinition = 'MELS travaille actuellement sur la création du livrable.';
    } else if (statusDescription === 'Facturé') {
        statusDefinition = 'MELS a envoyé le livrable et la facture au client.';
    } else if (statusDescription === 'Master reçu') {
        statusDefinition = "MELS a reçu tous les éléments nécessaires pour la création du livrable. Aucun travail n'a encore débuté.";
    } else if (statusDescription === 'Travaux terminés') {
        statusDefinition = "MELS a envoyé le livrable au client. La facture n'a toujours pas été envoyée.";
    } else if (statusDescription === 'Billed') {
        statusDefinition = 'MELS has sent the delivery file and the bill to the client.';
    } else if (statusDescription === 'Cancelled') {
        statusDefinition = 'The order has been cancelled by MELS or the client.';
    } else if (statusDescription === 'In production') {
        statusDefinition = 'MELS is currently working to create the delivery file.';
    } else if (statusDescription === 'Master received') {
        statusDefinition = 'MELS has received all the source files to produce the delivery file.  No work has been started.';
    } else if (statusDescription === 'Waiting for master') {
        statusDefinition = 'MELS is waiting for the source files from the provider (video,cc,  etc.).';
    } else if (statusDescription === 'Work completed') {
        statusDefinition = 'MELS has sent the delivery file to the client. The bill has not been  sent.';
    }

    return (
        <span style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={statusDefinition}>
                <span>
                    {statusDescription}
                </span>
            </Tooltip>
            { !hasProblem ? null : <ErrorIcon fontSize="small" color="error" style={{ marginLeft: '8px' }} /> }
        </span>
    );
}

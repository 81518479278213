import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { generatePath, useRouteMatch, useHistory, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import parseISO from 'date-fns/parseISO';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useUser } from 'contexts/user';
import RouteLink from 'components/RouteLink';
import { useConstants } from 'contexts/constants';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ErrorIcon from '@mui/icons-material/Error';

import Discussion from 'components/Discussion';
import DiscussionBadge from 'components/Discussion/Badge';
import DateRangePicker from 'components/DateRangePicker';
import DatePicker from 'components/DatePicker';
import DownloadButton from 'components/DownloadButton';
import OrderDetails from 'components/Orders/Details';
import { DistributionEntityDefinition, DistributionOrderDefinition } from 'routes/Paths/Definitions';
import Table from 'components/Table/EnhancedTable';
import Documents from 'components/Documents';

import AssetExpectedDate from 'components/Distribution/Orders/Create/AssetExpectedDate';
import AssetDescription from 'components/Distribution/Assets/Description';
import { OrderObject, DeliveryDate, fncTooltip } from './OrderData';

const discussionTab = 'DISCUSSION';
const detailsTab = 'DETAILS';
const orderTab = 'ORDER';
const orderDetailsTabs = [detailsTab, orderTab];
const defaultTab = detailsTab;

function TabPanel(props) {
    const { children, currentValue, value } = props;

    return value === currentValue && children;
}

const useStyles = makeStyles((theme) => createStyles({
    strikethrough: {
        textDecoration: 'line-through',
    },
    notes: {
        marginTop: theme.spacing(2),
    },
    orderDetails: {
        marginTop: '0',
        marginBottom: '0',
    },
    editableDetails: {
        marginLeft: '10px',
        marginTop: '0',
        marginBottom: '0',
    },
    dialog: {
    },
    dialogDiscussion: {
        '& > .MuiDialog-container.MuiDialog-scrollPaper > .MuiPaper-root.MuiDialog-paper': {
            height: '100%',
        },
    },
    discussion: {
        height: 'auto',
        width: 'auto',
        flex: '1 1 auto',
        margin: 0,
    },
    problemContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    problemIcon: {
        marginRight: theme.spacing(1),
    },
    discussionButton: {
        '& .MuiButton-label': {
            display: 'inline-flex',
        },
        '& .MuiButton-endIcon.MuiButton-iconSizeMedium > :first-child': {
            fontSize: '0.8125rem',
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& .MuiTouchRipple-root': {
            display: 'none',
        },
    },
}));

const propTypes = {
};

const defaultProps = {
};

function DistributionOrderDetails(props) {
    const match = useRouteMatch();
    const history = useHistory();
    const { order: orderId, client } = useParams();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { refreshPage } = props;

    const { api, user } = useUser();
    const [order, setOrder] = React.useState({});
    const [fetchingOrder, setFetchingOrder] = React.useState(false);
    const [updatingOrder, setUpdatingOrder] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const [isCancelSelected, setIsCancelSelected] = React.useState(false);
    const [newWindowStart, setNewWindowStart] = React.useState('');
    const [newWindowEnd, setNewWindowEnd] = React.useState('');
    const [newRating, setNewRating] = React.useState('');
    const [newAdvisory, setNewAdvisory] = React.useState('');
    const [newReceivedDate, setNewReceivedDate] = React.useState('');
    const [cancelOrderReason, setCancelOrderReason] = React.useState('');
    const [selectedTab, setSelectedTab] = React.useState(defaultTab);
    const classes = useStyles();
    const {
        ratings: ratingsUnfiltered, isLoading: fetchingConstants,

    } = useConstants();

    const ratings = ratingsUnfiltered.filter((thing, index, self) => index === self.findIndex((t) => (t.label === thing.label)));
    const userPermission = user.clients.find((c) => c.client === parseInt(client, 10)) || {};
    const WriteDistribution = userPermission.distribution === 2;
    const ReadDistribution = userPermission.distribution >= 1;

    function closeModal() {
        history.push({
            pathname: generatePath(match.path,  { client }),
            search: history.location.search,
        });
    }


    async function fetchOrder(abortController = new AbortController()) {
        if (client && orderId) {
            try {
                setFetchingOrder(true);
                setFailed(false);

                const response = await api.get(`/distribution/orders/${client}/${orderId}`, {}, { signal: abortController.signal });

                if (response.ok) {
                    const data = await response.json();
                    setOrder(data);
                } else {
                    setOrder({});
                    setFailed(true);
                }

                setFetchingOrder(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    async function updateOrder(params, abortController = new AbortController()) {
        const { cancelOrder } = params;
        if (client && orderId) {
            const snackId = enqueueSnackbar(<FormattedMessage id={cancelOrder ? 'CANCELLING_ORDER' : 'UPDATING_ORDER'} />, { variant: 'loading', persist: true });
            setUpdatingOrder(true);
            setFailed(false);
            let response = { ok: false };
            try {
                response = await api.put(`/distribution/orders/${client}/${orderId}`, params, { signal: abortController.signal });
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            } finally {
                closeSnackbar(snackId);
                if (response.ok) {
                    enqueueSnackbar(<FormattedMessage id={cancelOrder ? 'ORDER_CANCELLED' : 'ORDER_UPDATED'} />, { variant: 'success' });
                    closeModal();
                    if (refreshPage) {
                        refreshPage();
                    }
                } else {
                    enqueueSnackbar(<FormattedMessage id={cancelOrder ? 'ERROR_CANCELLING_ORDER' : 'ERROR_UPDATING_ORDER'} />, { variant: 'error' });
                }
                setUpdatingOrder(false);
            }
        }
    }


    React.useEffect(() => {
        const abortController = new AbortController();
        if (orderId) {
            fetchOrder(abortController);
        } else {
            setOrder({});
            setFetchingOrder(false);
            setUpdatingOrder(false);
            setFailed(false);
            setIsEditing(false);
            setIsCancelSelected(false);
            setNewWindowStart(undefined);
            setNewWindowEnd(undefined);
            setNewRating('');
            setNewAdvisory('');
            setCancelOrderReason('');
            setNewReceivedDate(undefined);
        }
        return () => {
            abortController.abort();
        };
    }, [client, orderId]);

    const {
        contentType, language, orderDate, orderedBy,
        resolution, isCancelable, title, audioType,
        encodingFormat, advisory, rating, contentAdvisory, translationType,
        product, itemStatus, itemStatusId, cancelledNotes,
        cancelledDate, episodeNumber, seasonNumber, cc,
        deliveryDate, windowEnd, windowStart, noteCommande1,
        noteCommande2, noteProduction, notification, broadcaster,
        ccMarketStatus, hasProblem, deliverTo, poNumber, note,
        packageAssets, specDocument, orderDocument, description,
        entityId, assetId, broadcastDelay, orderItems,
        cancelledBy, orderDocuments, isVodBroadcaster,
        clientHasXML, isReceived, isInVault, receivedDate, createdClientName
    } = order;

    const assetMarketStatus = () => {
        switch (ccMarketStatus) {
            case 'A_FAIRE_CCMARKET': return 'TODO_IN_HOUSE';
            case 'EN_COURS_CCMARKET': return 'IN_PROGRESS_IN_HOUSE';
            default: return null;
        }
    };

    const ccMsg = () => {
        switch (ccMarketStatus) {
            case 'OUI_SI_DISPONIBLE': return 'YES_IF_AVAILABLE';
            case 'NON': return 'NO';
            case null: return null;
            case undefined: return undefined;
            default: return 'YES';
        }
    };

    React.useEffect(() => {
        setNewWindowStart(windowStart);
        setNewWindowEnd(windowEnd);
        setNewRating(rating);
        setNewAdvisory(contentAdvisory);
        setNewAdvisory(contentAdvisory);
        setNewReceivedDate(receivedDate);
    }, [windowStart, windowEnd, rating, contentAdvisory, receivedDate]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function handleCancelOrder() {
        setIsCancelSelected(!isCancelSelected);
        setCancelOrderReason('');
    }

    function handleEdit() {
        setIsEditing(!isEditing);
        setNewWindowStart(windowStart);
        setNewWindowEnd(windowEnd);
        setNewRating(rating);
        setNewAdvisory(contentAdvisory);
        setNewReceivedDate(receivedDate);
    }

    function handleApply() {
        const params = {
            windowStart: newWindowStart,
            windowEnd: newWindowEnd,
            rating: newRating,
            advisory: newAdvisory,
            cancelOrder: isCancelSelected,
            message: cancelOrderReason,
            receivedDate: newReceivedDate
        };
        updateOrder(params);
    }

    function handleOrderItem({ itemId }) {
        setSelectedTab(detailsTab);
        history.push({
            pathname: generatePath(DistributionOrderDefinition.path, { client, order: itemId }),
            search: history.location.search,
        });
    }

    const canSave = (
        newWindowStart !== windowStart
        || newWindowEnd !== windowEnd
        || newReceivedDate !== receivedDate
        || newRating !== rating
        || newAdvisory !== contentAdvisory
        || (isCancelSelected && !isEmpty(cancelOrderReason))
    );

    let content = null;
    let notes = [];

    if (cancelledNotes) {
        notes.push({
            label: 'CANCELATION',
            content: cancelledNotes.split('\n'),
            date: cancelledDate,
        });
    }

    if (noteProduction) {
        notes.push({
            label: 'MELS',
            content: noteProduction.split('\n'),
        });
    }

    if (noteCommande1 || noteCommande2) {
        notes.push({
            label: 'CLIENT',
            content: [...(noteCommande1 || '').split('\n'), ...(noteCommande2 || '').split('\n')],
        });
    }

    if (notification) {
        notes = notes.concat(notification.map((notif) => ({
            label: 'DISTRIBUTOR',
            content: [notif.emailObject],
            date: notif.sentDate,
            additionalContent: notif.sentTo.replace(/"/gi, ''),
        })));
    }


    const loading = fetchingOrder || updatingOrder;
    const notFound = !loading && isEmpty(order);
    const hasContent = !notFound && parseInt(orderId, 10) === order.itemId;
    const isModifiable = [2,3,4].includes(itemStatusId) && (
        (isVodBroadcaster && clientHasXML) ||
        (!isReceived && !isInVault)
    )


    if (loading) {
        content = (
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        );
    } else if (notFound) {
        content = (
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage id="NOT_FOUND" />
                </DialogContentText>
            </DialogContent>
        );
    } else if (isCancelSelected) {
        content = (
            <>
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <Grid container wrap="wrap" spacing={2} className={classes.orderDetails}>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    maxRows={3}
                                    multiline
                                    margin="normal"
                                    value={cancelOrderReason}
                                    required
                                    fullWidth
                                    minRows={3}
                                    label={<FormattedMessage id="CANCEL_ORDER_REASON" />}
                                    error={isEmpty(cancelOrderReason)}
                                    helperText={!isEmpty(cancelOrderReason) ? null : <FormattedMessage id="MUST_FILL_FIELD" />}
                                    onChange={({ target: { value } }) => { setCancelOrderReason(value); }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </>
        );
    } else if (isEditing) {
        content = (
            <>
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <Grid container wrap="wrap" spacing={2} className={classes.orderDetails}>
                        {
                            !isVodBroadcaster || !clientHasXML ? null : (
                                <Grid item xs={12}>
                                    <DateRangePicker
                                        clearable
                                        fullWidth
                                        inputFormat="yyyy-MM-dd"
                                        inputVariant="outlined"
                                        size="small"
                                        margin="dense"
                                        disableToolbar
                                        startValue={newWindowStart}
                                        startName="newWindowStart"
                                        startLabel={<FormattedMessage id="AVAILABLE_FROM" />}
                                        endName="newWindowEnd"
                                        endValue={newWindowEnd}
                                        endLabel={<FormattedMessage id="AVAILABLE_TO" />}
                                        onStartChange={({ target: { value } }) => { setNewWindowStart(value); }}
                                        onEndChange={({ target: { value } }) => { setNewWindowEnd(value); }}
                                    />
                                </Grid>
                            )
                        }
                        {
                            !isVodBroadcaster || !clientHasXML ? null : (
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography color="textSecondary" gutterBottom>
                                            <FormattedMessage id="RATING" />
                                        </Typography>
                                        <Typography color="textPrimary">
                                            <Autocomplete
                                                options={ratings || []}
                                                getOptionLabel={(option) => (option && option.label) || newRating || ''}
                                                freeSolo
                                                value={newRating || ''}
                                                inputValue={newRating || ''}
                                                onInputChange={(e, newInputValue) => { setNewRating(newInputValue); }}
                                                renderInput={(props) => (
                                                    <TextField
                                                        {...props}
                                                        fullWidth
                                                        // label={<FormattedMessage id="RATING" />}
                                                    />
                                                )}
                                            />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            !isVodBroadcaster || !clientHasXML ? null : (
                                <Grid item xs={12}>
                                    <Typography color="textSecondary" gutterBottom>
                                        <FormattedMessage id="ADVISORY" />
                                    </Typography>
                                    <Typography color="textPrimary">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            value={newAdvisory || ''}
                                            // label={<FormattedMessage id="ADVISORY" />}
                                            onChange={({ target: { value } }) => { setNewAdvisory(value); }}
                                        />
                                    </Typography>
                                </Grid>
                            )
                        }
                        {
                            isReceived || isInVault ? null : (
                                <Grid item xs={12}>
                                    <DatePicker
                                        disableToolbar
                                        disablePast
                                        name="newReceivedDate"
                                        value={newReceivedDate || ''}
                                        onChange={({ target: { value } }) => setNewReceivedDate(value)}
                                        sx={{'& .MuiInputBase-root': { width: 'calc(100% + 40px)' } }}
                                        label={<FormattedMessage id="EXPECTED_SOURCE_RECEPTION" />}
                                    />
                                </Grid>
                            )
                        }
                    </Grid>
                </DialogContent>
            </>
        );
    } else if (hasContent) {

        content = (
            <DialogContent>
                <OrderDetails
                    orderType="DISTRIBUTION"
                    poNumber={poNumber}
                    notes={notes}
                    title={title}
                    description={`${description}${product ? ` - ${product}` : ''}${product && encodingFormat ? ' -' : ''}${encodingFormat ? ` (${encodingFormat})` : ''}`}
                    orderStatus={itemStatus}
                    orderStatusId={itemStatusId}
                    hasProblem={hasProblem}
                    cancelledDate={cancelledDate}
                    cancelledBy={cancelledBy}
                    cancelledReason={cancelledNotes}
                    availabilityStart={windowStart}
                    availabilityEnd={windowEnd}
                    orderDate={orderDate}
                    orderBy={orderedBy}
                    client={broadcaster}
                    deliverTo={deliverTo}
                    deliverDate={deliveryDate}
                    additionalDetails={advisory}
                    packageContent={
                        !packageAssets || !Array.isArray(packageAssets) ? null : packageAssets.map(
                            ({ descriptionPart1, descriptionPart2, status, assetTypeId }) => ({
                                description: descriptionPart1 || descriptionPart2,
                                status: (assetTypeId === 'CC' ? ((status === 'Not received' || status === 'Non reçu') ? ccMarketStatus : status) : status)
                            })
                        )
                    }
                    assetLink={generatePath(DistributionEntityDefinition.path, { client, id: entityId, assetId })}
                    broadcastDelay={broadcastDelay}
                    orderDocuments={orderDocuments}
                    orderId={orderId}
                    createdClientName={createdClientName}
                />
            </DialogContent>
        )
    }

    return (
        <Dialog
            fullWidth={!loading}
            open={!!orderId}
            onClose={selectedTab === discussionTab ? undefined : closeModal}
            className={!loading && selectedTab === discussionTab ? [classes.dialog, classes.dialogDiscussion].join(' ') : classes.dialog}
            maxWidth={{[discussionTab]: 'lg', [orderTab]: 'lg'}[selectedTab] || 'sm'}
        >
            {
                loading ? null : (
                    <AppBar position="static">
                        <Tabs indicatorColor="primary" textColor="inherit" value={selectedTab} onChange={handleTabChange}>
                            <Tab label={<FormattedMessage id="DETAILS" />} value={detailsTab} />
                            {
                                !orderItems || orderItems.length <= 1 ? null : (
                                    <Tab label={<FormattedMessage id="OTHER_ORDER_ITEMS" />} value={orderTab} />
                                )
                            }
                            <Tab
                                disabled={isEditing}
                                value={discussionTab}
                                label={<FormattedMessage id="DISCUSSION" />}
                                className={classes.discussionButton}
                                iconPosition='end'
                                icon={(
                                    <DiscussionBadge
                                        relationId={parseInt(orderId, 10)}
                                        relation="order_item"
                                        contactId={user.contact}
                                        client={client}
                                    />
                                )}
                            />
                        </Tabs>
                    </AppBar>
                )
            }
            <TabPanel currentValue={selectedTab} value={detailsTab}>
                {content}
            </TabPanel>
            <TabPanel currentValue={selectedTab} value={discussionTab}>
                <Discussion
                    relationId={parseInt(orderId, 10)}
                    relation="order_item"
                    client={client}
                    read={ReadDistribution}
                    write={WriteDistribution}
                    className={classes.discussion}
                    isLoading={loading}
                    disucssionType='order'
                />
            </TabPanel>
            <TabPanel currentValue={selectedTab} value={orderTab}>
                <DialogContent>
                    {
                        !orderItems ? null : (
                            <Table
                                rows={orderItems}
                                isSortable
                                size="small"
                                pagenate={false}
                                showDivisions={false}
                                uniqueField="itemId"
                                onRowClick={handleOrderItem}
                                columns={[
                                    {
                                        field: 'title',
                                        title: 'TITLE',
                                    },
                                    {
                                        field: 'description',
                                        title: 'ASSET',
                                        Component: AssetDescription,
                                    },
                                    {
                                        field: 'orderTypeDescription',
                                        title: 'DETAILS',
                                        Component: OrderObject,
                                    },
                                    {
                                        field: 'deliveryDate',
                                        title: 'ESTIMATED_DELIVERY_DATE',
                                        align: 'right',
                                        Component: DeliveryDate,
                                    },
                                    {
                                        field: 'statusDescription',
                                        title: 'STATUS',
                                        Component: fncTooltip,
                                    },
                                ]}

                            />
                        )
                    }
                </DialogContent>
            </TabPanel>
            {
                loading ? null : (
                    <DialogActions>
                        {
                            selectedTab !== detailsTab || !WriteDistribution ? null : (
                                <>
                                    {
                                        !isCancelable || isEditing || isCancelSelected ? null : (
                                            <Button onClick={handleCancelOrder}>
                                                <FormattedMessage id="CANCEL_ORDER" />
                                            </Button>
                                        )
                                    }
                                    {
                                        !isModifiable || (!isCancelSelected && !isEditing) ? null : (
                                            <Button onClick={isEditing || !isCancelSelected ? handleEdit : handleCancelOrder}>
                                                <FormattedMessage id="CANCEL" />
                                            </Button>
                                        )
                                    }
                                    {
                                        !isModifiable || isEditing || isCancelSelected ? null : (
                                            <Button onClick={handleEdit}>
                                                <FormattedMessage id="EDIT" />
                                            </Button>
                                        )
                                    }
                                </>
                            )
                        }
                        <div style={{ marginRight: 'auto' }} />
                        {
                            isEditing || isCancelSelected ? (
                                <Button onClick={handleApply} disabled={!canSave}>
                                    <FormattedMessage id="SAVE" />
                                </Button>
                            ) : (
                                <Button
                                    underline="none"
                                    component={RouteLink}
                                    to={{
                                        pathname: generatePath(match.path, { client }),
                                        search: history.location.search,
                                    }}
                                    // onClick={closeModal}
                                    color="primary"
                                >
                                    <FormattedMessage id="CLOSE" />
                                </Button>
                            )
                        }
                    </DialogActions>
                )
            }
        </Dialog>
    );
}

DistributionOrderDetails.propTypes = propTypes;
DistributionOrderDetails.defaultProps = defaultProps;

export default DistributionOrderDetails;

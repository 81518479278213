import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import uniqueId from 'lodash/uniqueId';
import isEmpty from 'lodash/isEmpty';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUploadTwoTone';
import RemoveIcon from '@mui/icons-material/RemoveCircleTwoTone';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { IconButton, InputBase } from '@mui/material';


const useStyles = makeStyles((theme) => createStyles({
    buttonLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));


function FileInput(props) {
    const classes = useStyles();
    const [fileInputId] = React.useState(uniqueId('file-input-'));
    const [value, setValue] = React.useState('');

    const {
        name, style, onChange, variant,
        className, fileList, accept, label,icon,noLabel,
        fullWidth, showFileName=true, disableIcon, multiple,
        size, iconButton, sx, wrapperProps={}, clearable,
        color
    } = props;

    const inputType = "file";

    function handleOnchange(event) {
        const { target, target: {
            files, type: targetType, value: targetValue,
            name: targetName,
        }, ...others } = event;

        onChange({
            ...others,
            target: {
                ...target,
                files: [...files],
                type: inputType,
                value: targetValue,
                name: name,
            },
        });
        setValue('');
    }

    function handleClear(event) {
        setValue('');
        onChange({
            target: {
                files: [],
                type: inputType,
                value: '',
                name: name,
            },
        })
    }

    const currentIcon = clearable && fileList && fileList.length > 0 ? <IconButton onClick={handleClear} style={{padding: 0}}><RemoveIcon /></IconButton> : icon;

    const Component = iconButton ? IconButton : Button;
    let buttonContent =  iconButton ? icon : null

    if (iconButton && clearable && !isEmpty(fileList)) {
        buttonContent = <RemoveIcon />
    }

    return (
        // <label  {...wrapperProps}>

        <Component
            sx={sx}
            size={size}
            variant={variant}
            style={style}
            className={className}
            component="label"
            htmlFor={fileInputId}
            color={color}
            {... iconButton ? {} : { fullWidth, startIcon: disableIcon ? undefined : currentIcon ? currentIcon : <CloudUploadIcon /> }}
            {... iconButton && clearable && !isEmpty(fileList) ? { onClick: handleClear} : {}}
        >
            <input
                name={name}
                style={{ display: 'none' }}
                id={fileInputId}
                type="file"
                value={value}
                multiple={multiple}
                accept={accept}
                onChange={handleOnchange}
            />
            {buttonContent}
            <span className={classes.buttonLabel}>
               {
                    showFileName && !isEmpty(fileList) && fileList[0] ? fileList[0].name : (
                        label ? label : noLabel ? null : <FormattedMessage id="UPLOAD_FILE" />
                     )
                }
            </span>
        </Component>
        // </label>
    )
}

export default FileInput;

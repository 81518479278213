import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useConstants } from 'contexts/constants'
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useUser } from 'contexts/user';
import { FormattedDate, FormattedMessage } from 'react-intl';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import { vaultService } from './constants';

function DestinationDescription(props) {
    const {
        destination, selectedService, setDelivery, setOutputData,
        setDestination, DestinationIndex, deliverylen
    } = props;

    const {
        virtualVaultEntry, sendTo, deliveryClient, deliveryDate
    } = destination ;


    const handleDelete = React.useCallback(({ target : { name, value } }) => {
        setDelivery((currentDelivery) => {
            return currentDelivery.filter((del, index) => index !== DestinationIndex);
        });
        setOutputData((currentAssets) => {
            return currentAssets.filter((asset, index) => index !== DestinationIndex);
        });
        setDestination((currentAssets) => {
            return currentAssets.filter((asset, index) => index !== DestinationIndex);
        });
    }, [DestinationIndex, deliverylen]);


    return (
        <ListItem>
            <ListItemText
                primary={
                    <>
                        {
                            (selectedService === vaultService && DestinationIndex === 0) ? null : (
                                sendTo.nom ? sendTo.nom : deliveryClient
                            )
                        }
                        {
                            !virtualVaultEntry && (selectedService !== vaultService || DestinationIndex !== 0) ? null : (
                                <>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {
                                            (!sendTo.nom && !deliveryClient) ? null : ' + '
                                        }
                                        <FormattedMessage id='VIRTUAL_VAULT' />
                                    </Typography>
                                </>
                            )
                        }
                    </>
                }
                secondary={
                    <FormattedMessage id='FOR_THE_DATE' values={{ date: new Date(deliveryDate) }} />
                }
            />
            {
                DestinationIndex === 0 ? null : (
                    <IconButton size="small" onClick={handleDelete} color="error">
                        <DeleteIcon />
                    </IconButton>
                )
            }
        </ListItem>
    )
}

export default DestinationDescription;


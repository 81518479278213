import React from 'react';
import PropTypes from 'prop-types';
import { Route, useParams } from 'react-router-dom';

import ForbiddenCheck from 'components/ForbiddenCheck';
import { useUser } from 'contexts/user';

const propTypes = {
    component: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    permissions: PropTypes.shape({}),
};

const defaultProps = {
    permissions: {},
};

const RouteWithPermissions = ({ permissions, path, component: Component }) => {
    const { user: { clients = [] } } = useUser();
    const { client } = useParams();

    let currentClientId = parseInt(client, 10) || clients.find((c) => c.default).client;
    let currentClient = clients.find((c) => c.client === currentClientId);
    if (!currentClient) {
        currentClient = clients.find((c) => c.default);
        currentClientId = currentClient.client;
    }

    return (
        <Route
            exact
            path={path}
        >
            <ForbiddenCheck
                currentClientId={currentClientId}
                permissions={permissions}
            >
                <Component currentClient={currentClientId} />
            </ForbiddenCheck>
        </Route>
    );
};

RouteWithPermissions.propTypes = propTypes;
RouteWithPermissions.defaultProps = defaultProps;

export default RouteWithPermissions;

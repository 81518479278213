'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.SUPPORTED_LANGUAGES = exports.DEFAULT_LANGUAGE = exports.DEFAULT_LOCALE = exports.SERVICEURL = exports.WSURL = exports.APIURL = exports.APPBaseURL = exports.AppURL = exports.SSEURL = undefined;

require('./environment')
var _environment = require('./environment');
var _environment2 = _interopRequireDefault(_environment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DEFAULT_LOCALE = 'fr-CA';
var DEFAULT_LANGUAGE = DEFAULT_LOCALE.split('-')[0];
var SUPPORTED_LANGUAGES = ['en', 'fr'];
var DEFAULT_THEME = 'light';

var isDev = false

if (!process.browser) {
    if (process.env.DEV) {
        isDev = true;
    }
} else {
    var currentPath = window.location.pathname;
    isDev = currentPath === '/dev' || currentPath === '/melodie/dev' || currentPath.indexOf('/dev/') >= 0;
}

// Default is PRODUCTION


var APIProtocol = 'https';
var APIVersion = 1;
var APIServerName = 'melodie.mels-studios.com';
var APIPort = null;

var APIASSProtocol = 'https';
var APIASSVersion = 1;
var APIASSServerName = 'assets.mels-studios.com';
var APIASSPort = null;
var APIKDMProtocol = 'https';
var APIKDMVersion = 1;
var APIKDMServerName = 'localhost';
var APIKDMPort = null;

var APPProtocol = 'https';
var APPServerName = 'melodie.mels-studios.com';
var APPBaseURL = isDev ? '/dev' : '/';
var APPPort = null;

var WSProtocol = 'wss';
var WSServerName = 'melodie.mels-studios.com/ws';
var WSPort = null;

var ServiceProtocol = 'https';
var ServiceName = 'melodie.mels-studios.com';
var ServicePort = null;

var SSEProtocol = 'https';
var SSEBase = '/subscribe';
var SSEServerName = 'melodie.mels-studios.com';
var SSEPort = null;

if (_environment2.default.isDevelopment()) {
    APIProtocol = 'http';
    APIVersion = 1;
    APIServerName = 'localhost';
    APIPort = 5002;

    APIASSProtocol = 'http';
    APIASSVersion = 1;
    APIASSServerName = 'localhost';
    APIASSPort = 5006;
    APIKDMProtocol = 'http';
    APIKDMVersion = 1;
    APIKDMServerName = 'localhost';
    APIKDMPort = 5007;

    APPProtocol = 'http';
    APPServerName = 'localhost';
    APPPort = 9494;

    WSProtocol = 'ws';
    WSServerName = 'localhost';
    WSPort = 8659;

    ServiceProtocol = 'http';
    ServiceName = 'dev-test/melodie';
    ServicePort = null;

    SSEProtocol = 'http';
    SSEServerName = 'dev-test/melodie';
    SSEPort = null;

} else if (_environment2.default.isTesting()) {
    APIProtocol = 'http';
    APIVersion = 1;
    APIServerName = 'dev-test/melodie';
    APIPort = null;

    APIASSProtocol = 'http';
    APIASSVersion = 1;
    APIASSServerName = 'dev-test/melodie/assets';
    APIASSPort = null;
    APIKDMProtocol = 'http';
    APIKDMVersion = 1;
    APIKDMServerName = 'dev-test/melodie/kdm';
    APIKDMPort = null;

    APPProtocol = 'http';
    APPServerName = 'dev-test';
    exports.APPBaseURL = APPBaseURL = isDev ? '/melodie/dev' : '/melodie';
    APPPort = null;

    WSProtocol = 'ws';
    WSServerName = 'dev-test/melodie/ws';
    WSPort = null;

    ServiceProtocol = 'http';
    ServiceName = 'dev-test/melodie';
    ServicePort = null;

    SSEProtocol = 'http';
    SSEServerName = 'dev-test/melodie';
} else if (_environment2.default.isStaging()) {
    APIProtocol = 'https';
    APIVersion = 1;
    APIServerName = '';
    APIPort = 9090;

    APIASSProtocol = 'https';
    APIASSVersion = 1;
    APIASSServerName = '';
    APIASSPort = 9595;
    APIKDMProtocol = 'https';
    APIKDMVersion = 1;
    APIKDMServerName = '';
    APIKDMPort = 9797;

    APPProtocol = 'http';
    APPServerName = '';
    APPPort = null;

    WSProtocol = 'wss';
    WSServerName = '';
    WSPort = 9090;

    ServiceProtocol = 'https';
    ServiceName = '';
    ServicePort = null;

    SSEProtocol = 'http';
    SSEServerName = '';
}

var APIURL = APIProtocol + '://' + APIServerName + (APIPort ? ':' + APIPort : '') + '/api/v' + APIVersion;
var APIASSURL = APIASSProtocol + '://' + APIASSServerName + (APIASSPort ? ':' + APIASSPort : '') + '/api/v' + APIASSVersion;
var APIKDMURL = APIKDMProtocol + '://' + APIKDMServerName + (APIKDMPort ? ':' + APIKDMPort : '') + '/api/v' + APIKDMVersion;
var SERVICEURL = ServiceProtocol + '://' + ServiceName + (ServicePort ? ':' + ServicePort : '');
var AppURL = APPProtocol + '://' + APPServerName + (APPPort ? ':' + APPPort : '') + APPBaseURL;

var WSURL = WSProtocol + '://' + WSServerName + (WSPort ? ':' + WSPort : '');
var SSEURL = SSEProtocol + '://' + SSEServerName + (SSEPort ? ':' + SSEPort : '') + SSEBase;

exports.AppURL = AppURL;
exports.SSEURL = SSEURL;
exports.APPBaseURL = APPBaseURL;
exports.APIURL = APIURL;
exports.APIASSURL = APIASSURL;
exports.APIKDMURL = APIKDMURL;
exports.WSURL = WSURL;
exports.SERVICEURL = SERVICEURL;
exports.DEFAULT_THEME = DEFAULT_THEME;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
exports.DEFAULT_LANGUAGE = DEFAULT_LANGUAGE;
exports.SUPPORTED_LANGUAGES = SUPPORTED_LANGUAGES;

import React from 'react';
import { DatePicker as  MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import parseISO from 'date-fns/parseISO';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CalendarIcon from '@mui/icons-material/EventTwoTone';

import { useLocale } from 'contexts/locale';

function toISODateString(localeDate) {
    if (!(localeDate instanceof Date)) {
        console.error('Must provide a valid date!');
        return null;
    }
    // using ISO 8601 YYYY-MM-DD
    return `${localeDate.getFullYear()}-${(localeDate.getMonth() + 1).toString().padStart(2, "0")}-${localeDate.getDate().toString().padStart(2, "0")}`
}



function DatePicker(props) {
    const { value, onChange, name, label, required, sx, variant, OpenPickerIcon=CalendarIcon, ...others } = props;

    const handleChange = React.useCallback((date) => {
        if (onChange) {
            onChange({ target: { name, value: toISODateString(date) } });
        }
    }, [onChange]);


    const { language: currentLanguage } = useLocale();

    const placeholder = currentLanguage === 'fr' ? 'jj/mm/aaaa' : 'dd/mm/yyyy';

    return (
        <MUIDatePicker
            label={label ? <>{label}{!required ? '' : ' *'}</> : undefined}
            value={value ? parseISO(value) : value}
            onChange={handleChange}
            cancelText={<FormattedMessage id="CANCEL" />}
            okText={<FormattedMessage id="OK" />}
            name={name}
            OpenPickerIcon={OpenPickerIcon}
            clearText={others.clearable ? <FormattedMessage id="REMOVE" /> : undefined}
            renderInput={(params) => (
                <TextField
                    sx={sx}
                    variant={variant}
                    {...params}
                    inputProps={{...params.inputProps, placeholder}}
                />
            )}
            {...others}
        />
    );
}

export default DatePicker;

import React from 'react';
import { useHistory } from 'react-router-dom';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

import { queryStringFromObj, queryStringToObj } from 'utils/ofQuery';

function getTypeClass(value) {
    if (isString(value)) {
        return String;
    }
    return (v) => v;
}

function useLocationSearch(key, initialValue, asInternal=true) {
    const history = useHistory();
    const urlSearch = queryStringToObj(history.location.search);

    let value;

    if (isArray(key)) {
        value = pick({ ...initialValue, ...urlSearch }, key);

        Object.keys(value).forEach((k) => {
            value[k] = getTypeClass(initialValue[k])(value[k]);
        });
    } else {
        value = getTypeClass(initialValue)(urlSearch[key]);
        if (value === "undefined") {
            value = undefined;
        }
    }

    if (value === undefined || value === '') {
        value = initialValue;
    }

    const [stateValue, setStateValue] = React.useState(value);
    const onSetStateValue = React.useCallback((valueOrFunction) => {
        let newValue = valueOrFunction;
        const currentUrlSearch = queryStringToObj(history.location.search);
        if (isFunction(valueOrFunction)) {
            newValue = valueOrFunction(currentUrlSearch);
        }

        if (isPlainObject(newValue)) {
            Object.keys(newValue).forEach((k) => {
                newValue[k] = getTypeClass(initialValue[k])(newValue[k]);
            });
        } else {
            newValue = getTypeClass(initialValue)(newValue);
        }

        setStateValue(newValue);
        history.replace({
            pathname: history.location.pathname,
            search: queryStringFromObj({ ...currentUrlSearch, ...(isPlainObject(newValue) ? newValue : { [key]: newValue }) }),
        }, { internal: asInternal });
    }, [key, history.location.search]);

    React.useEffect(() => {
        if (!history.location.state || !history.location.state.internal) {
            const currentUrlSearch = queryStringToObj(history.location.search);
            let currentValue ;

            if (isArray(key)) {
                currentValue = pick(currentUrlSearch, key);

                Object.keys(currentValue).forEach((k) => {
                    currentValue[k] = getTypeClass(initialValue[k])(currentValue[k]);
                });
            } else {
                currentValue = getTypeClass(initialValue)(currentUrlSearch[key]);
            }

            // why check for [null, undefined].includes(stateValue)
            // if (currentValue !== stateValue && [null, undefined].includes(stateValue)) {
            if (isArray(key) ? isEqual(currentValue, stateValue) : currentValue !== stateValue) {
                if ([null, undefined].includes(currentValue)) {
                    currentValue = initialValue
                }
                setStateValue(currentValue);
            }
        }
        return () => {};
    }, [history.location.search]);

    return [stateValue, onSetStateValue];
}

export default useLocationSearch;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedDate } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Paper from '@mui/material/Paper';
import { generatePath, useRouteMatch, useHistory } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';
import DownloadButton from 'components/DownloadButton';
import { useUser } from 'contexts/user';
import AssetDescription from 'components/Distribution/Assets/Description';


import Chip from '@mui/material/Chip';

import { OrderObject, DeliveryDate, fncTooltip } from './OrderData';


import Table from 'components/Table/EnhancedPagedTable';

const propTypes = {
    clientId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchPage: PropTypes.func.isRequired,
    filters: PropTypes.shape().isRequired,
    setPage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    pageRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    totalRowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => createStyles({
    root: {
        height: '100%',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    notFound: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
    },
}));

const columns = [
    {
        field: 'title',
        title: 'TITLE',
    },
    {
        field: 'description',
        title: 'ASSET',
        Component: AssetDescription,
    },
    {
        field: 'orderTypeDescription',
        title: 'DETAILS',
        Component: OrderObject,
    },
    {
        field: 'deliveryDate',
        title: 'ESTIMATED_DELIVERY_DATE',
        align: 'right',
        Component: DeliveryDate,
    },
    {
        field: 'statusDescription',
        title: 'STATUS',
        Component: fncTooltip,
    },
    {
        field: 'itemId',
        title: '#',
        align: 'right'
    },
];

function DistributionOrders(props) {
    const {
        clientId, isLoading, filters,
        fetchPage, setPage, page, pageRows,
        totalRowCount, order, setOrder, orderBy,
        setOrderBy, rowsPerPage, setRowsPerPage,
    } = props;
    const match = useRouteMatch();
    const history = useHistory();
    const classes = useStyles();
    const { user } = useUser();

    const handleRowClick = React.useCallback(({ itemId }) => {
        history.push({
            pathname: generatePath(match.path, { order: itemId, client: clientId }),
            search: history.location.search,
        });
    }, [match.path, clientId, history.location.search]);

    const fetchPageDependencies = React.useMemo(() => [clientId, filters], [clientId, filters]);
    const exportFileName = React.useMemo(() => {
        const clientName = (user.clients.find((c) => parseInt(clientId, 10) === c.client) || {}).name || clientId;
        const dateFileName = format(Date.now(), 'yyyy-MM-dd_HH-m');
        const cleanClientName = clientName.replace(/\./g, '').replace(/\s+/g, '_');
        return `${cleanClientName}_${dateFileName}.xlsx`;
    }, [clientId, user.clients]);

    return (
        <Paper className={classes.root}>
            <Table
                size="small"
                showDivisions={false}
                isSortable
                uniqueField="itemId"
                onRowClick={handleRowClick}
                emptyMessage="NOT_FOUND"
                columns={columns}
                fetchPageDependencies={fetchPageDependencies}
                fetchPage={fetchPage}
                setPage={setPage}
                page={page}
                pageRows={pageRows}
                totalRowCount={totalRowCount}
                isLoading={isLoading}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                additionalActions={(
                    <Tooltip title={<FormattedMessage id="EXPORT_DEFINITION" />}>
                        <span>
                            <DownloadButton
                                size="small"
                                url={`/distribution/orders/${clientId}`}
                                urlData={{ ...filters, action: 'xlsx' }}
                                fileName={exportFileName}
                                disabled={isEmpty(order) && !isLoading}
                            >
                                <FormattedMessage id="EXPORT" />
                            </DownloadButton>
                        </span>
                    </Tooltip>

                )}
            />
        </Paper>
    );
}

DistributionOrders.propTypes = propTypes;
DeliveryDate.propTypes = {
    deliveryDate: PropTypes.number,
};
DeliveryDate.defaultProps = {
    deliveryDate: undefined,
};

export default DistributionOrders;

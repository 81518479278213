import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';

const wordSeparators = /[\s\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-.\/:;<=>?@\[\]^_`{|}~]+/;
const capitals = /[A-Z\u00C0-\u00D6\u00D9-\u00DD]/g;

const snakeCase = (str) => {
    return str.replace(capitals, match => ` ${(match.toLowerCase() || match)}`).trim().split(wordSeparators).join('_');
};

const queryStringFromObj = (obj, toSnakeCase) => {
    if (obj === undefined || obj === null || isEmpty(obj)) {
        return '';
    }
    return `?${Object.keys(obj).reduce((a, k) => {

        if (obj[k] && (Array.isArray(obj[k]) && !isEmpty(obj[k]) || !Array.isArray(obj[k]))) {
            a.push(`${(toSnakeCase ? snakeCase(k) : k)}=${encodeURIComponent(isObject(obj[k]) ? JSON.stringify(obj[k]) : obj[k])}`);
        }
        return a;
    }, []).join('&')}`;
};

const queryStringToObj = (str) => {
    if (str === undefined || str === null || str.length === 0) {
        return {};
    } else {
        if (str.indexOf('?') >= 0) {
            str = str.substring(str.indexOf('?') + 1);
        }

        const vars = str.split('&').filter((s) => s && s.split('=').length === 2);
        const obj = {};

        for (let i = 0; i < vars.length; i += 1) {
            const pair = vars[i].split('=');
            const k = decodeURIComponent(pair[0])
            const v = decodeURIComponent(pair[1])

            try {
                obj[k] = JSON.parse(v);
            } catch(e) {
                obj[k] = v;
            }

        }
        return obj;
    }
};

export {
    snakeCase,
    queryStringFromObj,
    queryStringToObj,
};

import React from 'react';
import Grid from '@mui/material/Grid';
import Details from 'components/Distribution/Entities/Details';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NewIcon from '@mui/icons-material/NewReleases';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import DestinationDescription from './DestinationDescription';
import { useParams } from 'react-router-dom';
import { useUser } from 'contexts/user';
import { useConstants } from 'contexts/constants'
import { useLocale } from 'contexts/locale';
import AssetDescription from './AssetDescription';
import AssetStatusDescription from 'components/Distribution/Assets/Description';
import {
    creationServices, emptyAsset, CC_ASSET_TYPE, SUBTITLE_ASSET_TYPE,
    AUDIO_ASSET_TYPE, STEREO, DESCRIBED_VIDEO, adaptationService,
    subtitleService, videoDescriptionService, closedCaptionService
} from './constants';


const useStyles = makeStyles((theme) => createStyles({
    search: { // StyledTSearchCard
        marginBottom: theme.spacing(2),
    },
    extraAction: {
        textDecoration: 'none',
        '&:hover, &:active': {
            background: 'transparent',
            textDecoration: 'none',
        },
    },
    root: {
        display: 'none',
        padding: 0,
        height: 0,
        opacity: 0,
        pointerEvents: 'none',
        transition: `all ${theme.transitions.easing.easeInOut}`,
    },
    filterItem: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    accordionFilter: {
        boxShadow: 'none',
        width: '100%',
        '& .MuiExpansionPanelSummary-root, & .MuiExpansionPanelDetails-root': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    asset: {
        gap: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
    },
    newIcon: {
        fontSize: '.55rem',
        color: theme.palette.primary.main,
    }
}));

function Confirmation(props) {
    const {
        selectedService, selectedTitles, selectedAssets,
        newTitle, destination, assetList,
        delivery, entity, assetsDescription, titlesDescription,
        setDelivery, setOutputData, setDestination, targetLanguage
    } = props;

    const intl = useIntl();
    const classes = useStyles();
    const { locales, vodOptions: { assetTypeOptions, translationTypeOptions, audioTrackTypeOptions } } = useConstants();
    const { language } = useLocale();

    const {
        entityType, title , prodYear
    } = newTitle


    const assetDetails = React.useMemo(() => {
        let reducedAssets = assetsDescription.reduce((mem, asset) => {
            const match = mem.find(({ parentId }) => parentId === asset.parentId);
            if (match) {
                match.assets.push(asset);
            } else {
                mem.push({ parentId: asset.parentId, title: asset.parentTitle, assets: [asset] });
            }
            return mem;
        },[]);
        if (assetList) {
            const newAssets = assetList.map((a) => ({ ...a, isNew: true, assetDescription: AssetDescription(a, intl, language, locales, assetTypeOptions, translationTypeOptions, audioTrackTypeOptions)}));
            const createdTitle = { parentId: newTitle.id, title: newTitle.originalTitle, assets: newAssets };


            let found = false;
            if (reducedAssets && reducedAssets.length >= 1) {
                reducedAssets = reducedAssets.map((ra) => {
                    if (ra.parentId === (newTitle.id || selectedTitles[0])) {
                        ra.newAssets = newAssets;
                        found = true;
                    }
                    return ra;
                });
            } else if (selectedTitles && selectedTitles[0] && titlesDescription && titlesDescription[0]) {
                found = true;
                reducedAssets = [{ parentId: selectedTitles[0], title: titlesDescription[0].title, assets: newAssets }]
            }

            // WHAT AM I DOING HERE!!!???? shouldnt be possible if parentId will always be undefined
            if (!found) {
                reducedAssets = [...reducedAssets, createdTitle];
                // reducedAssets = [...reducedAssets, newTitle]
            }
        }
        return reducedAssets;
    }, [assetsDescription, assetList, newTitle]);

    return (
        <>
            {
                assetDetails && assetDetails.map(({ parentId, title, assets, newAssets = [] }, index) => (
                    <React.Fragment key={index}>
                        <Typography variant="subtitle2">
                            {title}
                        </Typography>
                        <List>
                            {
                                [...assets, ...newAssets].map(({ isNew, assetDescription, isReceived, isInVault }, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={
                                                <span className={classes.asset}>
                                                    {
                                                        !isNew ? null : (
                                                            <Typography variant="overline" className={classes.newIcon}>
                                                                <FormattedMessage id="NEW" />
                                                            </Typography>
                                                        )
                                                    }
                                                    <AssetStatusDescription
                                                        assetDescription={assetDescription}
                                                        isReceived={isReceived}
                                                        isInVault={isInVault}
                                                        isNew={isNew}
                                                    />
                                                </span>
                                            }
                                        />
                                    </ListItem>
                                ))
                            }
                        </List>
                    </React.Fragment>
                ))
            }
            <Typography color="textPrimary" variant="subtitle1">
                <FormattedMessage id='DESTINATION' />:
            </Typography>
            <List>
                {
                    destination.map((dest, index) => (
                        <DestinationDescription
                            key={index}
                            DestinationIndex={index}
                            destination={destination[index]}
                            deliverylen={delivery.length}
                            selectedService={selectedService}
                            setDelivery={setDelivery}
                            setOutputData={setOutputData}
                            setDestination={setDestination}
                        />
                    ))
                }
            </List>
        </>
    );
}

export default Confirmation;

import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CancelIcon from '@mui/icons-material/Cancel';
import FormControl from '@mui/material/FormControl';
import isEmpty from 'lodash/isEmpty';
import DiscussionFollowers from 'components/Discussion/Followers';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useParams, generatePath } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';

import { useUser } from 'contexts/user';

const useStyles = makeStyles((theme) => createStyles({
    entry: {
        marginLeft: 0,
        marginRight: 0,
        padding: theme.spacing(2),
    },
    entryForm: {
        padding: theme.spacing(0.5, 1),
        display: 'flex',
        alignItems: 'center',
    },
    entryFormInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
}));

function CreateDiscussion(props) {
    const {
        relationId, relation, forceRefreshList, disableCreateDiscussion,
        setActive, discussionTitle, setDiscussionTitle, selectedFollowers,
        setSelectedFollowers, disucssionType, setDiscussionProblem, isProblem
    } = props;
    const classes = useStyles();
    const intl = useIntl();
    const { api } = useUser();
    const { client } = useParams();

    function handleSetTitle({ target: { value } }) {
        setDiscussionTitle(value);
    }
    function handleSetProblem({ target: { value } }) {
        setDiscussionProblem(value);
    }

    return (
        <Grid container spacing={2} alignItems="flex-start" alignContent="flex-start" className={classes.root}>
            <Grid item xs>
                <Paper className={classes.entryForm} component="form">
                    <Grid container alignItems="flex-start">
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                fullWidth
                                size="small"
                                value={discussionTitle}
                                onChange={handleSetTitle}
                                placeholder={intl.formatMessage({ id: 'ENTER_A_SUBJECT' })}
                                className={classes.entryFormInput}
                                label={<FormattedMessage id="SUBJECT" />}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl required fullWidth className={classes.entryFormInput}>
                                <DiscussionFollowers
                                    creationEnabled
                                    useSelect
                                    selectedFollowers={selectedFollowers}
                                    setSelectedFollowers={setSelectedFollowers}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} container justifyContent="flex-end">
                            <FormControlLabel
                                fullWidth
                                className={classes.iconButton}
                                labelPlacement="start"
                                control={(
                                    <Checkbox
                                        onChange={handleSetProblem}
                                        name="isProblem"
                                        value={isProblem}
                                        margin="dense"
                                        size="small"
                                        checked={isProblem}
                                        />
                                )}
                                label={<FormattedMessage id="IS_PROBLEM" />}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid item container> */}
                    {/*     <Grid item> */}
                    {/*         <Button */}
                    {/*             className={classes.iconButton} */}
                    {/*             onClick={disableCreateDiscussion} */}
                    {/*         > */}
                    {/*             <FormattedMessage id="CANCEL" /> */}
                    {/*         </Button> */}
                    {/*     </Grid> */}
                    {/*     <Grid> */}
                    {/*         <Button */}
                    {/*             type="submit" */}
                    {/*             color="primary" */}
                    {/*             disabled={!discussionTitle || isEmpty(selectedFollowers)} */}
                    {/*             className={classes.iconButton} */}
                    {/*             onClick={addDiscussion} */}
                    {/*         > */}
                    {/*             <FormattedMessage id="CREATE" /> */}
                    {/*         </Button> */}
                    {/*     </Grid> */}
                    {/* </Grid> */}
                </Paper>
            </Grid>
        </Grid>
    );
}

CreateDiscussion.propTypes = {
    relationId: PropTypes.number,
    relation: PropTypes.string,
    forceRefreshList: PropTypes.func.isRequired,
    disableCreateDiscussion: PropTypes.func.isRequired,
    setActive: PropTypes.func.isRequired,
};

CreateDiscussion.defaultProps = {
    relationId: null,
    relation: null,
};

export default CreateDiscussion;

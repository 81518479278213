import React from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
    useRouteMatch, generatePath, useParams, useHistory,
} from 'react-router-dom';
import Grid from '@mui/material/Grid';

import Chip from '@mui/material/Chip';
import RouteLink from 'components/RouteLink';
import { useUser } from 'contexts/user';
import NotFound from 'components/NotFound';
import Detail from 'components/Detail';
import Box from '@mui/system/Box';

const propTypes = {
    idDcp: PropTypes.string,
    clientId: PropTypes.string,
};

const defaultProps = {
    idDcp: null,
    clientId: null,
};

const useStyles = makeStyles((theme) => createStyles({
    table: {
        minWidth: 0,
    },
    units: {
        whiteSpace: 'pre',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: "'Roboto Mono', monospace",
    },
    tableData: {
        verticalAlign: 'baseline',
        borderBottom: 'none',
    },
    description: {
        wordBreak: 'keep-all',
    },
    chipswarap: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    finePrint: {
        marginRight: 'auto',
        fontStyle: 'italic',
    },
    chips: {
        '& + &': {
            marginLeft: theme.spacing(1),
        },
    },
}));

function Dcp() {
    const classes = useStyles();

    const history = useHistory();
    const match = useRouteMatch();
    const { client, dcp } = useParams(); // use props?
    const { api } = useUser();
    const [fetchingDcp, setFetchingDcp] = React.useState(false);
    const [dcpDetails, setDcpDetails] = React.useState({});
    const [failed, setFailed] = React.useState(false);

    function closeModal() {
        history.push({
            pathname: generatePath(match.path, { client }),
            search: history.location.search,
        });
    }
    async function fetchObject(abortController = new AbortController()) {
        if (client && dcp) {
            try {
                setFailed(false);
                setFetchingDcp(true);

                const response = await api.get(`/dcp/${client}/${dcp}`, {}, { signal: abortController.signal });

                if (response.ok) {
                    const data = await response.json();
                    setDcpDetails(data);
                } else {
                    setFailed(true);
                    setDcpDetails({});
                }
                setFetchingDcp(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        if (dcp && dcp !== dcpDetails.idDcp) {
            fetchObject(abortController);
        }
        return () => {
            abortController.abort();
        };
    }, [client, dcp]);

    const {
        idDcp, dcpName, title,
        audio, credit,description,distributor, 
        duration, lang, pixels_2k, ratio, ratioImage,territory,
        resolution, subtitles,type, catdvId, catdvDeleteDate,
        studio,productionDcp, dateProduction, standard, typePackage,
        vi,hi, atmos, auro, ocap, ccap, three_d, dbox, willBeDeletedDate
    } = dcpDetails;

    let content = null;
    let dateColor = 'primary'
    const today = new Date();
    var deleteDate = new Date(catdvDeleteDate);
    if (deleteDate <= today) {
        dateColor = 'error'
    } 
    

    if (fetchingDcp) {
        content = (
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        );
    } else if (!fetchingDcp && failed) {
        content = (
            <DialogContent>
                <NotFound message="NOT_FOUND" />
            </DialogContent>
        );
    } else if (dcpDetails.idDcp) {
       
        content = (
            <>
                <DialogContent>
                <Grid item xs={12}>
                        <Typography variant="h6">
                            {dcpName}
                        </Typography>
                    </Grid>
                <Grid  spacing={3} className={classes.chipswarap}>
                        { ocap === 0 ? null: <Chip
                            className={classes.chips}
                            label={<FormattedMessage id='OPEN_CAPTION' />}
                            variant="outlined"
                            size="small"
                        />}
                        { vi === 0 ? null: <Chip
                            className={classes.chips}
                            label={<FormattedMessage id='VISUALLY_IMPAIRS' />}
                            variant="outlined"
                            size="small"
                        />}
                        { hi === 0 ? null: <Chip
                            className={classes.chips}
                            label={<FormattedMessage id='HEARING_IMPAIRS' />}
                            variant="outlined"
                            size="small"
                        />}

                        { three_d === 0 ? null: <Chip
                            className={classes.chips}
                            label={<FormattedMessage id='3D' />}
                            variant="outlined"
                            size="small"
                        />}
                        { dbox === 0 ? null: <Chip
                            className={classes.chips}
                            label={<FormattedMessage id='DBOX' />}
                            variant="outlined"
                            size="small"
                        />}
                        { atmos === 0 ? null: <Chip
                            className={classes.chips}
                            label={<FormattedMessage id='ATMOS' />}
                            variant="outlined"
                            size="small"
                        />}
                         { auro === 0 ? null: <Chip
                            className={classes.chips}
                            label={<FormattedMessage id='AURO' />}
                            variant="outlined"
                            size="small"
                        />}
                        { ccap === 0 ? null: <Chip
                            className={classes.chips}
                            label={<FormattedMessage id='ENCODE_HEARING' />}
                            variant="outlined"
                            size="small"
                        />}
                              
                        </Grid>
                    <Grid container wrap="wrap" spacing={2}>
                        <Detail xs={1} label="DCP_ID">
                            {idDcp}
                        </Detail>
             
                        <Detail xs={5} label="TITLE">
                            {title}
                        </Detail>
                        <Detail xs={5} label="DESCRIPTION">
                            {description}

                        </Detail>
                        <Detail xs={3} label="CATDV_ID">
                            {catdvId}
                        </Detail>


                        <Detail xs={3} label="CONTENT_TYPE">
                            {type}
                        </Detail>
                        <Detail xs={3} label="LANGUAGE">
                            {lang}
                        </Detail>
                        <Detail xs={3} label="RATIO">
                            {ratio}
                        </Detail>
                        <Detail xs={3} label="TERRITORY">
                            {territory}
                        </Detail>
                        <Detail xs={3} label="RESOLUTION">
                            {resolution}
                        </Detail>

                        <Detail xs={3} label="SUBTITLE">
                            {subtitles}
                        </Detail>

                        <Detail xs={3} label="STUDIO">
                            {studio}
                        </Detail>
                        <Detail xs={3} label="PRODUCTION_DCP">
                            {productionDcp}
                        </Detail>
                        <Detail xs={3} label="AUDIO">
                            {audio}
                        </Detail>

                        <Detail xs={3} label="STANDARD">
                            {standard}
                        </Detail>
                            
                        <Detail xs={3} label="TYPE_PACKAGE">
                            {typePackage?
                            <FormattedMessage id={typePackage} />:
                            null

                            }
                            
                        </Detail>
                        <Detail xs={3} label="DURATION">
                            {duration}
                        </Detail>
                        <Detail xs={3} label="CREDIT">
                            {credit}
                        </Detail>
                        <Detail xs={3} label="CREATION_DATE">
                            {dateProduction}
                        </Detail>
                        { !catdvDeleteDate && !willBeDeletedDate ? null :
                            <Detail xs={3} label="DELETE_DATE">
                                <Box sx={(theme)=> ({color:theme.palette[dateColor].main})} >
                                    {catdvDeleteDate ? catdvDeleteDate : willBeDeletedDate}
                                </Box>
                            </Detail>
                        }
                        
                    </Grid>
                   
                    
                    

              
                </DialogContent>
                <DialogActions>
                    <Button
                        underline="none"
                        component={RouteLink}
                        to={{
                            pathname: generatePath(match.path, { client }),
                            search: history.location.search,
                        }}
                        // onClick={closeModal}
                        color="primary"
                    >
                        <FormattedMessage id="CLOSE" />
                    </Button>
                </DialogActions>
            </>
        );
    }

    return (
        <Dialog
            open={!!dcp}
            onEscapeKeyDown={closeModal}
            onBackdropClick={closeModal}
            onClose={closeModal}
            maxWidth="md"
        >
            {content}
        </Dialog>
    );
}

Dcp.propTypes = propTypes;
Dcp.defaultProps = defaultProps;

export default Dcp;

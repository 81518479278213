import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useIntl, FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import GroupAddIcon from '@mui/icons-material/GroupAddTwoTone';
import GroupIcon from '@mui/icons-material/GroupTwoTone';
import NotificationsIcon from '@mui/icons-material/NotificationsTwoTone';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOffTwoTone';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';

import { UserChip, UserChipAlt } from 'components/Discussion/UserChip';
import { useUser } from 'contexts/user';
import { useConstants } from 'contexts/constants';

const useStyles = makeStyles((theme) => createStyles({
    menuRoot: {
        padding: theme.spacing(1, 2),
        maxWidth: '40vw',
        maxHeight: '40vh',
    },
    userItem: {
        gridGap: theme.spacing(1),
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: 'space-between',
    },
    actionItem: {
        position: 'sticky',
        cursor: 'default',
        bottom: 0,
        '&, &:hover': {
            backgroundColor: theme.palette.background.paper,
        }
    },
    menu: {
        maxHeight: '40vh',
    },
}));

function DiscussionFollowers(props) {

    const {
        followers = [], addFollowers, discussionId,
        isFollowed, refresh, creationEnabled = false,
        selectedFollowers: controlledSelectedFollowers,
        setSelectedFollowers: controlledSetSelectedFollowers,
        forceRefreshList, useSelect
    } = props;
    const { api, isCurrentUser, user } = useUser();
    const { client } = useParams();
    const [clientFollowers, setClientFollowers] = React.useState([]);
    const classes = useStyles();

    const autoCompleteRef = React.useRef();
    const [selectedFollowers, setSelectedFollowers] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [addFollowersEnabled, setAddFollowersEnabled] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [addingFollowers, setAddingFollowers] = React.useState(false);

    const availableFollowersOptions = clientFollowers.filter((cf={}) => !followers.find((f) => f.isEmployee === cf.isEmployee && f.followerId === cf.followerId))

    React.useEffect(() => {
        if (!isEmpty(selectedFollowers)) {
            setSelectedFollowers([]);
        }
    }, [discussionId]);

    function handleDisableAddFollowers() {
        setAddFollowersEnabled(false);
        setSelectedFollowers([]);
        setAddingFollowers(false);
        setError(false);
    }

    const handleClose = () => {
        setAnchorEl(null);
        handleDisableAddFollowers();
    };

     async function fetchClientFollowers(abortController = new AbortController()) {
        if (client) {
            const response = await api.get(`/followers/${client}`, {}, { signal: abortController.signal });
            if (response.ok) {
                const data = await response.json();
                setClientFollowers(data);

            }
        }
    }

    async function follow(event, abortController = new AbortController()) {
        if (discussionId) {
            event.preventDefault();

            const response = await api.post(`discussion/${discussionId}/follow`, {}, { signal: abortController.signal });

            if (response.ok) {
                refresh();
                forceRefreshList();
            }
        }
    }

    async function unfollow(event, abortController = new AbortController()) {
        if (discussionId) {
            event.preventDefault();

            const response = await api.delete(`discussion/${discussionId}/follow`, { }, { signal: abortController.signal });

            if (response.ok) {
                refresh();
                forceRefreshList();
            }
        }
    }

    function enableAddFollowers() {
        setAddFollowersEnabled(true);
        autoCompleteRef.current.focus();
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    async function handleAddFollowers() {
        if (!isEmpty(selectedFollowers) && addFollowers) {
            setAddingFollowers(true);
            const response = await addFollowers(selectedFollowers);
            if (response.ok) {
                setAddFollowersEnabled(false);
                refresh();
            } else {
                setError(true);
            }
            setAddingFollowers(false);
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        try {
            fetchClientFollowers(abortController);
        } catch (error) {
            if (!abortController.signal.aborted) {
                console.error(error);
            }
            setError(true);
        }

        return () => {
            abortController.abort();
        };
    }, [client]);

    const showFollowers = !addFollowersEnabled && !isEmpty(followers);

    if (creationEnabled && !useSelect) {
        return (
            <SelectFollowers
                availableFollowersOptions={availableFollowersOptions}
                classes={classes}
                selectedFollowers={controlledSelectedFollowers}
                setSelectedFollowers={controlledSetSelectedFollowers}
            />
        );
    }

    if (creationEnabled && useSelect) {
        return (
            <SelectFollowersAutocomplete
                autoCompleteRef={autoCompleteRef}
                availableFollowersOptions={availableFollowersOptions}
                classes={classes}
                selectedFollowers={controlledSelectedFollowers}
                setSelectedFollowers={controlledSetSelectedFollowers}
            />
        );
    }

    return <>
        <Button
            color="primary"
            className={classes.iconButton}
            onClick={handleClick}
            startIcon={<GroupIcon />}
        >
            {followers.length}
        </Button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            variant="menu"
            className={classes.menu}
        >
            {
                !showFollowers ? null : [
                    ...followers.map(({ name, isEmployee, followerId }) => (
                        <MenuItem disabled key={`${followerId}-${isEmployee}`} className={classes.userItem}>
                            <UserChip
                                isEmployee={isEmployee}
                                name={name}
                            />
                        </MenuItem>
                    )),
                    <Divider key="divider" />,
                    <MenuItem key="add-people" disabled={addFollowersEnabled} onClick={enableAddFollowers}>
                        <ListItemIcon>
                            <GroupAddIcon />
                        </ListItemIcon>
                        <FormattedMessage id="ADD_PEOPLE" />
                    </MenuItem>,
                    !discussionId ? null : (
                        <MenuItem key="follow-action" onClick={isFollowed ? unfollow : follow }>
                            <ListItemIcon>
                                {
                                    isFollowed ? <NotificationsOffIcon /> : <NotificationsIcon />
                                }
                            </ListItemIcon>
                            <FormattedMessage id={isFollowed ? 'UNFOLLOW' : 'FOLLOW'} />
                        </MenuItem>
                    ),
                ]
            }
            {
                showFollowers || !useSelect ? null : (
                    <Grid container spacing={2} className={classes.menuRoot}>
                        <Grid item xs={12}>
                            <SelectFollowersAutocomplete
                                autoCompleteRef={autoCompleteRef}
                                availableFollowersOptions={availableFollowersOptions}
                                classes={classes}
                                selectedFollowers={selectedFollowers}
                                setSelectedFollowers={setSelectedFollowers}
                            />
                        </Grid>
                        <Grid item xs={12} container spacing={1} justifyContent="flex-end">
                            <Grid item>
                                <Button
                                    onClick={handleAddFollowers}
                                    color={error ? 'error' : 'primary'}
                                    disabled={addingFollowers || isEmpty(selectedFollowers)}
                                >
                                    <FormattedMessage id={error ? 'TRY_AGAIN' : 'ADD'} />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleDisableAddFollowers}>
                                    <FormattedMessage id="CANCEL" />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            {
                showFollowers || useSelect ? null : (
                    <>
                        <SelectFollowers
                            availableFollowersOptions={availableFollowersOptions}
                            classes={classes}
                            selectedFollowers={selectedFollowers}
                            setSelectedFollowers={setSelectedFollowers}
                        />
                        <MenuItem disableRipple className={classes.actionItem}>
                            <Grid item xs={12} container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                    <Button onClick={handleDisableAddFollowers}>
                                        <FormattedMessage id="CANCEL" />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={handleAddFollowers}
                                        color={error ? 'error' : 'primary'}
                                        disabled={addingFollowers || isEmpty(selectedFollowers)}
                                    >
                                        <FormattedMessage id={error ? 'TRY_AGAIN' : 'ADD'} />
                                    </Button>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    </>
                )
            }
        </Menu>
    </>;
}

function SelectFollowersAutocomplete(props) {

    const {
        autoCompleteRef, availableFollowersOptions, classes,
        selectedFollowers, setSelectedFollowers
    } = props;

    return (
        <Autocomplete
            multiple
            autoComplete
            ref={autoCompleteRef}
            limitTags={3}
            value={selectedFollowers}
            onChange={(event, newValue) => {
                setSelectedFollowers(newValue);
            }}
            options={availableFollowersOptions}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
                <MenuItem {...props}>
                    <UserChip
                        className={classes.leftIcon}
                        isEmployee={option.isEmployee}
                        name={option.name}
                    />
                </MenuItem>
            )}
            renderTags={(values, getCustomizedTagProps) => (
                values.map((value) => (
                    <UserChipAlt
                        className={classes.leftIcon}
                        isEmployee={value.isEmployee}
                        name={value.name}
                        { ...getCustomizedTagProps(value)}
                    />
                ))
            )}
            isOptionEqualToValue={(option, value) => {
                return option.isEmployee === value.isEmployee && option.followerId === value.followerId;
            }}
            renderInput={(params) => (
                <>
                    <TextField
                        label={(
                            <FormattedMessage id="ADD_PEOPLE" />
                        )}
                        {...params}
                        size="small"
                        margin="dense"
                    />
                </>
            )}
        />
    );
}

function SelectFollowers(props) {

    const {
        autoCompleteRef, availableFollowersOptions, classes,
        selectedFollowers, setSelectedFollowers
    } = props;

    const setFollowers = (opt) => {
        if (selectedFollowers.find((v) =>  opt.isEmployee ===  v.isEmployee && opt.followerId === v.followerId)) {
            setSelectedFollowers(selectedFollowers.filter((v) =>  opt.isEmployee !==  v.isEmployee || opt.followerId !== v.followerId))
        } else {
            setSelectedFollowers([...selectedFollowers, opt])
        }
    }

    return availableFollowersOptions.map(({ name, isEmployee, followerId }) => (
        <MenuItem
            selected={selectedFollowers.find((v) =>  isEmployee ===  v.isEmployee && followerId === v.followerId)}
            onClick={()=> {setFollowers({ name, isEmployee, followerId })}}
            key={`${followerId}-${isEmployee}`}
            className={classes.userItem}
        >
            <UserChip
                isEmployee={isEmployee}
                name={name}
            />
        </MenuItem>
    ))
}

export default DiscussionFollowers;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';


function InfiniteScroll(props) {
    const {
        children, root, callback, threshold,
        component: Component, className, loader,
        hasMore,
    } = props;

    const [isLoading, setLoading] = useState(false);

    const [ref, inView, entry] = useInView({
        triggerOnce: false,
        threshold,
        root,
    });

    useEffect(() => {
        if (!isLoading && hasMore) {
            setLoading(true);
        }
    }, [inView]);

    useEffect(() => {
        if (isLoading) {
            callback();
        }
    }, [isLoading]);

    useEffect(() => {
        setLoading(false);
    }, [children.length]);

    if (!root || !children || children.length <= 0) {
        return null;
    }

    return (
        <Component className={className}>
            {children}
            {
                !isLoading ? null : loader
            }
            {
                !hasMore || isLoading ? null : <span ref={ref} />
            }
        </Component>
    );
}

InfiniteScroll.propTypes = {
    callback: PropTypes.func.isRequired,
    threshold: PropTypes.number,
    children: PropTypes.node,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    className: PropTypes.string,
    loader: PropTypes.node,
};

InfiniteScroll.defaultProps = {
    threshold: 1,
    children: undefined,
    component: React.Fragment,
    className: undefined,
    loader: undefined,
};

export default InfiniteScroll;

import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function FormMultiText(props) {
    const {
        fullWidth, label, onChange,
        name, value, options,
    } = props;

    return (
        <Autocomplete
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    label={label}
                    fullWidth={fullWidth}
                />
            )}
            multiple
            freeSolo
            options={options}
            value={value}
            getOptionLabel={(option) => (option.text || option)}
            onChange={onChange}
        />
    );
}

FormMultiText.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    label: PropTypes.node,
    name: PropTypes.string,
    fullWidth: PropTypes.bool,
    options: PropTypes.array,
};

FormMultiText.defaultProps = {
    label: null,
    name: undefined,
    fullWidth: false,
    options: [],
};

export default FormMultiText;

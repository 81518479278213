import React from 'react';
import PendingOrders from 'components/Orders/PendingOrders';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

function Pending() {
    const intl = useIntl();

    return (
        <>
            <Helmet>
                <title>
                    {`Melodie - ${intl.formatMessage({ id: 'VOD_PENDING_ORDERS' })}`}
                </title>
            </Helmet>
            <PendingOrders
                ordersEndpoint={(clientId) => `/vod/orders/${clientId}/pending/`}
                orderEndpoint={(clientId, orderId) => `/vod/orders/${clientId}/pending/${orderId}`}
            />
        </>
    );
}

export default Pending;

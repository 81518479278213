import { hot } from 'react-hot-loader/root';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Main from 'components/Main';
import Sorry from 'components/Sorry';
import Support from 'components/Support';
import Settings from 'components/Settings';
import Login from 'components/Login';
import Releases from 'components/Notes/Releases';
import Dashboard from 'components/Dashboard';
import { LocaleProvider } from 'contexts/locale';
import { UserProvider } from 'contexts/user';
import { NotificationProvider } from 'contexts/notification';
import Paths from 'routes/Paths';
import RouteWithAuth from 'routes/RouteWithAuth';
import RouteWithPermissions from 'routes/RouteWithPermissions';
import CallBackRoute from 'routes/CallBackRoute';
import { APPBaseURL } from 'utils/constants';

const MainRoute = hot(() => (
    <Router basename={APPBaseURL}>
        <LocaleProvider>
            <UserProvider>
                <Main>
                    <Switch>
                        <Route path="/callback">
                            <CallBackRoute />
                        </Route>
                        <Route path="/sorry">
                            <Sorry />
                        </Route>
                        <Route path="/login">
                            <Login />
                        </Route>
                        <NotificationProvider>
                            <RouteWithAuth path="/:page?/:client?/(.*)?">
                                <Route path="/releases/:client/" exact>
                                    <Releases />
                                </Route>
                                {
                                    Paths.map(({ path, permissions, component }) => {
                                        return (
                                            <RouteWithPermissions
                                                key={path}
                                                path={path}
                                                permissions={permissions}
                                                component={component}
                                            />
                                        );
                                    })
                                }
                                <Route path="/:client?/" exact>
                                    <Dashboard />
                                </Route>
                            </RouteWithAuth>
                        </NotificationProvider>
                    </Switch>
                </Main>
            </UserProvider>
        </LocaleProvider>
    </Router>
));

export default MainRoute;

import React from 'react';
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Skeleton from '@mui/material/Skeleton';
import Chip from '@mui/material/Chip';
import Detail from 'components/Detail';
import { useConstants } from 'contexts/constants';
import FormSelect from 'components/FormSelect';
import FormMultiText from 'components/FormMultiText';
import ImageInput from 'components/ImageInput';
import ExternalTitleSearch from 'components/ExternalTitleSearch';
import RadioMulti from 'components/RadioMulti';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CardMedia from '@mui/material/CardMedia';
import { useUser } from 'contexts/user';
import { MetadataLanguageToggles } from 'components/Distribution/Entities/MetadataDialog';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandCircleDownTwoTone';
import Collapse from '@mui/material/Collapse';

const commonLocales = ['en', 'fr-ca', 'fr'];
const commonTypes = ['MOVIE', 'SERIES', 'SHORT', 'DOCU'];

const gridSpacing = 2;

const useEntityDetailsStyles = makeStyles((theme) => createStyles({
    poster: {
        objectFit: 'cover',
        // display: 'grid',
        // gridArea: 'poster',
        backgroundColor: theme.palette.divider,
        borderRadius: theme.shape.borderRadius,
        height: '20vh',
        width: '13.5vh', // 0.675 ratio
        display: 'block',
        marginBottom: theme.spacing(1),
    },
    container: {
        marginTop: 'auto',
        marginBottom: 'auto',
        // display: 'grid',
    },
    accordionFilter: {
        justifyContent: 'center',
        boxShadow: 'none',
        width: '100%',
        '& .MuiExpansionPanelSummary-root, & .MuiExpansionPanelDetails-root': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    accordionSummaryFilter: {
        justifyContent: 'center',
        boxShadow: 'none',
        width: '100%',
        '& .MuiExpansionPanelSummary-root, & .MuiExpansionPanelDetails-root': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    accordionSummary: {
        margin: 0,
    },
    collapseContainer: {
        '&.MuiGrid-item': {
            paddingTop: theme.spacing(gridSpacing / 2),
        }
    },
    entityImage: {
        borderRadius: theme.shape.borderRadius,
    },

    more: {
        display: 'grid',
        gridArea: 'more',
    },
    chip: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },


}));

function EntityDetails(props) {
    const {
        metadata = {}, displayLanguage, handleInputChange,
        editMode, fetchingMetadata, externalTitle = false,
        onExternalTitleSelect, rootEntityTypes, handleChangeLocal, handleLangChange,
        error, helperText,
    } = props;


    const {
        title, genre, actor, runtime, writer,
        prodYear, director, countryOrigin,
        shortDesc, summary, producer,
        originalLanguage, poster, originalPoster, entityType
    } = metadata;
    const classes = useEntityDetailsStyles();
    const { api } = useUser();
    const [expanded, setExpanded] = React.useState(false);

    const {
        locales, genres, metadataTypes,
        countries, isLoading, fetchingConstants,
        defaultImage,
    } = useConstants();
    const hasPoster = ('poster' in metadata || 'originalPoster' in metadata) && editMode;
    const hasTitle = 'title' in metadata;
    let nbMeta = 0
    for (const meta in metadata) {
        const mainMeta = ["title", "prodYear", "poster", "originalLanguage", "entityType"];
        if (!mainMeta.includes(meta)) {
            if (metadata[meta]) {
                nbMeta += 1
            }
        }
    }



    return (
        <Grid container spacing={gridSpacing}  >

            {/* <Grid item xs  styles={{flexGrow:0}}> */}
            {/*     {!poster ? null : ( */}
            {/*         <MetadataDetail */}
            {/*             handleInputChange={handleInputChange} */}
            {/*             loading={fetchingConstants || fetchingMetadata} */}
            {/*             xs="auto" */}
            {/*             isUnique */}
            {/*             valueType="image" */}
            {/*             // defaultValue={originalPoster} */}
            {/*             // value={poster} */}
            {/*             defaultValue={api.static_dev(`metadata/${poster}/poster`) || originalPoster } */}
            {/*             value={poster} */}
            {/*             field="poster" */}
            {/*             label="CHANGE" */}
            {/*             editMode={editMode} */}
            {/*             className={classes.poster} */}
            {/*             // defaultImage={defaultImage} */}
            {/*             displayLanguage={displayLanguage} */}
            {/*         /> */}
            {/*     )} */}
            {/* </Grid> */}

            <Grid container item spacing={gridSpacing} alignItems="flex-start" className={classes.container}>
                <MetadataDetail
                    handleInputChange={handleInputChange}
                    loading={fetchingConstants || fetchingMetadata}
                    xs="auto"
                    isUnique={1}
                    valueType="image"
                    // defaultValue={originalPoster}
                    // value={poster}
                    defaultValue={poster ? api.static_dev(`metadata/${poster}/poster`) : originalPoster}
                    value={poster}
                    field="poster"
                    label="CHANGE"
                    editMode={editMode}
                    className={classes.poster}
                    defaultImage={defaultImage}
                    displayLanguage={displayLanguage}
                />
                <Grid item container xs spacing={gridSpacing} alignItems="flex-start">

                    <MetadataDetail
                        handleInputChange={handleInputChange}
                        year={prodYear}
                        externalTitle={externalTitle}
                        onOptionSelect={onExternalTitleSelect}
                        loading={fetchingConstants || fetchingMetadata}
                        xs={10}
                        md={poster ? 9 : 9}
                        label="TITLE"
                        hasLocale={metadataTypes.title && metadataTypes.title.hasLocale}
                        isUnique={metadataTypes.title && metadataTypes.title.isUnique}
                        valueType={metadataTypes.title && metadataTypes.title.valueType}
                        value={title}
                        field="title"
                        editMode={editMode}
                        displayLanguage={displayLanguage}
                        error={error}
                        helperText={helperText}
                    />
                    {/* <Tooltip title={<FormattedMessage id='CINEMACLOCK_SEARCH' />}> */}
                    {/*     <InfoIcon size="small" /> */}
                    {/* </Tooltip> */}

                    <MetadataDetail
                        handleInputChange={handleInputChange}
                        loading={fetchingConstants || fetchingMetadata}
                        md={3}
                        label="YEAR"
                        hasLocale={metadataTypes.prodYear && metadataTypes.prodYear.hasLocale}
                        isUnique={metadataTypes.prodYear && metadataTypes.prodYear.isUnique}
                        valueType={metadataTypes.prodYear && metadataTypes.prodYear.valueType}
                        value={prodYear}
                        displayLanguage={displayLanguage}
                        editMode={editMode}
                        input={TextField}
                        field="prodYear"
                    />
                    <MetadataDetail
                        handleInputChange={handleInputChange}
                        loading={fetchingConstants || fetchingMetadata}
                        xs={12}
                        md={4}
                        label="TYPE"
                        hasLocale={0}
                        isUnique={1}
                        valueType={metadataTypes.originalLanguage && metadataTypes.originalLanguage.valueType}
                        value={entityType}
                        displayLanguage={displayLanguage}
                        editMode={editMode}
                        field="entityType"
                        selectOptions={rootEntityTypes}
                        input={FormSelect}
                    // input={FormMultiText}
                    // row
                    />
                    <MetadataDetail
                        handleInputChange={handleInputChange}
                        loading={fetchingConstants || fetchingMetadata}
                        xs={12}
                        md={8}
                        label="ORIGINAL_LANGUAGE"
                        hasLocale={metadataTypes.originalLanguage && metadataTypes.originalLanguage.hasLocale}
                        isUnique={metadataTypes.originalLanguage && metadataTypes.originalLanguage.isUnique}
                        valueType={metadataTypes.originalLanguage && metadataTypes.originalLanguage.valueType}
                        value={originalLanguage}
                        displayLanguage={displayLanguage}
                        editMode={editMode}
                        field="originalLanguage"
                        commonValues={commonLocales}
                        radioOptions={locales}
                        row
                    // input={FormMultiText}
                    />
                    <Collapse in={expanded}
                        component={Grid} item xs={12}
                        className={classes.collapseContainer}
                    >
                        <Grid container spacing={gridSpacing} alignItems="flex-start">
                            <Grid item xs={12}>
                                <MetadataLanguageToggles
                                    metadata={metadata}
                                    displayLanguage={displayLanguage}
                                    handleLangChange={handleLangChange}
                                    defaultLanguages={['en', 'fr']}
                                    originalLanguage={originalLanguage || null}
                                />
                            </Grid>
                            <MetadataDetail
                                handleInputChange={handleInputChange}
                                loading={fetchingConstants || fetchingMetadata}
                                xs={12}
                                md={hasPoster ? 9 : 9}
                                label="SHORT_DESCRIPTION"
                                hasLocale={metadataTypes.shortDesc && metadataTypes.shortDesc.hasLocale}
                                isUnique={metadataTypes.shortDesc && metadataTypes.shortDesc.isUnique}
                                valueType={metadataTypes.shortDesc && metadataTypes.shortDesc.valueType}
                                value={shortDesc}
                                displayLanguage={displayLanguage}
                                editMode={editMode}
                                field="shortDesc"
                            />
                            <MetadataDetail
                                handleInputChange={handleInputChange}
                                loading={fetchingConstants || fetchingMetadata}
                                xs={12}
                                hasLocale={metadataTypes.summary && metadataTypes.summary.hasLocale}
                                isUnique={metadataTypes.summary && metadataTypes.summary.isUnique}
                                valueType={metadataTypes.summary && metadataTypes.summary.valueType}
                                label="SYNOPSIS"
                                value={summary}
                                displayLanguage={displayLanguage}
                                editMode={editMode}
                                field="summary"
                            />
                            <MetadataDetail
                                handleInputChange={handleInputChange}
                                loading={fetchingConstants || fetchingMetadata}
                                xs={6}
                                md={hasPoster ? 4 : 3}
                                label="GENRE"
                                hasLocale={metadataTypes.genre && metadataTypes.genre.hasLocale}
                                isUnique={metadataTypes.genre && metadataTypes.genre.isUnique}
                                valueType={metadataTypes.genre && metadataTypes.genre.valueType}
                                chips
                                value={genre}
                                field="genre"
                                displayLanguage={displayLanguage}
                                editMode={editMode}
                                input={FormSelect}
                                selectOptions={genres}
                            />
                            <MetadataDetail
                                handleInputChange={handleInputChange}
                                loading={fetchingConstants || fetchingMetadata}
                                xs={6}
                                md={hasPoster ? 4 : 3}
                                label="RUNTIME"
                                hasLocale={metadataTypes.runtime && metadataTypes.runtime.hasLocale}
                                isUnique={metadataTypes.runtime && metadataTypes.runtime.isUnique}
                                valueType={metadataTypes.runtime && metadataTypes.runtime.valueType}
                                value={runtime}
                                displayLanguage={displayLanguage}
                                editMode={editMode}
                                field="runtime"
                                input={TextField}
                            />

                            <MetadataDetail
                                handleInputChange={handleInputChange}
                                loading={fetchingConstants || fetchingMetadata}
                                xs={6}
                                md={hasPoster ? 4 : 3}
                                label="PRODUCER"
                                hasLocale={metadataTypes.producer && metadataTypes.producer.hasLocale}
                                isUnique={metadataTypes.producer && metadataTypes.producer.isUnique}
                                valueType={metadataTypes.producer && metadataTypes.producer.valueType}
                                chips
                                value={producer}
                                displayLanguage={displayLanguage}
                                editMode={editMode}
                                field="producer"
                                input={FormMultiText}
                            />
                            <MetadataDetail
                                handleInputChange={handleInputChange}
                                loading={fetchingConstants || fetchingMetadata}
                                xs={6}
                                md={hasPoster ? 4 : 3}
                                label="DIRECTOR"
                                hasLocale={metadataTypes.director && metadataTypes.director.hasLocale}
                                isUnique={metadataTypes.director && metadataTypes.director.isUnique}
                                valueType={metadataTypes.director && metadataTypes.director.valueType}
                                chips
                                value={director}
                                displayLanguage={displayLanguage}
                                editMode={editMode}
                                field="director"
                                input={FormMultiText}
                            />
                            <MetadataDetail
                                handleInputChange={handleInputChange}
                                loading={fetchingConstants || fetchingMetadata}
                                xs={6}
                                label="WRITER"
                                hasLocale={metadataTypes.writer && metadataTypes.writer.hasLocale}
                                isUnique={metadataTypes.writer && metadataTypes.writer.isUnique}
                                valueType={metadataTypes.writer && metadataTypes.writer.valueType}
                                chips
                                value={writer}
                                displayLanguage={displayLanguage}
                                editMode={editMode}
                                field="writer"
                                input={FormMultiText}
                            />
                            <MetadataDetail
                                handleInputChange={handleInputChange}
                                loading={fetchingConstants || fetchingMetadata}
                                xs={12}
                                md={hasPoster ? 12 : 6}
                                label="ACTORS"
                                hasLocale={metadataTypes.actor && metadataTypes.actor.hasLocale}
                                isUnique={metadataTypes.actor && metadataTypes.actor.isUnique}
                                valueType={metadataTypes.actor && metadataTypes.actor.valueType}
                                chips
                                value={actor}
                                displayLanguage={displayLanguage}
                                editMode={editMode}
                                field="actor"
                                input={FormMultiText}
                            />
                            <MetadataDetail
                                handleInputChange={handleInputChange}
                                loading={fetchingConstants || fetchingMetadata}
                                xs={6}
                                label="COUNTRY"
                                hasLocale={metadataTypes.countryOrigin && metadataTypes.countryOrigin.hasLocale}
                                isUnique={metadataTypes.countryOrigin && metadataTypes.countryOrigin.isUnique}
                                valueType={metadataTypes.countryOrigin && metadataTypes.countryOrigin.valueType}
                                chips
                                value={countryOrigin}
                                displayLanguage={displayLanguage}
                                editMode={editMode}
                                field="countryOrigin"
                                input={FormSelect}
                                selectOptions={countries}
                            />
                        </Grid>
                    </Collapse>
                    <Grid justifyContent="center" item container>
                        <Button
                            onClick={() => setExpanded((currentValue) => !currentValue)}
                            endIcon={<ExpandMoreIcon sx={expanded ? { transform: 'rotate(180deg)' } : {}} />}
                        >
                            <FormattedMessage id={expanded ? 'VIEW_LESS_METADATA_DETAILS' : 'VIEW_MORE_METADATA_DETAILS'} />
                            {
                                nbMeta < 1 ? null :
                                    <Chip
                                        size="small"
                                        color="secondary"
                                        label={nbMeta}
                                        className={classes.chip}
                                    />

                            }

                        </Button>
                    </Grid>
                    {/*     </AccordionDetails> */}
                    {/* </Accordion> */}
                    {/* </Grid> */}
                </Grid>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => createStyles({
    StyledFormattedMessage: {
        color: theme.palette.primary.main,
    },
    SkeletonPadding: {
        marginBottom: theme.spacing(1),
    },

}));

const MetadataDetail = (props) => {
    const {
        xs, sm, md,
        lg, xl, label,
        chips, editMode, value,
        displayLanguage,
        field, hasLocale,
        loading, isUnique,
        valueType = "", handleInputChange,
        selectOptions, defaultValue, className,
        defaultImage, externalTitle, onOptionSelect, year,
        commonValues, radioOptions, row,
        error, helperText,
    } = props;
    const classes = useStyles();
    if (commonValues) {
    };

    function onChange({ target: { value: targetValue } }, multiData) {
        // multiData is the data from the FormMutliText
        let newValue = null;

        if (isUnique) {
            if (hasLocale) {
                newValue = [
                    ...value.filter((v) => v.language !== displayLanguage),
                    { language: displayLanguage, value: targetValue, label: targetValue, id: undefined }
                ];

            } else {
                newValue = { ...value, value: targetValue, label: targetValue };
            }
        } else if (valueType && valueType.indexOf('id(') === 0) {

            newValue = targetValue.map((d) => ((Array.isArray(value) && value.find((v) => v && v.value === d)) || { id: undefined, value: d }));
        } else if (hasLocale) {

            if (value && Array.isArray(value)) {
                newValue = [
                    ...value.filter((v) => v.language !== displayLanguage),
                    { language: displayLanguage, value: targetValue, label: targetValue, id: undefined }
                ];
            } else {
                newValue = [
                    { language: displayLanguage, value: targetValue, label: targetValue, id: undefined }
                ];
            }

        } else if (multiData) {
            newValue = multiData.map((d) => (isString(d) ? { id: undefined, value: d } : { id: d.value, value: d.text }));
        }
        handleInputChange(field, newValue);
    }

    let displayValue = null;
    if (editMode && !loading && `valueType`) {
        if (commonValues) {
        };
        if (isUnique) {
            if (hasLocale) {
                displayValue = (!isEmpty(value) && value.find((v) => v.language === displayLanguage)) || '';
            } else {
                displayValue = value && value.value ? value.value : '';
            }
        } else if (valueType.indexOf('id(') === 0) {
            displayValue = value && Array.isArray(value) ? value.map((v) => v.value ? v.value : v) : [];
        } else if (hasLocale) {
            displayValue = (Array.isArray(value) && !isEmpty(value) && value.find((v) => v.language === displayLanguage)) || (Array.isArray(value) && !isEmpty(value) && value.find((v) => v.value != '')) || '';
        } else {
            displayValue = value && Array.isArray(value) ? value.map((val) => ({ text: val.value ? val.value : val, value: val.id ? val.id : null })) : [];
        }

        const [, typeFn, typeArg, typeOnly] = valueType.match(/(\w+)\(([\w,]+)\)|(\w+)/);
        const typeArgs = typeArg ? typeArg.split(',') : [];

        let Input = null;
        let inputProps = {};

        switch (typeFn || typeOnly) {
            case 'text':
                if (isUnique || hasLocale) {
                    Input = TextField;
                } else {
                    Input = FormMultiText;
                }
                break;
            case 'textarea':
                Input = TextField;
                inputProps = {
                    multiline: true,
                    maxRows: field === 'shortDesc' ? 2 : 10,
                };
                break;
            case 'range':
                Input = TextField;
                inputProps = {
                    min: typeArgs.length === 3 ? typeArgs[0] : undefined,
                    max: typeArgs.length === 3 ? typeArgs[1] : undefined,
                };
                break;
            case 'id':
                if (commonValues) {
                    Input = RadioMulti;
                    inputProps = {
                        commonValues: commonValues,
                        options: radioOptions,
                        row,
                    };
                } else {
                    Input = FormSelect;
                    inputProps = {
                        multiple: !isUnique,
                        children: selectOptions.map(({ label, value }, index) => (
                            <MenuItem key={index} value={value}>
                                {label}
                            </MenuItem>
                        )),
                    };
                }

                break;
            case 'time':
                Input = TextField;
                inputProps = {
                    min: 0,
                };
                break;
            case 'image':
                Input = ImageInput;
                inputProps = {
                    fileList: value,
                    originalSrc: value ? (defaultValue || defaultImage) : defaultImage,
                    deleteEnabled: (value ? (defaultValue || defaultImage) : defaultImage) !== defaultImage,
                    onChange: ({ target: { files } }) => {
                        handleInputChange(field, files);
                    },
                };
                break;
            default:
                Input = null;
                break;
        }

        if (externalTitle) {
            Input = ExternalTitleSearch;
            inputProps = { year, onOptionSelect, error, helperText }
        }

        if (commonValues) {
        };
        return !Input ? null : (
            <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                <Input
                    value={displayValue && displayValue.value !== undefined ? displayValue.value : displayValue}
                    // value={''}
                    fullWidth
                    className={className}
                    name={field}
                    label={(
                        <span className={hasLocale ? classes.StyledFormattedMessage : null}>
                            <FormattedMessage
                                id={label}
                            />
                        </span>
                    )}
                    onChange={onChange}
                    {...inputProps}
                />
            </Grid>
        );
    }
    if (loading) {
        displayValue = <Skeleton variant="rectangular" />;
    } else if (isUnique || hasLocale) {
        if (hasLocale && Array.isArray(value)) {
            value.forEach((langValue) => {
                if (!displayValue && langValue.language === displayLanguage) {
                    displayValue = langValue.value;
                }
            });
        } else if (value && value.label) {
            displayValue = value.label;
        }
    } else if (Array.isArray(value)) {
        displayValue = value.map((v) => v.label);
    } else {
        displayValue = value;
    }


    return (
        <Detail xs={xs} sm={sm} md={md} lg={lg} xl={xl} label={loading ? <Skeleton className={classes.SkeletonPadding} variant="rectangular" width="30%" /> : label} chips={chips}>
            {displayValue}
        </Detail>
    );
}

const metadataShape = PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.string,
    language: PropTypes.string,
});

MetadataDetail.propTypes = {
    value: PropTypes.any,
    defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(metadataShape), metadataShape, PropTypes.string]),
    selectOptions: PropTypes.arrayOf(metadataShape),
    valueType: PropTypes.string,
    xs: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
    sm: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
    md: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
    lg: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
    xl: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]),
    label: PropTypes.string,
    chips: PropTypes.bool,
    editMode: PropTypes.bool,
    displayLanguage: PropTypes.string,
    field: PropTypes.string,
    inputProps: PropTypes.shape({}),
    hasLocale: PropTypes.number,
    loading: PropTypes.bool,
    isUnique: PropTypes.number,
    handleInputChange: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default EntityDetails;

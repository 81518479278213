import React from 'react';
import PropTypes from 'prop-types';

import EnhancedTableCommon from './EnhancedTableCommon';

function EnhancedTable(props) {
    const {
        rows, pagenate, uniqueField,
        totalRowCount, selected, defaultOrderBy,
        defaultOrder, isSortable, onSelectAll,
        setSelected, columns, showDivisions,
        showHover, stickyHeader, size,
        additionalActions, rowsPerPageOptions, onRowClick,
        isLoading, isSelectable, disabledRow,
        disabledRowMessage, disabledSelect, disabledSelectMessage,
        emptyMessage, multiple,
    } = props;
    const [order, setOrder] = React.useState(defaultOrder);
    const [orderBy, setOrderBy] = React.useState(defaultOrderBy);
    const [prevOrderBy, setPrevOrderBy] = React.useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [page, setPage] = React.useState(0);

    function desc(a, b) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const handleRequestSort = React.useCallback((event, property) => {
        const isDesc = orderBy === property && order === 'desc';
        const newOrder = isDesc ? 'asc' : 'desc';
        const isCanceled = orderBy === property && prevOrderBy === property;

        setOrder(isCanceled ? defaultOrder : newOrder);
        setPrevOrderBy(orderBy);
        setOrderBy(isCanceled ? '' : property);
    }, [orderBy, order, prevOrderBy, defaultOrder]);

    const sortedRows = React.useMemo(() => {
        if (isSortable) {
            const stabilizedThis = rows.map((el, index) => [el, index]);
            stabilizedThis.sort((a, b) => {
                const orderValue = order === 'desc' ? desc(a[0], b[0], orderBy) : -desc(a[0], b[0], orderBy);
                if (orderValue !== 0) {
                    return orderValue;
                }
                return a[1] - b[1];
            });
            return stabilizedThis.map((el) => el[0]);
        }

        return rows;
    }, [rows, order, isSortable, orderBy]);

    const selectable = React.useMemo(() => (
        rows.filter((o) => (disabledSelect ? !disabledSelect(o) : true)).map((o) => (o[uniqueField]))
    ), [rows, disabledSelect, uniqueField]);


    const allChecked = React.useMemo(() => (

        selectable.reduce((acc, id) => (acc && selected.includes(id)), true)
    ), [selected, selectable]);

    const viewedRows = React.useMemo(() => (
        pagenate ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows
    ), [pagenate, page, rowsPerPage, sortedRows]);


    const handleRowSelect = React.useCallback((objectId) => {

        if (selectable.includes(objectId)) {
            setSelected((currentSelected) => {
                let selLst = [];
                if (!multiple) {
                    selLst = currentSelected[0] === objectId ? [] : [objectId]
                } else if (Array.isArray(objectId)) {
                    selLst = objectId; // Weird!!! Does this ever happen?
                } else if (currentSelected.includes(objectId)) {
                    selLst = currentSelected.filter((id) => id !== objectId);
                } else {
                    selLst = [...currentSelected, objectId];
                }

                return selLst;
            });
        }
    }, [selectable, multiple]);

    const handleSelectAll = React.useCallback(() => {
        if (allChecked) {
            setSelected([]);
        } else {
            setSelected((currentSelected) => {
                const added = selectable.filter((id) => (!currentSelected.includes(id)));
                return [...currentSelected, ...added];
            });
        }
    }, [allChecked, selectable]);


    return (
        <EnhancedTableCommon
            rows={rows}
            pagenate={pagenate}
            uniqueField={uniqueField}
            selected={selected}
            defaultOrderBy={defaultOrderBy}
            defaultOrder={defaultOrder}
            isSortable={isSortable}
            totalRowCount={totalRowCount || rows.length}
            page={page}
            pageRows={viewedRows}
            onChangeRowsPerPage={setRowsPerPage}
            onChangePage={setPage}
            onRowSelect={isSelectable ? handleRowSelect : null}
            onSelectAll={handleSelectAll}
            handleRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            selectable={selectable}
            columns={columns}
            showDivisions={showDivisions}
            showHover={showHover}
            stickyHeader={stickyHeader}
            size={size}
            additionalActions={additionalActions}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowClick={onRowClick}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
            isSelectable={isSelectable}
            multiple={multiple}
            disabledRow={disabledRow}
            disabledRowMessage={disabledRowMessage}
            disabledSelect={disabledSelect}
            disabledSelectMessage={disabledSelectMessage}
            emptyMessage={emptyMessage}
        />
    );
}

EnhancedTable.propTypes = {
    pagenate: PropTypes.bool,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalRowCount: PropTypes.number,
    uniqueField: PropTypes.string.isRequired,
    selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    defaultOrder: PropTypes.oneOf(['asc', 'desc']),
    defaultOrderBy: PropTypes.string,
    isSortable: PropTypes.bool,
    multiple: PropTypes.bool,
    onRowSelect: PropTypes.func,
    onSelectAll: PropTypes.func,
};

EnhancedTable.defaultProps = {
    pagenate: true,
    totalRowCount: undefined,
    selected: [],
    defaultOrder: 'asc',
    defaultOrderBy: '',
    isSortable: false,
    multiple: true,
    onRowSelect: null,
    onSelectAll: null,
};

export default EnhancedTable;

import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Helmet } from 'react-helmet';

import useLocationSearch from 'hooks/useLocationSearch';
import { useUser } from 'contexts/user';
import Loading from 'components/Loading';
import NotFound from 'components/NotFound';
import DistriProceed from 'components/Distribution/Orders/Proceed';
import OrderCreation from 'components/Distribution/Orders/Create';
import { Dialog } from '@mui/material';

import SelectionMenu from './SelectionMenu';
import Filter from './Filter';
import EntityList from './List';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'grid',
        gridTemplateRows: 'max-content auto',
    },
    loading: {
        backgroundColor: 'transparent',
        height: '100%',
        width: '100%',
    },
    notFound: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
        '& > svg': {
            height: 'auto !important',
        },
    },
}));

const defaultFilter = {
    inVault: false,
    title: '',
    entityType: [],
    searchAllTitleLangauges: false,
    assetLanguage: 'all'
};

function Catalogue() {
    const { api, user } = useUser();
    const classes = useStyles();

    const { client } = useParams();
    const intl = useIntl();
    const [selectionEnabled, setSelectionEnabled] = React.useState(false);

    const [selectedTitles, setSelectedTitles] = React.useState([]);

    const [filter, setFilter] = useLocationSearch(Object.keys(defaultFilter), defaultFilter);

    const {
        inVault, title, entityType, searchAllTitleLangauges, assetLanguage
    } = filter;

    const [failed, setFailed] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState('');
    const [titles, setTitles] = React.useState([]);
    const [fetchingTitles, setFetchingTitles] = React.useState(false);
    const limit = 25;
    const [viewLimit, setViewLimit] = React.useState(limit);

    const items = titles.slice(0, viewLimit);
    const fullCount = (titles.length > 0 ? titles[0].fullCount : 0);

    async function fetchTitles(abortController = new AbortController(), offset) {
        if (client) {
            try {
                setFetchingTitles(true);
                setFailed(false);
                const formattedSearch = filter.title ? {
                    filter: {
                        title: {
                            value: filter.title,
                            condition: 'ilike',
                        },
                        parent_id: {
                            value: null,
                        },
                    },
                } : {
                    filter: {
                        parent_id: {
                            value: null,
                        },
                    },
                };

                if (!isEmpty(filter.entityType)) {
                    formattedSearch.filter.entity_type_id = { value: filter.entityType, condition: 'in' };
                }

                if (searchAllTitleLangauges && formattedSearch.filter.title) {
                    formattedSearch.filter.title = {
                        ...formattedSearch.filter.title,
                        OR: {
                            value: {
                                value: filter.title,
                                condition: 'ilike',
                            },
                        },
                    };
                }

                if (assetLanguage !== 'all') {
                    formattedSearch.filter.locale_id = { value: filter.assetLanguage, condition: '=' };
                }

                const body = {
                    inVault, ...formattedSearch, offset, limit,
                };
                const response = await api.get(`/${client}/entity/`, body, { signal: abortController.signal });
                if (response.ok) {
                    const data = await response.json();

                    if (offset === 0) {
                        setTitles(data);
                    } else {
                        setTitles([...titles, ...data]);
                    }
                } else {
                    setTitles([]);
                    setFailed(true);
                }
                setFetchingTitles(false);
            } catch (error) {
                setFetchingTitles(false);
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    React.useEffect(() => {
        setViewLimit(limit);
        const abortController = new AbortController();
        fetchTitles(abortController, 0);
        return () => { abortController.abort(); };
    }, [client, inVault, title, entityType, searchAllTitleLangauges, assetLanguage]);

    React.useEffect(() => {
        const abortController = new AbortController();
        if (viewLimit !== limit && titles.length !== 0) { // only run when asking for more titles.
            fetchTitles(abortController, titles.length);
        }
        return () => { abortController.abort(); };
    }, [viewLimit]);


    const loadMore = React.useCallback(() => {
        if (titles.length < fullCount) {
            setViewLimit((prevViewLimit) => prevViewLimit + limit);
        }
    }, [titles.length, fullCount]);

    function handleSwitch({ target: { checked } }) {
        setSelectionEnabled(checked);
        setSelectedTitles([]);
    }

    const handleTitleClick = React.useCallback(({ currentTarget: { value } }) => {
        setSelectedTitles((prevSelectedTitles) => {
            let newTitles = [];
            const titleId = parseInt(value, 10);
            if (prevSelectedTitles.includes(titleId)) {
                newTitles = prevSelectedTitles.filter((t) => t !== titleId);
            } else {
                newTitles = prevSelectedTitles.concat([titleId]);
            }
            return newTitles;
        });
    }, []);

    function handleOpenModal({ currentTarget: { value } }) {
        setModalOpen(value);
    }

    function handleCloseModal() {
        setModalOpen('');
        setSelectedTitles([]);
    }

    const currentClientId = parseInt(client, 10);
    const currentClient = find(user.clients, { client: currentClientId });
    const clientName = (currentClient).name || client;

    const isLoading = isEmpty(titles) && fetchingTitles;
    const notFound = !isLoading && !fetchingTitles && isEmpty(titles);
    const hasResults = !notFound;

    let nonVisibleSelected = 0;
    if (inVault && !isEmpty(selectedTitles)) {
        const itemIds = items.map((x) => x.id);
        nonVisibleSelected = selectedTitles.filter((x) => !itemIds.includes(x)).length;
    }

    return (
        <div className={classes.root}>
            <Helmet>
                <title>
                    {`Melodie - ${intl.formatMessage({ id: 'CATALOGUE' })}`}
                </title>
            </Helmet>
            <Filter
                selectionEnabled={selectionEnabled}
                initialFilters={filter}
                applyFilters={setFilter}
                fetchingTitles={fetchingTitles}
                managePermission={currentClient.distribution === 2}
                handleSwitch={handleSwitch}
                defaultFilter={defaultFilter}
            />
            {
                !isLoading ? null : (
                    <Loading className={classes.loading} />
                )
            }
            {
                !notFound ? null : (
                    <NotFound className={classes.notFound} message="NOT_FOUND" />
                )
            }
            {
                !hasResults ? null : (
                    <EntityList
                        limit={limit}
                        loadMore={loadMore}
                        viewLimit={viewLimit}
                        titles={titles}
                        fetchingTitles={fetchingTitles}
                        selectionEnabled={selectionEnabled}
                        selectedTitles={selectedTitles}
                        onTitleClick={handleTitleClick}
                        currentClientId={currentClientId}
                    />
                )
            }
            <SelectionMenu
                selectionEnabled={selectionEnabled}
                managePermission={currentClient.distribution === 2}
                openModal={handleOpenModal}
                hasSelection={!isEmpty(selectedTitles) && selectionEnabled}
                selectionCount={selectedTitles.length}
                nonVisibleSelected={nonVisibleSelected}
                selectedTitles={selectedTitles}
                clientName={clientName}
                currentClientId={currentClientId}
            />
            <DistriProceed
                isOpen={modalOpen === 'WORKSPACE'}
                titles={selectedTitles}
                closeModal={handleCloseModal}
            />
            <Dialog
                open={modalOpen === 'ORDER'}
                className={classes.wizard}
                maxWidth="lg"
                // fullWidth
            >
                <OrderCreation
                    closeModal={handleCloseModal}
                    selectedTitles={!isEmpty(selectedTitles) && selectedTitles}
                />
            </Dialog>
        </div>
    );
}

export default Catalogue;

import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import AcceptIcon from '@mui/icons-material/CheckCircleTwoTone';
import DownloadIcon from '@mui/icons-material/DownloadForOfflineTwoTone';
import { useSnackbar } from 'notistack';

import FormSelect from 'components/FormSelect';
import { formatPostData } from 'utils/ofApi';
import FileInput from 'components/FileInput';
import DownloadButton from 'components/DownloadButton';
import { useUser } from 'contexts/user';


function Document(props) {
    const {
        isNew, file, documentId, storageId,
        documentName, documentType, managementPermission,
        onChange, onDelete, onUpload, intl,
        documentComment, documentTypes, downloadLink,
    } = props;

    const handleChange = React.useCallback((event) => {
        onChange(event, documentId, isNew);
    }, [documentId, isNew]);

    const handleDelete = React.useCallback((event) => {
        onDelete(documentId, isNew);
    }, [documentId, isNew]);

    const handleUpload = React.useCallback(() => {
        onUpload(file, documentType, documentName, documentComment, documentId);
    }, [file, documentType, documentName, documentId]);


    if (isNew) {
        return (
            <ListItem
                disableGutters
                alignItems="flex-start"
                // secondaryAction={
                // }
            >

                <div style={{
                    minWidth: 0,
                    marginTop: 0,
                    marginbottom: 0,
                    width: 'calc(100% - 68px)',
                }}>
                     <TextField
                            margin="dense"
                            size="small"
                            variant="standard"
                            name="documentName"
                            fullWidth
                            placeholder={intl.formatMessage({id: 'DOCUMENT_NAME'})}
                            value={documentName}
                            onChange={handleChange}
                        />
<FormSelect
                            displayEmpty
                            margin="dense"
                            size="small"
                            variant="standard"
                            name="documentType"
                            value={documentType}
                            placeholder={intl.formatMessage({id: 'DOCUMENT_TYPE'})}
                            onChange={handleChange}
                        >
                            {
                                documentTypes.map(({ documentTypeId, documentType }) => (
                                    <MenuItem value={documentTypeId}>
                                        {documentType}
                                    </MenuItem>
                                ))
                            }

                        </FormSelect>
                </div>
                {/* <ListItemText */}
                {/*     primary={( */}
                {/*         */}
                {/*     )} */}
                {/*     secondary={( */}
                {/*          */}
                {/*     )} */}
                {/* /> */}
                <React.Fragment>
                    <IconButton edge="end" color="error" onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                    <IconButton edge="end" color="primary" onClick={handleUpload}>
                        <AcceptIcon />
                    </IconButton>
                </React.Fragment>
            </ListItem>
        )
    }
    
    return (
        <ListItem
            disableGutters
            alignItems="flex-start"
        >
            <ListItemText
                primary={documentName}
                secondary={
                    <>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                        >
                            {documentType}
                        </Typography>
                        {!documentComment ? '' : ` — ${ documentComment }`}
                    </>
                }
            />
            <React.Fragment>
                {
                    !managementPermission ? null : (
                        <IconButton edge="end" color="error" onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    )
                }
                <DownloadButton
                    url={downloadLink}
                    color="primary"
                    iconButton
                    edge="end"
                    hideContentOnProgress
                >
                    <DownloadIcon />
                </DownloadButton>
            </React.Fragment>
        </ListItem>
    );
}

function Documents(props) {

    const {
        relationType, relationId, managementPermission, fetchUrl, fetchTypes=`/document_types` ,
        listProps={},
    } = props;

    const [documents, setDocuments] = React.useState([]);
    const [documentTypes, setDocumentTypes] = React.useState([]);
    const [newDocuments, setNewDocuments] = React.useState([]);
    const [fetching, setFetching] = React.useState(false);

    const { client } = useParams();
    const { api } = useUser();
    const intl = useIntl();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    async function fetchDocuments(abortController = new AbortController()) {
        if (!relationId) {
            return;
        }
        try {
            setFetching(true);
            const response = await api.get(`/${client}/${relationType}/${relationId}/document/`, null, { signal: abortController.signal, artificialDelay: 100 });

            if (response.ok) {
                const data = await response.json();
                setDocuments(data);
            } else {
                setDocuments({});
            }
        } catch (error) {
            if (!abortController.signal.aborted) {
                console.error(error);
            }
        }
        setFetching(false);
    }

    async function fetchDocumentTypes(abortController = new AbortController()) {
        try {
            const response = await api.get(fetchTypes, null, { signal: abortController.signal });

            if (response.ok) {
                const data = await response.json();
                setDocumentTypes(data);
            } else {
                setDocumentTypes({});
            }
        } catch (error) {
            if (!abortController.signal.aborted) {
                console.error(error);
            }
        }
    }

    const handleDocuments = React.useCallback(({ target: { files } }) => {
        setNewDocuments((currentNewDocuments) =>  [...currentNewDocuments, ...files.map((f) => ({ documentName: f.name, documentType: "", file: f }))]);
    }, []);

    const changeDocument = React.useCallback(({ target: { name, value } }, documentId, isNew) => {
        setNewDocuments((currentNewDocuments) => currentNewDocuments.map((d, index) => {
            if (index === documentId) {
                return { ...d, [name]: value };
            }
            return d;
        }))
    }, []);

    const uploadDocument = React.useCallback(async (file, documentType, documentName, documentComment, documentId) => {
        const response = await api.post(`/${client}/${relationType}/${relationId}/document`, formatPostData({documentType, documentName, documentComment}, [file]));

        if (response.ok) {
            const result = await response.json();
            if (result.documentId) {
                setNewDocuments((currentNewDocuments) => currentNewDocuments.filter((d, index) => index !== documentId));
                setDocuments((currentDocuments) => [...currentDocuments, result]);
                fetchDocuments();
            }
        } else {
            enqueueSnackbar(<FormattedMessage id="ERROR_UPLOADING_DOCUMENT" />, { variant: 'error' });
        }
    }, [client, relationId]);

    const removeDocument = React.useCallback(async (documentId, isNew) => {
        if (isNew) {
            setNewDocuments((currentNewDocuments) => currentNewDocuments.filter((d, index) => index !== documentId));
        } else {
            const response = await api.delete(`/${client}/${relationType}/${relationId}/document/${documentId}`, {});

            if (response.ok) {
                setDocuments((currentDocuments) => currentDocuments.filter(({documentId: di}) => documentId !== di));
                fetchDocuments();
            } else {
                enqueueSnackbar(<FormattedMessage id="ERROR_DELETING_DOCUMENT" />, { variant: 'error' });
            }
        }
    }, [client, relationId, relationType]);

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchDocuments(abortController);

        return () => {
            abortController.abort();
        };
    }, [client, relationId, relationType]);

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchDocumentTypes(abortController);

        return () => {
            abortController.abort();
        };
    }, []);

    if (!managementPermission && isEmpty(documents)) {
        return null;
    }


    return (
        <>
            <Grid container justifyContent="flex-end" alignItems="center">
                <Grid item sx={{ marginRight: 'auto' }}>
                    <Typography variant="subtitle1">
                        <FormattedMessage id="DOCUMENTS" />
                    </Typography>
                </Grid>
                {
                    !managementPermission ? null : (
                        <Grid item>
                            <FileInput
                                disableIcon
                                size="small"
                                variant="outlined"
                                onChange={handleDocuments}
                                showFileName={false}
                                multiple={true}
                                label={<FormattedMessage id="ADD_DOCUMENTS" />}
                            />
                        </Grid>
                    )
                }
            </Grid>
            <List {...listProps}>
                {
                    [...newDocuments, ...documents].map(({documentId, documentUid: storageId, documentName, documentType, file, documentComment}, index, documentList) => (
                        <React.Fragment key={documentId}>
                            <Document
                                managementPermission={managementPermission}
                                isNew={!documentId}
                                documentId={documentId || index}
                                storageId={storageId}
                                documentName={documentName}
                                documentType={documentType}
                                documentComment={documentComment}
                                onDelete={removeDocument}
                                onUpload={uploadDocument}
                                onChange={changeDocument}
                                file={file}
                                intl={intl}
                                documentTypes={documentTypes}
                                downloadLink={`/${client}/${relationType}/${relationId}/document/${documentId}`}
                            />
                            {
                                !documentList[index + 1] || !documentList[index + 1].documentId || documentId ? null : (
                                    <Divider />
                                )
                            }
                        </React.Fragment>
                    ))
                }
            </List>
        </>
    );

}

export default Documents;

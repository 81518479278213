import React from 'react';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import parseISO from 'date-fns/parseISO';
import { useLocation, generatePath, useRouteMatch } from 'react-router-dom';
import range from 'lodash/range';
import Skeleton from '@mui/material/Skeleton';

import useLocationSearch from 'hooks/useLocationSearch';
import NotFound from 'components/NotFound';
import RouteLink from 'components/RouteLink';
import Loading from 'components/Loading';
import VodPendingOrder from 'components/Orders/PendingOrder';
import InfiniteScroll from 'components/InfiniteScroll';
import { useUser } from 'contexts/user';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    notFound: {
        maxHeight: '100%',
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
        alignSelf: 'flex-start',
    },
    pendingContainer: {
        overflow: 'auto',
        marginTop: theme.spacing(4),
    },
    title: {
        wordWrap: 'break-word',
    },
    reason: {
        '& + &::before': {
            content: "', '",
        },
    },
    strikethrough: {
        textDecoration: 'line-through',
    },
    loading: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        width: '100%',
        height: '100%',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gridAutoRows: 'auto',
        gridGap: theme.spacing(1, 2),
        paddingBottom: theme.spacing(2),
    },
    filters: {
        marginBottom: theme.spacing(2),
    },
}));

const propTypes = {
};

function VodPendingOrders(props) {
    const {
        ordersEndpoint, orderEndpoint, downloadDisabled = false,
    } = props;

    const classes = useStyles();
    const match = useRouteMatch();
    const { api } = useUser();
    const { client: clientId } = match.params;
    const [fetchingOrders, setFetchingOrders] = React.useState(false);

    const limit = 25;
    const [viewLimit, setViewLimit] = React.useState(limit);
    const [failed, setFailed] = React.useState(false);
    const [orders, setOrders] = React.useState([]);

    const scrollableTarget = React.useRef(null);

    const [showCancelled, setShowCancelled] = useLocationSearch('showCancelled', false);

    const fullCount = (orders.length > 0 ? orders[0].fullCount : 0);
    const items = orders.slice(0, viewLimit);

    async function fetchOrders(abortController = new AbortController(), offset = 0) {
        if (clientId) {
            try {
                setFetchingOrders(true);
                setFailed(false);
                const response = await api.get(ordersEndpoint(clientId), { showCancelled, offset, limit }, { signal: abortController.signal });

                if (response.ok) {
                    const data = await response.json();

                    if (offset === 0) {
                        setOrders(data);
                    } else {
                        setOrders((current) => [...current, ...data]);
                    }
                } else {
                    setOrders([]);
                    setFailed(true);
                }

                setFetchingOrders(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    function handleSwitch({ target: { checked } }) {
        setShowCancelled(checked);
    }

    const loadMore = React.useCallback(() => {
        if (orders.length < fullCount) {
            setViewLimit((prevViewLimit) => prevViewLimit + limit);
        }
    }, [orders.length, fullCount]);

    React.useEffect(() => {
        setViewLimit(limit);
        const abortController = new AbortController();
        fetchOrders(abortController, 0);
        return () => {
            abortController.abort();
        };
    }, [clientId, showCancelled]);

    React.useEffect(() => {
        const abortController = new AbortController();
        if (viewLimit !== limit && orders.length !== 0) { // only run when asking for more titles.
            fetchOrders(abortController, orders.length);
        }
        return () => { abortController.abort(); };
    }, [viewLimit]);

    const isLoading = isEmpty(orders) && fetchingOrders;
    const notFound = !isLoading && !fetchingOrders && isEmpty(orders);
    const hasResults = !notFound;

    return (
        <div className={classes.root}>
            <VodPendingOrder orderEndpoint={orderEndpoint} refresh={fetchOrders} />
            <Grid container justifyContent="flex-end" className={classes.filters}>
                <FormControlLabel
                    control={(
                        <Switch
                            checked={showCancelled}
                            name="showCancelled"
                            onChange={handleSwitch}
                            value="showCancelled"
                        />
                    )}
                    label={<FormattedMessage id="CANCELLED" />}
                />
            </Grid>
            {
                !isLoading ? null : (
                    <Loading className={classes.loading} />
                )
            }
            {
                !notFound ? null : (
                    <NotFound message="NOT_FOUND" className={classes.notFound} />
                )
            }
            {
                !hasResults ? null : (
                    <div style={{ overflow: 'auto', height: '100%' }} ref={scrollableTarget}>
                        <InfiniteScroll
                            component="div"
                            hasMore={items.length < fullCount}
                            className={classes.grid}
                            callback={loadMore}
                            root={scrollableTarget.current}
                            isLoading={fetchingOrders}
                            loader={range(limit).map((i) => (
                                <div key={i} className={classes.entity}>
                                    <Skeleton variant="rectangular" height={225} />
                                </div>
                            ))}
                        >
                            {
                                items.map((order) => (
                                    <PendingOrderCard
                                        key={order.orderId}
                                        orderId={order.orderId}
                                        deliverables={order.deliverables}
                                        orderName={order.orderName}
                                        cancelledDate={order.cancelledDate}
                                        expectedBy={order.expectedBy}
                                        cancelled={order.cancelled}
                                        cancelledReasons={order.cancelledReasons}
                                        document={order.document}
                                        downloadDisabled={downloadDisabled}
                                        clientId={clientId}
                                    />
                                ))
                            }
                        </InfiniteScroll>
                    </div>
                )
            }
        </div>
    );
}

function PendingOrderCard(props) {
    const {
        orderId, deliverables, orderName, cancelledDate,
        expectedBy, cancelled, cancelledReasons = [], document,
        downloadDisabled, clientId
    } = props;

    const match = useRouteMatch();
    const location = useLocation();
    const { api } = useUser();
    const classes = useStyles();

    return (
        <Card>
            <CardContent>
                <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    className={`${classes.title} ${cancelled === deliverables ? classes.strikethrough : ''}`}
                >
                    {orderName}
                </Typography>
                {
                    cancelled === deliverables ? null : (
                        <Typography variant="body2" color="textSecondary" component="p">
                            <FormattedMessage
                                id="DELIVERABLES"
                                values={{ deliverables }}
                            />
                        </Typography>
                    )
                }
                {
                    !cancelled || cancelled === deliverables ? null : (
                        <Typography variant="body2" color="error">
                            <FormattedMessage
                                id="CANCELLED_DELIVERABLES"
                                values={{ deliverables: cancelled }}
                            />
                        </Typography>
                    )
                }
                {
                    !cancelled || cancelled !== deliverables ? null : (
                        <Typography variant="body2" color="error">
                            <FormattedMessage
                                id="CANCELLED_ON"
                                values={{ date: parseISO(cancelledDate) }}
                            />
                        </Typography>
                    )
                }
                {
                    cancelled === deliverables ? null : (
                        <Typography variant="body2" color="textSecondary" component="p">
                            <FormattedMessage
                                id="EXPECTED_BY"
                                values={{
                                    date: parseISO(expectedBy),
                                }}
                            />
                        </Typography>
                    )
                }
                {
                    !cancelled || cancelled !== deliverables ? null : (
                        <Typography variant="caption" color="textSecondary" component="p">
                            {
                                cancelledReasons.map((reason) => (
                                    !reason ? null : (
                                        <span key={reason} className={classes.reason}>
                                            {reason}
                                        </span>
                                    )
                                ))
                            }
                        </Typography>
                    )
                }
            </CardContent>
            <CardActions>
                <Button
                    underline="none"
                    component={RouteLink}
                    to={{
                        pathname: generatePath(match.path, { client: clientId, order: orderId }),
                        search: location.search,
                    }}
                    size="small"
                    color="primary"
                >
                    <FormattedMessage id="DETAILS" />
                </Button>
                {
                    !document || downloadDisabled ? null : (
                        <Button
                            underline="none"
                            component={Link}
                            size="small"
                            color="primary"
                            href={api.static(`/storage/download/${document}`, null, 'SERVICE')}
                        >
                            <FormattedMessage id="DOWNLOAD" />
                        </Button>
                    )
                }
            </CardActions>
        </Card>
    );
}

VodPendingOrders.propTypes = propTypes;

export default VodPendingOrders;

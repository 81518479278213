import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';

const propTypes = {
    alt: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    fallback: PropTypes.string.isRequired,
    className: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    loading: PropTypes.bool,
};

const defaultProps = {
    className: undefined,
    height: undefined,
    width: undefined,
    loading: false,
};

function FallbackImage(props) {
    const [errored, setErrored] = React.useState(false);
    const {
        alt, source, fallback, className,
        height, width,
        loading, loadingProps={}
    } = props;

    React.useEffect(() => {
        setErrored(false);
    }, [source]); // reset if source prop changes

    function handleError() {
        if (!errored) {
            setErrored(true);
        }
    }

    if (loading) {
        return (
            <Skeleton
                className={className}
                height={height}
                width={width}
                variant="rectangular"
                {...loadingProps}
            />
        );
    }

    return (
        <img
            className={className}
            alt={alt}
            onError={handleError}
            src={errored || !source ? fallback : source}
            height={height}
            width={width}
        />
    );
}

FallbackImage.propTypes = propTypes;
FallbackImage.defaultProps = defaultProps;

export default FallbackImage;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import amber from '@mui/material/colors/amber';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CreateIcon from '@mui/icons-material/Create';
import Tooltip from '@mui/material/Tooltip';


import { useUser } from 'contexts/user';
import Table from 'components/Table';

const useStyles = makeStyles((theme) => createStyles({
    legend: {
        padding: theme.spacing(1, 0),
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    legendItem: {
        display: 'inline-flex',
    },
    legendIcon: {
        marginRight: theme.spacing(1),
    },
    amber: {
        color: amber[500],
    },
    root: {
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
}));

// TODO: fix mapping so its universal!!!!!
const mappedPermissions = {
    distribution: { title: 'DISTRIBUTION', contact: 'distribution' },
    invoices: { title: 'INVOICES', contact: 'invoices' },
    kdm: { title: 'KDM', contact: 'kdm' },
    manageUser: { title: 'MANAGE_USER', contact: 'clientManagement' },
    physicalVault: { title: 'PHYSICAL_VAULT', contact: 'physicalVault' },
    vod: { title: 'VOD', contact: 'vodOrders' },
    dcp: { title: 'DCP', contact: 'dcp' },
};

const propTypes = {
    selectedContacts: PropTypes.arrayOf(PropTypes.number).isRequired,
    contactClient: PropTypes.shape({
        contacts: PropTypes.arrayOf(PropTypes.shape({
            clientManagement: PropTypes.number,
            contact: PropTypes.number,
            distribution: PropTypes.number,
            email: PropTypes.string,
            firstName: PropTypes.string,
            invoices: PropTypes.number,
            kdm: PropTypes.number,
            lastName: PropTypes.string,
            name: PropTypes.string,
            physicalVault: PropTypes.number,
            tableau: PropTypes.number,
            vodOrders: PropTypes.number,
            dcp: PropTypes.number,
        })),
        clientPermissions: PropTypes.shape({
            distribution: PropTypes.number,
            invoices: PropTypes.number,
            kdm: PropTypes.number,
            manageUser: PropTypes.number,
            physicalVault: PropTypes.number,
            tableau: PropTypes.number,
            vod: PropTypes.number,
            dcp: PropTypes.number,
        }),
    }).isRequired,
    handleSelection: PropTypes.func.isRequired,
    currentClient: PropTypes.number.isRequired,
};

const permissionTitleMap = {
    0: 'NO_ACCES',
    1: 'DISPLAY',
    2: 'MANAGEMENT',
};

function ContactList(props) {
    const {
        currentClient, selectedContacts, handleSelection, clientDetails,
        fetchClientDetails, actionRunning, failed, writeManagement,
    } = props;
    const { user } = useUser();
    const classes = useStyles();
    const permissionFields = React.useMemo(() => {
        const fields = {};

        Object.keys(mappedPermissions).map((key) => {
            const { contact } = mappedPermissions[key];
            fields[`permissionsOn${key}`] = (contactPermissions) => {
                const permission = contactPermissions[contact] || 0;
                let Icon = VisibilityOffIcon;

                if (permission === 1) {
                    Icon = VisibilityIcon;
                } else if (permission === 2) {
                    Icon = CreateIcon;
                }

                return (
                    <Tooltip title={<FormattedMessage id={permissionTitleMap[permission]} />}>
                        <Icon
                            className={{ 0: 'MuiCheckbox-root', 1: classes.amber, 2: undefined }[permission]}
                            color={permission === 2 ? 'primary' : undefined}
                        />
                    </Tooltip>
                );
            };
        });
        return fields;
    }, [classes.amber]);

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchClientDetails(abortController);
        return () => {
            abortController.abort();
        };
    }, [currentClient]);

    const { clientPermissions = {}, contacts = [] } = clientDetails;
    console.log("1",clientPermissions)
    const keyValuePermissions = Object.keys(clientPermissions).map((key) => ({ key, value: clientPermissions[key] }));
    const validPermissions = keyValuePermissions.filter((perm) => perm.value > 0 && perm.key in mappedPermissions);
    console.log("2",keyValuePermissions)
    console.log("3",validPermissions)
    return (
        <Paper className={classes.root}>
            <Table
                multiple
                showDivisions={false}
                isSelectable={writeManagement}
                isLoading={actionRunning}
                selected={selectedContacts}
                setSelected={handleSelection}
                uniqueField="contact"
                rows={contacts}
                pagenate={false}
                disabledSelect={(row) => (row.contact === user.contact)}
                disabledSelectMessage="CANNOT_MODIFY_CURRENT_USER"
                additionalActions={(
                    <Grid container spacing={1} className={classes.legend}>
                        <Grid item component={Typography} variant="caption" className={classes.legendItem}>
                            <VisibilityOffIcon fontSize="small" className={classes.legendIcon} />
                            <FormattedMessage id="NO_ACCES" />
                        </Grid>
                        <Grid item component={Typography} variant="caption" className={classes.legendItem}>
                            <VisibilityIcon className={`${classes.legendIcon} ${classes.amber}`} fontSize="small" />
                            <FormattedMessage id="DISPLAY" />
                        </Grid>
                        <Grid item component={Typography} variant="caption" className={classes.legendItem}>
                            <CreateIcon color="primary" className={classes.legendIcon} fontSize="small" />
                            <FormattedMessage id="MANAGEMENT" />
                        </Grid>
                    </Grid>
                )}
                columns={[
                    {
                        field: 'name',
                        title: 'NAME',
                    },
                    {
                        field: 'email',
                        title: 'EMAIL',
                    },
                    ...validPermissions.map(({ key }) => ({
                        field: key,
                        title: mappedPermissions[key].title,
                        align: 'center',
                        Component: permissionFields[`permissionsOn${key}`],
                    })),
                ]}
            />
        </Paper>
    );
}

ContactList.propTypes = propTypes;

export default ContactList;

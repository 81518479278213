import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme) => createStyles({
    disabled: {
        color: theme.palette.action.disabled,
        opacity: theme.palette.action.disabledOpacity,
        pointerEvents: 'none',
    },
}));

const AdapterRouterLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);
const AdapterNavLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

const propTypes = {
    to: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
    className: PropTypes.string,
    classes: PropTypes.shape({}),
    component: PropTypes.node,
    color: PropTypes.string,
    TypographyClasses: PropTypes.string,
    underline: PropTypes.oneOf(['none', 'always', 'hover']),
    variant: PropTypes.string,
    children: PropTypes.node,
    navLink: PropTypes.bool,
    activeClassName: PropTypes.string,
};

const defaultProps = {
    className: undefined,
    classes: undefined,
    component: undefined,
    color: undefined,
    TypographyClasses: undefined,
    underline: undefined,
    variant: undefined,
    children: undefined,
    navLink: false,
    activeClassName: undefined,
};

function RouteLinkHidden(props) {
    const linkClasses = useStyles();
    const {
        to, className, classes,
        component, color, TypographyClasses,
        underline, variant, children, navLink,
        activeClassName, disabled,
        ...componentProps
    } = props;

    return (
        <Link
            component={navLink ? AdapterNavLink : AdapterRouterLink}
            to={to}
            className={`${className} ${disabled ? linkClasses.disabled : ''}`}
            classes={classes}
            color={color}
            TypographyClasses={TypographyClasses}
            underline={underline}
            variant={variant}
            disabled={disabled}
            {...navLink ? { activeClassName } : {}}
            {...componentProps}
        >
            {children}
        </Link>
    );
}

RouteLinkHidden.propTypes = propTypes;
RouteLinkHidden.defaultProps = defaultProps;

const RouteLink = React.forwardRef((props, ref) => <RouteLinkHidden innerRef={ref} {...props} />);

export default RouteLink;

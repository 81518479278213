import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import Paper from '@mui/material/Paper';

import Table from 'components/Table/EnhancedPagedTable';
import Box from '@mui/system/Box';
import Tooltip from '@mui/material/Tooltip';

import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import Brightness1RoundedIcon from '@mui/icons-material/Brightness1Rounded';

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        height: '100%',
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    exportButton: {
        paddingLeft: theme.spacing(2),
    },
    notFound: {
        width: '20vw',
        margin: `${theme.spacing(8)} auto 0`,
    },
    numeric: {
        whiteSpace: 'pre',
        fontVariantNumeric: 'lining-nums tabular-nums',
        fontFamily: "'Roboto Mono', monospace",
        margin: 'auto',
    },
    warning: {
        color: theme.palette.warning.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    primary: {
        color: theme.palette.text.primary,
    },
}));

const gridDate = {
    timeZone: 'UTC',
    year: 'numeric',
    day: '2-digit',
    month: 'long',
};


function fncAvailability({ availability}) {

    let colorClass = null;
    let availableIcon = <Brightness1RoundedIcon />
    let tooltipData =<FormattedMessage id={availability} />
    if (availability === 'NOT_AVAILABLE') {
        colorClass = 'error';
    } else if (availability === 'AVAILABLE') {
        colorClass = 'primary';
        }
    else if (availability === 'ARCHIVED') {
        colorClass = 'text';
        availableIcon = <CloudCircleIcon />
        }

    return (
            <Box sx={(theme)=> ({color: colorClass === null ? undefined : theme.palette[colorClass].main})} >
            <Tooltip title={tooltipData}>
            {availableIcon}
            </Tooltip>
               
            </Box>
        )
}

function Dcps(props) {
    const {
        clientId, failed,
        // Dcps, fetchingDcps,
        isLoading, fetchPage, filters,
        setPage, page, pageRows,
        totalRowCount, order, orderBy,
        rowsPerPage, setRowsPerPage,
        setOrder, setOrderBy,
    } = props;
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();

    const handleRowClick = React.useCallback(({ idDcp }) => {
        history.push({
            pathname: generatePath(match.path, { dcp: idDcp, client: clientId }),
            search: history.location.search,
        });
    }, [match.path, clientId, history.location.search]);


    return (
        <Paper className={classes.paper}>
            <Table
                size="small"
                showDivisions={false}
                isSortable
                isLoading={isLoading}
                columns={[
                    {
                        field: 'availability',
                        title: '',
                        align: 'left',
                        Component: fncAvailability,
                    },
                    {
                        field: 'title',
                        title: 'TITLE',
                        align: 'left',
                    },
                    {
                        field: 'description',
                        title: 'DESCRIPTION',
                        align: 'left',
                    },
                    {
                        field: 'dateProduction',
                        title: 'CREATION_DATE',
                        align: 'right',
                    },
                    {
                        field: 'willBeDeletedDate',
                        title: 'DELETE_DATE',
                        align: 'left',
                    },
                    {
                        field: 'idDcp',
                        title: 'DCP_ID',
                    },
                   
                   

                   
                ]}
                onRowClick={handleRowClick}
                uniqueField="idDcp"
                emptyMessage="NOT_FOUND"
                fetchPage={fetchPage}
                fetchPageDependencies={[clientId, filters]}
                setPage={setPage}
                page={page}
                pageRows={pageRows}
                totalRowCount={totalRowCount}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </Paper>
    );
}


export default Dcps;

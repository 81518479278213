import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl'
import { useConstants } from 'contexts/constants'
import { useIntl } from 'react-intl';
import { useLocale } from 'contexts/locale';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from 'components/Table/EnhancedTable';
import DatePicker from 'components/DatePicker';
import EditIcon from '@mui/icons-material/EditTwoTone';


import AssetDescription from './AssetDescription';

export function validAssetsForReception(assetsDescription, assetList) {
    return [...assetsDescription || [], ...assetList || []].filter((a) => {
            const { assetId, isInVault=0, isReceived=0, expectedDate } = a;
            return (!expectedDate && isReceived === 0) && isInVault !== 1 && isReceived !== 1;
        }).map((a) => {
            const { assetId, isInVault, isReceived, expectedDate } = a;
            const isNew = isInVault === undefined && isReceived === undefined;
            return { assetId: a.id || a.assetIndex, ...a, isNew, receptionDate: null}
        }
    );
}


function AssetExpectedDate(props) {
    const {
        onChange, receptionDates
    } = props;

    const [receptionDate, setReceptionDate] = React.useState(null);

    const intl = useIntl();
    const { locales, vodOptions: { assetTypeOptions, translationTypeOptions, audioTrackTypeOptions } } = useConstants();
    const { language } = useLocale();

    const handleAllChange = React.useCallback(({ target: { value } }) => {
        setReceptionDate(value);
        onChange((currentReceptionDates) => {
            return currentReceptionDates.map((a) => ({...a, receptionDate: value}));
        });

    }, [onChange]);

    const ReceptionDate = ({ assetId, receptionDate: assetReceptionDate, ...other}) => {
        const handleChange = ({ target: { value } }) => {
            onChange((currentReceptionDates) => {
                return currentReceptionDates.map((a) => {
                    if (a.assetId === assetId) {
                        return {...a, receptionDate: value}
                    }
                    return a;
                });
            });
        };

        return (
            <DatePicker
                disableToolbar
                disablePast
                name={`${assetId}-receptionDate`}
                value={assetReceptionDate}
                onChange={handleChange}
                variant="standard"
                components={{ OpenPickerIcon: EditIcon }}
                sx={{'.MuiInput-root::before,.MuiInput-root::after': {'borderBottomColor': 'transparent!important'} }}
            />
        );
    };

    const Description = (a) => {
        let description;

        if (a.isNew) {
            description = AssetDescription(a, intl, language, locales, assetTypeOptions, translationTypeOptions, audioTrackTypeOptions);
        } else {
            description = `${a.parentTitle}: ${a.assetDescription}`
        }


        return description;
    };


    if (isEmpty(receptionDates)) {
        return null;
    }

    if (receptionDates.length === 1) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DatePicker
                        disableToolbar
                        disablePast
                        name="receptionDate"
                        value={receptionDate}
                        onChange={handleAllChange}
                        sx={{'& .MuiInputBase-root': { width: 'calc(100% + 40px)' } }}
                        label={<FormattedMessage id="EXPECTED_SOURCE_RECEPTION" />}
                    />
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography paragraph>
                    <FormattedMessage id="SET_EXPECTED_DATE_ALL" />
                </Typography>
                <DatePicker
                    disableToolbar
                    disablePast
                    name="receptionDate"
                    value={receptionDate}
                    onChange={handleAllChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Table
                    pagenate={false}
                    size="small"
                    uniqueField="assetId"
                    rows={receptionDates}
                    columns={[
                        {
                            field: 'element',
                            title: 'ASSET',
                            Component: Description,
                        },
                        {
                            field: 'receptionDate',
                            title: 'EXPECTED_SOURCE_RECEPTION',
                            Component: ReceptionDate,
                            align: 'right',
                        },
                    ]}
                />
            </Grid>
        </Grid>
    )
}

export default AssetExpectedDate;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import { useSnackbar } from 'notistack';

import { useUser } from 'contexts/user';

const propTypes = {
    userPermission: PropTypes.number.isRequired,
    selectedContacts: PropTypes.arrayOf(PropTypes.number).isRequired,
    currentClient: PropTypes.number.isRequired,
    clientName: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    clientDetails: PropTypes.shape({
        contacts: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    actionRunning: PropTypes.bool.isRequired,
    setActionRunning: PropTypes.func.isRequired,
    fetchClientDetails: PropTypes.func.isRequired,
};

const useStyles = makeStyles((theme) => createStyles({
    // red: {
    //     borderColor: alpha(theme.palette.error.dark, 0.5),
    //     color: theme.palette.error.main,
    //     '&:hover': {
    //         backgroundColor: alpha(theme.palette.error.dark, 0.1),
    //         borderColor: theme.palette.error.main,
    //     },
    // },
    name: {
        '& + &': {
            marginLeft: theme.spacing(1),
        },
    },
}));

function RemoveContact(props) {
    const {
        currentClient, selectedContacts, clientDetails: { contacts = [], clientPermissions = {} },
        userPermission, actionRunning, setActionRunning, fetchClientDetails,
        userName, clientName,
    } = props;
    const classes = useStyles();
    const { api } = useUser();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isOpen, setIsOpen] = React.useState(false);
    const [removingContact, setRemovingContact] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    async function removeContact(abortController = new AbortController()) {
        if (currentClient) {
            const snackId = enqueueSnackbar(<FormattedMessage id="DELETING_CONTACT" />, { variant: 'loading', persist: true });
            setActionRunning(true);
            setRemovingContact(true);
            const response = await api.delete(`/contacts/${currentClient}`, {
                client: currentClient,
                contacts: selectedContacts,
                clientName,
                createdBy: userName,
            }, { signal: abortController.signal });

            if (response.ok) {
                closeModal();
                fetchClientDetails(abortController);
                closeSnackbar(snackId);
                enqueueSnackbar(<FormattedMessage id="CONTACT_DELETED" />, { variant: 'success' });
            } else {
                closeSnackbar(snackId);
                enqueueSnackbar(<FormattedMessage id="ERROR_DELETING_CONTACT" />, { variant: 'error' });
            }
            setActionRunning(false);
            setRemovingContact(false);
        }
    }


    if (userPermission !== 2) {
        return null;
    }

    const selection = React.useMemo(() => (
        contacts.filter(({ contact }) => selectedContacts.includes(contact)) || []
    ), [selectedContacts]);



    let changesFor = '';

    if (selection.length === 1) {
        changesFor = <FormattedMessage id="CONFIRM_REMOVE_SINGLE_CONTACT" />;
    } else if (selection.length >= 1) {
        changesFor = <FormattedMessage id="CONFIRM_REMOVE_CONTACT" />;
    }

    return (
        <>
            <Button
                variant="outlined"
                color="error"
                size="small"
                className={classes.red}
                disabled={!selectedContacts || selectedContacts.length === 0 || actionRunning || removingContact}
                onClick={openModal}
            >
                {
                    removingContact ? (
                        <CircularProgress color="inherit" size="1.54rem" />
                    ) : (
                        <FormattedMessage id="DELETE" />
                    )
                }
            </Button>
            <Dialog
                open={isOpen}
                onClose={closeModal}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <FormattedMessage id="PERMISSIONS" />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { changesFor }
                    </DialogContentText>
                    <DialogContentText>
                        {
                            selection.map(({ name, contact }) => (
                                <Chip key={contact} className={classes.name} label={name} />
                            ))
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={closeModal}
                    >
                        <FormattedMessage id="CANCEL" />
                    </Button>
                    <Button
                        color="primary"
                        onClick={removeContact}
                    >
                        <FormattedMessage id="DELETE" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

RemoveContact.propTypes = propTypes;

export default RemoveContact;

import React from 'react';
import { DateRangePicker as MUIDateRangePicker } from '@mui/x-date-pickers-pro';
import parseISO from 'date-fns/parseISO';
import { FormattedMessage } from 'react-intl';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

function toISODateString(localeDate) {
    if (!(localeDate instanceof Date)) {
        console.error('Must provide a valid date!');
        return null;
    }
    // using ISO 8601 YYYY-MM-DD
    return `${localeDate.getFullYear()}-${(localeDate.getMonth() + 1).toString().padStart(2, "0")}-${localeDate.getDate().toString().padStart(2, "0")}`
}

function DateRangePicker(props) {
    const {
        startLabel, endLabel, startValue, endValue,
        onStartDateChange, onEndDateChange, startName, endName,
        joiningLabel, onChange, maxDateMessage, minDateMessage,
        size, margin, inputVariant, maxDate, minDate, ...others
    } = props;

    const handleChange = React.useCallback(([startDate, endDate]) => {
        const startHandle = onStartDateChange || onChange;
        const endHandle = onEndDateChange || onChange;

        if (startValue !== startDate) {
            startHandle({ target: { name: startName, value: toISODateString(startDate) } });
        }
        if (endValue !== endDate) {
            endHandle({ target: { name: endName, value: toISODateString(endDate) } });
        }
    }, [onChange, onStartDateChange, onEndDateChange]);

    return (
        <MUIDateRangePicker
            value={[startValue ? parseISO(startValue) : startValue, endValue ? parseISO(endValue) : endValue]}
            onChange={handleChange}
            startText={!startLabel ? <FormattedMessage id="START" /> : startLabel}
            endText={!endLabel ? <FormattedMessage id="END" /> : endLabel}
            name={name}
            clearable={others.clearable}
            maxDate={maxDate}
            minDate={minDate}
            clearText={others.clearable ? <FormattedMessage id="REMOVE" /> : undefined}
            cancelText={<FormattedMessage id="CANCEL" />}
            okText={<FormattedMessage id="OK" />}
            maxDateMessage={maxDateMessage}
            minDateMessage={minDateMessage}
            renderInput={(startProps, endProps) => (
                <>
                    <TextField
                        {...startProps}
                        size={size}
                        margin={margin}
                        variant={inputVariant}
                    />
                    <Box sx={{ mx: 1 }} style={{ textTransform: 'lowercase' }}>
                        <FormattedMessage id={joiningLabel ? joiningLabel : 'TO'} />
                    </Box>
                    <TextField
                        {...endProps}
                        size={size}
                        margin={margin}
                        variant={inputVariant}
                    />
                </>
            )}
        />
    );
}

export default DateRangePicker;

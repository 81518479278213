import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import random from 'lodash/random';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useUser } from 'contexts/user';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckboxIconIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FormattedDateOrTime from 'components/FormattedDateOrTime';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ArticleIcon from '@mui/icons-material/Article';
import DownloadButton from 'components/DownloadButton';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        padding: theme.spacing(0.5, 1),
        maxWidth: '35vw',
        minWidth: theme.spacing(10),
        '& $options': {
            opacity: 0,
        },
        '&:hover $options': {
            opacity: 1,
        },
    },
    mergeUp: {
        marginTop: theme.spacing(-1.5),
    },
    'fullWidth-40': {
        flexBasis: '40%',
    },
    'fullWidth-34': {
        flexBasis: '34%',
    },
    'fullWidth-55': {
        flexBasis: '55%',
    },
    'fullWidth-60': {
        flexBasis: '60%',
    },
    'fullWidth-72': {
        flexBasis: '72%',
    },
    externalIdentifier: {
        textTransform: 'uppercase',
    },
    commentText: {
        minHeight: '1rem',
    },
    disabledText: {
        color: theme.palette.text.disabled,
    },
    undoButton: {
        ...theme.typography.caption,
    },
    options: {
        marginLeft: 'auto',
    },
    commentHeader: {
        minWidth: 'fit-content',
    },
    document: {
        marginLeft: 'auto',
    },
}));

function Comment(props) {
    const {
        commentId, commentText, commentBy, createdDate,
        isInternal, jobTitle, clientName, creatorId,
        isDeleted, isEmployee, isLoading: parentLoading, avatar,
        discussionId, refreshComments, mergeUp, mergeDown, isLast,
        isUser, documentId, documentName, attachement
    } = props;

    const { user, api } = useUser();
    const classes = useStyles();
    const commentRef = React.useRef();
    const [optionsMenuAnchor, setOptionsMenuAnchor] = React.useState(null);
    const isLoading = parentLoading;
    const showUserIdentifier = !isLoading && !!user.employee !== !!isEmployee;

    async function updateComment(attributes, abortController = new AbortController()) {
        if (!attributes || isLoading) {
            return;
        }

        const response = await api.put(`/discussion/${discussionId}/comment/${commentId}`, attributes, { signal: abortController.signal });
        if (response.ok) {
            refreshComments(abortController);
        }
    }

    function openOptions(event) {
        setOptionsMenuAnchor(event.currentTarget);
    }

    function closeOptions(event) {
        setOptionsMenuAnchor(null);
    }

    function toggleDeleteComment(event) {
        event.stopPropagation();
        closeOptions();
        updateComment({ isDeleted: !isDeleted });
    }

    function toggleInternalComment(event) {
        if (!user.employee) {
            return;
        }
        event.stopPropagation();
        closeOptions();
        updateComment({ isInternal: !isInternal });
    }

    React.useEffect(() => {
        if (isLast) {
            commentRef.current.scrollIntoView();
        }
    }, []);

    const paperClasses = [
        classes.root,
        (isLoading ? classes[`fullWidth-${[40, 34, 55, 60, 72][random(5)]}`] : null),
        (mergeUp ? classes.mergeUp : null),
    ].join(' ');

    return (
        <Paper
            ref={commentRef}
            elevation={0}
            className={paperClasses}
        >
            {
                mergeUp || isDeleted ? null : (
                    <Grid container spacing={2} alignItems="baseline" className={classes.commentHeader}>
                        {
                            isUser ? null : (
                                <>
                                    <Grid item xs={isLoading ? 4 : undefined}>
                                        <Typography variant="body2" color="textSecondary">
                                            {isLoading ? <Skeleton variant="text" /> : commentBy}
                                        </Typography>
                                    </Grid>
                                    {
                                        !showUserIdentifier ? null : (
                                            <Grid item>
                                                <Typography variant="caption" color="textSecondary" className={classes.externalIdentifier}>
                                                    <FormattedMessage id={user.employee && !isEmployee ? 'CLIENT' : 'MELS'} />
                                                </Typography>
                                            </Grid>
                                        )
                                    }
                                </>
                            )
                        }
                        {
                            !isInternal || isLoading ? null : (
                                <Grid item>
                                    <Typography variant="caption" color="secondary" className={classes.externalIdentifier}>
                                        <FormattedMessage id="INTERNAL" />
                                    </Typography>
                                </Grid>
                            )
                        }
                        <Grid item xs={isLoading ? 2 : undefined}>
                            <Typography variant="caption" color="textSecondary">
                                {
                                    isLoading ? <Skeleton variant="text" /> : (
                                        <FormattedDateOrTime
                                            value={createdDate}
                                            month="numeric"
                                            day="numeric"
                                            year="numeric"
                                            ifRelevantIncludeYear
                                            includeTime
                                        />
                                    )
                                }
                            </Typography>
                        </Grid>
                        {
                            !isUser || isLoading ? null : (
                                <Grid item className={classes.options}>
                                    <IconButton onClick={openOptions} size="small">
                                        <MoreHorizIcon fontSize="inherit" />
                                    </IconButton>
                                    <Menu
                                        anchorEl={optionsMenuAnchor}
                                        keepMounted
                                        open={Boolean(optionsMenuAnchor)}
                                        onClose={closeOptions}
                                    >
                                        {
                                            !user.employee ? null : (
                                                <MenuItem onClick={toggleInternalComment}>
                                                    <ListItemIcon>
                                                        {
                                                            isInternal ? (
                                                                <CheckboxIconIcon fontSize="small" />
                                                            ) : (
                                                                <CheckBoxOutlineBlankIcon fontSize="small" />
                                                            )
                                                        }
                                                    </ListItemIcon>
                                                    <FormattedMessage id="INTERNAL" />
                                                </MenuItem>
                                            )
                                        }

                                        <MenuItem onClick={toggleDeleteComment}>
                                            <ListItemIcon>
                                                <DeleteForeverIcon fontSize="small" />
                                            </ListItemIcon>
                                            <FormattedMessage id="DELETE" />
                                        </MenuItem>
                                    </Menu>
                                </Grid>
                            )
                        }
                    </Grid>
                )
            }
            {
                !isLoading || isDeleted ? null : (
                    <Typography
                        variant="body2"
                        gutterBottom
                    >
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="40%" />
                    </Typography>
                )
            }
            {!isEmpty(attachement) ?
            <div>
                {
                    isLoading || isDeleted ? null : commentText.split('\n').map((t, i, l) => (
                        <Typography variant="body2" className={classes.commentText} gutterBottom={i === l.length - 1 && !mergeDown}>{t}</Typography>
                    ))
                }
                { isLoading || isDeleted ? null :
                    <Grid container spacing={1} >
                        {attachement.map((a) => (
                            <Grid item>
                                <DownloadButton
                                url={`/discussion/${discussionId}/comment/${commentId}/document/${a.documentId}`}
                                edge="end"
                                // fileName={commentText}
                                startIcon={<ArticleIcon />}
                            >
                                {a.documentName}
                            </DownloadButton>
                            </Grid>
                            ))}
                    </Grid>
                }

            </div>

                    :
                isLoading || isDeleted ? null : commentText.split('\n').map((t, i, l) => (
                    <Typography variant="body2" className={classes.commentText} gutterBottom={i === l.length - 1 && !mergeDown}>{t}</Typography>
                ))

            }

            {
                isLoading || !isDeleted ? null : (
                    <Grid container spacing={1} alignItems="baseline">
                        <Grid item>
                            <Typography
                                variant="body2"
                                gutterBottom
                                className={classes.disabledText}
                            >
                                <em><FormattedMessage id="DELETED_COMMENT" /></em>
                            </Typography>
                        </Grid>
                        {
                            !isUser ? null : (
                                <Grid item>
                                    <Button
                                        className={classes.undoButton}
                                        onClick={toggleDeleteComment}
                                        size="small"
                                        disableRipple
                                        disableTouchRipple
                                        disableElevation
                                    >
                                        <FormattedMessage id="UNDO" />
                                    </Button>
                                </Grid>
                            )
                        }
                    </Grid>
                )
            }
        </Paper>
    );
}

Comment.propTypes = {
    commentId: PropTypes.number.isRequired,
    commentText: PropTypes.string.isRequired,
    commentBy: PropTypes.string.isRequired,
    createdDate: PropTypes.string.isRequired,
    isInternal: PropTypes.bool.isRequired,
    jobTitle: PropTypes.string,
    clientName: PropTypes.string,
    creatorId: PropTypes.number.isRequired,
    isDeleted: PropTypes.bool.isRequired,
    isEmployee: PropTypes.bool.isRequired,
    avatar: PropTypes.string,
    discussionId: PropTypes.number.isRequired,
    refreshComments: PropTypes.func.isRequired,
    mergeUp: PropTypes.bool.isRequired,
    isUser: PropTypes.bool.isRequired,
};

Comment.defaultProps = {
    jobTitle: null,
    clientName: null,
    avatar: null,
};

export default Comment;

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonGroup from '@mui/material/ButtonGroup';
import StarIcon from '@mui/icons-material/Star';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { useUser } from 'contexts/user';
import { snakeCase } from 'utils/ofQuery';
import { useConstants } from 'contexts/constants';
import RouteLink from 'components/RouteLink';
import Details from 'components/Distribution/Entities/Details';
import { formatPostData } from 'utils/ofApi';

function EditMetadataDialogLoadSave(props) {
    const {
        open,
        defaultLanguages, displayLanguage, handleLangChange,
        defaultPath,
        handleFetchEntityDetails,
    } = props;

    const { client, id } = useParams();

    const [metadata, setMetadata] = React.useState({});
    const [fetchingMetadata, setFetchingMetadata] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { api } = useUser();

    async function fetchMetadata(abortController = new AbortController()) {
        if (client && id) {
            try {
                setFetchingMetadata(true);
                const response = await api.get(`${client}/entity/${id}`, { detailLevel: 2 }, { signal: abortController.signal });

                if (response.ok) {
                    const data = await response.json();
                    setMetadata(data.metadata);
                } else {
                    setMetadata({});
                }
                setFetchingMetadata(false);
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        if (id) {
            fetchMetadata(abortController);
        }
        return () => {
            abortController.abort();
        };
    }, [client, id]);

    const handleSave = React.useCallback(async (editedMetadata) => {
        const snackId = enqueueSnackbar(<FormattedMessage id="SAVING_TITLE_METADATA" />, { variant: 'loading', persist: true });

        const newMetadata = [];


        Object.keys(editedMetadata).forEach((key) => {
            newMetadata.push({ key: snakeCase(key), value: editedMetadata[key] });
        });

        const response = await api.put(`${client}/entity/${id}`, { metadata: newMetadata });
        if (response.ok) {
            const data = await response.json();

            if (handleFetchEntityDetails) {
                handleFetchEntityDetails(data);
            }
            closeSnackbar(snackId);
            enqueueSnackbar(<FormattedMessage id="TITLE_METADATA_SAVED" />, { variant: 'success' });
        } else {
            closeSnackbar(snackId);
            enqueueSnackbar(<FormattedMessage id="ERROR_SAVING_TITLE_METADATA" />, { variant: 'error' });
        }
    }, [client, id]);

    return (
        <EditMetadataDialog
            defaultPath={defaultPath}
            open={open}
            metadata={metadata}
            handleSave={handleSave}
            fetchingMetadata={(isEmpty(metadata) && fetchingMetadata)}
            defaultLanguages={defaultLanguages}
            displayLanguage={displayLanguage}
            handleLangChange={handleLangChange}
            originalLanguage={metadata.originalLanguage}
        />
    );
}

EditMetadataDialogLoadSave.propTypes = {
    open: PropTypes.bool.isRequired,
    defaultLanguages: PropTypes.arrayOf(PropTypes.string),
    displayLanguage: PropTypes.string.isRequired,
    handleLangChange: PropTypes.func,
    defaultPath: PropTypes.string.isRequired,
    handleFetchEntityDetails: PropTypes.func,
};

EditMetadataDialogLoadSave.defaultProps = {
    defaultLanguages: undefined,
    handleLangChange: null,
    handleFetchEntityDetails: null,
};


function EditMetadataDialog(props) {

    const {
        open, fetchingMetadata, metadata,
        defaultLanguages, displayLanguage, handleLangChange, handleSave,
        defaultPath, originalLanguage,
    } = props;

    const [editedMetadata, setEditedMetadata] = React.useState({});

    React.useEffect(() => {
        setEditedMetadata(metadata);
    }, [metadata]);

    const handleEdit = React.useCallback((name, value) => {
        setEditedMetadata((oldMetadata) => ({
            ...oldMetadata,
            [name]: value,
        }));
    }, []);

    const handleSubmitMetadata = React.useCallback(async () => {
        handleSave(editedMetadata);
    }, [editedMetadata]);

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>
                <MetadataLanguageToggles
                    fetchingMetadata={fetchingMetadata}
                    metadata={metadata}
                    displayLanguage={displayLanguage}
                    handleLangChange={handleLangChange}
                    defaultLanguages={defaultLanguages || ['en', 'fr']}
                    originalLanguage={originalLanguage}
                />
            </DialogTitle>
            <DialogContent>
                <Details
                    editMode
                    metadata={editedMetadata}
                    displayLanguage={displayLanguage}
                    fetchingMetadata={fetchingMetadata}
                    handleInputChange={handleEdit}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    underline="none"
                    component={RouteLink}
                    to={defaultPath}
                >
                    <FormattedMessage id="CANCEL" />
                </Button>
                <Button
                    to={defaultPath}
                    underline="none"
                    component={RouteLink}
                    color="primary"
                    onClick={handleSubmitMetadata}
                >
                    <FormattedMessage id="SAVE" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

EditMetadataDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    fetchingMetadata: PropTypes.bool.isRequired,
    metadata: PropTypes.shape({}).isRequired,
    defaultLanguages: PropTypes.arrayOf(PropTypes.string),
    displayLanguage: PropTypes.string.isRequired,
    handleLangChange: PropTypes.func.isRequired,
    defaultPath: PropTypes.string.isRequired,
    handleSave: PropTypes.func.isRequired,
};

EditMetadataDialog.defaultProps = {
    defaultLanguages: undefined,
};


function ViewMetadataDialog(props) {
    const {
        open,
        fetchingMetadata,
        metadata,
        defaultPath,
        displayLanguage,
        handleLangChange,
    } = props;
    let originalLanguage = null
    if (metadata.originalLanguage) {
        originalLanguage = metadata.originalLanguage.value
    }


    return (
        <Dialog
            open={open}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <MetadataLanguageToggles
                    fetchingMetadata={fetchingMetadata}
                    metadata={metadata}
                    displayLanguage={displayLanguage}
                    handleLangChange={handleLangChange}
                    originalLanguage={originalLanguage}
                />
            </DialogTitle>
            <DialogContent>
                <Details
                    metadata={metadata}
                    displayLanguage={displayLanguage}
                    fetchingMetadata={fetchingMetadata}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    underline="none"
                    component={RouteLink}
                    to={defaultPath}
                >
                    <FormattedMessage id="CLOSE" />
                </Button>
            </DialogActions>
        </Dialog>

    );
}

ViewMetadataDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    fetchingMetadata: PropTypes.bool.isRequired,
    metadata: PropTypes.shape({}).isRequired,
    displayLanguage: PropTypes.string.isRequired,
    handleLangChange: PropTypes.func.isRequired,
    defaultPath: PropTypes.string.isRequired,
};

ViewMetadataDialog.defaultProps = {
};


function MetadataLanguageToggles(props) {
    const { locales, isLoading } = useConstants();

    const {
        metadata, displayLanguage, handleLangChange,
        defaultLanguages = [], fetchingMetadata, originalLanguage,
    } = props;

    const availableLanguageIds = [].concat(defaultLanguages);

    if (!isEmpty(metadata)) {
        Object.keys(metadata).forEach((meta) => {
            if (Array.isArray(metadata[meta])) {
                metadata[meta].forEach((localedMeta) => {
                    if (localedMeta.language && !availableLanguageIds.includes(localedMeta.language)) {
                        availableLanguageIds.push(localedMeta.language);
                    }
                });
            }
        });
    }

    const languageButtons = React.useMemo(() => (
        availableLanguageIds.map((locale) => {
            const foundLocale = locales.find((localeVal) => (localeVal.value === locale));
            const localeLabel = foundLocale ? foundLocale.label : locale;
            return { label: localeLabel, value: locale };
        }).sort((a, b) => {
            if (a.label < b.label) { return -1; }
            if (a.label > b.label) { return 1; }
            return 0;
        }).map(({ label, value }) => (
            <Tab
                key={label}
                icon={value === originalLanguage ? <StarIcon /> : undefined}
                iconPosition="start"
                label={label}
                value={value}
            />
        ))
    ), [availableLanguageIds, originalLanguage]);


    if (isLoading || fetchingMetadata) {
        return (
            <Grid container spacing={1}>
                {
                    [1, 2, 3].map((k) => (
                        <Grid item xs={1} key={k}>
                            <Skeleton variant="rectangular" />
                        </Grid>
                    ))
                }
            </Grid>
        );
    }


    return (

        <Tabs
            value={displayLanguage}
            onChange={handleLangChange}
        >
            { languageButtons }
        </Tabs>
    );
}

MetadataLanguageToggles.propTypes = {
    metadata: PropTypes.shape({}).isRequired,
    displayLanguage: PropTypes.string.isRequired,
    handleLangChange: PropTypes.func.isRequired,
    defaultLanguages: PropTypes.arrayOf(PropTypes.string),
    fetchingMetadata: PropTypes.bool,
};

MetadataLanguageToggles.defaultProps = {
    defaultLanguages: [],
    fetchingMetadata: false,
};

export { EditMetadataDialog, EditMetadataDialogLoadSave, ViewMetadataDialog, MetadataLanguageToggles };

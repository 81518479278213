import React from 'react';
import PropTypes from 'prop-types';
import clsx from  'clsx';
import { matchPath, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import useLocationSearch from 'hooks/useLocationSearch';
import Discussions from 'components/Discussion';
import DiscussionBadge from 'components/Discussion/Badge';
import { VodOrderDefinition, DistributionOrderDefinition } from 'routes/Paths/Definitions';

const propTypes = {
};

const defaultProps = {
};

const useStyles = makeStyles((theme) => createStyles({
    dialogDiscussion: {
        '& > .MuiDialog-container.MuiDialog-scrollPaper > .MuiPaper-root.MuiDialog-paper': {
            height: '100%',
        },
    },
    discussion: {
        height: 'auto',
        width: 'auto',
        flex: '1 1 auto',
        margin: 0,
    },
    button: {
        '& .MuiButton-endIcon.MuiButton-iconSizeMedium > :first-child': {
            fontSize: '0.8125rem',
        },
    },
}));

const invalidPaths = [VodOrderDefinition, DistributionOrderDefinition];

function Followed(props) {

    const { className, children, forwardedRef } = props;

    const { url: matchUrl } = useRouteMatch();

    const match = invalidPaths.map((invalidPath) => matchPath(matchUrl, invalidPath)).find((p) => !!p);
    const canAutoOpen = !match || !match.params.order;

    const [discussionId, setDiscussionId] = useLocationSearch('discussion', null);
    const [isOpen, setIsOpen] = React.useState(!!discussionId && canAutoOpen);

    const classes = useStyles();

    function closeDialog() {
        setDiscussionId(null);
        setIsOpen(false);
    }

    function openDialog() {
        setIsOpen(true);
    }

    React.useEffect(() => {
        if (discussionId && canAutoOpen) {
            setIsOpen(true);
        }
    }, [discussionId]);

    return (
        <>
            <Button
                ref={forwardedRef}
                fullWidth
                onClick={openDialog}
                className={clsx(classes.button, className)}
                endIcon={(
                    <DiscussionBadge />
                )}
            >
                {children || (
                    <ListItemText>
                        <FormattedMessage id="YOUR_DISCUSSIONS" />
                    </ListItemText>
                )}
            </Button>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={isOpen}
                className={classes.dialogDiscussion}
            >
                <Discussions
                    className={classes.discussion}
                    discussionId={discussionId}
                    setDiscussionId={setDiscussionId}
                />
                <DialogActions>
                    <Button
                        // underline="none"
                        // component={RouteLink}
                        // to={{
                        //     pathname: generatePath(match.path, { client }),
                        //     search: history.location.search,
                        // }}
                        onClick={closeDialog}
                        color="primary"
                    >
                        <FormattedMessage id="CLOSE" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

Followed.propTypes = propTypes;
Followed.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <Followed {...props} forwardedRef={ref} />);

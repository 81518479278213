export const VodOrderDefinition = {
    path: '/vod/:client/orders/:order?',
    permissions: { vodOrders: 1 },
};

export const VodPendingOrderDefinition = {
    path: '/vod/:client/pending/:order?',
    permissions: { vodOrders: 1 },
};

export const VodSubmitOrderDefinition = {
    path: '/vod/:client/order',
    permissions: { vodOrders: 2 },
};

export const DistributionEntitiesDefinition = {
    path: '/distribution/:client/entities/',
    permissions: { distribution: 1 },
};

export const DistributionPendingOrderDefinition = {
    path: '/distribution/:client/pending/:order?',
    permissions: { distribution: 1 },
};

export const DistributionEntityDefinition = {
    path: "/distribution/:client/entities/:id/(asset)?/:assetId?/:detailType(qc|order)?/:detailId?",
    permissions: { distribution: 1 },
};

export const DistributionEntityDefinitionAsset = {
    path: "/distribution/:client/entities/:id/asset/:assetId/:detailType(qc|order)?/:detailId?",
    permissions: { distribution: 1 },
};

export const DistributionOrderDefinition = {
    path: '/distribution/:client/orders/:order?',
    permissions: { distribution: 1 },
};

export const DistributionWorkspaceDefinition = {
    path: '/distribution/:client/workspace/',
    permissions: { distribution: 1 },
};

export const DistributionSubmitOrderDefinition = {
    path: '/distribution/:client/order/create/',
    permissions: { distribution: 2 },
};

export const PhysicalVaultDefinition = {
    path: '/physical-vault/:client/:objectId?',
    permissions: { physicalVault: 1 },
};

export const DcpDefinition = {
    path: '/dcp/:client/:dcp?',
    permissions: { dcp: 1 },
};

export const InvoicesDefinition = {
    path: '/invoices/:client/:invoice?',
    permissions: { invoices: 1 },
};

export const ManagementDefinition = {
    path: '/management/:client/',
    permissions: { clientManagement: 1 },
};

export const NotFoundDefinition = {
    path: '/notfound',
};

import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import isEmpty from 'lodash/isEmpty';
import range from 'lodash/range';
import random from 'lodash/random';
import { useParams } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';

import { useUser } from 'contexts/user';
import { useSocketListener } from 'contexts/socket';
import Create from 'components/Discussion/Create';
import Comment from 'components/Discussion/Comment';
import RelationTitle from 'components/Discussion/RelationTitle';
import DiscussionInput from 'components/Discussion/DiscussionInput';
import DiscussionFollowers from 'components/Discussion/Followers';
import MoreVertIcon from '@mui/icons-material/MoreVertTwoTone';
import { formatPostData } from 'utils/ofApi';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturbTwoTone';
import DoneIcon from '@mui/icons-material/DoneTwoTone';
import ErrorIcon from '@mui/icons-material/ErrorTwoTone';
import DeleteForever from '@mui/icons-material/DeleteForeverTwoTone';

const propTypes = {
    discussionId: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
    createDiscussionEnabled: PropTypes.bool.isRequired,
    relationId: PropTypes.number,
    relation: PropTypes.string,
    forceRefreshList: PropTypes.func.isRequired,
    disableCreateDiscussion: PropTypes.func.isRequired,
    setActive: PropTypes.func.isRequired,
};

const defaultProps = {
    discussionId: null,
    relationId: null,
    relation: null,
};

const useStyles = makeStyles((theme) => createStyles({
    comments: {
        padding: theme.spacing(1),
        overflowX: 'hidden',
        overflowY: 'auto',
        margin: theme.spacing(1, 0),
    },
    contained: {
        width: '100%',
    },
    commentContainer: {
        maxWidth: '50vw',
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '100%',
    },

    messageContainer: {
        maxWidth: '50vw',
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        alignContent: 'center',
        padding: theme.spacing(2),
    },

    root: {
    },
    entry: {
        padding: theme.spacing(2),
    },
    header: {
        padding: theme.spacing(0.5, 1),
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
    },
    entryForm: {
        padding: theme.spacing(0.5, 1),
        display: 'flex',
        alignItems: 'center',
    },
    entryFormInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    icon: {
        marginRight: theme.spacing(2),
    },
    leftIcon: {
        marginRight: theme.spacing(2),
    },
    menuButton: {
        margin: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

const defaultComment = {
    commentText: '',
    commentBy: '',
    createdDate: '',
    isInternal: false,
    creatorId: 0,
    isDeleted: false,
    isEmployee: false,
    discussionId: 0,
    refreshComments: false,
    mergeUp: false,
    isUser: false,
};

function sameTime(d1, d2) {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    date1 = date1.valueOf() - (date1.getMilliseconds() + date1.getSeconds() * 1000);
    date2 = date2.valueOf() - (date2.getMilliseconds() + date2.getSeconds() * 1000);

    return date1 === date2;
}

function Discussion(props) {
    const classes = useStyles();
    const { api, isCurrentUser, user } = useUser();
    const {
        discussionId, isLoading: parentLoading, createDiscussionEnabled,
        relationId, relation, forceRefreshList, disableCreateDiscussion,
        setActive, handleDrawerToggle
    } = props;
    const commentRef = React.useRef();

    const [fetchingDiscussion, setFetchingDiscussion] = React.useState(false);
    const [fetchingComments, setFetchingComments] = React.useState(false);
    const [comments, setComments] = React.useState([]);
    const [discussion, setDiscussion] = React.useState({});
    const [failed, setFailed] = React.useState(false);
    const [discussionTitle, setDiscussionTitle] = React.useState('');
    const [selectedFollowers, setSelectedFollowers] = React.useState([]);
    const [newDocuments, setNewDocuments] = React.useState([]);
    const [discussionProblem, setDiscussionProblem] = React.useState(false);
    const [optionsMenuAnchor, setOptionsMenuAnchor] = React.useState(null);
    let displayDiscussionInput = true
    // const [newDocuments, setNewDocuments] = React.useState([]);

    const { client } = useParams();

    const {
        isDeleted, isNew, subject,
        relations,assetRelations,dRelations, followers = [], isFollowed, disucssionType, isAssetOwner, discussionType
    } = discussion;
    const isDiscussionLoading = discussionId && (parentLoading || (isEmpty(discussion) && fetchingDiscussion));
    const isCommentsLoading = discussionId && (parentLoading || (isEmpty(comments) && fetchingComments));
    if (discussionType === 'asset' && !isAssetOwner && !user.employee  ) {
        displayDiscussionInput = false
    }

    const changeDocument = React.useCallback(({ target: { name, value } }, documentId, isNew) => {
        setNewDocuments({[name]: value })
    }, []);

    const handleChange = React.useCallback((event) => {
        changeDocument(event, newDocuments.documentId, newDocuments.isNew);
    }, [newDocuments.documentId, isNew]);

    const handleDocuments = React.useCallback(({ target: { files } }) => {
        setNewDocuments(files);
    }, []);


    const removeNewDocument = React.useCallback((documentIndex) => {
        setNewDocuments((currentNewDocuments) => currentNewDocuments.filter((d, index) => index !== documentIndex));
    },[]);

    const removeDocument = React.useCallback(async (documentId, isNew) => {
        if (isNew) {
            setNewDocuments((currentNewDocuments) => currentNewDocuments.filter((d, index) => index !== documentId));
        } else {
            const response = await api.delete(`/${client}/entity/${discussionId}/document/${documentId}`, {});

            if (response.ok) {
                fetchDocumentTypes();
            }
        }
    }, [client, discussionId]);

    async function addDiscussion(newCommentText, internalComment, callback, abortController = new AbortController()) {
        event.preventDefault();

        try {
            const queryData = {
                relation, relationId, discussionTitle, discussionProblem, followers: selectedFollowers,
                discussionComment: newCommentText, isInternal: user.employee && internalComment,
            };
            const response = await api.post('/discussion/', queryData, { signal: abortController.signal });
            if (callback) {
                callback(response);
            }
            if (response.ok) {

                const { discussionId } = await response.json();
                setSelectedFollowers([]);
                setDiscussionTitle('');
                forceRefreshList();
                if (discussionId) {
                    setActive(discussionId);
                }
                disableCreateDiscussion();
            }
        } catch (error) {
            if (!abortController.signal.aborted) {
                console.error(error);
            }
        }
    }

    async function addFollowers(selectedFollowers, abortController = new AbortController()) {
        if (!discussionId) {
            return;
        }

        return api.post(`discussion/${discussionId}/followers`, { followers: selectedFollowers }, { signal: abortController.signal });
    }


    async function fetchComments(abortController = new AbortController()) {
        if (!discussionId) {
            return;
        }

        try {
            setFetchingComments(true);
            setFailed(false);
            const response = await api.get(`/discussion/${discussionId}/comment`, null, { signal: abortController.signal });
            if (response.ok) {
                const data = await response.json();
                setComments(data);
            } else {
                setFailed(true);
            }
        } catch (error) {
            console.log(error)
            if (!abortController.signal.aborted) {
                console.error(error);
            }
        }
        setFetchingComments(false);
    }

    async function fetchDiscussion(abortController = new AbortController()) {
        if (!discussionId) {
            return;
        }

        try {
            setFetchingDiscussion(true);
            setFetchingComments(true);
            setFailed(false);
            const response = await api.get(`/discussion/${discussionId}`, {clientId:client}, { signal: abortController.signal });
            if (response.ok) {
                const data = await response.json();
                const { comments: c, ...d } = data;
                setDiscussion(d);
                setComments(c);
                commentRef.current.focus();
            } else {
                setFailed(true);
            }
        } catch (error) {
            if (!abortController.signal.aborted) {
                console.error(error);
            }
        }
        setFetchingDiscussion(false);
        setFetchingComments(false);
    }

    async function addComment(newCommentText, internalComment, callback, abortController = new AbortController()) {
        let documentName = null
        let files = null
        if (newDocuments[0]) {
            documentName = newDocuments[0].name
            files = newDocuments
        }

        if (newCommentText.replace(/\s/gi, '') === '' && isEmpty(newDocuments)) {
            return;
        }

        const response = await api.post(`/discussion/${discussionId}/comment`,  formatPostData({documentName, discussionId, commentText: newCommentText, isInternal: user.employee && internalComment}, files), { signal: abortController.signal });

        if (callback) {
            callback(response);
        }

        if (response.ok) {
            if (isNew) {
                fetchDiscussion(abortController);
            } else {
                fetchComments(abortController);
            }
            setNewDocuments([])
            forceRefreshList();
        }
    }


    async function readComments(commentIds = [], abortController = new AbortController()) {
        if (!commentIds || isEmpty(commentIds) || !discussionId) {
            return;
        }

        const response = await api.post(`/discussion/${discussionId}/log`, { comments: commentIds }, { signal: abortController.signal });
        if (response.ok) {
            if (isNew) {
                forceRefreshList();
                fetchDiscussion(abortController);
            }
        }
    }

    async function updateDiscussion(attributes, abortController = new AbortController()) {
        const response = await api.put(`/discussion/${discussionId}`, attributes, { signal: abortController.signal });
        if (response.ok) {
            fetchDiscussion(abortController);
        }
    }

    function toggleDeleteDiscussion() {
        event.stopPropagation();
        closeOptions();
        updateDiscussion({ isDeleted: !isDeleted });
        forceRefreshList();
        fetchDiscussion();
    }

    function toggleIgnoreDiscussion(event) {
        event.stopPropagation();
        closeOptions();
        updateDiscussion({ problemStatusId: 'IGNORED' });
        fetchDiscussion();
        forceRefreshList();
    }

    function toggleSolveDisucussion(event) {
        event.stopPropagation();
        closeOptions();
        updateDiscussion({ problemStatusId: 'SOLVED' });
        fetchDiscussion();
        forceRefreshList();
    }

    function toggleReportDisucussion(event) {
        event.stopPropagation();
        updateDiscussion({ problemStatusId: 'REPORTED' });
        fetchDiscussion();
        forceRefreshList();
    }

    const handleEvent = React.useCallback((event) => {
        const data = JSON.parse(event.data);
        if (data.id && data.id === parseInt(discussionId, 10)) {
            fetchComments();
        }
    }, [discussionId]);

    useSocketListener("discussion", handleEvent);

    React.useEffect(() => {
        const abortController = new AbortController();
        fetchDiscussion(abortController);
        return () => {
            abortController.abort();
        };
    }, [discussionId]);

    function openOptions(event) {
        setOptionsMenuAnchor(event.currentTarget);
    }

    function closeOptions(event) {
        setOptionsMenuAnchor(null);
    }






    React.useEffect(() => {
        if (!createDiscussionEnabled && commentRef.current) {
            commentRef.current.focus();
        }
    }, [createDiscussionEnabled]);

    // will be changed...
    React.useEffect(() => {
        const commentIds = comments.filter((c) => !!c.isNew).map((c) => c.commentId);
        readComments(commentIds);
    }, [comments.some((c) => !!c.isNew)]);

    function commentMap(comment, i, l) {
        const prev = i > 0 ? l[i - 1] : {};
        const next = i + 1 < l.length ? l[i + 1] : {};

        const mergeUp = (
            !isCommentsLoading
            && prev.commentBy === comment.commentBy
            && prev.isEmployee === comment.isEmployee
            && sameTime(prev.createdDate, comment.createdDate)
        );

        const mergeDown = (
            !isCommentsLoading
            && comment.commentBy === next.commentBy
            && comment.isEmployee === next.isEmployee
            && sameTime(comment.createdDate, next.createdDate)
        );
        const isUser = isCommentsLoading ? true : isCurrentUser(comment.creatorId);
        const key = isCommentsLoading ? `${random(265)}-${random(832)}` : `${discussionId}-${comment.commentId}`;
        return (
            <Grid
                key={key}
                container
                item
                xs="auto"
                justifyContent={isUser ? 'flex-end' : 'flex-start'}
                className={classes.commentContainer}
            >
                <Comment
                    discussionId={discussionId}
                    commentId={comment.commentId}
                    commentText={comment.commentText}
                    commentBy={comment.commentBy}
                    createdDate={comment.createdDate}
                    isInternal={comment.isInternal}
                    jobTitle={comment.jobTitle}
                    clientName={comment.clientName}
                    creatorId={comment.creatorId}
                    isDeleted={comment.isDeleted}
                    isEmployee={comment.isEmployee}
                    documentId={comment.documentId}
                    documentName={comment.documentName}
                    attachement={comment.attachement}
                    refreshComments={fetchComments}
                    isLoading={isCommentsLoading}
                    mergeUp={mergeUp}
                    mergeDown={mergeDown}
                    isLast={i === l.length - 1}
                    isUser={isUser}
                />
            </Grid>
        );
    }



    return (
        <Grid container direction="column" className={classes.root}>

            <Grid item>
                {
                    !createDiscussionEnabled ? null : (
                        <Create
                            discussionTitle={discussionTitle}
                            setDiscussionTitle={setDiscussionTitle}
                            selectedFollowers={selectedFollowers}
                            setSelectedFollowers={setSelectedFollowers}
                            relationId={relationId}
                            relation={relation}
                            forceRefreshList={forceRefreshList}
                            disableCreateDiscussion={disableCreateDiscussion}
                            setActive={setActive}
                            discussionProblem={discussionProblem}
                            setDiscussionProblem={setDiscussionProblem}
                            disucssionType
                        />
                    )
                }
                {
                    createDiscussionEnabled || !subject ? null : (
                        <Paper className={classes.header} component={Grid} container justifyContent="space-between" alignItems="center">
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                className={classes.menuButton}
                                size="large">
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="body1" color="textPrimary" component={Grid} item xs="auto">
                                {subject}
                            </Typography>
                            <Grid item xs="auto">
                                {
                                    relationId || relation ? null : (
                                        <RelationTitle relations={dRelations} assetRelations={assetRelations} setDiscussionId={setActive} />
                                    )
                                }
                                {
                                    relation === 'order_item' ? 
                                    <RelationTitle relations={dRelations} assetRelations={assetRelations} setDiscussionId={setActive} relation={relation} />
                                   :   null
                                }
                                <DiscussionFollowers
                                    followers={followers}
                                    addFollowers={addFollowers}
                                    discussionId={discussionId}
                                    isFollowed={isFollowed}
                                    refresh={fetchDiscussion}
                                    forceRefreshList={forceRefreshList}
                                />
                                {
                                    !user.employee ? null : (
                                        <>
                                            <IconButton onClick={openOptions} size="small">
                                                <MoreVertIcon fontSize="inherit" />
                                            </IconButton>
                                            <Menu
                                                anchorEl={optionsMenuAnchor}
                                                keepMounted
                                                open={Boolean(optionsMenuAnchor)}
                                                onClose={closeOptions}
                                            >
                                                {
                                                    discussion.problemStatusId != 'REPORTED' ? (
                                                        <MenuItem onClick={toggleReportDisucussion}>
                                                            <ListItemIcon>
                                                                <ErrorIcon />
                                                            </ListItemIcon>
                                                            <FormattedMessage id="REPORT_PROBLEM" />
                                                        </MenuItem>
                                                    ) : (
                                                        <>
                                                            <MenuItem onClick={toggleSolveDisucussion}>
                                                                <ListItemIcon>
                                                                    <DoneIcon />
                                                                </ListItemIcon>
                                                                <FormattedMessage id="MARK_RESOLVED" />
                                                            </MenuItem>
                                                            <MenuItem onClick={toggleIgnoreDiscussion}>
                                                                <ListItemIcon>
                                                                    <DoNotDisturbIcon />
                                                                </ListItemIcon>
                                                                <FormattedMessage id="IGNORE_PROBLEM" />
                                                            </MenuItem>

                                                        </>
                                                    )
                                                }

                                                <Divider />
                                                <MenuItem onClick={toggleDeleteDiscussion}>
                                                    <ListItemIcon>
                                                        <DeleteForever />
                                                    </ListItemIcon>
                                                    <FormattedMessage id="DELETE_DISCUSSION" />
                                                </MenuItem>
                                            </Menu>
                                        </>
                                    )}
                                </Grid>
                        </Paper>
                    )
                }
            </Grid>
            <Grid item xs container spacing={2} alignContent="flex-start" className={[classes.comments, classes.contained].join(' ')}>
                {
                    createDiscussionEnabled ? null : (
                        (isCommentsLoading ? range(3).map((k) => ({ ...defaultComment, commentId: k })) : comments).map(commentMap)
                    )
                }
            </Grid>
            <Grid container spacing={1} item direction="column" className={displayDiscussionInput ? [classes.entry, classes.commentContainer].join(' '):classes.messageContainer }>
                <Grid item xs>
                    {displayDiscussionInput ?
                    <DiscussionInput
                        disabled={createDiscussionEnabled ? !discussionTitle || isEmpty(selectedFollowers) : false}
                        classes={classes}
                        addComment={createDiscussionEnabled ? addDiscussion : addComment}
                        commentRef={commentRef}
                        discussionId={createDiscussionEnabled ? 'new' : discussionId}
                        isDiscussionLoading={isDiscussionLoading}
                        newDocuments={newDocuments}
                        handleDocuments={handleDocuments}
                        handleChange={handleChange}
                        removeDocument={removeNewDocument}
                        updateDiscussion={updateDiscussion}
                        discussion={discussion}
                        forceRefreshList={forceRefreshList}
                    />
                    :
                    <Grid item xs container spacing={2} alignContent="center" className={classes.messageContainer}>
                        <FormattedMessage id="ONLY_ASSET_OWNER" />
                    </Grid>
                }   
                </Grid>
            </Grid>
        </Grid>
    );
}

Discussion.propTypes = propTypes;

Discussion.defaultProps = defaultProps;

export default Discussion;

import Auth from 'utils/Auth';
import PropTypes from 'prop-types';

const auth = new Auth({
    domain: 'mels-auth.auth0.com',
    clientID: 'TdGWqx5mEWnOq0v3WuBlaZMs87VdtvAZ',
    redirectUri: `${window.location.origin}/callback`,
    allowSignUp: false,
    audience: 'https://mels-auth.auth0.com/userinfo',
    responseType: 'token id_token',
    scope: 'openid email profile app_metadata user_metadata aggregator_uid',
    device: 'Aggregator app',
});

const clientDefinition = {
    client: PropTypes.number.isRequired,
    clientManagement: PropTypes.number.isRequired,
    default: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    invoices: PropTypes.number.isRequired,
    vodOrders: PropTypes.number.isRequired,
    physicalVault: PropTypes.number.isRequired,
}

const userDefinition = {
    uid: PropTypes.string,
    name: PropTypes.string,
    lastName: PropTypes.string,
    language: PropTypes.string,
    firstName: PropTypes.string,
    email: PropTypes.string,
    contact: PropTypes.number,
    employee: PropTypes.number,
    clients: PropTypes.arrayOf(PropTypes.shape(clientDefinition)),
};

export { auth, userDefinition, clientDefinition };

import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import uniqueId from 'lodash/uniqueId';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Helmet } from 'react-helmet';

import { formatPostData } from 'utils/ofApi';
import Table from 'components/Table';
import ErrorDisplay from 'components/ErrorDisplay';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useUser } from 'contexts/user';
import { useSnackbar } from 'notistack';
import DatePicker from 'components/DatePicker';
import FileInput from 'components/FileInput';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    modal: {
        maxHeight: '100%',
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        display: 'none',
    },
    errorMessages: {
        color: theme.palette.error.main,
    },
    errorMessage: {
        '& > svg': {
            width: '20vw',
        },
        width: '50vw',
        margin: `${theme.spacing(8)} auto 0`,
        textAlign: 'center',
    },
    bottom: {
        marginTop: theme.spacing(2),
    },
}));

const propTypes = {
    currentClient: PropTypes.number,
};

const defaultProps = {
    currentClient: 0,

};

function VodSubmitOrder(props) {
    const classes = useStyles();
    const { api, user: { name: userName } } = useUser();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [creatingOrder, setCreatingOrder] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [parsedFileTitles, setParsedFileTitles] = React.useState([]);
    const [file, setFile] = React.useState([]);
    const [loadingFile, setLoadingFile] = React.useState();
    const [error, setError] = React.useState(null);
    const [fileInputId] = React.useState(uniqueId('file-input-'));
    const { currentClient } = props;
    const intl = useIntl();
    const [FormInputs, setFormInputs] = React.useState({
        order: null,
        orderName: '',
        po: '',
        note: '',
        errorSubmitting: false,
        vodFile: null,
    });

    const {
        orderName, po, note,
        errorSubmitting,
    } = FormInputs;

    function handleInputChange({ target: { name, value } }) {
        setFormInputs({ ...FormInputs, [name]: value });
    }

    function resetState() {
        setFormInputs({
            order: null,
            orderName: '',
            po: '',
            note: '',
            errorSubmitting: false,
            vodFile: null,
        });
        setParsedFileTitles([]);
    }

    function changeDate(rowId, value) {
        const newUploadedFile = cloneDeep(parsedFileTitles);
        newUploadedFile[rowId].deliveryDate = value;
        setParsedFileTitles(newUploadedFile);
    }

    function deliveryDate({ rowId, suggestedDeliveryDate, deliveryDate }, index) {
        return (
            <DatePicker
                disableToolbar
                margin="dense"
                size="small"
                name="date"
                format="yyyy-MM-dd"
                value={deliveryDate || suggestedDeliveryDate}
                onChange={({ target: { value }}) => changeDate(rowId, value)}
                disablePast
            />
        );
    }

    async function parseFile(files, dateFormat) {
        if (currentClient) {
            setLoadingFile(true);
            setFailed(false);
            setError('');
            const response = await api.post(`/vod/orders/${currentClient}/file`, formatPostData({ date_format: dateFormat }, files), { });
            if (response.ok) {
                const data = await response.json();
                setParsedFileTitles(data);
            } else {
                const data = await response.text();
                setParsedFileTitles([]);
                setError(data);
                setFailed(true);
            }
            setLoadingFile(false);
        }
    }

    async function createVodOrder(userName, uploadedFile, abortController = new AbortController()) {
        setCreatingOrder(true);
        setFailed(false);
        const newUploadedFile = parsedFileTitles.map(({rowId, ...others}) => ({...others}));

        const response = await api.post(`/vod/orders/${currentClient}`, formatPostData({ createdBy: userName, ...FormInputs, titles: newUploadedFile }, uploadedFile), { signal: abortController.signal });
        if (response.ok) {
            // closeSnackbar(snackId);
            enqueueSnackbar(<FormattedMessage id="ORDER_CREATED" />, { variant: 'success' });
            setParsedFileTitles([]);
        } else {
            // closeSnackbar(snackId);
            enqueueSnackbar(<FormattedMessage id="ERROR_CREATING_ORDER" />, { variant: 'error' });
            setFailed(true);
        }
        setCreatingOrder(false);
    }

//     async function uploadDocument(userName, uploadedFile) {
//         if (uploadedFile) {
//             const snackId = enqueueSnackbar(<FormattedMessage id="CREATING_ORDER" />, { variant: 'loading', persist: true });
//             setCreatingOrder(true);
//             setFailed(false);
//             const response = await api.post('/storage', formatPostData({}, uploadedFile), { scope: 'SERVICE' });
//             let uploadFailled = false;
//             if (response.ok) {
//                 const data = await response.json();
//                 if (data.id) {
//                     const fileId = data.id;
//                     createVodOrder(userName, fileId, snackId);
//                 } else {
//                     uploadFailled = true;
//                 }
//             } else {
//                 uploadFailled = true;
//             }
//             if (uploadFailled) {
//                 closeSnackbar(snackId);
//                 enqueueSnackbar(<FormattedMessage id="ERROR_CREATING_ORDER" />, { variant: 'error' });
//                 setFailed(true);
//
//             }
//             setCreatingOrder(false);
//         }
//     }

    function uploadFileWithFormat({ currentTarget: { value } }) {
        parseFile(file, value);
    }

    function setFileNull() {
        setFile([])
    }

    function handleFileInputChange({ target }) {
        const { files } = target;

        setFile(files);
        const fileName = files[0].name;

        setFormInputs({ ...FormInputs, vodFile: files, orderName: orderName || fileName.replace(/\.[^/.]+$/, '') });
        parseFile(files, '');

    }

    function handleSubmit() {
        if (parsedFileTitles && orderName) {
            createVodOrder(userName, file);
            resetState();
        }
    }

    return (
        <div className={classes.root}>
            <Helmet>
                <title>
                    {`Melodie - ${intl.formatMessage({ id: 'ADD_ORDER' })}`}
                </title>
            </Helmet>
            <Grid container wrap="wrap" spacing={1}>
                <Grid item xs={12} sm={8}>
                    <TextField
                        label={<FormattedMessage id="ORDER_TITLE" />}
                        value={orderName}
                        name="orderName"
                        margin="dense"
                        size="small"
                        fullWidth
                        variant="outlined"
                        onChange={handleInputChange}
                        error={errorSubmitting && !orderName}
                        helperText={errorSubmitting && !orderName ? <FormattedMessage id="INCLUDE_ORDER_TITLE_ERROR" /> : ''}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label={<FormattedMessage id="PO_NUMBER" />}
                        value={po}
                        name="po"
                        margin="dense"
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={<FormattedMessage id="NOTES" />}
                        value={note}
                        name="note"
                        margin="dense"
                        size="small"
                        variant="outlined"
                        fullWidth
                        multiline
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>
            {
                !error || !['CONFUSEDDAY', 'CONFUSEDYEAR'].includes(error) ? null : (
                    <Grid container>
                        <Grid item xs={12}>
                            <ErrorDisplay
                                message={error}
                                messageProps={{ defaultMessage: intl.formatMessage({ id: error }) }}
                                className={classes.errorMessage}
                            />
                        </Grid>
                        <Grid item container justifyContent="center">
                            <ButtonGroup>
                                <Button
                                    variant="outlined"
                                    value={{ CONFUSEDDAY: 'MM/DD/YYYY', CONFUSEDYEAR: 'YYYY/MM/DD' }[error]}
                                    onClick={uploadFileWithFormat}
                                >
                                    {{ CONFUSEDDAY: 'MM/DD/YYYY', CONFUSEDYEAR: 'YYYY/MM/DD' }[error]}
                                </Button>
                                <Button
                                    variant="outlined"
                                    value={{ CONFUSEDDAY: 'DD/MM/YYYY', CONFUSEDYEAR: 'YYYY/DD/MM' }[error]}
                                    onClick={uploadFileWithFormat}
                                >
                                    {{ CONFUSEDDAY: 'DD/MM/YYYY', CONFUSEDYEAR: 'YYYY/DD/MM' }[error]}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                )
            }
            {
                !error || ['CONFUSEDDAY', 'CONFUSEDYEAR'].includes(error) ? null : (
                    <Grid container>
                        <Grid item xs={12}>
                            <ErrorDisplay
                                message={error}
                                messageProps={{ defaultMessage: intl.formatMessage({ id: error }) }}
                                className={classes.errorMessage}
                            />
                        </Grid>
                    </Grid>
                )
            }
            {
                (isEmpty(parsedFileTitles) && !loadingFile) || error ? null : (

                    <Paper className={classes.modal}>
                        <Table
                            columns={[
                                {
                                    field: 'title',
                                    title: 'TITLE',
                                },
                                {
                                    field: 'provider',
                                    title: 'OWNER',
                                },
                                {
                                    field: 'languages',
                                    title: 'LANGUAGE',
                                },
                                {
                                    field: 'trailerNeededFromLabo',
                                    title: 'TRAILER',
                                },
                                {
                                    field: 'seasonNumber',
                                    title: 'SEASON',
                                },
                                {
                                    field: 'episodeNumber',
                                    title: 'EPISODE',
                                },
                                {
                                    field: 'resolution',
                                    title: 'RESOLUTION',
                                },
                                {
                                    field: 'suggestedDeliveryDate',
                                    title: 'ESTIMATED_DELIVERY_DATE',
                                    Component: deliveryDate,
                                },
                            ]}
                            uniqueField="rowId"
                            rows={parsedFileTitles}
                            isSortable
                            isLoading={loadingFile}
                        />
                    </Paper>
                )
            }
            {
                !errorSubmitting || (orderName && !isEmpty(parsedFileTitles)) ? null : (
                    <Grid container xs={12}>
                        <Grid item>
                            <ul className={classes.errorMessages}>
                                <li>
                                    <Typography paragraph>
                                        <FormattedMessage id={!orderName ? 'INCLUDE_ORDER_TITLE_ERROR' : 'INCLUDE_CSV__XLSX_ERROR'} />
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                )
            }
            <Grid container spacing={1} justifyContent="space-between" className={classes.bottom}>
                <Grid item>
                    <FileInput
                        fileList={file}
                        variant="outlined"
                        accept=".csv,.xlsx,.xls"
                        className={classes.button}
                        onChange={handleFileInputChange}
                        // onClick={setFileNull}
                        label={<FormattedMessage id={isEmpty(parsedFileTitles) ? 'SELECT_ORDER' : 'CHANGE_FILE'} />}
                        showFileName={false}
                        multiple={true}
                    />
                </Grid>
                <Grid item>
                    <ButtonGroup>
                        {/* <Button */}
                        {/*     variant="outlined" */}
                        {/*     onClick={resetState} */}
                        {/*     disabled={!(isEmpty(parsedFileTitles) || error || !orderName)} */}
                        {/* > */}
                        {/*     <FormattedMessage id="CLEAR" /> */}
                        {/* </Button> */}
                        <Button
                            variant="outlined"
                            type="submit"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={isEmpty(parsedFileTitles) || error}
                        >
                            <FormattedMessage id="SUBMIT" />
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </div>
    );
}


VodSubmitOrder.propTypes = propTypes;
VodSubmitOrder.defaultProps = defaultProps;

export default VodSubmitOrder;

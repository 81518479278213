import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import { FormattedMessage, FormattedDate } from 'react-intl';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Orders from 'components/Distribution/Assets/OrderList';
import useLocationSearch from 'hooks/useLocationSearch';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import DiscussionList from 'components/Discussion/List';
import Discussion from 'components/Discussion';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import { useUser } from 'contexts/user';
import Detail from 'components/Detail';

const detailsTab = 'DETAILS';
const orderTab = 'ORDER';
const discussionsTab = 'DISCUSSIONS';
const addDiscussionsTab = 'ADD_DISCUSSIONS';
const defaultTab = detailsTab;

function TabPanel(props) {
    const { children, currentValue, value } = props;

    return value === currentValue && children;
}

const useStyles = makeStyles((theme) => createStyles({
    mainDiv: {
        padding: theme.spacing(2),
    },
    audioDiv: {
        padding: theme.spacing(2),
    },
    chips: {
        '& + &': {
            marginLeft: theme.spacing(1),
        },
    },
    technical: {
        marginTop: theme.spacing(3),
    },
    lang: {
        marginTop: theme.spacing(1),
    },
    disucssions: {
        flexGrow: 1,
        maxWidth: '100%',
        flexBasis: 0,
        overflowX: 'initial',
        overflowY: 'initial',
    },
    discussion: {
        height: 'auto',
        width: 'auto',
        flex: '1 1 auto',
        margin: 0,
    },
    tabPanel: {
        flexGrow: 1,
        maxWidth: '100%',
        flexBasis: 0,
        overflowX: 'auto',
        overflowY: 'auto',
    },
    dialogDiscussion: {
        '& > .MuiDialog-container.MuiDialog-scrollPaper > .MuiPaper-root.MuiDialog-paper': {
            height: '100%',
        },
    },

}));

const assetDetailsOrder = [
    'assetType', 'masterCode', 'definition', 'dynamicRange', 'lang', 'name', 'translationType', 'tranlationLocal', 'format',
    'duration', 'cc', 'is_3d', 'fileSizes', 'aspectRatio', 'codec', 'fps', 'importDate',
    'startTimeCode', 'endTimeCode', 'audioFormat', 'extension', 'externalNote',
];

const assetChipsOrder = [
    'assetType', 'lang', 'translationType', 'tranlationLocal', 'definition',
    'imageRatio', 'dynamicRange', 'is_3d', 'frameRate', 'duration', 'commercialBreaks',
    'hasTextless', 'runtime',
];

const tempAsset = { asset: {}, audio: {} };

function AssetDetails(props) {
    const { api } = useUser();
    const { assetId, client } = useParams();
    const classes = useStyles();
    const [assetDetails, setAssetDetails] = React.useState(tempAsset);
    const [fetchingAsset, setFetchingAsset] = React.useState(false);
    const [page, setPage] = useLocationSearch('page', 0);
    const [rowsPerPage, setRowsPerPage] = useLocationSearch('rowsPerPage', 50);
    const [order, setOrder] = useLocationSearch('order', 'asc');
    const [orderBy, setOrderBy] = useLocationSearch('orderBy', '');
    const [selectedTab, setSelectedTab] = React.useState(defaultTab);
    const [pages, setPages] = React.useState({});
    const [discussionId, setDiscussionId] = useLocationSearch('discussion', null);
    const [isOpen, setIsOpen] = React.useState(false);
    const [refreshDiscussion, setRefreshDiscussion] = React.useState(false);
    const [createDiscussionEnabled, setCreateDiscussion] = React.useState(false);
    // const [refreshList, setRefreshList] = React.useState(false);
    const { showInVaultOnly } = props;
    const { asset, audio } = assetDetails;
    
    let orders = []
    let discussions = []
    if (assetDetails) {
        orders = assetDetails.asset.orders
        discussions = assetDetails.asset.discussions
    }
    const showBasic = !!asset.masterCode || !!asset.fileSizes || !!asset.importDate || !!asset.externalNote;
    const showLanguage = !asset.hasQc && (!!asset.localeMainTitle || !!asset.localeSubtitles || !!asset.localeOpeningCredits || !!asset.localeEndCredits);

    const showTechnical = !!asset.codec || !!asset.fps || !!asset.format || !!asset.size || !!asset.aspectRatio || (!!asset.startTimeCode && !!asset.endTimeCode);
    const showAudio = !isEmpty(audio) || !!asset.audioFormat;
    function closeDialog() {
        setDiscussionId(null);
        setIsOpen(false);
        setSelectedTab(discussionsTab)
        setCreateDiscussion(false)
        // fetchAsset();
        setRefreshDiscussion(true);
    }

    function cancelRefreshList() {
        setRefreshDiscussion(false)
    }

    function openDialog() {
        setCreateDiscussion(true)
        setIsOpen(true);
    }
    const handlePageChange = React.useCallback((newPage) => {
        setPage(newPage);
    }, [pages]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function enableCreateDiscussion() {
        setCreateDiscussion(true);
    }
    async function fetchAsset(abortController = new AbortController()) {
        if (client && assetId) {
            try {
                setFetchingAsset(true);
                const response = await api.get(`${client}/asset/${assetId}`, null, { signal: abortController.signal, artificialDelay: 100 });
                if (response.ok) {
                    const data = await response.json();
                    setAssetDetails(data);
                } else {
                    setAssetDetails({});
                }
            } catch (error) {
                if (!abortController.signal.aborted) {
                    console.error(error);
                }
            }
            setFetchingAsset(false);
        }
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        setSelectedTab(defaultTab)
        fetchAsset(abortController);
        return () => {
            abortController.abort();
        };
    }, [assetId, client]);

    const assetChips = assetChipsOrder.reduce((memo, key) => {
        if (asset[key]) {
            let value = asset[key].toString();
            if (key === 'is_3d') {
                value = <FormattedMessage id="3D" />;
            } else if (key === 'commercialBreaks') {
                value = <FormattedMessage id="COMMERCIAL_BREAKS" />;
            } else if (key === 'hasTextless') {
                value = <FormattedMessage id="HAS_TEXTLESS" />;
            } else if (key === 'duration') {
                const [hr, min, sec, frames] = value.split(/[:;]/);
                const totalMin = parseInt(min, 10) + (parseInt(sec, 10) / 60) + (parseInt(hr, 10) * 60);
                value = `${Math.ceil(totalMin)} MIN`;
            }

            memo.push({ key, value });
        }
        return memo;
    }, []);

    const showAsset = !showInVaultOnly || ['ARCHIVED', 'PENDING_QC', 'PENDING_CLIENT', 'APPROVED'].includes(asset.vaultStatusId);
    
    const showSkeleton = assetDetails === tempAsset || fetchingAsset;
    return !showAsset ? null : (
      
        <div>
            {/* <AppBar position="static"> */}
            <Tabs indicatorColor="primary" textColor="inherit" value={selectedTab} onChange={handleTabChange}>
                <Tab label={<FormattedMessage id="DETAILS" />} value={detailsTab} />
                    {
                        !orders || orders.length < 1 ? null : (
                            <Tab label={<FormattedMessage id="ORDERED_ASSET" />} value={orderTab} />
                        )
                    }
                    {
                        isEmpty(discussions) ?  
                        null
                     : (
                        <Tab label={<FormattedMessage id="DISCUSSIONS" />} value={discussionsTab} />
                        )
                    }
                    <Tab onClick={openDialog} label={<FormattedMessage id="ADD_DISCUSSIONS" />} value={addDiscussionsTab}  />
            </Tabs>
            {/* </AppBar> */}
            <TabPanel currentValue={selectedTab} value={detailsTab}>
                <Typography variant="h5" gutterBottom className={classes.technical}>
                    { showSkeleton ? <Skeleton width="80%" /> : asset.assetDescription}
                </Typography>
                {
                    showSkeleton ? (
                        [1, 2, 3].map(() => (
                            <Skeleton style={{ display: 'inline-block' }} width="4rem" className={classes.chips} />
                        ))
                    ) : (
                        assetChips.map((chip) => (
                            <Chip
                                className={classes.chips}
                                key={chip.value}
                                label={chip.value}
                                variant="outlined"
                                size="small"
                            />
                        ))
                    )
                }
                {
                    !showBasic && !showSkeleton ? null : (
                        <Grid container wrap="wrap" spacing={2} className={classes.technical}>
                            <Detail xs={6} label="FILE_NAME" breakWord loading={showSkeleton}>
                                {asset.name}
                            </Detail>
                            <Detail xs={3} label="MASTER_CODE" loading={showSkeleton}>
                                {asset.masterCode}
                            </Detail>
                            <Detail xs={3} label="SIZE" loading={showSkeleton}>
                                {asset.fileSizes}
                            </Detail>
                            <Detail xs={3} label="IMPORT" loading={showSkeleton}>
                                {
                                    asset.importDate && <FormattedDate value={asset.importDate} timeZone="UTC" />
                                }
                            </Detail>
                            <Detail xs={12} label="NOTES" loading={showSkeleton}>
                                {asset.externalNote}
                            </Detail>
                        </Grid>
                    )
                }

                {
                    !showLanguage || showSkeleton ? null : (
                        <div className={classes.technical}>
                            <Typography variant="h6" gutterBottom>
                                <FormattedMessage id="LANGUAGES" />
                            </Typography>
                            <Grid container wrap="wrap" spacing={2} className={classes.lang}>
                                <Detail xs={3} label="IMGTITLELANG">
                                    {asset.localeMainTitle}
                                </Detail>
                                <Detail xs={3} label="IMGSTLANG">
                                    {asset.localeSubtitles}
                                </Detail>
                                <Detail xs={3} label="IMGOPENCREDITLANG">
                                    {asset.localeOpeningCredits}
                                </Detail>
                                <Detail xs={3} label="IMGENDCREDITLANG">
                                    {asset.localeEndCredits}
                                </Detail>
                            </Grid>
                        </div>
                    )
                }
                {
                    !showTechnical || showSkeleton ? null : (
                        <div className={classes.technical}>
                            <Typography variant="h6" gutterBottom>
                                <FormattedMessage id="VIDEO" />
                            </Typography>
                            <Grid container wrap="wrap" spacing={2}>
                                <Detail xs={6} label="CODEC">
                                    {asset.codec}
                                </Detail>
                                <Detail xs={6} label="FORMAT">
                                    {asset.format}
                                </Detail>
                                <Detail xs={3} label="FPS">
                                    {asset.fps}
                                </Detail>
                                <Detail xs={3} label="SIZE">
                                    {asset.size}
                                </Detail>
                                <Detail xs={3} label="ASPECT_RATIO">
                                    {asset.aspectRatio}
                                </Detail>
                                <Detail xs={6} label="TIMECODE">
                                    {asset.startTimeCode && asset.endTimeCode && `${asset.startTimeCode} - ${asset.endTimeCode}`}
                                </Detail>
                            </Grid>
                        </div>
                    )
                }
                {
                    !showAudio || showSkeleton ? null : (
                        <div className={classes.technical}>
                            <Typography variant="h6" gutterBottom>
                                <FormattedMessage id="AUDIO" />
                            </Typography>
                            <Grid container wrap="wrap" spacing={2}>
                                <Detail label="FORMAT" xs={12}>
                                    {asset.audioFormat}
                                </Detail>
                                {isEmpty(audio) ? null : (
                                    <Detail label="TRACKS" xs={12}>
                                        {audio.map(({ contentType, audioType, lang }) => (
                                            <>
                                                <Typography gutterBottom>
                                                    <span className={classes.chips}>
                                                        {contentType}
                                                    </span>
                                                    {

                                                        !audioType ? null : (
                                                            <Chip
                                                                className={classes.chips}
                                                                label={audioType}
                                                                variant="outlined"
                                                                size="small"
                                                            />
                                                        )
                                                    }
                                                    {
                                                        !lang ? null : (
                                                            <Chip
                                                                className={classes.chips}
                                                                label={lang}
                                                                variant="outlined"
                                                                size="small"
                                                            />
                                                        )
                                                    }
                                                </Typography>

                                            </>
                                        ))}
                                    </Detail>
                                )}
                            </Grid>
                        </div>
                    )
                }
                {
                    isEmpty(asset.packageAssets) || !showSkeleton ? null : (
                        <div className={classes.technical}>
                            <Typography variant="h6" gutterBottom>
                                <FormattedMessage id="PACKAGE_ASSETS" />
                            </Typography>
                            <Grid container wrap="wrap" spacing={2}>
                                <Detail xs={12}>
                                    {asset.packageAssets.map(({ descriptionPart1, descriptionPart2, status }) => (
                                        <Typography gutterBottom>
                                            <span className={classes.chips}>
                                                {descriptionPart1 || descriptionPart2}
                                                {' '}
                                                <Chip
                                                    className={classes.chips}
                                                    label={status}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </span>
                                        </Typography>
                                    ))}
                                </Detail>
                            </Grid>
                        </div>
                    )
                }
            </TabPanel>
            <TabPanel currentValue={selectedTab} className={classes.tabPanel} value={orderTab}>
                {
                    isEmpty(orders) ? null : (
                        <div className={classes.technical}>
                        
                            <Grid container wrap="wrap" spacing={2}>
                                <Orders
                                    clientId={client}
                                    // filters={filters}
                                    setPage={handlePageChange}
                                    page={page}
                                    fetchPage={fetchAsset}
                                    pageRows={orders}
                                    totalRowCount={5}
                                    isLoading={fetchingAsset}
                                    order={order}
                                    setOrder={setOrder}
                                    orderBy={orderBy}
                                    setOrderBy={setOrderBy}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                />
                            </Grid>
                    </div>
                    )
                }
        </TabPanel>
        <TabPanel currentValue={selectedTab} value={discussionsTab}>
                        <Dialog
                        fullWidth
                        maxWidth="lg"
                        open={!!discussionId || isOpen}
                        className={classes.dialogDiscussion}
                        >
                            <Discussion
                                relationId={assetId}
                                relation="asset"
                                client={client}
                                defaultEnableCreation={createDiscussionEnabled}
                                className={classes.discussion}
                                disucssionType='asset'
                            />
                        <DialogActions>
                            <Button
                                onClick={closeDialog}
                                color="primary"
                            >
                                <FormattedMessage id="CLOSE" />
                            </Button>
                        </DialogActions>
                    </Dialog>
                    
                        <div className={classes.disucssions}>
                                <DiscussionList
                                    // handleDrawerToggle={handleDrawerToggle}
                                    enableCreateDiscussion={enableCreateDiscussion}
                                    removeCreateDiscussionButton={true}
                                    relationId={assetId}
                                    relation='asset'
                                    setActive={setDiscussionId}
                                    activeId={discussionId}
                                    className={classes.disucssions}
                                    isLoading={fetchingAsset}
                                    cancelRefreshList={cancelRefreshList}
                                    refreshList={refreshDiscussion}
                                    // includeRelationDetails={includeRelationDetails}
                                />
                        </div>
                 
        </TabPanel>
        <TabPanel currentValue={selectedTab} value={addDiscussionsTab}>
                        <Dialog
                        fullWidth
                        maxWidth="lg"
                        open={!!discussionId || isOpen}
                        className={classes.dialogDiscussion}
                        >
                            <Discussion
                                relationId={assetId}
                                relation="asset"
                                client={client}
                                defaultEnableCreation={createDiscussionEnabled}
                                className={classes.discussion}
                                disucssionType='asset'
                            />
                        <DialogActions>
                            <Button
                                onClick={closeDialog}
                                color="primary"
                            >
                                <FormattedMessage id="CLOSE" />
                            </Button>
                        </DialogActions>
                    </Dialog>
        </TabPanel>
        </div>
    );
}

export default AssetDetails;
